import { MetricName, RiskLevel, getRiskWeight } from "@cur8/measurements";
import { Patient } from "@cur8/rich-entity";
import { Result } from "lib/doctor-scribe/types";
import { refineRecord } from "./refine";
import { getResultRecord, remapName } from "./resultHelpers";
import { PatientVisitMetrics } from "render/hooks/patient/usePatientVisitsMetrics";
import { RiskMetric } from "lib/metric";
import { APITypesV2 } from "@cur8/api-client";

function isDefined<T>(value: T | undefined): value is T {
  return value != null;
}

function flattenVisitMetrics(data: PatientVisitMetrics, depth: number) {
  const result: RiskMetric[][] = [];

  for (let i = 0; i <= depth; i++) {
    const cholesterol = data.bloodwork.cholesterol?.at(i);
    const crp = data.bloodwork.crp?.at(i);
    const glucose = data.bloodwork.glucose?.at(i);
    const haemoglobin = data.bloodwork.haemoglobin?.at(i);
    const hba1c = data.bloodwork.hba1c?.at(i);
    const hdl = data.bloodwork.hdl?.at(i);
    const ldl = data.bloodwork.ldl?.at(i);
    const nonHDL = data.bloodwork.nonHDL?.at(i);
    const triglycerides = data.bloodwork.triglycerides?.at(i);
    const basophils = data.bloodwork.basophils?.at(i);
    const eosinophils = data.bloodwork.eosinophils?.at(i);
    const lymphocytes = data.bloodwork.lymphocytes?.at(i);
    const neutrophils = data.bloodwork.neutrophils?.at(i);
    const bmi = data.body.bmi?.at(i);
    const whiteBloodCells = data.bloodwork.whiteBloodCells?.at(i);
    const gripStrengthLeft = data.body.gripStrength.left?.at(i);
    const gripStrengthRight = data.body.gripStrength.right?.at(i);
    const height = data.body.height?.at(i);
    const waist = data.body.waist?.at(i);
    const weight = data.body.weight?.at(i);
    const anklePressureLeft = data.cardio.anklePressure.left?.at(i);
    const anklePressureRight = data.cardio.anklePressure.right?.at(i);
    const brachialPressureLeft = data.cardio.brachialPressure.left?.at(i);
    const brachialPressureRight = data.cardio.brachialPressure.right?.at(i);
    const heartRate = data.cardio.heartRate?.at(i);
    const oxygenSaturation = data.cardio.bloodOxygen?.at(i);
    const respiratoryRate = data.cardio.respiratoryRate?.at(i);
    const toePressureLeft = data.cardio.toePressure.left?.at(i);
    const toePressureRight = data.cardio.toePressure.right?.at(i);
    const hearFailure = data.risk.congestiveHeartFailureHistory?.at(i);
    const diabetesHistory = data.risk.diabetesHistory?.at(i);
    const drugInducedBleeding = data.risk.drugInducedBleeding?.at(i);
    const ecgDiscrepancy = data.risk.ecgDiscrepancy?.at(i);
    const eyePressureLeft = data.body.eyePressure.left?.at(i);
    const eyePressureRight = data.body.eyePressure.left?.at(i);
    const wearsContactLenses = data.body.eyePressure.wearsContactLenses?.at(i);
    const hypertensionHistory = data.risk.hypertensionHistory?.at(i);
    const kidneyDisease = data.risk.kidneyDisease?.at(i);
    const labileINR = data.risk.labileINR?.at(i);
    const liverDisease = data.risk.liverDisease?.at(i);
    const majorBleedingHistory = data.risk.majorBleedingHistory?.at(i);
    const strokeHistory = data.risk.strokeHistory?.at(i);
    const thromboembolismHistory = data.risk.thromboembolismHistory?.at(i);
    const TIAHistory = data.risk.TIAHistory?.at(i);
    const vascularDiseaseHistory = data.risk.vascularDiseaseHistory?.at(i);

    const metric = [
      cholesterol,
      crp,
      glucose,
      haemoglobin,
      hba1c,
      hdl,
      ldl,
      nonHDL,
      triglycerides,
      basophils,
      eosinophils,
      lymphocytes,
      neutrophils,
      whiteBloodCells,
      gripStrengthLeft,
      gripStrengthRight,
      height,
      waist,
      weight,
      anklePressureLeft,
      anklePressureRight,
      brachialPressureLeft,
      brachialPressureRight,
      heartRate,
      oxygenSaturation,
      respiratoryRate,
      toePressureLeft,
      toePressureRight,
      bmi,
      hearFailure,
      diabetesHistory,
      drugInducedBleeding,
      ecgDiscrepancy,
      eyePressureLeft,
      eyePressureRight,
      wearsContactLenses,
      hypertensionHistory,
      kidneyDisease,
      labileINR,
      liverDisease,
      majorBleedingHistory,
      strokeHistory,
      thromboembolismHistory,
      vascularDiseaseHistory,
      TIAHistory,
    ].filter(isDefined);

    result.push(metric);
  }

  return result;
}

function toResultAdapter(riskMetrics: RiskMetric[][]) {
  const result = new Map<string, Result>();

  const findRisk = <M extends MetricName>(riskMetric: RiskMetric<M>) => {
    const riskRanges = riskMetric.riskRanges;
    if (riskRanges == null) {
      return RiskLevel.Unknown;
    }
    if ("riskLevel" in riskRanges) {
      return riskRanges.riskLevel;
    }
    if ("diastolic" in riskRanges && "systolic" in riskRanges) {
      return [
        riskRanges.diastolic.riskLevel,
        riskRanges.systolic.riskLevel,
      ].sort((a, b) => getRiskWeight(b) - getRiskWeight(a))[0];
    }
    return RiskLevel.Unknown;
  };

  riskMetrics.forEach((visitRiskMetrics) => {
    visitRiskMetrics.forEach((riskMetric) => {
      const name = remapName(riskMetric.name);
      const entity: Result = result.get(name) ?? { name, measurements: [] };

      entity.measurements.push({
        timestamp: riskMetric.measurement.timestampStart.toISODate(),
        value: riskMetric.unit,
        deviation: undefined,
        risk: findRisk(riskMetric),
      });

      result.set(name, entity);
    });
  });

  return Array.from(result).map(([_, value]) => value);
}

export function createMergedRecord({
  visitMetrics,
  patient,
  visitCount,
  questionnaire,
}: {
  patient: Patient;
  visitCount: number;
  visitMetrics: PatientVisitMetrics;
  questionnaire?: APITypesV2.QuestionnaireResponse;
}) {
  const metricsFlatten = flattenVisitMetrics(visitMetrics, visitCount);
  const list = toResultAdapter(metricsFlatten);
  const resultRecord = getResultRecord(list);

  return refineRecord(resultRecord, patient, questionnaire);
}
