import { RiskLevel } from "@cur8/measurements";
import { RiskMetric } from "lib/metric";
import { useMemo } from "react";
import {
  AuxTitle,
  Diff,
  DiffValue,
  MainTitle,
  MetricResultHeader,
  Subtitle,
  Titles,
} from "render/ui/presentation/MetricResultHeader";
import {
  RangeChart,
  RangeChartDataComposer,
} from "render/ui/presentation/RangeChart";
import {
  selectChartRanges,
  toChartRanges,
} from "render/ui/presentation/RangeChart/lib/risk";
import { getTitlesForValues } from "./getTitlesForValues";
import styles from "./styles.module.sass";

interface HbA1cSegmentProps {
  metrics: RiskMetric<"bloodwork.hba1c">[];
}

export function HbA1cSegment({ metrics }: HbA1cSegmentProps) {
  const latestMetric = metrics.at(0);
  const currentValue = latestMetric?.unit["mmol/mol"];

  const prevMetric = metrics.length > 1 ? metrics[1] : undefined;
  const prevValue = prevMetric?.unit["mmol/mol"];

  const valuesDiff =
    prevValue && currentValue ? currentValue - prevValue : undefined;

  // https://dev.azure.com/cur8/Engineering/_workitems/edit/26137
  const ranges = useMemo(() => {
    const riskIntervals = latestMetric?.riskRanges.riskIntervals ?? [];
    const chartRanges = toChartRanges(riskIntervals);

    return selectChartRanges(chartRanges, {
      mode: "static",
      startAt: RiskLevel.Optimal,
      endAt: RiskLevel.HighRisk,
    });
  }, [latestMetric?.riskRanges.riskIntervals]);

  // https://dev.azure.com/cur8/Engineering/_workitems/edit/26137
  const rangesCapLow = 30;
  const rangesCapHigh = 50;

  const titles = getTitlesForValues(currentValue, prevValue);

  return (
    <div className={styles.HbA1cSegment}>
      <MetricResultHeader>
        <Titles>
          <MainTitle>{titles.title}</MainTitle>
          {titles.subtitle ? <Subtitle>{titles.subtitle}</Subtitle> : null}
          {titles.aux ? <AuxTitle>{titles.aux}</AuxTitle> : null}
        </Titles>
        <Diff>
          <DiffValue
            valuesDiff={valuesDiff}
            unit="mmol/mol"
            formatValue={(value) => value?.toFixed(1)}
          />
        </Diff>
      </MetricResultHeader>
      <RangeChartDataComposer
        ranges={ranges}
        rangesCapLow={rangesCapLow}
        rangesCapHigh={rangesCapHigh}
        value={currentValue}
        previousValue={prevValue}
      >
        {({ ranges, values }) => (
          <RangeChart ranges={ranges} values={values} showEmpty />
        )}
      </RangeChartDataComposer>
    </div>
  );
}
