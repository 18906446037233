import { APITypesV1 } from "@cur8/api-client";
import { Patient } from "@cur8/rich-entity";
import * as HeartAgeLib from "lib/heart-age";
import { mapLinear } from "lib/math";
import { zeroPad } from "lib/number";
import { useMemo, useState } from "react";
import { useAge } from "render/hooks/patient/useAge";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import { Typography } from "render/ui/presentation/Typography";
import InfoIcon from "./assets/info.svg?react";
import { HeartAgeGraph } from "./components/HeartAgeGraph";
import { RollingNumber } from "./components/RollingNumber";
import { useHeartAge } from "./hooks/useHeartAge";
import styles from "./styles.module.sass";

export type HeartAgeSectionState = "locked" | "idle" | "active";

interface HeartAgeSectionProps {
  patient: Patient;
}

export function HeartAgeSection({ patient }: HeartAgeSectionProps) {
  const age = useAge(patient);
  const { data: heartAgePrediction, error } = useHeartAge(patient);
  const { lifestyle } = usePatientData();

  const [isSectionReadyToReveal, setIsSectionReadyToReveal] = useState(false);

  const state = useMemo(() => {
    if (heartAgePrediction.score == null) {
      return "locked";
    } else if (isSectionReadyToReveal) {
      return "active";
    } else {
      return "idle";
    }
  }, [isSectionReadyToReveal, heartAgePrediction.score]);

  const labels: {
    heartAge: string;
    unit: string;
    lockedTitle?: string;
    lockedReason?: string;
  } = useMemo(() => {
    if (error || heartAgePrediction.error) {
      if (
        heartAgePrediction.error ===
        APITypesV1.ReasonInvalid.ALREADY_HAD_A_CVD_EVENT
      ) {
        return {
          heartAge: "00",
          unit: "years",
          lockedTitle: "N/A",
          lockedReason: "Previous CVD",
        };
      }

      if (
        heartAgePrediction.error ===
          APITypesV1.ReasonInvalid.AGE_OUT_OF_RANGE &&
        age < HeartAgeLib.AgeInputBounds.min
      ) {
        return {
          heartAge: `<${HeartAgeLib.AgeInputBounds.min}`,
          unit: "years",
          lockedTitle: "N/A",
          lockedReason: `Unlock module at age ${HeartAgeLib.AgeInputBounds.min}`,
        };
      }

      if (
        heartAgePrediction.error ===
          APITypesV1.ReasonInvalid.AGE_OUT_OF_RANGE &&
        age > HeartAgeLib.AgeInputBounds.max
      ) {
        return {
          heartAge: `>${HeartAgeLib.AgeInputBounds.max}`,
          unit: "years",
          lockedTitle: "N/A",
          lockedReason: `Prediction not possible`,
        };
      }

      return {
        heartAge: "00",
        unit: "years",
        lockedTitle: "N/A",
        lockedReason: "Prediction failed",
      };
    }

    if (heartAgePrediction.score != null) {
      return {
        heartAge: heartAgePrediction.score.toString(),
        unit: "years",
      };
    }

    return {
      heartAge: "00",
      unit: "years",
      lockedTitle: "N/A",
      lockedReason: "Information missing",
    };
  }, [age, error, heartAgePrediction.score, heartAgePrediction.error]);

  const riskFactors: string = useMemo(() => {
    if (!heartAgePrediction.input) {
      return "";
    }

    const list = [];

    if (heartAgePrediction.input.cvd) {
      list.push(lifestyle?.cardioConditions);
    }

    if (heartAgePrediction.input.chronicRenalDisease) {
      list.push("Kidney disease");
    }

    if (heartAgePrediction.input.diabetesStatus !== "None") {
      list.push("Diabetes");
    }

    if (heartAgePrediction.input.atrialFibrillation) {
      list.push("Afib");
    }

    return list.filter(Boolean).join(", ");
  }, [heartAgePrediction.input, lifestyle]);

  const animationConfig = useMemo(() => {
    if (age == null || heartAgePrediction.score == null) {
      return undefined;
    }

    return getAnimationConfig({ age, heartAge: heartAgePrediction.score });
  }, [heartAgePrediction.score, age]);

  return (
    <div
      className={styles.HeartAgeSection}
      data-state={state}
      onClick={() => setIsSectionReadyToReveal(true)}
    >
      <div className={styles.content}>
        <div className={styles.header}>
          <Typography variant="title-s">Heart age</Typography>
          {riskFactors && (
            <div className={styles.riskFactors}>
              <InfoIcon />
              <Typography variant="body-s">{riskFactors}</Typography>
            </div>
          )}
        </div>
        <div className={styles.main}>
          <div className={styles.number}>
            <Typography variant="numeral-xl">
              {state === "locked" || heartAgePrediction.score == null ? (
                labels.heartAge
              ) : (
                <RollingNumber
                  value={state === "active" ? heartAgePrediction.score : 0}
                  formatValue={(value) => zeroPad(Math.abs(value))}
                  animationDuration={animationConfig?.numberRollDuration()}
                  animationEase={animationConfig?.numberRollEase()}
                />
              )}
            </Typography>
          </div>
          <div className={styles.unit}>
            <Typography variant="label-m">{labels.unit}</Typography>
          </div>
        </div>
        {state === "locked" && (
          <div className={styles.sub}>
            <Typography variant="body-m">{labels.lockedTitle}</Typography>
            <hr />
            <Typography variant="body-m">{labels.lockedReason}</Typography>
          </div>
        )}
      </div>
      <div className={styles.graph}>
        <HeartAgeGraph
          age={age}
          heartAge={heartAgePrediction.score}
          state={state}
          bounds={{ min: -10, max: 10 }}
          dialAnimation={{
            duration: animationConfig?.dialAnimationDuration() ?? 0,
            delay: animationConfig?.dialAnimationDelay() ?? 0,
          }}
          ageDiffTextReveal={{
            duration: animationConfig?.ageDiffTextRevealDuration() ?? 0,
            delay: animationConfig?.ageDiffTextRevealDelay() ?? 0,
          }}
        />
      </div>
    </div>
  );
}

/**
 * Animation config for HeartAgeSection.
 *
 * The animation timeline consists of three parts:
 * * 1. number roll animation: Rolls a number from 00 to the predicted heart age (e.g. "43"). Triggered by doctor clicking on the heart age section.
 * * 2. dial animation: Reflects the difference between the predicted heart age and the patient's age, consists of:
 *  - Movement of the age difference marker along the dial
 *  - Change of the dial bar color to reflect the +/- difference (orange for older, blue for younger).
 *  - Change of the dial labels color (Younger/Older) to reflect the +/- difference.
 * * 3. age difference number reveal: Reveal of the age difference text (e.g. "+2 years", "Aligned").
 *
 * Animation timeline:
 * | Doctor clicks on the heart age section, number roll animation starts
 * |
 * | ... numbers are rolling towards the predicted heart age...
 * |
 * | While the number roll animation slowly eases to a stop, the dial rotation + age diff text reveal plays out
 * |
 * | Number roll animation stops, dial rotation + age diff text reveal finishes, the heart age section is fully revealed
 */
const getAnimationConfig = ({
  age,
  heartAge,
}: {
  age: number;
  heartAge: number;
}) => {
  const ageDiff = Math.abs(heartAge - age);

  const config = {
    numberRollDuration: () => {
      const duration = mapLinear(
        heartAge,
        HeartAgeLib.AgeInputBounds.min,
        50,
        3.2,
        3.6,
        { clamp: true }
      );

      return parseFloat(duration.toFixed(1));
    },
    numberRollEase: () => {
      return "cubic-bezier(0.35, 0, 0.15, 1)" as const;
    },
    dialAnimationDuration: () => {
      if (ageDiff === 0) {
        return 1;
      }

      const duration = mapLinear(ageDiff, 1, 10, 1.5, 2, { clamp: true });

      return parseFloat(duration.toFixed(1));
    },
    dialAnimationDelay: () => {
      return (
        config.numberRollDuration() - config.dialAnimationDuration() * 0.85
      );
    },
    ageDiffTextRevealDuration: () => 0.9,
    ageDiffTextRevealDelay: () => {
      // When the age difference is 0, trigger the result reveal animation close to the end of the number roll animation.
      // Otherwise sync the result reveal animation with the end of the dial animation
      if (ageDiff === 0) {
        return (
          config.numberRollDuration() - config.ageDiffTextRevealDuration() * 0.4
        );
      }

      return (
        config.dialAnimationDelay() +
        config.dialAnimationDuration() -
        config.ageDiffTextRevealDuration()
      );
    },
  };

  return config;
};
