function extraSign(value: number) {
  return value > 0 ? "+" : "";
}

export function truncate(value: number, decimalPlaces: number) {
  // Why are we truncating instead of rounding?? https://dev.azure.com/cur8/TheRigg/_workitems/edit/23804
  return Math.floor(value * 10 ** decimalPlaces) / 10 ** decimalPlaces;
}

export function toFixedWithSign(value: number) {
  return value ? extraSign(value) + value.toFixed(1) : "0";
}
