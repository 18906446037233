import { useEffect } from "react";
import { useAppInsights } from "render/context/AppInsightsContext";

interface TimeSpentTrackerProps {
  page: string;
  label: string;
}

export function TimeSpentTracker({ page, label }: TimeSpentTrackerProps) {
  const appInsights = useAppInsights();

  /**
   * Log time spent on different sections of the page
   */
  useEffect(() => {
    const name = `TimeSpent_${page}_${label}`;
    appInsights.startTrackPage(name);
    return () => {
      appInsights.stopTrackPage(name);
    };
  }, [appInsights, label, page]);

  return <></>;
}
