import { convertQuestionnaire } from "lib/questionnaire/convert";
import { Country, getCountryFromVisit } from "lib/visit/visit";
import { createContext, ReactNode, useContext, useMemo } from "react";
import { usePatient } from "render/hooks/api/usePatient";
import { usePatientImmutableScans } from "render/hooks/api/usePatientImmutableScans";
import { usePatientSMPLMetrics } from "render/hooks/api/usePatientSMPLMetrics";
import { useQuestionnaire } from "render/hooks/api/useQuestionnaire";
import { usePatientVisitsMetrics } from "render/hooks/patient/usePatientVisitsMetrics";
import { useVisitHistoryQuery } from "render/hooks/queries/useVisitHistoryQuery";
import { useVisitQuery } from "render/hooks/queries/useVisitQuery";
import { useLesions } from "./hooks/lesions";
import { usePatientVisits } from "render/hooks/api/usePatientVisits";

function useDashboardData(patientId: string) {
  const patient = usePatient(patientId);

  const visitHistoryQuery = useVisitHistoryQuery({ patientId });

  const latestVisitQuery = useVisitQuery({
    patientId,
    visitId:
      visitHistoryQuery.data?.medicalExamVisitTimeSeries.nekoBodyScan?.at(0)
        ?.visitId,
  });

  const pastThreeAttendedBodyScanVisitIds = useMemo(() => {
    return visitHistoryQuery.data?.medicalExamVisitTimeSeries.nekoBodyScan
      ?.slice(0, 3)
      .map(({ visitId }) => visitId);
  }, [visitHistoryQuery.data?.medicalExamVisitTimeSeries.nekoBodyScan]);

  const attendedVisits = usePatientVisits(
    patientId,
    pastThreeAttendedBodyScanVisitIds
  );

  const visitMetrics = usePatientVisitsMetrics(patientId, attendedVisits);

  const scans = usePatientImmutableScans(patientId);

  const metrics = visitMetrics;
  const questionnaire = useQuestionnaire({ patientId, visitId: undefined });
  const lifestyle = useMemo(() => {
    if (questionnaire.data) {
      return convertQuestionnaire(questionnaire.data);
    }
  }, [questionnaire.data]);

  const smplResult = usePatientSMPLMetrics(scans?.skin);

  const lesionsResult = useLesions(scans?.skin);

  const country = useMemo(() => {
    if (latestVisitQuery.isLoading) {
      return undefined;
    }

    if (latestVisitQuery.data == null) {
      return Country.Unknown;
    }

    return getCountryFromVisit(latestVisitQuery.data);
  }, [latestVisitQuery.data, latestVisitQuery.isLoading]);

  return {
    skin: {
      lesions: lesionsResult?.data,
    },
    country,
    patientId,
    patient,
    scans,
    metrics,
    lifestyle,
    questionnaire,
    smpl: {
      metrics: smplResult?.data,
    },
  };
}

type PatientContextValue = ReturnType<typeof useDashboardData>;

const Context = createContext<PatientContextValue | null>(null);

interface PatientDataContextProps {
  patientId: string;
  children: ReactNode;
}

export function PatientDataContext({
  patientId,
  children,
}: PatientDataContextProps) {
  const value = useDashboardData(patientId);

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export function usePatientData() {
  const context = useContext(Context);
  if (!context) {
    throw new Error("usePatientData without PatientContext");
  }
  return context;
}
