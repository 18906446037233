import { Side } from "lib/api/types";
import { createPositionTween, createRotationTween } from "lib/tween";
import * as THREE from "three";

export class PodiumState {
  pos = new THREE.Vector3();
  angle = new THREE.Euler();

  clone() {
    const next = new PodiumState();
    next.pos.copy(this.pos);
    next.angle.copy(this.angle);
    return next;
  }
}

function createTweens(podium: THREE.Object3D) {
  const config = {
    friction: 97.4,
    mass: 711.2,
    stiffness: 292.2,
    precision: 0.01,
  };

  return {
    rotation: createRotationTween(podium, config),
    position: createPositionTween(podium, config),
  };
}

export function getSide(podium: AvatarPodium): Side {
  const lap = Math.PI * 2;
  const rotation = podium.rotation.z;
  const absRotation = (rotation % lap) / lap;
  return absRotation > 0.25 && absRotation < 0.75 ? "back" : "front";
}

export class AvatarPodium extends THREE.Group {
  tweens = createTweens(this);

  setTo(state: PodiumState) {
    this.position.copy(state.pos);
    this.rotation.copy(state.angle);

    this.tweens.position.set(state.pos);
    this.tweens.rotation.set(state.angle);
  }

  moveTo(state: PodiumState) {
    this.tweens.position.to(state.pos);
    this.tweens.rotation.to(state.angle);
  }

  update(deltaTime: number) {
    this.tweens.position.update(deltaTime);
    this.tweens.rotation.update(deltaTime);
  }
}

const Front = new PodiumState();
Front.angle.set(0, 0, 0);

const Back = new PodiumState();
Back.angle.set(0, 0, Math.PI);

const ProfileFront = new PodiumState();
ProfileFront.angle.set(0, 0, 0.5);

const ProfileBack = new PodiumState();
ProfileBack.angle.set(0, 0, Math.PI + 0.5);

export const PodiumView = {
  Front,
  Back,
  ProfileFront,
  ProfileBack,
};
