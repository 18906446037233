import { CholesterolItem } from "./components/Cholesterol";
import { LDLItem } from "./components/LDL/LDL";
import styles from "./styles.module.sass";

export function BloodFatsRightSection() {
  return (
    <div className={styles.BloodFatsSection}>
      <div className={styles.items}>
        <CholesterolItem />
        <LDLItem />
      </div>
    </div>
  );
}
