import { RiskLabel, RiskLevel } from "@cur8/measurements";
import { clamp } from "lib/math";
import { sortBy } from "lib/sort";
import { ReactElement, useMemo } from "react";
import {
  groomDecilmasIfNecessary,
  isHigherOrEqualThanRange,
  isLowerOrEqualThanRange,
} from "./lib/util";
import { ChartRange, MarkerData, RangeData, Variant } from "./types";

export const LabelMap: Record<RiskLabel, string> = {
  elevated: "Elevated",
  diabetes: "Diabetes",
  high: "High",
  low: "Low",
  mild: "Mild",
  moderate: "Moderate",
  normal: "Normal",
  optimal: "Optimal",
  "pre-diabetes": "Pre-Diabetes",
  "very high": "Very High",
  "very low": "Very Low",
  undefined: "Unknown",
  balanced: "Balanced",
  prolonged: "Prolonged",
  unbalanced: "Unbalanced",
  "left deviation": "Left Deviation",
  "very unbalanced": "Very Unbalanced",
  shortened: "Shortened",
  "right deviation": "Right Deviation",
};

const VariantMap: Record<RiskLevel, Variant> = {
  [RiskLevel.Unknown]: "unknown",
  [RiskLevel.Optimal]: "normal",
  [RiskLevel.Normal]: "normal",
  [RiskLevel.LowRisk]: "warning",
  [RiskLevel.Risk]: "warning",
  [RiskLevel.ModerateRisk]: "danger",
  [RiskLevel.HighRisk]: "danger",
  [RiskLevel.ImmediateRisk]: "danger",
};

interface ChildrenData {
  values: MarkerData[];
  ranges: RangeData[];
}

interface RangeChartDataComposerProps {
  ranges: ChartRange[];
  rangesCapLow?: number;
  rangesCapHigh?: number;
  value?: number;
  previousValue?: number;
  formatValue?: (value?: number) => string | number | undefined;
  children: (data: ChildrenData) => ReactElement;
}

export function RangeChartDataComposer({
  ranges,
  rangesCapLow,
  rangesCapHigh,
  children,
  value,
  previousValue,
  formatValue = groomDecilmasIfNecessary,
}: RangeChartDataComposerProps) {
  const rangesSortedAndCapped = ranges
    .toSorted(byChartRangeTo("asc"))
    .map((range, i, ranges) => {
      const isLowestRange = i === 0;
      const isHighestRange = i === ranges.length - 1;

      if (rangesCapLow && isLowestRange) {
        // cap lowest range but prevent squishing
        const desiredRangeFrom = Math.min(rangesCapLow, range.to * 0.9);
        range.from = Math.max(range.from, desiredRangeFrom);
      }

      if (rangesCapHigh && isHighestRange) {
        // cap highest range but prevent squishing
        const desiredRangeTo = Math.max(rangesCapHigh, range.from * 1.1);
        range.to = Math.min(range.to, desiredRangeTo);
      }

      // cap from infinity
      if (!isFinite(range.from)) {
        range.from = range.to * 0.9;
      }

      // cap to infinity
      if (!isFinite(range.to)) {
        range.to = range.from * 1.1;
      }

      return range;
    });

  const max = Math.max(...rangesSortedAndCapped.map((range) => range.to));
  const min = Math.min(...rangesSortedAndCapped.map((range) => range.from));
  const middleRange = rangesSortedAndCapped[Math.floor(ranges.length / 2)];

  const rangeData: RangeData[] = rangesSortedAndCapped.map((range) => ({
    fromIsInclusive: range.fromIsInclusive,
    from: range.from,
    toIsInclusive: range.toIsInclusive,
    to: range.to,
    label: LabelMap[range.label] ?? "-",
    variant: VariantMap[range.risk],
    leftLabel: isHigherOrEqualThanRange(range.from, middleRange)
      ? range.from
      : undefined,
    rightLabel: isLowerOrEqualThanRange(range.to, middleRange)
      ? range.to
      : undefined,
    width: Math.floor(((range.to - range.from) / (max - min)) * 100),
  }));

  const values: MarkerData[] = useMemo(() => {
    if (value === undefined) {
      return [];
    }

    const areValuesTheSame = previousValue === value;
    const valueMaker: MarkerData = {
      value: clamp(value, min, max),
      label: formatValue(value),
      variant: areValuesTheSame ? "primary-outlined" : "primary",
    };

    const previousValueMaker: MarkerData | undefined =
      previousValue && !areValuesTheSame
        ? {
            value: clamp(previousValue, min, max),
            variant: "outlined",
          }
        : undefined;

    return [previousValueMaker, valueMaker].filter(
      (value): value is MarkerData => value !== undefined
    );
  }, [value, previousValue, min, max, formatValue]);

  return <>{children({ ranges: rangeData, values })}</>;
}

const byChartRangeTo = sortBy<ChartRange>((chartRange) => chartRange.to);
