import { MetricName } from "@cur8/measurements";
import { Measurement, fromAPI } from "@cur8/rich-entity";
import { toRiskMetric } from "lib/metric";
import { Country } from "lib/visit/visit";
import { useEffect, useMemo, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { PatientVisitMetrics } from "render/hooks/patient/usePatientVisitsMetrics";

export function useRiskMetric<M extends MetricName>(
  patientId: string,
  name: M,
  country: Country | undefined
) {
  const api = useAPIClient();
  const [measurements, setMeasurements] = useState<Measurement[]>();

  useEffect(() => {
    const req = api.measurement.queryMeasurements({
      patientId,
      metricName: name,
      pageSize: 1,
    });

    req.result
      .then((response) => response.items.map(fromAPI.toMeasurement))
      .then(setMeasurements);

    return () => {
      req.abandon();
    };
  }, [api, name, patientId]);

  const result = useMemo(() => {
    if (!measurements) {
      return undefined;
    }
    if (country == null) {
      return undefined;
    }
    return measurements.map((measurement) =>
      toRiskMetric(name, measurement, country)
    );
  }, [country, measurements, name]);

  return result;
}

export type PatientEncyclopediaMetrics = ReturnType<
  typeof usePatientEncyclopediaMetrics
>;

export function usePatientEncyclopediaMetrics(
  patientId: string,
  country: Country | undefined
): PatientVisitMetrics {
  const body = {
    height: useRiskMetric(patientId, "body.height", country),
    bmi: useRiskMetric(patientId, "body.bmi", country),
    weight: useRiskMetric(patientId, "body.weight", country),
    waist: useRiskMetric(patientId, "body.waist", country),
    eyePressure: {
      left: useRiskMetric(
        patientId,
        "risk_assessment.eye_pressure.left",
        country
      ),
      right: useRiskMetric(
        patientId,
        "risk_assessment.eye_pressure.right",
        country
      ),
      difference: useRiskMetric(
        patientId,
        "risk_assessment.eye_pressure.difference",
        country
      ),
      wearsContactLenses: useRiskMetric(
        patientId,
        "risk_assessment.eye_pressure.wears_contact_lenses",
        country
      ),
    },
    gripStrength: {
      left: useRiskMetric(patientId, "body.grip_strength.left", country),
      right: useRiskMetric(patientId, "body.grip_strength.right", country),
    },
  };

  const bloodwork = {
    cholesterol: useRiskMetric(patientId, "bloodwork.cholesterol", country),
    cholesterolHDL: useRiskMetric(
      patientId,
      "bloodwork.cholesterol_hdl",
      country
    ),
    crp: useRiskMetric(patientId, "bloodwork.crp", country),
    hba1c: useRiskMetric(patientId, "bloodwork.hba1c", country),
    hdl: useRiskMetric(patientId, "bloodwork.hdl", country),
    ldl: useRiskMetric(patientId, "bloodwork.ldl", country),
    nonHDL: useRiskMetric(patientId, "bloodwork.non_hdl", country),
    triglycerides: useRiskMetric(patientId, "bloodwork.triglycerides", country),
    haemoglobin: useRiskMetric(patientId, "bloodwork.haemoglobin", country),
    glucose: useRiskMetric(patientId, "bloodwork.glucose", country),
    whiteBloodCells: useRiskMetric(
      patientId,
      "bloodwork.white_blood_cells.total",
      country
    ),
    neutrophils: useRiskMetric(
      patientId,
      "bloodwork.white_blood_cells.neutrophils",
      country
    ),
    basophils: useRiskMetric(
      patientId,
      "bloodwork.white_blood_cells.basophils",
      country
    ),
    monocytes: useRiskMetric(
      patientId,
      "bloodwork.white_blood_cells.monocytes",
      country
    ),
    eosinophils: useRiskMetric(
      patientId,
      "bloodwork.white_blood_cells.eosinophils",
      country
    ),
    lymphocytes: useRiskMetric(
      patientId,
      "bloodwork.white_blood_cells.lymphocytes",
      country
    ),
  };

  const cardio = {
    bloodOxygen: useRiskMetric(patientId, "cardio.oxygen_saturation", country),
    heartRate: useRiskMetric(patientId, "cardio.ecg.heart_rate", country),
    respiratoryRate: useRiskMetric(
      patientId,
      "cardio.respiratory_rate",
      country
    ),
    anklePressure: {
      left: useRiskMetric(patientId, "cardio.ankle_pressure.left", country),
      right: useRiskMetric(patientId, "cardio.ankle_pressure.right", country),
    },
    brachialPressure: {
      left: useRiskMetric(patientId, "cardio.brachial_pressure.left", country),
      right: useRiskMetric(
        patientId,
        "cardio.brachial_pressure.right",
        country
      ),
      difference: useRiskMetric(
        patientId,
        "cardio.brachial_pressure.difference",
        country
      ),
    },
    toePressure: {
      left: useRiskMetric(patientId, "cardio.toe_pressure.left", country),
      right: useRiskMetric(patientId, "cardio.toe_pressure.right", country),
    },
    ecg: {
      p: useRiskMetric(patientId, "cardio.ecg.segments.p", country),
      pr: useRiskMetric(patientId, "cardio.ecg.segments.pr", country),
      qrs: useRiskMetric(patientId, "cardio.ecg.segments.qrs", country),
      qt: useRiskMetric(patientId, "cardio.ecg.segments.qt", country),
      qtc: useRiskMetric(patientId, "cardio.ecg.segments.qtc", country),
    },
    abi: {
      left: useRiskMetric(patientId, "cardio.abi.left", country),
      right: useRiskMetric(patientId, "cardio.abi.right", country),
    },
  };

  const risk = {
    congestiveHeartFailureHistory: useRiskMetric(
      patientId,
      "risk_assessment.congestive_heart_failure_history",
      country
    ),
    ecgDiscrepancy: useRiskMetric(
      patientId,
      "risk_assessment.ecg_discrepancy",
      country
    ),
    diabetesHistory: useRiskMetric(
      patientId,
      "risk_assessment.diabetes_history",
      country
    ),
    drugInducedBleeding: useRiskMetric(
      patientId,
      "risk_assessment.drug-induced_bleeding",
      country
    ),
    hypertensionHistory: useRiskMetric(
      patientId,
      "risk_assessment.hypertension_history",
      country
    ),
    kidneyDisease: useRiskMetric(
      patientId,
      "risk_assessment.kidney_disease",
      country
    ),
    labileINR: useRiskMetric(patientId, "risk_assessment.labile_inr", country),
    liverDisease: useRiskMetric(
      patientId,
      "risk_assessment.liver_disease",
      country
    ),
    majorBleedingHistory: useRiskMetric(
      patientId,
      "risk_assessment.major_bleeding_history",
      country
    ),
    strokeHistory: useRiskMetric(
      patientId,
      "risk_assessment.stroke_history",
      country
    ),
    thromboembolismHistory: useRiskMetric(
      patientId,
      "risk_assessment.thromboembolism_history",
      country
    ),
    TIAHistory: useRiskMetric(
      patientId,
      "risk_assessment.tia_history",
      country
    ),
    vascularDiseaseHistory: useRiskMetric(
      patientId,
      "risk_assessment.vascular_disease_history",
      country
    ),
  };

  return {
    body,
    bloodwork,
    risk,
    cardio,
  };
}
