import {
  FloatingValueTarget,
  RiskToHighlightMap,
} from "render/ui/presentation/FloatingValueTarget";
import { RiskMetric } from "lib/metric";
import { ValueItem } from "render/ui/symbol/ValueItem";
import { RiskLevel } from "@cur8/measurements";

interface LeftAnklePressureProps {
  anklePressure: RiskMetric<"cardio.ankle_pressure.left">[];
}

export function LeftAnklePressure({ anklePressure }: LeftAnklePressureProps) {
  const pressure = anklePressure.at(0);
  const risk = pressure?.riskRanges.riskLevel;
  const highlight = RiskToHighlightMap[risk ?? RiskLevel.Unknown];

  return (
    <FloatingValueTarget
      label="Left ankle"
      value={
        <ValueItem
          value={pressure ? pressure.unit.mmHg : "--"}
          unit="mmHg"
          size="huge"
          alignItems="baseline"
        />
      }
      width={200}
      highlight={highlight}
      align="bottom-right"
    />
  );
}
