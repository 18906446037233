import { PadAssessmentBox, Patient } from "@cur8/rich-entity";
import { useEffect } from "react";
import { Typography } from "render/ui/presentation/Typography";
import { useAssessmentContext } from "../../context/AssessmentContext";
import { useAnnotation } from "./hooks/useAnnotation";
import { PadPage } from "./PadPage";

interface PadAnnotationLoaderProps {
  patient: Patient;
}

export function PadAnnotationLoader({ patient }: PadAnnotationLoaderProps) {
  const { mutablePAD, selected, setInitialRoIs } = useAssessmentContext();
  const { forearm, hypothenar, loading } = useAnnotation(
    patient.patientId,
    selected.scanId,
    selected.scanVersion
  );

  useEffect(() => {
    if ((selected as PadAssessmentBox).regionsOfInterest) {
      return;
    }
    // no selected ROI, use from annotations
    if (forearm && hypothenar) {
      setInitialRoIs(
        forearm.annotation.data.rect,
        hypothenar.annotation.data.rect
      );
      console.debug("Loading RoI:s from annotation");
    }
  }, [forearm, hypothenar, selected, setInitialRoIs]);

  if (loading) {
    return <Typography variant="title-m">Fetching data...</Typography>;
  }

  if (!forearm || !hypothenar) {
    return (
      <>
        <div>
          <Typography variant="title-m">Failed to load data</Typography>
          <p>
            Annotation is missing: <b>{!forearm ? "forearm" : "hypothenar"}</b>.
          </p>
          <p>
            If possible, annotate the scan with the missing region of interest.
            <br />
            Otherwise a new scan will be necessary.
          </p>
        </div>
      </>
    );
  }

  return (
    <PadPage
      patient={patient}
      createAssessment={mutablePAD!}
      selected={selected}
    />
  );
}
