import { RiskLevel } from "@cur8/measurements";
import { RiskMetric } from "lib/metric";
import { useMemo } from "react";
import { LabelMap } from "render/ui/presentation/RangeChart";
import { Typography } from "render/ui/presentation/Typography";
import styles from "./styles.module.sass";

export const RiskToHighlightMap: Record<
  RiskLevel,
  "normal" | "warning" | "danger"
> = {
  [RiskLevel.Unknown]: "normal",
  [RiskLevel.Optimal]: "normal",
  [RiskLevel.Normal]: "normal",
  [RiskLevel.LowRisk]: "warning",
  [RiskLevel.Risk]: "warning",
  [RiskLevel.ModerateRisk]: "warning",
  [RiskLevel.HighRisk]: "danger",
  [RiskLevel.ImmediateRisk]: "danger",
};

interface HeartRateSummaryProps {
  metrics: RiskMetric<"cardio.ecg.heart_rate">[] | undefined;
}

export const HeartRateSummary = ({ metrics }: HeartRateSummaryProps) => {
  const latest = metrics?.at(0);
  const latestValue = latest?.unit.bpm;

  const risk = latest?.riskRanges.riskLevel;
  const rangeLabel = useMemo(() => {
    if (latest == null) {
      return "";
    }
    const interval = latest.riskRanges.findIntervalFor({
      bpm: latest.unit.bpm,
    });
    return interval?.riskLabel ? LabelMap[interval?.riskLabel] : "";
  }, [latest]);

  const riskHighlight = RiskToHighlightMap[risk ?? RiskLevel.Unknown];

  return (
    <div className={styles.HeartRateSummary} data-highlight={riskHighlight}>
      <Typography variant="label-m">
        Heart rate: {latestValue ? `${latestValue.toFixed(0)} bpm` : "--"}
      </Typography>
      {risk && (
        <>
          <div className={styles.divider} />
          <Typography variant="label-m">{rangeLabel}</Typography>
        </>
      )}
    </div>
  );
};
