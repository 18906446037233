import { spring } from "@pomle/tween";
import { lerp, norm } from "lib/anim";
import { AvatarPodium } from "lib/avatar/podium";
import { toSide } from "lib/avatar/side";
import { clamp } from "lib/math";
import { createRenderTimer } from "lib/timer";
import { useEffect, useMemo, useState } from "react";
import { useTexture } from "render/hooks/three/useTexture";
import { useDelay } from "render/hooks/useDelay";
import { useGLTF } from "render/hooks/useGLTF";
import { useAvatarContext } from "render/pages/DashboardPage/context/AvatarContext";
import { useDashboardContext } from "render/pages/DashboardPage/context/DashboardContext";
import { Layer } from "render/pages/DashboardPage/types";
import * as THREE from "three";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { useRenderer } from "../Renderer/Renderer";
import dotImage from "./assets/dot-4.png";
import heartModelURL from "./assets/neko-heart-animated.glb";
import { useSettings } from "./hooks/settings";
import { createLights } from "./light";
import { DepthMaterial } from "./materials/DepthMaterial";
import { AnimationPose, setupAvatarSource } from "./setup/avatar";
import { setupDotGrid } from "./setup/dot-grid";
import { setupHeartSource } from "./setup/heart";

enum DepthMapLayer {
  Avatar = 1,
  Heart = 2,
}

const ANIM_MAP: Partial<Record<Layer, AnimationPose>> = {
  [Layer.ArterialHealth]: "apose",
  [Layer.Bloodwork]: "relax",
  [Layer.Body]: "relax",
  [Layer.Cardio]: "apose",
  [Layer.Diabetes]: "relax",
  [Layer.Identity]: "reveal",
  [Layer.LesionLibrary]: "recpose",
  [Layer.LesionMacro]: "recpose",
  [Layer.Skin]: "relax",
};

const SCAN_INTENSITY_MAP: Partial<Record<Layer, 0 | 1>> = {
  [Layer.Skin]: 1,
};

const DEPTH_MAP_LAYER_MAP: Partial<Record<Layer, DepthMapLayer>> = {
  [Layer.Heart]: DepthMapLayer.Heart,
};

interface AvatarDotGridProps {
  layer: Layer;
  scene: THREE.Object3D;
  podium: AvatarPodium;
  avatarSource?: GLTF;
}

export function AvatarDotGrid({
  layer,
  scene,
  podium,
  avatarSource,
}: AvatarDotGridProps) {
  const { camera } = useDashboardContext();

  const spatial = useAvatarContext();
  const areas = spatial.smpl.front.areas;

  const avatarReady = useDelay({ active: true, delay: 10000 });

  const { renderer } = useRenderer();

  const [animationMap, setAnimationMap] = useState(ANIM_MAP);

  const heartSource = useGLTF(heartModelURL);

  const dotTexture = useTexture(dotImage);

  const heart = useMemo(() => {
    return heartSource && setupHeartSource(heartSource);
  }, [heartSource]);

  const avatar = useMemo(() => {
    if (avatarReady && avatarSource) {
      return setupAvatarSource(avatarSource);
    }
  }, [avatarSource, avatarReady]);

  const dotGrid = useMemo(() => {
    if (!dotTexture) {
      return;
    }

    const dotGrid = setupDotGrid({
      dotTexture,
      size: 2400,
      res: 500,
    });

    dotGrid.camera.position.set(0, 0, 1100);
    dotGrid.points.position.z = dotGrid.camera.position.z;
    //dotGrid.points.position.y = -10;
    dotGrid.points.material.pointSize = 10 * window.devicePixelRatio;

    return dotGrid;
  }, [dotTexture]);

  const lights = useMemo(createLights, []);

  useEffect(() => {
    scene.add(lights.group);

    return () => {
      scene.remove(lights.group);
    };
  }, [scene, lights]);

  const [tweens] = useState(() => {
    return {
      depthMap: spring(
        { layer: DepthMapLayer.Avatar },
        {
          friction: 20.0,
          mass: 120.0,
          stiffness: 10.0,
          precision: 0.01,
        }
      ),
      scan: spring(
        {
          intensity: 0,
        },
        {
          friction: 10.5,
          mass: 80.8,
          stiffness: 24.6,
          precision: 0.01,
        }
      ),
    };
  });

  const settings = useSettings();

  useEffect(() => {
    if (!dotGrid) {
      return;
    }

    const revFx = dotGrid.points.material.revealEffect;
    Object.assign(revFx, settings.reveal);
  }, [dotGrid, settings.reveal]);

  useEffect(() => {
    if (!dotGrid) {
      return;
    }

    const bodyEffect = dotGrid.points.material.bodyEffect;
    Object.assign(bodyEffect, settings.body);
  }, [dotGrid, settings.body]);

  useEffect(() => {
    if (!dotGrid) {
      return;
    }

    dotGrid.view.rotation.z = settings.grid.projectionAngle;
  }, [dotGrid, settings.grid]);

  useEffect(() => {
    const light1 = lights.spotLight1;
    const light2 = lights.spotLight2;

    light2.position.copy(settings.light.light2Position);
    light2.target.position.copy(settings.light.light2Target);
    light2.target.updateMatrixWorld();

    light2.intensity = settings.light.light2Intensity;

    light2.angle = settings.light.light2Angle;

    light1.penumbra = 0.2;
    light2.penumbra = 0.2;
  }, [lights, settings.light]);

  useEffect(() => {
    if (!areas) {
      return;
    }

    const light1 = lights.spotLight1;
    const light2 = lights.spotLight2;

    const top = areas.top.max.z;
    light1.position.z = top - 312.45;
    light2.position.z = top + 438.55;
  }, [lights, areas]);

  useEffect(() => {
    if (!avatar) {
      return;
    }

    const anim = avatar.animator;

    const pose = animationMap[layer];
    if (pose != null) {
      anim.transitionTo(pose);
    }
  }, [avatar, animationMap, layer]);

  useEffect(() => {
    /* 
      Effect responsible for transitioning away from reveal anim once nearing playback end
    */
    if (!avatar) {
      return;
    }

    const anim = avatar.animator;

    const reveal = anim.clip.reveal;
    const endTime = reveal.getClip().duration - 4;

    const stop = createRenderTimer(() => {
      if (reveal.time > endTime) {
        stop();

        setAnimationMap((map) => {
          return {
            ...map,
            [Layer.Identity]: "relax",
          };
        });
      }
    });

    return () => {
      stop();

      setAnimationMap(ANIM_MAP);
    };
  }, [avatar]);

  useEffect(() => {
    tweens.depthMap.to({
      layer: DEPTH_MAP_LAYER_MAP[layer] ?? DepthMapLayer.Avatar,
    });
  }, [tweens, layer]);

  useEffect(() => {
    tweens.scan.to({
      intensity: SCAN_INTENSITY_MAP[layer] ?? 0,
    });
  }, [tweens, layer]);

  useEffect(() => {
    // Scan effect mediator
    if (!dotGrid) {
      return;
    }

    if (layer === Layer.Skin) {
      let prevSide = -1;
      let scanTime = 0;

      const mat = dotGrid.points.material;
      const stop = createRenderTimer(({ deltaTime }) => {
        const side = toSide(podium.rotation.z);
        if (side !== prevSide) {
          scanTime = -2;
          prevSide = side;
        }

        scanTime += deltaTime;

        const scanPos = scanTime * 0.2;
        mat.scanEffect.length = 300;
        mat.scanEffect.position.z = -(scanPos * 2400 - 1200);
      });

      return () => {
        stop();
      };
    }
  }, [dotGrid, layer, podium]);

  useEffect(() => {
    return createRenderTimer(({ deltaTime }) => {
      tweens.depthMap.update(deltaTime);
      tweens.scan.update(deltaTime);
    });
  }, [tweens]);

  useEffect(
    function avatarRender() {
      if (!avatar || !heart || !dotGrid) {
        return;
      }

      const front = avatar.front;
      const back = avatar.back;
      front.mesh.visible = true;
      back.mesh.visible = true;
      back.handle.rotation.z = Math.PI;

      const shadowMapMeshes = new THREE.Group();

      shadowMapMeshes.add(avatar.scene);
      //shadowMapMeshes.add(heart.scene);

      front.material.colorWrite = true;
      back.material.colorWrite = true;
      heart.material.colorWrite = true;
      front.material.depthWrite = true;
      back.material.depthWrite = true;
      heart.material.depthWrite = true;

      heart.scene.scale.setScalar(16.5);
      heart.scene.position.set(100, 0, 1450);

      dotGrid.camera.layers.set(1);
      front.mesh.layers.enable(1);
      back.mesh.layers.enable(1);
      heart.mesh.layers.enable(1);

      function planarDistance(a: THREE.Vector3, b: THREE.Vector3) {
        const p1 = new THREE.Vector2(a.x, a.y);
        const p2 = new THREE.Vector2(b.x, b.y);
        return p1.distanceTo(p2);
      }

      function distanceToScale(distance: number) {
        const near = 1226;
        const far = 4000;
        const p = norm(near, far, distance);
        return lerp(0.8, 1.4, p);
      }

      function layerPeak(layer: number, peak: number) {
        return Math.max(0, 1 - Math.abs(layer - peak) * 2);
      }

      shadowMapMeshes.renderOrder = 0;
      dotGrid.points.renderOrder = 1;

      const heartDotScale = 3;

      const clips = avatar.animator.clip;

      const points = dotGrid.points;

      function updateMaterialEffects(time: number) {
        points.material.digitalEffect.progression = time;

        const emissionOpacity = clamp(
          time / clips.reveal.getClip().duration,
          0,
          1
        );

        front.material.opacity = emissionOpacity;
        back.material.opacity = emissionOpacity;
      }

      function updateReveal(playbackTime: number) {
        const normTime = playbackTime / clips.reveal.getClip().duration;

        points.material.revealEffect.normalizedRevealTime = normTime;

        const revFX = points.material.revealEffect;

        {
          const [start, end] = [0.06, 1.63];
          revFX.bodyProgression = (normTime - start) / (end - start);
        }

        {
          const [start, end] = [0.1, 0.48];
          revFX.leftArmProgression = ((normTime - start) / (end - start)) * 1.4;
        }

        {
          const [start, end] = [0.1, 0.73];
          revFX.rightArmProgression = (normTime - start) / (end - start);
        }
      }

      function updateAnimations(playbackTime: number) {
        const oscillatingTimeScale =
          0.3 + (Math.sin(playbackTime * 0.5) * 0.5 + 0.5) * 0.3;

        const timeSinceRevealEnded =
          playbackTime - clips.reveal.getClip().duration;
        const fadeDelay = 0;
        const fadeDuration = 7;
        const fadeTime = timeSinceRevealEnded - fadeDelay;
        const ratio = clamp(fadeTime / fadeDuration, 0, 1);
        const timeScale = THREE.MathUtils.lerp(1, oscillatingTimeScale, ratio);

        clips.relaxFront.timeScale = timeScale;
        clips.relaxBack.timeScale = timeScale;
      }

      const destroyTimer = createRenderTimer(({ time, accTime, deltaTime }) => {
        const layer = tweens.depthMap.value.layer;
        const avatarLayer = layerPeak(layer, DepthMapLayer.Avatar);
        const heartLayer = layerPeak(layer, DepthMapLayer.Heart);

        updateAnimations(accTime);

        if (heartLayer > 0) {
          heart.animator.update(deltaTime);
        }

        avatar.animator.update(deltaTime);

        const distance = planarDistance(
          camera.position,
          shadowMapMeshes.position
        );

        const avatarDotScale = distanceToScale(distance);
        let dotScale = avatarDotScale;
        if (heartLayer > 0) {
          dotScale = heartDotScale;
        }

        const side = toSide(podium.rotation.z);
        let opacity = Math.abs(side - 0.5) * 2;
        opacity *= Math.abs(2 * (layer % 1) - 1);

        front.mesh.visible = side > 0.5 && avatarLayer > 0;
        back.mesh.visible = side < 0.5 && avatarLayer > 0;

        // heart.mesh.visible = heartLayer > 0;
        heart.mesh.visible = false;

        dotGrid.points.material.scanEffect.intensity =
          tweens.scan.value.intensity;

        dotGrid.points.material.opacity = opacity;
        dotGrid.points.material.scale = dotScale;

        updateReveal(accTime);
        updateMaterialEffects(accTime);
        front.mesh.material = front.normalMaterial;
        back.mesh.material = back.normalMaterial;
        heart.mesh.material = heart.normalMaterial;
        dotGrid.points.rotation.z = dotGrid.view.rotation.z;
        dotGrid.render(renderer, scene);

        front.mesh.material = front.bodyPartsMaterial;
        back.mesh.material = back.bodyPartsMaterial;
        //heart.mesh.material = heart.normalMaterial;
        dotGrid.renderBodyparts(renderer, scene);

        front.mesh.material = front.colorMaterial;
        back.mesh.material = back.colorMaterial;
        //heart.mesh.material = heart.normalMaterial;
        dotGrid.renderBodyColor(renderer, scene);

        front.mesh.material = front.material;
        back.mesh.material = back.material;
        heart.mesh.material = heart.material;

        renderer.render(scene, camera);
      });

      scene.add(dotGrid.points);
      podium.add(shadowMapMeshes);

      return () => {
        destroyTimer();

        scene.remove(dotGrid.points);
        podium.remove(shadowMapMeshes);
      };
    },
    [tweens, renderer, camera, heart, podium, scene, avatar, dotGrid]
  );

  useEffect(
    function dotGridTextureDebug() {
      if (!dotGrid) {
        return;
      }

      const type = settings.grid.map;
      if (type === "none") {
        return;
      }

      const helperSize = 800;

      const helper = new THREE.Mesh(
        new THREE.PlaneGeometry(helperSize, helperSize)
      );

      helper.rotation.x = Math.PI / 2;
      helper.position.set(-700, 1000, 1000);

      if (type === "normal") {
        helper.material = new THREE.MeshBasicMaterial({
          color: "#fff",
          map: dotGrid.normalMap,
          transparent: true,
        });
      }

      if (type === "depth") {
        helper.material = new DepthMaterial(dotGrid.camera, dotGrid.depthMap);
      }

      if (type === "body-parts") {
        helper.material = new THREE.MeshBasicMaterial({
          color: "#fff",
          map: dotGrid.bodyPartsMap,
          transparent: false,
        });
      }

      if (type === "body-color") {
        helper.material = new THREE.MeshStandardMaterial({
          map: dotGrid.bodyColorMap,
          color: "#fff",
          transparent: false,
        });
      }

      scene.add(helper);

      return () => {
        scene.remove(helper);
      };
    },
    [scene, dotGrid, settings.grid]
  );

  return null;
}
