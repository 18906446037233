import * as THREE from "three";

export function createDotPlane(size: number, count: number) {
  const geometry = new THREE.BufferGeometry();

  const positions: number[] = [];
  const locations: number[] = [];
  const random: number[] = [];

  const semi = size / 2;
  const pitch = size / (count - 1);

  for (let x = 0; x < count; x++) {
    for (let y = 0; y < count; y++) {
      positions.push(x * pitch - semi, 0, y * pitch - semi);
      locations.push(x, y);
      random.push(Math.random());
    }
  }

  geometry.setAttribute(
    "position",
    new THREE.Float32BufferAttribute(positions, 3)
  );

  geometry.setAttribute(
    "location",
    new THREE.Float32BufferAttribute(locations, 2)
  );

  geometry.setAttribute("random", new THREE.Float32BufferAttribute(random, 1));

  geometry.computeBoundingSphere();

  return geometry;
}
