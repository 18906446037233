import { Slot, Visit, fromAPI } from "@cur8/rich-entity";
import { APIClient } from "lib/api/client";
import { silenceAbort } from "lib/error";
import { useCallback, useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { PatientIdentifier } from "render/fragments/patient/PatientIdentifier";
import { usePatient } from "render/hooks/api/usePatient";
import { useQuestionnaire } from "render/hooks/api/useQuestionnaire";
import { PassedTime } from "render/ui/format/PassedTime";
import { FramedPage } from "render/ui/layouts/FramedPage/FramedPage";
import { PageHeader } from "render/ui/layouts/PageHeader";
import { HoverTextButton } from "render/ui/trigger/HoverTextButton";
import { Details } from "./components/Details";
import { DetailsV1 } from "./components/DetailsV1";
import { LLMBrief } from "./components/LLMBrief";
import { OnboardingQuestionnaire } from "./components/OnboardingQuestionnaire";
import { SlotDate } from "./fragments/SlotDate";
import { SlotTime } from "./fragments/SlotTime";
import { useCancelAppointmentPopup } from "./hooks/useCancelAppointmentPopup";
import styles from "./styles.module.sass";
import { APITypesV1 } from "@cur8/api-client";
import { Typography } from "render/ui/presentation/Typography";
import { useNav } from "@pomle/react-router-paths";
import { paths } from "render/routes/paths";
import { query } from "render/routes/querys";
import { useDeviceStorage } from "render/hooks/deviceStorage/useDeviceStorage";
import { SingleSiteCalendarPreference } from "render/hooks/deviceStorage/lib/calendarPreference";

interface Props {
  patientId: string;
  bookingSlotId: string;
}

function fetchVisit(
  apiClient: APIClient,
  pointer: { patientId: string; visitId: string }
) {
  const { patientId, visitId } = pointer;
  const req = apiClient.visit.fetchVisit({ patientId, visitId });

  return {
    abandon: req.abandon,
    result: req.result.then(fromAPI.toVisit),
  };
}

function fetchSlot(apiClient: APIClient, slotId: string) {
  const req = apiClient.bookingV2.getSlot({ slotId });

  return {
    abandon: req.abandon,
    result: req.result.then(fromAPI.toSlot),
  };
}

const record: Record<APITypesV1.MedicalExam, string> = {
  [APITypesV1.MedicalExam.Dermascope]: "Dermascope",
  [APITypesV1.MedicalExam.Dermatologist]: "Dermatologist",
  [APITypesV1.MedicalExam.ExternalReferral]: "External referral",
  [APITypesV1.MedicalExam.GeneralExam]: "General referral",
  [APITypesV1.MedicalExam.NekoBodyScan]: "Body scan",
  [APITypesV1.MedicalExam.Perimed]: "Perimed",
  [APITypesV1.MedicalExam.TwelveLeadECG]: "ECG",
  [APITypesV1.MedicalExam.Ultrasound]: "Ultrasound",
};

export function PatientAppointmentPage({ patientId, bookingSlotId }: Props) {
  const deviceStorage = useDeviceStorage();
  const api = useAPIClient();
  const patient = usePatient(patientId);
  const [slot, setSlot] = useState<Slot>();
  const [visit, setVisit] = useState<Visit>();
  const visitId = bookingSlotId.includes("_")
    ? bookingSlotId.split("_")[1]
    : bookingSlotId;
  const questionnaire = useQuestionnaire({
    patientId,
    visitId,
  });

  const nav = {
    calendarPage: useNav(paths.schedule.site, query.schedule),
  };
  const goToCalendar = useCallback(
    (visit: Visit) => () => {
      const censorAppointments =
        deviceStorage.calendarSettings.data instanceof
        SingleSiteCalendarPreference;
      nav.calendarPage.go(
        { siteId: visit.site.siteId },
        {
          censorAppointments: [censorAppointments],
          roomId: [visit.roomId],
          to: [visit.startTime.endOf("day")],
          from: [visit.endTime.startOf("day")],
        }
      );
    },
    [deviceStorage.calendarSettings.data, nav.calendarPage]
  );

  const { emitDialog } = useCancelAppointmentPopup();

  const appointment = slot;

  useEffect(() => {
    if (!visitId) {
      return;
    }

    const visitReq = fetchVisit(api, {
      patientId,
      visitId,
    });

    visitReq.result.then(setVisit).catch(silenceAbort);

    return () => {
      visitReq.abandon();
    };
  }, [api, patientId, bookingSlotId, visitId]);

  useEffect(() => {
    const slotReq = fetchSlot(api, bookingSlotId);
    slotReq.result.then(setSlot).catch(silenceAbort);

    return () => {
      slotReq.abandon();
    };
  }, [api, bookingSlotId]);

  const handleCancelAppointment = useCallback(() => {
    if (appointment) {
      emitDialog(appointment);
    }
  }, [emitDialog, appointment]);
  const isBodyScan = visit?.medicalExams?.some(
    (exam) => exam === APITypesV1.MedicalExam.NekoBodyScan
  );

  return (
    <FramedPage>
      <div className={styles.PatientAppointmentDetails}>
        <div className={styles.pageHeader}>
          <div className={styles.checkIn}>
            <PageHeader caption="Appointment Details" />
            {visit && (
              <>
                <CheckinTime visit={visit} />
              </>
            )}
          </div>

          <div className={styles.cta}>
            {visit && (
              <HoverTextButton onClick={goToCalendar(visit)}>
                Go To Calendar
              </HoverTextButton>
            )}

            {isBodyScan && (
              <HoverTextButton
                onClick={handleCancelAppointment}
                disabled={!appointment}
              >
                Cancel appointment
              </HoverTextButton>
            )}
          </div>
        </div>
        <div className={styles.content}>
          <section className={styles.leftSection}>
            <div className={styles.header}>
              <h1 className={styles.patientName}>
                <PatientIdentifier patientId={patientId} patient={patient} />
              </h1>
              <div className={styles.date}>
                {appointment ? <SlotDate slot={appointment} /> : null}
              </div>
              <div>{appointment ? appointment.room?.site?.siteName : null}</div>
              <div className={styles.time}>
                {appointment ? <SlotTime slot={appointment} /> : null}
              </div>
              {!isBodyScan && visit?.medicalExams && (
                <div className={styles.medicalExams}>
                  <span>Medical Exams:</span>
                  <ul className={styles.medicalExamsList}>
                    [
                    {visit?.medicalExams?.map((exam, index, arr) => (
                      <li>
                        {record[exam]}
                        {index !== arr.length - 1 ? "," : undefined}
                      </li>
                    ))}
                    ]
                  </ul>
                </div>
              )}
            </div>

            {isBodyScan && questionnaire.data ? (
              <>
                {questionnaire.data ? (
                  <OnboardingQuestionnaire response={questionnaire.data} />
                ) : (
                  <Typography variant="body-m">
                    Questionnaire for this visit has not been completed
                  </Typography>
                )}
                {patient ? (
                  <LLMBrief patient={patient} visitId={visitId} />
                ) : null}
              </>
            ) : null}
          </section>

          <section className={styles.rightSection}>
            {isBodyScan && questionnaire.data ? (
              <>
                {questionnaire.data?.questionnaireType === "onboarding/1" ? (
                  <DetailsV1
                    patient={patient}
                    onboarding={questionnaire.data}
                  />
                ) : (
                  <Details patient={patient} onboarding={questionnaire.data} />
                )}
              </>
            ) : null}
          </section>
        </div>
      </div>
    </FramedPage>
  );
}

function CheckinTime({ visit }: { visit: Visit }) {
  const timestamp = visit.checkinEndTimestamp || visit.checkinStartTimestamp;

  if (!timestamp) {
    return null;
  }

  return (
    <span className={styles.caption}>
      {visit.checkinEndTimestamp ? "Checked in" : "Check in started"}{" "}
      <span title={timestamp.toFormat("yyyy-LL-dd HH:mm:ss")}>
        <PassedTime date={timestamp} />
      </span>
    </span>
  );
}
