import {
  HabitualFrequency,
  HabitualStyle,
  MetricName,
  Pressure,
  RiskLevel,
  Unit,
  Weight,
} from "@cur8/measurements";
import { LocaleCode } from "lib/locales";

interface Enum<T> {
  value: T;
  label?: string;
}

export type OnboardingData = {
  responseId: string;
  state: "complete" | "incomplete";
  medication?: {
    currentMedication: string[];
  };
  healthConditions?: {
    conditions: string;
  };
  healthConditionsFamily?: {
    conditions: string;
  };
  diet?: {
    currentDiet: string;
  };
  additionalWorries?: string;
  ethnicities?: string[];
  allergies?: string[];
  //lifestyle.drinking-habit
  alcohol?: {
    beer: boolean;
    wine: boolean;
    liquor: boolean;
    weeklyDrinks: number;
  };
  //lifestyle.physical-activity
  weeklyExerciseHours?: Enum<number>;
  //lifestyle.smoking-habit
  smoking?: {
    style?: Enum<HabitualStyle>;
    frequency?: Enum<HabitualFrequency>;
    dailyCigarettes?: Enum<number>;
    quitSmokingAt?: string;
  };
  //lifestyle.snus-habit
  snus?: {
    style?: Enum<HabitualStyle>;
    weeklyCans?: Enum<HabitualFrequency>;
    quitSnusingAt?: string;
  };
};

export type Transcription = {
  text: string;
  text_tokens: number;
  anonymized: string;
  anonymized_tokens: number;
  language: LocaleCode;
  summary?: string;
};

export type ExtendedMetricName = MetricName | "bloodwork.cholesterol_hdl_ratio";

export enum Deviation {
  Above = 1,
  None = 0,
  Below = -1,
}

export type Entry = {
  deviation: Deviation | undefined;
  risk: RiskLevel | undefined;
  timestamp: string;
  value: Unit<MetricName>;
};

export type Result = {
  name: ExtendedMetricName;
  measurements: Entry[];
};

export type ResultRecord = Record<ExtendedMetricName, Entry[]>;

export type CombinedPressure = {
  left: Pressure;
  right: Pressure;
};

export type AggregateEntry = {
  timestamp: string;
  value:
    | { score: number }
    | { maxStrength: Weight; leftRightDifference?: Weight }
    | CombinedPressure
    | { weeklyDrinks: number };
  risk: RiskLevel;
  deviation?: Deviation;
};

export type MutableResultRecord = {
  [K in keyof ResultRecord]: Entry[];
};

export type MergedEntry = {
  timestamp: string;
  value: Unit<MetricName> | { score: number };
  risk: RiskLevel | undefined;
  deviation: Deviation | undefined;
};

export type MergedRecord = Record<ExtendedMetricName, MergedEntry[]>;

export type RefinedRecord = {
  age: number;
  sex: string;
  allergies: string[];
  onboardingQuestionnaire: OnboardingData | undefined;
} & MergedRecord;

export type GripRecord = {
  age: {
    from: number;
    to: number;
  };
  left: {
    average: number;
    std: number;
  };
  right: {
    average: number;
    std: number;
  };
  diff: {
    average: number;
    std: number;
  };
};

export type DermaStatus = {
  count: number;
  excise: boolean;
};

export type MemberSummary = {
  timestamp: string;
  transcription: Transcription;
  patient_record: any;
  summary: string;
  prompt: string;
  instruction: string;
};

export enum ScribeFlavor {
  Stable = "stable",
  Tuned = "tuned",
  None = "none",
}

export type TransSumPair = {
  language: LocaleCode | string;
  transcriptionSummary: string;
  results: any;
  temp: number;
  generatedSummary: string;
  savedSummary: string;
  readTime: number;
  isLiked: boolean | undefined;
  flavor: ScribeFlavor;
};
