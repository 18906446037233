import { createPath } from "@pomle/paths";
import { createPaths as patient } from "./patient";
import { createPaths as settings } from "./settings";
import { createPaths as schedule } from "./schedule";

const root = createPath("/", {});

const internalEntry = root.append("/internal/entry/device-ui", {});
const notifications = root.append("/notifications", {});
const linda = root.append("/linda", {});
const skinCRM = root.append("/skin-crm", {});
const dashboardStyleGuide = root.append("/dashboard/style-guide", {});

export const paths = {
  root,
  internalEntry,
  notifications,
  settings: settings(root),
  schedule: schedule(root),
  linda,
  patient: patient(root),
  skinCRM,
  dashboardStyleGuide,
};
