import { ReactNode } from "react";
import { Typography } from "render/ui/presentation/Typography";
import styles from "./styles.module.sass";

interface ValueItemProps {
  value: ReactNode;
  unit?: ReactNode;
  size?: "normal" | "huge";
  alignItems?: "center" | "baseline";
}

export function ValueItem({
  value,
  unit,
  size = "normal",
  alignItems = "center",
}: ValueItemProps) {
  return (
    <span className={styles.ValueItem} style={{ alignItems }}>
      <Typography
        as="span"
        variant={size === "normal" ? "numeral-s" : "numeral-m"}
        className={styles.amount}
      >
        {value}
      </Typography>
      {unit && (
        <Typography
          as="span"
          variant={size === "normal" ? "label-m" : "body-m"}
          className={styles.unit}
        >
          {unit}
        </Typography>
      )}
    </span>
  );
}
