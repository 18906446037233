import { useEffect } from "react";
import { useDashboardContext } from "render/pages/DashboardPage/context/DashboardContext";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import { Typography } from "render/ui/presentation/Typography";
import { Layout, LayoutProps } from "../Layout";
import { SidePanel } from "../SidePanel";
import SoundNotAvailableIcon from "./assets/sound-not-available.svg?react";
import SoundOffIcon from "./assets/sound-off.svg?react";
import SoundOnIcon from "./assets/sound-on.svg?react";
import { HeartPanel as HeartPanel2 } from "./components/HeartPanel2";
import styles from "./styles.module.sass";

export function HeartLayout({ active }: LayoutProps) {
  const { patient } = usePatientData();
  const { heartAudio } = useDashboardContext();

  // mute heart audio when layout is inactive
  useEffect(() => {
    if (!active) {
      heartAudio.setMuted(true);
    }
  }, [heartAudio, active]);

  return (
    <Layout active={active}>
      <div className={styles.HeartLayout} data-active={active}>
        <div className={styles.heartPanel}>
          <SidePanel>
            {patient && (
              <HeartPanel2
                active={active}
                patient={patient}
                muted={heartAudio.muted}
              />
            )}
          </SidePanel>
        </div>
        <button
          className={styles.soundButton}
          onClick={heartAudio.toggleMuted}
          disabled={!heartAudio.enabled}
        >
          {heartAudio.enabled ? (
            <>
              {heartAudio.muted ? <SoundOffIcon /> : <SoundOnIcon />}
              <Typography variant="label-m">Sound</Typography>
            </>
          ) : (
            <>
              <SoundNotAvailableIcon />
              <Typography variant="body-s">N/A</Typography>
            </>
          )}
        </button>
      </div>
    </Layout>
  );
}
