import { ReactNode } from "react";
import { useUniq } from "render/assets/svg/gradient";
import { Typography } from "../Typography";
import styles from "./styles.module.sass";
import { RiskLevel } from "@cur8/measurements";

export type FloatingValueTargetHighlight = "danger" | "warning" | "normal";

export const RiskToHighlightMap: Record<
  RiskLevel,
  FloatingValueTargetHighlight
> = {
  [RiskLevel.Unknown]: "normal",
  [RiskLevel.Optimal]: "normal",
  [RiskLevel.Normal]: "normal",
  [RiskLevel.Risk]: "warning",
  [RiskLevel.LowRisk]: "warning",
  [RiskLevel.ModerateRisk]: "danger",
  [RiskLevel.HighRisk]: "danger",
  [RiskLevel.ImmediateRisk]: "danger",
};

export interface FloatingValueTargetProps {
  label: ReactNode;
  value: ReactNode;
  highlight?: FloatingValueTargetHighlight;
  align?: `${"top" | "bottom"}-${"left" | "right"}`;
  width?: number;
  withKnob?: boolean;
}

export function FloatingValueTarget({
  label,
  value,
  highlight = "normal",
  align = "top-right",
  width = 200,
  withKnob = true,
}: FloatingValueTargetProps) {
  const backdropFilterClipPath = useUniq("backdropFilterClipPath");

  return (
    <div
      className={styles.FloatingValueTarget}
      data-align={align}
      data-highlight={highlight}
      data-with-knob={withKnob}
    >
      <div className={styles.target}>
        {withKnob && (
          <div
            className={styles.knob}
            style={{ clipPath: backdropFilterClipPath.ref }}
          >
            <svg
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className={styles.knobBackground}
                d="M0 17.312C0 3.05557 3.05557 0 17.312 0H22.688C36.9444 0 40 3.05557 40 17.312V22.688C40 36.9444 36.9444 40 22.688 40H17.312C3.05557 40 0 36.9444 0 22.688V17.312Z"
                fill="var(--color-intelligent-blue-300)"
                fillOpacity="0.3"
              />
              <circle
                className={styles.knobDot}
                cx="20"
                cy="20"
                r="4"
                fill="var(--color-intelligent-blue-800)"
              />
              <defs>
                <clipPath id={backdropFilterClipPath.id}>
                  <path d="M0 17.312C0 3.05557 3.05557 0 17.312 0H22.688C36.9444 0 40 3.05557 40 17.312V22.688C40 36.9444 36.9444 40 22.688 40H17.312C3.05557 40 0 36.9444 0 22.688V17.312Z" />
                </clipPath>
              </defs>
            </svg>
          </div>
        )}

        <div className={styles.line} style={{ width }}>
          <svg
            viewBox={`0 0 ${width} 43`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className={styles.linePath}
              d="M1 42L30.9913 12.4886 C38.4734 5.12621 48.5496 1 59.0466 1 L73.5 1"
              stroke="var(--color-intelligent-blue-300)"
              strokeWidth="1.5"
            />
            <path
              className={styles.linePath}
              d={`M73.5 1 L${width} 1`}
              stroke="var(--color-intelligent-blue-300)"
              strokeWidth="1.5"
            />
          </svg>

          <div className={styles.content}>
            <div className={styles.label}>
              <Typography variant="label-m">{label}</Typography>
            </div>
            <div className={styles.value}>{value}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
