import { getHighestRisk } from "@cur8/measurements";
import { Patient } from "@cur8/rich-entity";
import { RiskMetric } from "lib/metric";
import { useMemo } from "react";
import { MetricStatus } from "render/ui/presentation/MetricStatus";
import { MetricStatusBadge } from "render/ui/presentation/MetricStatusBadge";
import ArterialHealthIcon from "./assets/arterial-health.svg?react";

interface ArterialHealthItemProps {
  patient: Patient;
  abi: {
    left: RiskMetric<"cardio.abi.left">[] | undefined;
    right: RiskMetric<"cardio.abi.right">[] | undefined;
  };
  brachialPressure: {
    left: RiskMetric<"cardio.brachial_pressure.left">[] | undefined;
    right: RiskMetric<"cardio.brachial_pressure.right">[] | undefined;
  };
}

export function ArterialHealthItem({
  abi,
  brachialPressure,
}: ArterialHealthItemProps) {
  const latestAbiLeft = abi.left?.at(0);
  const latestAbiRight = abi.right?.at(0);

  const latestBrachialPressureLeft = brachialPressure.left?.at(0);
  const latestBrachialPressureRight = brachialPressure.right?.at(0);

  const risk = useMemo(() => {
    if (!latestAbiLeft) {
      return undefined;
    }
    if (!latestAbiRight) {
      return undefined;
    }
    if (!latestBrachialPressureLeft) {
      return undefined;
    }
    if (!latestBrachialPressureRight) {
      return undefined;
    }
    return getHighestRisk([
      latestAbiLeft.riskRanges.riskLevel,
      latestAbiRight.riskRanges.riskLevel,
      latestBrachialPressureLeft.riskRanges.diastolic.riskLevel,
      latestBrachialPressureLeft.riskRanges.systolic.riskLevel,
      latestBrachialPressureRight.riskRanges.diastolic.riskLevel,
      latestBrachialPressureRight.riskRanges.systolic.riskLevel,
    ]);
  }, [
    latestAbiLeft,
    latestAbiRight,
    latestBrachialPressureLeft,
    latestBrachialPressureRight,
  ]);

  return (
    <MetricStatus
      caption="Arterial health"
      summaryContent={risk != null ? <MetricStatusBadge risk={risk} /> : "--"}
    >
      <ArterialHealthIcon />
    </MetricStatus>
  );
}
