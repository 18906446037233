import { APITypesV1 } from "@cur8/api-client";
import styles from "./styles.module.sass";
import { ViewRangeMode } from "../../../../hooks/useCalendarState";

export function MedicalExamsTags({
  medicalExams,
  viewRange,
  variant,
}: {
  medicalExams: APITypesV1.MedicalExam[] | undefined;
  viewRange: ViewRangeMode;
  variant: "dark" | "light";
}) {
  const record: Record<APITypesV1.MedicalExam, string> = {
    [APITypesV1.MedicalExam.Dermascope]: "Dermascope",
    [APITypesV1.MedicalExam.Dermatologist]: "Dermatologist",
    [APITypesV1.MedicalExam.ExternalReferral]: "External referral",
    [APITypesV1.MedicalExam.GeneralExam]: "General referral",
    [APITypesV1.MedicalExam.NekoBodyScan]: "Body scan",
    [APITypesV1.MedicalExam.Perimed]: "Perimed",
    [APITypesV1.MedicalExam.TwelveLeadECG]: "ECG",
    [APITypesV1.MedicalExam.Ultrasound]: "Ultrasound",
  };
  const labels = medicalExams?.map((v) => record[v]);

  return (
    <ul
      className={styles.MedicalExamsTags}
      title={labels?.join(", ")}
      data-variant={variant}
    >
      {viewRange === ViewRangeMode.Daily && (
        <>
          {labels?.map((label) => (
            <li className={styles.tag}>{label}</li>
          ))}
        </>
      )}
    </ul>
  );
}
