import { SpringConfig } from "@pomle/tween";
import {
  createLookAtTween,
  createPositionTween,
  createZoomTween,
} from "lib/tween";
import * as THREE from "three";

export class CameraView {
  fov = 35;
  pos = new THREE.Vector3();
  focus = new THREE.Vector3();

  clone() {
    const next = new CameraView();
    next.fov = this.fov;
    next.pos.copy(this.pos);
    next.focus.copy(this.focus);
    return next;
  }
}

function createTweens(camera: THREE.PerspectiveCamera) {
  const pos = createPositionTween(camera);
  const lookAt = createLookAtTween(camera);
  const zoom = createZoomTween(camera);

  return {
    pos,
    lookAt,
    zoom,
  };
}

export class DashboardCamera extends THREE.PerspectiveCamera {
  tweens = createTweens(this);

  constructor() {
    super(35, 1, 100, 20000);
  }

  reconfigure(config: SpringConfig) {
    this.tweens.pos.reconfigure(config);
    this.tweens.lookAt.reconfigure(config);
    this.tweens.zoom.reconfigure(config);
  }

  setTo(view: CameraView) {
    this.position.copy(view.pos);
    this.lookAt(view.focus);

    this.tweens.pos.set(view.pos);
    this.tweens.lookAt.set(view.focus);
    this.tweens.zoom.set({ fov: view.fov });
  }

  moveTo(view: CameraView) {
    this.tweens.pos.to(view.pos);
    this.tweens.lookAt.to(view.focus);
    this.tweens.zoom.to({ fov: view.fov });
  }

  update(deltaTime: number) {
    this.tweens.pos.update(deltaTime);
    this.tweens.lookAt.update(deltaTime);
    this.tweens.zoom.update(deltaTime);
  }
}
