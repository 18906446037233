import { useEffect, useState } from "react";
import { TextScrambler } from "render/ui/presentation/TextScrambler";
import { usePatientData } from "../../../../context/PatientDataContext";
import LoaderAnim from "./assets/loader-anim.svg?react";
import styles from "./styles.module.sass";

type Sequence = {
  duration: number;
  callback: () => void;
};

function createSequencer(sequences: Sequence[]) {
  const timers: NodeJS.Timeout[] = [];

  let acc = 0;
  for (const seq of sequences) {
    const timer = setTimeout(seq.callback, acc);
    acc += seq.duration * 1000;
    timers.push(timer);
  }

  return {
    abort() {
      for (const timer of timers) {
        clearTimeout(timer);
      }
    },
  };
}

export function IntroLayout() {
  const { patient } = usePatientData();

  const [loader, setLoader] = useState(false);
  const [text, setText] = useState<string>();

  useEffect(() => {
    if (!patient) {
      return;
    }

    const name = patient.name?.displayName;

    const sequencer = createSequencer([
      {
        duration: 3,
        callback: () => {
          if (name) {
            setText(`${name}, welcome to your Neko Health Debrief`);
          } else {
            setText("Welcome to your Neko Health Debrief");
          }
        },
      },

      {
        duration: 2,
        callback: () => {
          setLoader(true);
          setText("Collecting all your health data");
        },
      },

      {
        duration: 2,
        callback: () => {
          setText("Organizing biomarkers");
        },
      },

      {
        duration: 2,
        callback: () => {
          setText("Analyzing findings");
        },
      },

      {
        duration: 2.5,
        callback: () => {
          setLoader(false);
          setText("");
        },
      },
    ]);

    return () => {
      setText(undefined);
      setLoader(false);
      sequencer.abort();
    };
  }, [patient]);

  return (
    <div className={styles.IntroLayout}>
      <div className={styles.loader}>{loader && <LoaderAnim />}</div>

      <div className={styles.scramble}>
        {text != null && <TextScrambler text={text} />}
      </div>
    </div>
  );
}
