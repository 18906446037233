import { Layout, LayoutProps } from "../Layout";
import { SidePanel } from "../SidePanel";
import { CirculationSection } from "./components/CirculationSection";
import styles from "./styles.module.sass";

interface CirculationLayoutProps extends LayoutProps {}

export function CirculationLayout({ active }: CirculationLayoutProps) {
  return (
    <Layout active={active}>
      <div className={styles.CirculationLayout}>
        <div className={styles.leftPanel} data-active={active}>
          <SidePanel side="left">
            {<CirculationSection side="right" />}
          </SidePanel>
        </div>

        <div className={styles.rightPanel} data-active={active}>
          <SidePanel side="right">
            {<CirculationSection side="left" />}
          </SidePanel>
        </div>
      </div>
    </Layout>
  );
}
