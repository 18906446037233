import { Length } from "@cur8/measurements";
import { toFeet } from "lib/conversion/height";
import { useUnitConverter } from "render/hooks/conversion/useUnitConverter";
import { FloatingValueTarget } from "render/ui/presentation/FloatingValueTarget";
import { ValueItem } from "render/ui/symbol/ValueItem";

function HeightInFeet({ height }: { height: Length }) {
  const value = toFeet(height).feet;
  const feet = Math.floor(value);
  const inch = Math.floor((value - feet) / 0.083333);

  return (
    <>
      {feet}' {inch}"
    </>
  );
}

function HeightInCentimeters({ height }: { height: Length }) {
  const cm = height.meters * 100;

  return <>{cm.toFixed(0)}</>;
}

interface HeightProps {
  height: Length;
}

export function Height({ height }: HeightProps) {
  const conv = useUnitConverter().height;
  const unit = conv.unit;

  return (
    <FloatingValueTarget
      label="Height"
      value={
        unit === "feet" ? (
          <ValueItem
            value={<HeightInFeet height={height} />}
            size="huge"
            alignItems="baseline"
          />
        ) : (
          <ValueItem
            value={<HeightInCentimeters height={height} />}
            unit="cm"
            size="huge"
            alignItems="baseline"
          />
        )
      }
      align="top-left"
      withKnob={false}
    />
  );
}
