import { useMemo } from "react";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import {
  Diff,
  DiffValue,
  MainTitle,
  MetricResultHeader,
  Subtitle,
  Titles,
} from "render/ui/presentation/MetricResultHeader";
import {
  RangeChart,
  RangeChartDataComposer,
} from "render/ui/presentation/RangeChart";
import { toChartRanges } from "render/ui/presentation/RangeChart/lib/risk";

const getTitlesForValueChange = (value?: number, previousValue?: number) => {
  if (value === undefined || previousValue === undefined) {
    return {
      title: "HDL",
      subtitle: "",
    };
  }

  if (value === previousValue) {
    return {
      title: "Maintained",
      subtitle: "HDL",
    };
  }

  if (value > previousValue) {
    return {
      title: "Increased",
      subtitle: "HDL",
    };
  }

  return {
    title: "Decreased",
    subtitle: "HDL",
  };
};

export function HDLItem() {
  const { metrics } = usePatientData();
  const latest = metrics.bloodwork.hdl?.at(0);
  const previous = metrics.bloodwork.hdl?.at(1);

  const ranges = useMemo(() => {
    const riskIntervals = latest?.riskRanges.riskIntervals ?? [];
    const result = toChartRanges(riskIntervals);

    return result;
  }, [latest]);

  // https://dev.azure.com/cur8/Engineering/_workitems/edit/26137
  const rangesCapLow = 0.9;

  const value = latest?.unit["mmol/L"];
  const previousValue = previous?.unit["mmol/L"];

  const { title, subtitle } = getTitlesForValueChange(value, previousValue);

  return (
    <div>
      <MetricResultHeader>
        <Titles>
          <MainTitle>{title}</MainTitle>
          <Subtitle>{subtitle}</Subtitle>
        </Titles>
        <Diff>
          <DiffValue
            valuesDiff={
              previousValue !== undefined && value !== undefined
                ? value - previousValue
                : undefined
            }
            formatValue={(value) => value?.toFixed(2)}
            unit="mmol/L"
          />
        </Diff>
      </MetricResultHeader>
      <RangeChartDataComposer
        ranges={ranges}
        rangesCapLow={rangesCapLow}
        value={value}
        previousValue={previousValue}
        formatValue={(value) => value?.toFixed(2)}
      >
        {({ ranges, values }) => (
          <RangeChart ranges={ranges} values={values} showEmpty />
        )}
      </RangeChartDataComposer>
    </div>
  );
}
