import { MetricName, MetricRiskInterval, RiskLevel } from "@cur8/measurements";
import {
  fromAPI,
  Measurement,
  Visit,
  VisitMeasurementSummary,
} from "@cur8/rich-entity";
import { Initialization } from "@microsoft/applicationinsights-web/types/Initialization";
import { silenceAbort } from "lib/error";
import { RiskMetric, toRiskMetric } from "lib/metric";
import { getCountryFromVisit } from "lib/visit/visit";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useAppInsights } from "render/context/AppInsightsContext";
import { useReporting } from "render/hooks/useReporting";

interface VisitMeasurement {
  visit: Visit;
  measurements: Measurement[];
}

function trackDataValidity(appInsights: Initialization) {
  return (patientId: string) => {
    return <M extends MetricName>(riskMetric: RiskMetric<M>) => {
      const name = "dashboard--risk_metric--missing_risk";
      const properties = {
        patientId: patientId,
        name: riskMetric.name,
      };
      if (riskMetric.riskRanges == null) {
        return;
      }
      const isEmpty = (riskIntervals: MetricRiskInterval[]) => {
        return riskIntervals.length === 0;
      };
      const isRiskUnknown = (riskLevel: RiskLevel) => {
        return riskLevel === RiskLevel.Unknown;
      };
      const trackUnknown = () => {
        appInsights.trackEvent({
          name,
          properties: { ...properties, label: "unknown risk level" },
        });
      };
      const trackEmpty = () => {
        appInsights.trackEvent({
          name,
          properties: { ...properties, label: "risk ranges empty" },
        });
      };

      if ("diastolic" in riskMetric.riskRanges) {
        const { diastolic } = riskMetric.riskRanges;
        if (isEmpty(diastolic.riskIntervals)) {
          trackEmpty();
        }
        if (isRiskUnknown(diastolic.riskLevel)) {
          trackUnknown();
        }
      }
      if ("systolic" in riskMetric.riskRanges) {
        const { systolic } = riskMetric.riskRanges;
        if (isEmpty(systolic.riskIntervals)) {
          trackEmpty();
        }
        if (isRiskUnknown(systolic.riskLevel)) {
          trackUnknown();
        }
      }
      if ("riskLevel" in riskMetric.riskRanges) {
        if (isEmpty(riskMetric.riskRanges.riskIntervals)) {
          trackEmpty();
        }
        if (isRiskUnknown(riskMetric.riskRanges.riskLevel)) {
          trackUnknown();
        }
      }
    };
  };
}

function getRiskMetric<M extends MetricName>(
  name: M,
  visitMetrics: VisitMeasurement[]
): Array<RiskMetric<M>> {
  return visitMetrics.reduce<RiskMetric<M>[]>((acc, visitMetric) => {
    const { measurements, visit } = visitMetric;
    const country = getCountryFromVisit(visit);

    const isMeasurementNameMatch = (measurement: Measurement) => {
      return measurement.metricName === name;
    };

    const orderByTimestampDesc = (a: Measurement, b: Measurement) => {
      return (
        b.timestampStart.toUnixInteger() - a.timestampStart.toUnixInteger()
      );
    };

    const transformToMetric = (measurement: Measurement) => {
      return toRiskMetric(name, measurement, country);
    };

    const result = measurements
      .filter(isMeasurementNameMatch)
      .sort(orderByTimestampDesc)
      .map(transformToMetric)
      .at(0);

    if (result) {
      acc.push(result);
    }

    return acc;
  }, []);
}

export type PatientVisitMetrics = ReturnType<typeof usePatientVisitsMetrics>;
export type PatientVisitBloodMetrics = PatientVisitMetrics["bloodwork"];
export type PatientVisitBodyMetrics = PatientVisitMetrics["body"];
export type PatientVisitCardioMetrics = PatientVisitMetrics["cardio"];

export function usePatientVisitsMetrics(
  patientId: string,
  visits: Visit[] | undefined
) {
  const appInsights = useAppInsights();

  const api = useAPIClient();
  const { logError } = useReporting();
  const [visitsMeasurements, setVisitsMeasurements] =
    useState<VisitMeasurement[]>();

  useEffect(() => {
    if (patientId == null) {
      return;
    }
    if (visits == null || visits.length === 0) {
      return;
    }
    const requests = visits.map((visit) => {
      const req = api.measurement.fetchVisitMeasurements(
        patientId,
        visit.visitId
      );

      const toVisitMeasurement = (data: VisitMeasurementSummary) => {
        const { measurements } = data;
        return {
          visit,
          measurements,
        };
      };

      const result = req.result
        .then(fromAPI.toVisitMeasurementSummary)
        .then(toVisitMeasurement);

      return {
        result,
        abandon: req.abandon,
      };
    });

    Promise.all(requests.map((req) => req.result))
      .then(setVisitsMeasurements)
      .catch(silenceAbort)
      .catch(logError);

    return () => {
      requests.forEach((req) => req.abandon());
    };
  }, [api, logError, patientId, visits]);

  const logMetric = useMemo(
    () => trackDataValidity(appInsights)(patientId),
    [appInsights, patientId]
  );
  const trackMetric = useCallback(
    (metric: RiskMetric[] | undefined) => {
      if (!metric) {
        return;
      }
      metric.forEach(logMetric);
    },
    [logMetric]
  );

  const result = useMemo(() => {
    const get = <M extends MetricName>(name: M, trackDataValidity = false) => {
      if (visitsMeasurements == null) {
        return undefined;
      }
      const riskMetrics = getRiskMetric(name, visitsMeasurements);
      if (trackDataValidity) {
        trackMetric(riskMetrics);
      }
      return riskMetrics;
    };

    const shouldTrackDataValidity = true;

    const body = {
      height: get("body.height"),
      bmi: get("body.bmi", shouldTrackDataValidity),
      weight: get("body.weight"),
      waist: get("body.waist"),
      eyePressure: {
        left: get("risk_assessment.eye_pressure.left", shouldTrackDataValidity),
        right: get(
          "risk_assessment.eye_pressure.right",
          shouldTrackDataValidity
        ),
        difference: get(
          "risk_assessment.eye_pressure.difference",
          shouldTrackDataValidity
        ),
        wearsContactLenses: get(
          "risk_assessment.eye_pressure.wears_contact_lenses"
        ),
      },
      gripStrength: {
        left: get("body.grip_strength.left"),
        right: get("body.grip_strength.right"),
      },
    };

    const bloodwork = {
      cholesterol: get("bloodwork.cholesterol", shouldTrackDataValidity),
      cholesterolHDL: get("bloodwork.cholesterol_hdl", shouldTrackDataValidity),
      crp: get("bloodwork.crp", shouldTrackDataValidity),
      hba1c: get("bloodwork.hba1c", shouldTrackDataValidity),
      hdl: get("bloodwork.hdl", shouldTrackDataValidity),
      ldl: get("bloodwork.ldl", shouldTrackDataValidity),
      nonHDL: get("bloodwork.non_hdl", shouldTrackDataValidity),
      triglycerides: get("bloodwork.triglycerides", shouldTrackDataValidity),
      haemoglobin: get("bloodwork.haemoglobin", shouldTrackDataValidity),
      glucose: get("bloodwork.glucose", shouldTrackDataValidity),
      whiteBloodCells: get(
        "bloodwork.white_blood_cells.total",
        shouldTrackDataValidity
      ),
      neutrophils: get(
        "bloodwork.white_blood_cells.neutrophils",
        shouldTrackDataValidity
      ),
      basophils: get(
        "bloodwork.white_blood_cells.basophils",
        shouldTrackDataValidity
      ),
      monocytes: get(
        "bloodwork.white_blood_cells.monocytes",
        shouldTrackDataValidity
      ),
      eosinophils: get(
        "bloodwork.white_blood_cells.eosinophils",
        shouldTrackDataValidity
      ),
      lymphocytes: get(
        "bloodwork.white_blood_cells.lymphocytes",
        shouldTrackDataValidity
      ),
    };

    const cardio = {
      bloodOxygen: get("cardio.oxygen_saturation"),
      heartRate: get("cardio.ecg.heart_rate"),
      respiratoryRate: get("cardio.respiratory_rate"),
      anklePressure: {
        left: get("cardio.ankle_pressure.left"),
        right: get("cardio.ankle_pressure.right"),
      },
      brachialPressure: {
        left: get("cardio.brachial_pressure.left", shouldTrackDataValidity),
        right: get("cardio.brachial_pressure.right", shouldTrackDataValidity),
        difference: get(
          "cardio.brachial_pressure.difference",
          shouldTrackDataValidity
        ),
      },
      toePressure: {
        left: get("cardio.toe_pressure.left"),
        right: get("cardio.toe_pressure.right"),
      },
      ecg: {
        p: get("cardio.ecg.segments.p", shouldTrackDataValidity),
        pr: get("cardio.ecg.segments.pr", shouldTrackDataValidity),
        qrs: get("cardio.ecg.segments.qrs", shouldTrackDataValidity),
        qt: get("cardio.ecg.segments.qt", shouldTrackDataValidity),
        qtc: get("cardio.ecg.segments.qtc", shouldTrackDataValidity),
      },
      abi: {
        left: get("cardio.abi.left", true),
        right: get("cardio.abi.right", true),
      },
    };

    const risk = {
      congestiveHeartFailureHistory: get(
        "risk_assessment.congestive_heart_failure_history"
      ),
      ecgDiscrepancy: get("risk_assessment.ecg_discrepancy"),
      diabetesHistory: get("risk_assessment.diabetes_history"),
      drugInducedBleeding: get("risk_assessment.drug-induced_bleeding"),
      hypertensionHistory: get("risk_assessment.hypertension_history"),
      kidneyDisease: get("risk_assessment.kidney_disease"),
      labileINR: get("risk_assessment.labile_inr"),
      liverDisease: get("risk_assessment.liver_disease"),
      majorBleedingHistory: get("risk_assessment.major_bleeding_history"),
      strokeHistory: get("risk_assessment.stroke_history"),
      thromboembolismHistory: get("risk_assessment.thromboembolism_history"),
      TIAHistory: get("risk_assessment.tia_history"),
      vascularDiseaseHistory: get("risk_assessment.vascular_disease_history"),
    };

    return { bloodwork, body, cardio, risk };
  }, [trackMetric, visitsMeasurements]);

  return result;
}
