import { APITypesV1 } from "@cur8/api-client";
import {
  Annotation,
  Assessment,
  ImmutableScan,
  JournalNoteItem,
  Patient,
  Recording,
  RoomWithScheduleSlots,
  ScheduleViewSlot,
  User,
  VisitSummary,
} from "@cur8/rich-entity";
import { PrivateJournalNote } from "@cur8/rich-entity/dist/types/PrivateJournalNote";
import { createStoreContext, useEntity } from "@pomle/react-flat-store";

// TODO: move to rich-entity
import { BookingToken } from "render/pages/PatientDetailPage/types";

function useEntities() {
  return {
    annotation: useEntity<Annotation>(),
    assessment: useEntity<Assessment>(),
    bookingSchedule: useEntity<RoomWithScheduleSlots[]>(),
    patient: useEntity<Patient>(),
    patientAppointment: useEntity<ScheduleViewSlot>(),
    patientBookingToken: useEntity<BookingToken>(),
    recording: useEntity<Recording>(),
    scan: useEntity<ImmutableScan>(),
    user: useEntity<User>(),
    physicalArtefact: useEntity<APITypesV1.PhysicalArtefact>(),
    visit: useEntity<VisitSummary>(),
    journal: useEntity<JournalNoteItem>(),
    privateJournalNote: useEntity<PrivateJournalNote>(),
  };
}

export const { StoreContext, useStore } = createStoreContext(useEntities);
