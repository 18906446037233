import { Path, codecs } from "@pomle/paths";

export function createPaths<T extends {}>(origin: Path<T>) {
  const root = origin.append("/schedule", {});

  const sites = root.append("/sites", {});
  const site = root.append("/site/:siteId", { siteId: codecs.string });

  return { sites, site };
}
