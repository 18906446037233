import { AvatarPodium, PodiumView } from "lib/avatar/podium";
import { useEffect } from "react";
import { Layer } from "render/pages/DashboardPage/types";

enum Mode {
  Front,
  Profile,
}

function toMode(layer: Layer) {
  if ([Layer.Identity, Layer.Body, Layer.Bloodwork].includes(layer)) {
    return Mode.Profile;
  }

  if ([Layer.Circulation, Layer.ArterialHealth, Layer.Cardio].includes(layer)) {
    return Mode.Front;
  }
}

interface PodiumMotionProps {
  layer: Layer;
  podium: AvatarPodium;
}

export function PodiumMotion({ layer, podium }: PodiumMotionProps) {
  const mode = toMode(layer);

  useEffect(() => {
    if (mode === Mode.Front) {
      podium.moveTo(PodiumView.Front);
    } else if (mode === Mode.Profile) {
      podium.moveTo(PodiumView.ProfileFront);
    }
  }, [podium, mode]);

  return null;
}
