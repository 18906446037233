import { useCallback } from "react";
import { useManageSelectedPatient } from "render/pages/SchedulePage/hooks/useManageSelectedPatient";
import { useSelectedPatient } from "render/pages/SchedulePage/hooks/useSelectedPatient";
import { useCalendarInteraction } from "../../../Calendar/hooks/useCalendarInteraction";
import { useCalendarState } from "../../../Calendar/hooks/useCalendarState";
import { AssigningPatient } from "../AssigningPatient";
import { EditingSlots } from "../EditingSlots";
import { MedicalExamsFilter } from "../MedicalExamsFilter";

export function CalendarStateDescription() {
  const selectedPatient = useSelectedPatient();
  const { clearPatient } = useManageSelectedPatient();
  const { enterCalendarViewMode } = useCalendarInteraction();
  const { calendarInteractionMode, medicalExamsFilter } = useCalendarState();

  const onCloseEditing = useCallback(enterCalendarViewMode, [
    enterCalendarViewMode,
  ]);

  return (
    <>
      {medicalExamsFilter.length > 0 && (
        <MedicalExamsFilter data={medicalExamsFilter} />
      )}
      {calendarInteractionMode === "booking" && (
        <AssigningPatient patient={selectedPatient} onClose={clearPatient} />
      )}

      {calendarInteractionMode === "editing" && (
        <EditingSlots onClose={onCloseEditing} />
      )}
    </>
  );
}
