import { getHighestRisk, RiskLevel } from "@cur8/measurements";
import { RiskMetric } from "lib/metric";
import { bySystolic } from "lib/metric/sort";
import { useMemo } from "react";
import {
  FloatingValueTarget,
  FloatingValueTargetProps,
  RiskToHighlightMap,
} from "render/ui/presentation/FloatingValueTarget";
import { ValueItem } from "render/ui/symbol/ValueItem";
import { BloodPressure } from "../components/BloodPressure";

interface MaxArmBloodpressureProps {
  brachialPressure: {
    left: RiskMetric<"cardio.brachial_pressure.left">[] | undefined;
    right: RiskMetric<"cardio.brachial_pressure.right">[] | undefined;
  };
  align: FloatingValueTargetProps["align"];
  withKnob: FloatingValueTargetProps["withKnob"];
}

export function MaxArmBloodpressure({
  brachialPressure: { left, right },
  align,
  withKnob,
}: MaxArmBloodpressureProps) {
  const highestPressure = useMemo(() => {
    if (!left || !right) {
      return;
    }

    return [...left.slice(0, 1), ...right.slice(0, 1)]
      .sort(bySystolic("desc"))
      .at(0);
  }, [left, right]);

  const risk = useMemo(() => {
    if (!highestPressure) {
      return RiskLevel.Unknown;
    }
    const { systolic, diastolic } = highestPressure.riskRanges;
    return getHighestRisk([systolic.riskLevel, diastolic.riskLevel]);
  }, [highestPressure]);

  const highlight = RiskToHighlightMap[risk];

  return (
    <FloatingValueTarget
      label="Arm Pressure"
      value={
        <ValueItem
          value={
            highestPressure ? (
              <BloodPressure brachial={highestPressure.unit} />
            ) : (
              "--"
            )
          }
          size="huge"
          alignItems="baseline"
        />
      }
      align={align}
      highlight={highlight}
      withKnob={withKnob}
      width={170}
    />
  );
}
