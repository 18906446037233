import { ReactNode, useMemo } from "react";
import styles from "./AnchorPopUp.module.sass";

interface AnchorPopUpProps {
  parent: HTMLDivElement | null;
  isLight: boolean;
  isUpper: boolean;
  anchorRect: DOMRect | null;
  children: ReactNode;
}

export function AnchorPopUp({
  parent,
  isLight,
  isUpper,
  anchorRect,
  children,
}: AnchorPopUpProps) {
  const style = useMemo(() => {
    if (!parent || !anchorRect) {
      return null;
    }

    const rect = parent.getBoundingClientRect();
    const midp = (anchorRect.right - anchorRect.left) / 2;
    const left = anchorRect.left - rect.left + midp;
    const top = isUpper
      ? anchorRect.bottom - rect.top + 8
      : anchorRect.top - rect.top - 8;
    const translateY = isUpper ? "0" : "-100%";

    return {
      left,
      top,
      transform: `translateX(-50%) translateY(${translateY})`,
    };
  }, [isUpper, anchorRect, parent]);

  if (!style || !anchorRect || !parent) {
    return null;
  }

  return (
    <div className={styles.AnchorPopUp} style={style} data-light={isLight}>
      {children}
    </div>
  );
}
