import {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useAPIClient } from "render/context/APIContext";
import { HoverTextButton } from "render/ui/trigger/HoverTextButton";
import styles from "./Chat.module.sass";
import { Bubble } from "./components/Bubble";
import { TypingIndicator } from "./components/TypingIndicator";
import { prePrompts } from "./prePrompts";

import { APITypesV1, APITypesV2 } from "@cur8/api-client";
import {
  firstInstruction,
  firstPrompt,
  ImproveResponse,
  repeatInstruction,
  response_format,
} from "./prompts";
import { Model } from "./types";
interface ChatProps {
  currentEditorSections: APITypesV1.SummarySections;
  onSectionsUpdated: (newSections: APITypesV1.SummarySections) => void;
  onReset: () => void;
}

const FIRST_MESSAGE = [
  { role: "system", content: [{ type: "text", text: firstInstruction }] },
  {
    role: "assistant",
    content: [{ type: "text", text: firstPrompt }],
  },
];

export function Chat({
  currentEditorSections,
  onSectionsUpdated,
  onReset,
}: ChatProps) {
  const [inputText, setInputText] = useState("");
  const [messages, setMessages] =
    useState<APITypesV2.OpenAIMessageV2[]>(FIRST_MESSAGE);
  const [waiting, setWaiting] = useState(false);

  const scrollAreaRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { OAIChatV2 } = useAPIClient().nlp;

  const addUserInput = useCallback(
    async (text: string) => {
      setWaiting(true);
      setInputText("");
      const newMessages: APITypesV2.OpenAIMessageV2[] = [
        ...messages,
        {
          role: "system",
          content: [
            { type: "text", text: repeatInstruction(currentEditorSections) },
          ],
        },
        { role: "user", content: [{ type: "text", text }] },
      ];
      setMessages(newMessages);

      try {
        let answer = await OAIChatV2({
          messages: newMessages,
          response_format,
          model: Model.GPT4o,
          temperature: 0.21,
          max_tokens: 2000,
        }).result;

        const content = answer.choices[0].message.content;

        if (content) {
          const data: ImproveResponse = JSON.parse(content);
          setMessages((p) => {
            return [
              ...p,
              {
                role: "assistant",
                content: [{ type: "text", text: data.whatWasChangedComment }],
              },
            ];
          });
          onSectionsUpdated(data.updatedSections);
        }
      } catch (e) {
        const msg = `Error calling llm: ${e}`;
        console.error(msg);
        setMessages((p) => {
          return [
            ...p,
            { role: "assistant", content: [{ type: "text", text: msg }] },
          ];
        });
      }
      setWaiting(false);
    },
    [messages, OAIChatV2, onSectionsUpdated, currentEditorSections]
  );

  const onStartOver = useCallback(() => {
    setMessages(FIRST_MESSAGE);
    onReset();
  }, [setMessages, onReset]);

  const handleInputKeyPress = async (e: KeyboardEvent<HTMLInputElement>) => {
    if (inputText && e.key === "Enter") {
      addUserInput(inputText);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputText(e.target.value);
  };

  useEffect(() => {
    if (scrollAreaRef.current) {
      scrollAreaRef.current.scrollTop = scrollAreaRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    inputRef.current?.focus();
  }, [waiting]);

  // Ensure we are always seeing the end of the text input
  useEffect(() => {
    if (inputRef && inputRef.current) {
      const element = inputRef.current;
      element.scrollLeft = element.scrollWidth;
    }
  }, [inputText]);

  return (
    <div className={styles.Chat}>
      <div className={styles.scrollArea} ref={scrollAreaRef}>
        {messages
          .filter((m) => {
            return m.role !== "system";
          })
          .map((m, index) => {
            return <Bubble message={m} key={index} />;
          })}
        {waiting && <TypingIndicator />}
      </div>
      <div className={styles.input}>
        <div className={styles.buttonRow}>
          {prePrompts.map((p, idx) => {
            return (
              <HoverTextButton onClick={() => addUserInput(p.prompt)} key={idx}>
                {p.title}
              </HoverTextButton>
            );
          })}
          <HoverTextButton onClick={onStartOver}>Start over</HoverTextButton>
        </div>
        <input
          ref={inputRef}
          value={inputText}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyPress}
          disabled={waiting}
          placeholder={waiting ? "" : "How can the summary be improved..."}
        />
      </div>
    </div>
  );
}
