import { APITypesV1 } from "@cur8/api-client";
import { ImmutableScan } from "@cur8/rich-entity";
import { silenceAbort } from "lib/error";
import { useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";

export function useHeartSoundQuality(scan: ImmutableScan | undefined) {
  const api = useAPIClient().scan;
  const [quality, setQuality] =
    useState<APITypesV1.QualityResultData["quality"]>();

  useEffect(() => {
    if (!scan) {
      return;
    }

    const request = api.fetchScanResult<APITypesV1.QualityResultData>({
      patientId: scan.patientId,
      scanId: scan.id,
      scanVersion: scan.version,
      resultName: "ui_heart_sound",
    });

    request.result
      .then((r) => {
        const data = r.data;
        setQuality(data.quality);
      })
      .catch(silenceAbort);

    return () => {
      setQuality(undefined);
      request.abandon();
    };
  }, [api, scan]);

  return quality;
}
