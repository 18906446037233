import { useCallback, useMemo } from "react";
import { useAssessmentNav } from "render/pages/AssessmentPage/hooks/useAssessmentNav";
import { Typography } from "render/ui/presentation/Typography";
import { ButtonSet } from "render/ui/trigger/ButtonSet";
import { HoverTextButton } from "render/ui/trigger/HoverTextButton";
import { useRangeContext } from "../../../context/RangeContext";
import { PulsewaveType } from "../../../lib/types";
import styles from "./styles.module.sass";

export function ButtonControl() {
  const { pulsewaveType, setPulsewaveType } = useAssessmentNav();
  const { onPulseWaveTypeChanged } = useRangeContext();

  const title = useMemo(() => {
    switch (pulsewaveType) {
      case PulsewaveType.lveti:
        return "Ejection Time";
      case PulsewaveType.pulseWaveVelocity:
        return "Pulse Wave Velocity";
      case PulsewaveType.footAsymmetry:
        return "Pulse Asymmetry (feet)";
      case PulsewaveType.handAsymmetry:
        return "Pulse Asymmetry (hands)";
    }
  }, [pulsewaveType]);

  const setPulsewaveTypeWrapper = useCallback(
    (pwt: PulsewaveType) => {
      setPulsewaveType(pwt);
      onPulseWaveTypeChanged(pwt);
    },
    [onPulseWaveTypeChanged, setPulsewaveType]
  );

  return (
    <div className={styles.ButtonControl}>
      <Typography variant="title-m">{title}</Typography>
      <ButtonSet>
        <HoverTextButton
          active={pulsewaveType === PulsewaveType.lveti}
          onClick={() => {
            setPulsewaveTypeWrapper(PulsewaveType.lveti);
          }}
        >
          Ejection Time
        </HoverTextButton>

        <HoverTextButton
          active={pulsewaveType === PulsewaveType.pulseWaveVelocity}
          onClick={() => {
            setPulsewaveTypeWrapper(PulsewaveType.pulseWaveVelocity);
          }}
        >
          Pulse Wave Velocity
        </HoverTextButton>

        <HoverTextButton
          active={pulsewaveType === PulsewaveType.handAsymmetry}
          onClick={() => {
            setPulsewaveTypeWrapper(PulsewaveType.handAsymmetry);
          }}
        >
          Hand Asymmetry
        </HoverTextButton>
        <HoverTextButton
          active={pulsewaveType === PulsewaveType.footAsymmetry}
          onClick={() => {
            setPulsewaveTypeWrapper(PulsewaveType.footAsymmetry);
          }}
        >
          Foot Asymmetry
        </HoverTextButton>
      </ButtonSet>
    </div>
  );
}
