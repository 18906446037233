import { RiskLevel } from "@cur8/measurements";
import { RiskMetric } from "lib/metric";
import { useMemo } from "react";
import {
  AuxTitle,
  Diff,
  DiffValue,
  MainTitle,
  MetricResultHeader,
  Subtitle,
  Titles,
} from "render/ui/presentation/MetricResultHeader";
import {
  RangeChart,
  RangeChartDataComposer,
} from "render/ui/presentation/RangeChart";
import {
  selectChartRanges,
  toChartRanges,
} from "render/ui/presentation/RangeChart/lib/risk";
import { BloodPressureType } from "../../lib/projection";

const getTitlesForValuesAndType = (
  value: number,
  type: BloodPressureType,
  prevValue?: number
): { title: string; subtitle?: string; aux?: string } => {
  const areValuesEqual = value === prevValue;

  const AuxTitleForBloodPressureType: Record<BloodPressureType, string> = {
    systolic_blood_pressure: "[Systolic]",
    diastolic_blood_pressure: "[Diastolic]",
  };

  const TitleTitleForBloodPressureType: Record<BloodPressureType, string> = {
    systolic_blood_pressure: "upper arm",
    diastolic_blood_pressure: "lower arm",
  };

  if (!prevValue) {
    return {
      title: "Blood Pressure",
      aux: AuxTitleForBloodPressureType[type],
    };
  }

  if (areValuesEqual) {
    return {
      title: "Maintained",
      subtitle: TitleTitleForBloodPressureType[type],
      aux: AuxTitleForBloodPressureType[type],
    };
  }

  if (value > prevValue) {
    return {
      title: "Increased",
      subtitle: TitleTitleForBloodPressureType[type],
      aux: AuxTitleForBloodPressureType[type],
    };
  }

  if (value < prevValue) {
    return {
      title: "Improved",
      subtitle: TitleTitleForBloodPressureType[type],
      aux: AuxTitleForBloodPressureType[type],
    };
  }

  return {
    title: AuxTitleForBloodPressureType[type],
    aux: AuxTitleForBloodPressureType[type],
  };
};

interface BloodPressureSegmentProps {
  currentMetric:
    | RiskMetric<"cardio.brachial_pressure.left">
    | RiskMetric<"cardio.brachial_pressure.right">;
  previousMetric?:
    | RiskMetric<"cardio.brachial_pressure.left">
    | RiskMetric<"cardio.brachial_pressure.right">;
  bloodPressureType: BloodPressureType;
}

export function BloodPressureSegment({
  currentMetric,
  previousMetric,
  bloodPressureType,
}: BloodPressureSegmentProps) {
  const currentValue = useMemo(() => {
    return bloodPressureType === "diastolic_blood_pressure"
      ? currentMetric.unit.diastolic
      : currentMetric.unit.systolic;
  }, [
    bloodPressureType,
    currentMetric.unit.diastolic,
    currentMetric.unit.systolic,
  ]);
  const previousValue = useMemo(() => {
    return bloodPressureType === "diastolic_blood_pressure"
      ? previousMetric?.unit.diastolic
      : previousMetric?.unit.systolic;
  }, [
    bloodPressureType,
    previousMetric?.unit.diastolic,
    previousMetric?.unit.systolic,
  ]);

  const riskRanges = useMemo(() => {
    return bloodPressureType === "diastolic_blood_pressure"
      ? currentMetric.riskRanges.diastolic
      : currentMetric.riskRanges.systolic;
  }, [
    bloodPressureType,
    currentMetric.riskRanges.diastolic,
    currentMetric.riskRanges.systolic,
  ]);

  // https://dev.azure.com/cur8/Engineering/_workitems/edit/26137
  const ranges = useMemo(() => {
    const chartRanges = toChartRanges(riskRanges.riskIntervals);
    return selectChartRanges(chartRanges, {
      mode: "static",
      startAt: RiskLevel.Optimal,
      endAt: RiskLevel.HighRisk,
    });
  }, [riskRanges]);

  // https://dev.azure.com/cur8/Engineering/_workitems/edit/26137
  const rangesCapLow = 100;
  const rangesCapHigh = 150;

  const titles = getTitlesForValuesAndType(
    currentValue.mmHg,
    bloodPressureType,
    previousValue?.mmHg
  );

  return (
    <div>
      <MetricResultHeader>
        <Titles>
          <MainTitle>{titles.title}</MainTitle>
          <Subtitle>{titles.subtitle}</Subtitle>
          <AuxTitle>{titles.aux}</AuxTitle>
        </Titles>
        <Diff>
          <DiffValue
            formatValue={(value) => value?.toFixed(0)}
            valuesDiff={previousValue && currentValue.mmHg - previousValue.mmHg}
            unit="mmHg"
          />
        </Diff>
      </MetricResultHeader>
      <RangeChartDataComposer
        ranges={ranges}
        rangesCapLow={rangesCapLow}
        rangesCapHigh={rangesCapHigh}
        value={currentValue.mmHg}
        previousValue={previousValue?.mmHg}
      >
        {({ ranges, values }) => (
          <RangeChart ranges={ranges} values={values} showEmpty />
        )}
      </RangeChartDataComposer>
    </div>
  );
}
