import { $isLinkNode } from "@lexical/link";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $createParagraphNode,
  $createTextNode,
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_NORMAL,
  KEY_ENTER_COMMAND,
  LexicalNode,
  ParagraphNode,
  TextNode,
} from "lexical";
import { useEffect } from "react";

/**
 * Checks if the candidate node contains the target node in its hierarchy.
 *
 * @param candidate - The node to test as an ancestor.
 * @param target - The node to check.
 * @returns True if candidate is the same as or an ancestor of target.
 */
function nodeContains(candidate: LexicalNode, target: LexicalNode): boolean {
  let current: LexicalNode | null = target;
  while (current !== null) {
    if (current === candidate) {
      return true;
    }
    current = current.getParent();
  }
  return false;
}

export function BulletReturnPlugin(): JSX.Element | null {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerCommand(
      KEY_ENTER_COMMAND,
      (event: KeyboardEvent) => {
        event.preventDefault();
        editor.update(() => {
          const selection = $getSelection();
          if (!$isRangeSelection(selection) || !selection.isCollapsed()) {
            return;
          }

          let bullet: LexicalNode | null = selection.anchor.getNode();
          while (bullet && !(bullet instanceof ParagraphNode)) {
            bullet = bullet.getParent();
          }
          if (!bullet) {
            return;
          }

          // Check if the selection is inside a link.
          let current: LexicalNode | null = selection.anchor.getNode();
          let inLink = false;
          while (current !== null) {
            if ($isLinkNode(current)) {
              inLink = true;
              break;
            }
            current = current.getParent();
          }

          // Mimic Ctrl+X: cut all content from the selection point to the end.
          const bulletChildren = bullet.getChildren();
          const cutNodes: LexicalNode[] = [];
          let found = false;

          for (const child of bulletChildren) {
            if (!found) {
              if (
                child === selection.anchor.getNode() ||
                nodeContains(child, selection.anchor.getNode())
              ) {
                found = true;
                if (inLink) {
                  // If the selection is inside a link, move the entire node intact.
                  cutNodes.push(child);
                } else if (child instanceof TextNode) {
                  const textContent = child.getTextContent();
                  const offset = selection.anchor.offset;
                  // Keep left portion in the original node.
                  child.setTextContent(textContent.slice(0, offset));
                  const rightPart = textContent.slice(offset);
                  if (rightPart.length > 0) {
                    cutNodes.push($createTextNode(rightPart));
                  }
                } else {
                  cutNodes.push(child);
                }
              }
            } else {
              cutNodes.push(child);
            }
          }

          // Remove the cut nodes from the current bullet.
          for (const node of cutNodes) {
            if (node.isAttached()) {
              node.remove();
            }
          }

          // Create a new bullet for the cut content.
          const newBullet = $createParagraphNode();
          // Always preserve the original node structure, including any link nodes.
          for (const node of cutNodes) {
            newBullet.append(node);
          }

          // If no content was cut, ensure the new bullet has an empty text node.
          if (newBullet.getChildren().length === 0) {
            newBullet.append($createTextNode(""));
          }

          // Ensure the new bullet starts with a text node.
          const firstChild = newBullet.getFirstChild();
          if (firstChild) {
            if (!(firstChild instanceof TextNode)) {
              firstChild.insertBefore($createTextNode(""));
            }
          } else {
            newBullet.append($createTextNode(""));
          }

          // Insert the new bullet after the current bullet.
          bullet.insertAfter(newBullet);

          // Place the selection at the beginning of the new bullet.
          const newSelection = $getSelection();
          if ($isRangeSelection(newSelection)) {
            const firstChild = newBullet.getFirstChild();
            if (firstChild instanceof TextNode) {
              newSelection.setTextNodeRange(firstChild, 0, firstChild, 0);
            }
          }
        });
        return true;
      },
      COMMAND_PRIORITY_NORMAL
    );
  }, [editor]);

  return null;
}
