import { BookableSlot, Site } from "@cur8/rich-entity";
import { PathLink, useNav, useQueryParams } from "@pomle/react-router-paths";
import { DateTime, Interval } from "luxon";
import { useMemo } from "react";
import { paths } from "render/routes/paths";
import { query } from "render/routes/querys";
import { DayOfMonthDate } from "render/ui/format/DayOfMonthDate";
import styles from "./styles.module.sass";

interface CalendarDayProps {
  site: Site;
  date: DateTime;
  slots: BookableSlot[] | undefined;
  isActive: boolean;
}

export function CalendarDay({ site, date, slots, isActive }: CalendarDayProps) {
  const [params] = useQueryParams(query.schedule);

  const now = useMemo(() => {
    return DateTime.now();
  }, []);

  const day = useMemo(() => {
    return Interval.fromDateTimes(date.startOf("day"), date.endOf("day"));
  }, [date]);

  const isPast = now > day.end;
  const isToday = day.contains(now);
  const delay = date.day * 20;
  const nav = useNav(paths.schedule.site, query.schedule);

  const link = useMemo(() => {
    return nav.to(
      { siteId: site.siteId },
      {
        from: [day.start],
        to: [day.end],
        assignPatientId: params.assignPatientId,
        medicalExams: params.medicalExams,
        censorAppointments: params.censorAppointments,
        slotManagement: params.slotManagement,
        roomId: params.roomId,
      }
    );
  }, [nav, day, site, params]);

  const showLoader = !isPast && !slots && isActive;

  const variant = useMemo(() => {
    if (isPast) {
      return "past-day";
    }

    if (!slots) {
      return "loading";
    }

    const relevant = slots?.filter((slot) => {
      return day.contains(slot.startTime);
    });

    if (relevant.some((slot) => slot.isOpenPublicly)) {
      return "has-open-slots";
    }

    if (relevant.some((slot) => !slot.isOpenPublicly)) {
      return "has-open-but-reserved";
    }

    return "no-slots";
  }, [isPast, day, slots]);

  return (
    <PathLink to={link}>
      <div
        className={styles.CalendarDay}
        style={{
          transitionDelay: `${delay}ms`,
        }}
        data-is-today={isToday}
        data-variant={variant}
      >
        <div className={styles.indicator} />

        {isToday && <div className={styles.todayIndicator} />}

        {showLoader && <div className={styles.loader} />}

        <DayOfMonthDate date={date} />
      </div>
    </PathLink>
  );
}
