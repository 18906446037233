import { PropsWithChildren, ReactNode } from "react";
import { PageHeader } from "render/ui/layouts/PageHeader";
import styles from "./styles.module.sass";
import { Typography } from "render/ui/presentation/Typography";

interface StepLayoutProps {
  header: ReactNode;
  subHeader?: ReactNode;
}

export function StepLayout(props: PropsWithChildren<StepLayoutProps>) {
  return (
    <div className={styles.StepLayout}>
      <div className={styles.head}>
        <PageHeader caption={props.header} />
        <div className={styles.subHeader}>
          <Typography variant="label-m">{props.subHeader ?? ""}</Typography>
        </div>
      </div>
      {props.children}
    </div>
  );
}
