import { APITypesV1 } from "@cur8/api-client";
import { BulletSectionEditor } from "../BulletSectionEditor";
import { PlaintextSectionEditor } from "../PlaintextSectionEditor";
import { NO_MEASUREMENTS, SectionTitle } from "./sections";
import { useSectionMeasurements } from "./useSectionMeasurements";
interface SummaryEditorProps {
  patientId: string;
  visitId: string;
  sections: APITypesV1.SummarySections | undefined;
  onChange: (newSections: APITypesV1.SummarySections) => void;
  editorKey: string;
}

export function SummaryEditor({
  patientId,
  visitId,
  editorKey,
  sections,
  onChange,
}: SummaryEditorProps) {
  const measurements = useSectionMeasurements({ patientId, visitId });

  return (
    <>
      <PlaintextSectionEditor
        title={SectionTitle.overview}
        placeholder="Write a short summary"
        content={sections?.introduction || ""}
        onChange={(v) =>
          sections ? onChange({ ...sections, introduction: v }) : null
        }
      />
      <BulletSectionEditor
        editorKey={editorKey}
        title={SectionTitle.heart}
        content={sections?.heart}
        measurements={measurements}
        extraLinks={[]}
        onChange={(v) =>
          sections ? onChange({ ...sections, heart: v }) : null
        }
      />
      <BulletSectionEditor
        editorKey={editorKey}
        title={SectionTitle.body}
        content={sections?.body}
        measurements={measurements}
        extraLinks={[]}
        onChange={(v) => (sections ? onChange({ ...sections, body: v }) : null)}
      />
      <BulletSectionEditor
        editorKey={editorKey}
        title={SectionTitle.skin}
        content={sections?.skin}
        measurements={NO_MEASUREMENTS}
        extraLinks={[
          { metricName: "skin.markings", friendlyName: "Skin markings" },
        ]}
        onChange={(v) => (sections ? onChange({ ...sections, skin: v }) : null)}
      />
      <BulletSectionEditor
        editorKey={editorKey}
        title={SectionTitle.recommendations}
        content={sections?.recommendations}
        measurements={NO_MEASUREMENTS}
        extraLinks={[]}
        onChange={(v) =>
          sections ? onChange({ ...sections, recommendations: v }) : null
        }
      />
    </>
  );
}
