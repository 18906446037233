import { LinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getNearestNodeOfType } from "@lexical/utils";
import {
  $getSelection,
  $isRangeSelection,
  $setSelection,
  RangeSelection,
} from "lexical";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SectionMeasurement } from "render/fragments/visit/Editors/SummaryEditor/sections";
import { stripLeftRight } from "../../../../../SummaryEditor/useSectionMeasurements";
import LinkIcon from "../../assets/link.svg?react";
import { AnchorPopUp } from "../AnchorPopUp";
import styles from "./SelectionActionPlugin.module.sass";

export type LinkInfo = {
  metricName: string;
  friendlyName: string;
};
interface SelectionActionPluginProps {
  parent: HTMLDivElement | null;
  measurements: SectionMeasurement[];
  extraLinks: LinkInfo[];
}

function isLinked(selection: RangeSelection) {
  const nodes = selection.getNodes();
  for (const node of nodes) {
    const linkNode = $getNearestNodeOfType(node, LinkNode);
    if (linkNode) {
      return linkNode.getURL();
    }
  }

  return null;
}

export function SelectionActionPlugin({
  parent,
  measurements,
  extraLinks,
}: SelectionActionPluginProps) {
  const [editor] = useLexicalComposerContext();
  const [rect, setRect] = useState<DOMRect | null>(null);
  const [visible, setVisible] = useState(false);
  const [linked, setLinked] = useState<string | null>();
  const [isUpper, setIsUpper] = useState<boolean>();

  useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        const selection = $getSelection();
        if (
          selection &&
          $isRangeSelection(selection) &&
          !selection.isCollapsed()
        ) {
          setLinked(isLinked(selection));
          const domSelection = window.getSelection();
          if (domSelection?.rangeCount) {
            const range = domSelection.getRangeAt(0);
            setRect(range.getBoundingClientRect());
            setVisible(true);
          }
        } else {
          setVisible(false);
        }
      });
    });
  }, [editor, linked]);

  const handleLinkAdded = useCallback(() => {
    // clear selection after adding link
    editor.update(() => {
      $setSelection(null);
    });
    window.getSelection()?.removeAllRanges();
    setVisible(false);
  }, [editor]);

  const toggleLink = useCallback(
    (url: string) => {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          // Apply a new link
          editor.dispatchCommand(TOGGLE_LINK_COMMAND, url);
          handleLinkAdded();
        }
      });
    },
    [editor, handleLinkAdded]
  );

  const uniqueNames = useMemo(() => {
    const seen = new Set();
    let unique: LinkInfo[] = [];
    for (const m of measurements) {
      if (!m.metricName) {
        continue;
      }
      const stripped = stripLeftRight(m.metricName);
      if (seen.has(stripped)) {
        continue;
      }
      unique.push({ metricName: stripped, friendlyName: m.friendlyName });
      seen.add(stripped);
    }

    unique = unique.concat(extraLinks);

    return unique.sort((a, b) => a.friendlyName.localeCompare(b.friendlyName));
  }, [measurements, extraLinks]);

  const isOnUpper = (event: MouseEvent): boolean => {
    const y = event.clientY || 0;
    return y < window.innerHeight / 2;
  };

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      if (isUpper === undefined) {
        setIsUpper(isOnUpper(event));
      }
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [isUpper]);

  if (linked || !visible || uniqueNames.length < 1) {
    return null;
  }

  return (
    <AnchorPopUp
      parent={parent}
      anchorRect={rect}
      isLight={false}
      isUpper={isUpper === true}
    >
      <div className={styles.SelectionActionPlugin}>
        <div className={styles.selectLinkTitle}>
          <LinkIcon /> Add link
        </div>
        <div className={styles.buttons}>
          {uniqueNames.map((l, index) => {
            return (
              <button
                onClick={() => {
                  toggleLink(l.metricName!);
                }}
                key={index}
              >
                {l.friendlyName}
              </button>
            );
          })}
        </div>
      </div>
    </AnchorPopUp>
  );
}
