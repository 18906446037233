import { APITypesV1 } from "@cur8/api-client";
import { Patient, Sex } from "@cur8/rich-entity";
import { useCallback, useEffect, useState } from "react";
import { useAssessmentContext } from "render/pages/AssessmentPage/context/AssessmentContext";
import { LVETISelectionState } from "render/pages/AssessmentPage/context/AssessmentContext/types";
import { TimeSpentTracker } from "../../../TimeSpentTracker";
import { useRangeContext } from "../../context/RangeContext";
import { CardioSignals } from "../../hooks/useCardioSignalsTimestamps";
import { calculateAverageLVETI, calculateLVETI } from "../../lib/calculations";
import { PulsewaveType } from "../../lib/types";
import { GraphWithRuler } from "../shared/GraphWithRuler";
import { ECG_COLOR, SSN_COLOR } from "../shared/constants";
import { SelectionBox } from "./SelectionBox";
import styles from "./styles.module.sass";

interface EjectionTimeProps {
  patient: Patient | undefined;
  signals: CardioSignals;
}

export function EjectionTime({ patient, signals }: EjectionTimeProps) {
  const { mutablePW, setMutablePW, type } = useAssessmentContext();
  const { onPulseWaveTypeChanged } = useRangeContext();
  const lvetiSelections = mutablePW?.lveti.selections;

  const [selectionIndex, setSelectionIndex] = useState<number>(0);
  const [txtInstructions, setInstructions] = useState<string>();
  const [txtError, setError] = useState<string>();

  const setSelectionStatus = useCallback(
    (idx: number, state: LVETISelectionState) => {
      setMutablePW((prev) => {
        if (!prev) {
          return undefined;
        }
        return {
          ...prev,
          lveti: {
            ...prev.lveti,
            selections: {
              ...prev.lveti?.selections,
              [idx]: {
                ...prev.lveti?.selections![idx],
                state: state,
              },
            },
          },
        };
      });
    },
    [setMutablePW]
  );

  const markSelectionAsReviewed = useCallback(
    (idx: number) => {
      if (!mutablePW) {
        return;
      }
      const asel = mutablePW.lveti.selections[idx];
      if (asel.state === LVETISelectionState.proposed) {
        setSelectionStatus(idx, LVETISelectionState.reviewed);
      }
    },
    [mutablePW, setSelectionStatus]
  );

  const calcLvetiWrapper = useCallback(
    (lvetRange: APITypesV1.Range, rrInterval: APITypesV1.Range) => {
      return calculateLVETI(lvetRange, rrInterval, patient!.sex);
    },
    [patient]
  );

  const wrapSetSelectionIndex = useCallback(
    (idx: number) => {
      setSelectionIndex(idx);
      onPulseWaveTypeChanged(PulsewaveType.lveti, idx);
      markSelectionAsReviewed(idx);
      setError(undefined);
    },
    [markSelectionAsReviewed, onPulseWaveTypeChanged]
  );

  const flagSelectionIncorrect = useCallback(
    (errmsg: string) => {
      setError(errmsg);
      setSelectionStatus(selectionIndex, LVETISelectionState.incorrect);
    },
    [selectionIndex, setSelectionStatus]
  );

  const flagSelectionCleared = useCallback(() => {
    setError(undefined);
    setSelectionStatus(selectionIndex, LVETISelectionState.reviewed);
  }, [selectionIndex, setSelectionStatus]);

  useEffect(() => {
    if (mutablePW?.lveti && mutablePW?.lveti.medianSSNSignalQuality) {
      // Suggested LVETI values
      setInstructions(
        "Make sure to review all three selections before saving the assessment."
      );
    } else {
      setInstructions("Make a selection in the plot to measure LVETI");
    }
  }, [mutablePW?.lveti]);

  useEffect(() => {
    if (!patient || patient.sex === Sex.Unknown) {
      setError("Member's sex not specified");
    }
  }, [patient]);

  /**
   * Find R-peaks and calculate HR
   */
  const findRPeaks = useCallback(
    (range: APITypesV1.Range) => {
      if (!signals || !signals.rPeaks) {
        return;
      }
      const rpeaks = signals.rPeaks;

      let left = 0;
      let right = rpeaks.length - 1;
      let resultIndex: number | null = null;

      while (left <= right) {
        const mid = Math.floor((left + right) / 2);

        if (rpeaks[mid] < range.from) {
          // If peak is less than time, update resultIndex to mid
          resultIndex = mid;
          // Move right to potentially find a closer value
          left = mid + 1;
        } else {
          // Move left to search for values less than time
          right = mid - 1;
        }
      }

      if (!resultIndex) {
        flagSelectionIncorrect("Can't find R-R Interval");
        return;
      }
      // Verify that the R-peak after the one we've found is larger than end-time
      if (rpeaks[resultIndex + 1] < range.to) {
        flagSelectionIncorrect(
          "The LVET-interval cannot be larger than R-R-interval"
        );
        return;
      }
      const interval: APITypesV1.Range = {
        from: rpeaks[resultIndex],
        to: rpeaks[resultIndex + 1],
        unit: "s",
      };
      flagSelectionCleared();
      return interval;
    },
    [flagSelectionCleared, flagSelectionIncorrect, signals]
  );

  /**
   * Run initial calculations
   */
  useEffect(() => {
    if (!mutablePW) {
      return;
    }
    const sels = mutablePW.lveti.selections;
    let modified = false;
    for (let i = 0; i < 3; i++) {
      if (sels[i].ssn && sels[i].rrInterval && !sels[i].hr) {
        // Proposed data => run calculations
        let state =
          !mutablePW.lveti.medianSSNSignalQuality || i === selectionIndex
            ? LVETISelectionState.reviewed
            : LVETISelectionState.proposed;
        sels[i] = calculateLVETI(
          sels[i].ssn!,
          sels[i].rrInterval!,
          patient!.sex,
          state
        );
        modified = true;
        if (sels[selectionIndex].ssn && sels[selectionIndex].rrInterval) {
          calcLvetiWrapper(
            sels[selectionIndex].ssn!,
            sels[selectionIndex].rrInterval!
          );
        }
      }
    }
    if (modified) {
      setMutablePW((prev) => {
        if (!prev) {
          return undefined;
        }
        return {
          ...prev,
          lveti: {
            ...prev.lveti,
            average: calculateAverageLVETI(sels),
            selections: sels,
          },
        };
      });
    }
  }, [calcLvetiWrapper, mutablePW, patient, selectionIndex, setMutablePW]);

  const rrIntervalResize = useCallback(
    (pos: number, dragLeft: boolean, complete: boolean = false) => {
      setMutablePW((prev) => {
        if (!prev || !prev.lveti || !prev.lveti.selections) {
          return prev;
        }
        const sels = prev.lveti.selections;
        if (
          sels &&
          sels[selectionIndex] &&
          sels[selectionIndex].ssn &&
          sels[selectionIndex].rrInterval
        ) {
          const cur = sels[selectionIndex].rrInterval!;
          const lvetRange = sels[selectionIndex].ssn!;
          const rr: APITypesV1.Range = dragLeft
            ? { from: pos, to: cur.to }
            : { from: cur.from, to: pos };

          if (rr.from > lvetRange.from || rr.to < lvetRange.to) {
            flagSelectionIncorrect(
              "R-R-interval has to cover the LVET-interval"
            );
          } else {
            sels[selectionIndex] = calcLvetiWrapper(lvetRange, rr);
            sels[selectionIndex].state = LVETISelectionState.reviewed;
            flagSelectionCleared();
          }
        }
        return {
          ...prev,
          lveti: {
            ...prev.lveti,
            average: calculateAverageLVETI(sels),
            selections: sels,
          },
        };
      });
    },
    [
      calcLvetiWrapper,
      flagSelectionCleared,
      flagSelectionIncorrect,
      selectionIndex,
      setMutablePW,
    ]
  );

  const lvetIntervalResize = useCallback(
    (pos: number, dragLeft: boolean, complete: boolean = false) => {
      setMutablePW((prev) => {
        if (!prev || !prev.lveti || !prev.lveti.selections) {
          return prev;
        }
        const sels = prev.lveti.selections;
        if (
          sels &&
          sels[selectionIndex] &&
          sels[selectionIndex].ssn &&
          sels[selectionIndex].rrInterval
        ) {
          const rr = sels[selectionIndex].rrInterval!;
          const cur = sels[selectionIndex].ssn!;
          const lvetRange: APITypesV1.Range = dragLeft
            ? { from: pos, to: cur.to }
            : { from: cur.from, to: pos };

          if (rr.from > lvetRange.from || rr.to < lvetRange.to) {
            flagSelectionIncorrect(
              "R-R-interval has to cover the LVET-interval"
            );
          } else if (lvetRange.from >= lvetRange.to) {
            // Don't allow negative selection on LVET
            console.debug("Negative selection - ignored");
          } else {
            sels[selectionIndex] = calcLvetiWrapper(lvetRange, rr);
            sels[selectionIndex].state = LVETISelectionState.reviewed;
            flagSelectionCleared();
          }
        }
        return {
          ...prev,
          lveti: {
            ...prev.lveti,
            average: calculateAverageLVETI(sels),
            selections: sels,
          },
        };
      });
    },
    [
      calcLvetiWrapper,
      flagSelectionCleared,
      flagSelectionIncorrect,
      selectionIndex,
      setMutablePW,
    ]
  );

  const onLvetRange = useCallback(
    (range: APITypesV1.Range | undefined, complete: boolean = false) => {
      let setRange: APITypesV1.Range | undefined = range;
      if (!range || range.to - range.from <= 0) {
        setRange = undefined;
        setError(undefined);
      }
      setMutablePW((prev) => {
        if (!prev || !prev.lveti || !prev.lveti.selections) {
          return prev;
        }
        const sels = prev.lveti.selections;

        if (!setRange) {
          // No area selected => reset
          sels[selectionIndex] = {
            state: LVETISelectionState.empty,
          };
        } else {
          sels[selectionIndex].ssn = range ? range : { from: 0, to: 0 };
          const rrInt = findRPeaks(setRange);
          if (rrInt) {
            sels[selectionIndex] = calcLvetiWrapper(setRange, rrInt);
            sels[selectionIndex].state = LVETISelectionState.reviewed;
          }
        }
        return {
          ...prev,
          lveti: {
            ...prev.lveti,
            average: calculateAverageLVETI(sels),
            selections: sels,
          },
        };
      });
    },
    [calcLvetiWrapper, findRPeaks, selectionIndex, setMutablePW]
  );

  return signals ? (
    <div>
      <GraphWithRuler
        data={[
          {
            label: "Cardiac Rhythm",
            signal: signals.bioamp_ecg,
            color: ECG_COLOR,
          },
          {
            label: "SSN",
            signal: signals.ssn_displacement,
            color: SSN_COLOR,
          },
        ]}
        diffData={[
          {
            label: "SSN Acceleration",
            signal: signals.ssn_acceleration,
            color: SSN_COLOR,
          },
        ]}
        lvetIntervalResize={lvetIntervalResize}
        onSelectionRange={onLvetRange}
        selectionRange={
          lvetiSelections ? lvetiSelections[selectionIndex].ssn : undefined
        }
        rrInterval={
          lvetiSelections
            ? lvetiSelections[selectionIndex].rrInterval
            : undefined
        }
        rrIntervalResize={rrIntervalResize}
      />
      <div className={styles.EjectionFooter}>
        <div className={styles.boxWrapper}>
          {lvetiSelections &&
            Object.entries(lvetiSelections).map(([key, sel]) => {
              const idx = parseInt(key) as 0 | 1 | 2;
              return (
                <SelectionBox
                  key={idx}
                  idx={idx}
                  setSelected={() => wrapSetSelectionIndex(idx)}
                  selected={selectionIndex === idx}
                  data={sel}
                  sex={patient?.sex}
                />
              );
            })}
        </div>
        <div className={styles.txtmsg}>
          {txtError && (
            <div className={styles.error}>
              <b>Can't compute LVETI</b>
              <br />
              {txtError}
            </div>
          )}
          <div className={styles.instructions}>{txtInstructions}</div>
        </div>
      </div>
      <TimeSpentTracker page={type} label="LVETI" />
    </div>
  ) : null;
}
