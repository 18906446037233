import { APITypesV1 } from "@cur8/api-client";
import { Assessment, AssessmentTypeName, toTypeName } from "@cur8/rich-entity";
import { useNav } from "@pomle/react-router-paths";
import { useCallback, useMemo } from "react";
import { toReadableState } from "render/pages/AssessmentPage/lib/utils";
import { paths } from "render/routes/paths";
import { FullDate } from "render/ui/format/FullDate";
import { Checkmark, CheckmarkType } from "render/ui/symbol/Checkmark";
import { getEcgAssessmentAdminUrl } from "../../../../urls";
import styles from "../../styles.module.sass";
import { UserDisplayName } from "./UserDisplayName";

interface AssessmentsTableProps {
  assessment: Assessment;
}

export function AssessmentRow({ assessment }: AssessmentsTableProps) {
  const userUri = useMemo(() => {
    const uri = assessment.audit.created?.userUri;
    if (!uri || uri.toString().startsWith("cur8:user:worker:")) {
      // TODO: Move this logic to Uri (UserWorkerURI?)
      return undefined;
    } else {
      return uri;
    }
  }, [assessment.audit.created?.userUri]);

  const nav = {
    assessment: useNav(paths.patient.assessment),
  };

  const showAssessment = useCallback(() => {
    nav.assessment.go({
      patientId: assessment.patientId,
      assessmentId: assessment.itemId,
      versionId: assessment.itemVersion,
    });
  }, [
    assessment.itemId,
    assessment.itemVersion,
    assessment.patientId,
    nav.assessment,
  ]);

  const onClickWrapper = (event: React.MouseEvent) => {
    if (assessment.itemTypeName === AssessmentTypeName.Ecg) {
      // ECG are in Admin UI
      const url = getEcgAssessmentAdminUrl(
        assessment.patientId,
        assessment.itemId
      );
      event.preventDefault();
      event.stopPropagation();
      window.location.assign(url);
      return;
    }
    // else
    showAssessment();
  };

  const checkboxType = useMemo((): CheckmarkType => {
    if (assessment.assessmentState === APITypesV1.AssessmentState.New) {
      return "unchecked";
    } else if (
      assessment.assessmentState === APITypesV1.AssessmentState.ReviewRequested
    ) {
      return "notfilled";
    }
    return "filled";
  }, [assessment.assessmentState]);

  return (
    <tr
      onClick={onClickWrapper}
      className={styles.clickable}
      data-state={assessment.assessmentState}
      data-clickable={true}
    >
      <td className={styles.icon}>
        <Checkmark type={checkboxType} />
      </td>
      <td className={styles.name}>
        {toTypeName(assessment.itemTypeName ?? "")}
      </td>
      <td>{toReadableState(assessment.assessmentState)}</td>
      <td>{userUri && <UserDisplayName userUri={userUri} />}</td>
      <td className={styles.date}>
        <time dateTime={assessment.createdAt.toISO()}>
          <FullDate date={assessment.createdAt} />
        </time>
      </td>
      <td></td>
      <td className={styles.action}></td>
    </tr>
  );
}
