import { APITypesV1 } from "@cur8/api-client";
import { ReactNode, useEffect, useRef } from "react";
import { Size, Tooltip } from "render/ui/presentation/Tooltip";
import { Typography } from "render/ui/presentation/Typography";
import { useRangeContext } from "../../../context/RangeContext";
import { GraphData } from "../../../lib/types";
import { DataPlot } from "./components/DataPlot";
import { DataWindow } from "./components/DataWindow";
import { NavGraph } from "./components/NavGraph";
import { Ruler } from "./components/Ruler";
import styles from "./styles.module.sass";

export interface GraphWithRulerProps {
  data: GraphData[];
  diffData: GraphData[];
  plugin?: ReactNode;
  selectionRange?: APITypesV1.Range;
  lvetIntervalResize?: (
    pos: number,
    dragLeft: boolean,
    complete?: boolean
  ) => void;
  onSelectionRange: (range: APITypesV1.Range, complete?: boolean) => void;
  rrInterval?: APITypesV1.Range;
  rrIntervalResize?: (
    pos: number,
    dragLeft: boolean,
    complete?: boolean
  ) => void;
}

export type RulerData = {
  distanceMs: number;
  bpm: number | undefined;
  range: APITypesV1.Range;
};

export function GraphWithRuler({
  data,
  diffData,
  plugin,
  selectionRange,
  lvetIntervalResize,
  onSelectionRange,
  rrInterval,
  rrIntervalResize,
}: GraphWithRulerProps) {
  const { windowScroll } = useRangeContext();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const div = ref.current;
    if (div) {
      div.addEventListener("wheel", windowScroll, { passive: false });
    }

    return () => {
      if (div) {
        div.removeEventListener("wheel", windowScroll);
      }
    };
  }, [windowScroll]);

  if (data.length === 0) {
    console.warn("No signal provided");
    return null;
  }

  return (
    <div
      className={styles.GraphWithRuler}
      onContextMenu={(e) => {
        e.preventDefault();
      }}
      ref={ref}
    >
      <div className={styles.signal}>
        <div className={styles.grow}>
          <DataWindow>
            <div className={styles.fullSignal}>
              <NavGraph data={data} height={60} width="100%" />
            </div>
          </DataWindow>
        </div>
        <div className={styles.plugin}>{plugin}</div>
      </div>
      <div className={styles.GraphContainer}>
        <Ruler
          selectionRange={selectionRange}
          lvetIntervalResize={lvetIntervalResize}
          onSelectionRange={onSelectionRange}
          rrInterval={rrInterval}
          rrIntervalResize={rrIntervalResize}
        >
          <DataPlot grid={{ x: 0.04 }} data={data} height={140} width="100%" />
          <DataPlot
            grid={{ x: 0.04 }}
            data={diffData}
            height={140}
            width="100%"
          />
        </Ruler>
      </div>
      <div className={styles.helpIcon}>
        <Tooltip size={Size.large}>
          <div>(?)</div>
          <div>
            <Typography variant="title-s">Graph instructions</Typography>
            <Typography variant="body-s">
              <p>
                <b>Zoom</b>
                <br />
                You can easily zoom in the graph, by using the mouse wheel or
                pinching on the trackpad.
                <br />
                You can also create a new selection in the overview in the top,
                by simply click-n-drag.
              </p>
              <p>
                <b>Pan</b>
                <br />
                Pan in time on the graph by pressing <i>CTRL</i> and
                click-dragging left/right.
              </p>
              <p>
                <b>Make measurements</b>
                <br />
                Simply click-n-drag to make a measurement in the selected view.
                To start over, just click-n-drag again to replace the existing
                one.
              </p>
              <p>
                <b>Adjust LVETI-intervals</b>
                <br />
                The existing LVETI-selections (both LVET and RR) can be modified
                by grabbing the edges and drag left/right. You will not be
                allowed to make a LVET-interval larger than the RR-interval.{" "}
                <br />(<i>Hint: The cursor will change to a left-right arrow</i>
                ).
              </p>
            </Typography>
          </div>
        </Tooltip>
      </div>
    </div>
  );
}
