import { APITypesV1 } from "@cur8/api-client";
import {
  Assessment,
  AssessmentTypeName,
  PulseWaveAssessment,
  RegionsOfInterestBox,
} from "@cur8/rich-entity";

export type CreatePadAssessmentRequestBox = Pick<
  APITypesV1.CreatePadAssessmentRequest,
  "assessmentState" | "comment" | "ratios" | "tags"
> & {
  regionsOfInterest?: RegionsOfInterestBox;
};

export type CreatePulseWaveAssessmentRequestBox = Pick<
  APITypesV1.CreatePulseWaveAssessmentRequest,
  | "assessmentState"
  | "comment"
  | "lveti"
  | "footAsymmetry"
  | "handAsymmetry"
  | "pulseWaveVelocity"
  | "tags"
>;

export type CreateThermalAssessmentRequestBox = Pick<
  APITypesV1.CreateThermalAssessmentRequest,
  "assessmentState" | "comment" | "status" | "tags"
>;

export type PulseWaveData = Pick<
  CreatePulseWaveAssessmentRequestBox,
  "pulseWaveVelocity" | "handAsymmetry" | "footAsymmetry" | "tags"
>;
export type PadData = Pick<
  CreatePadAssessmentRequestBox,
  "ratios" | "regionsOfInterest" | "tags"
>;

/**
 * Mutable types to cater for all UI features
 */
// @see APITypesV1.LVETIRangeData
export type MutableLVETISelection = {
  hr?: number;
  lvet?: number;
  lveti?: number;
  ssn?: APITypesV1.Range; // Required in LVETIRangeData
  state: LVETISelectionState;
  rrInterval?: APITypesV1.Range; // Required in LVETIRangeData
};

export type MutablePulseWaveLVETISelections = Record<
  number,
  MutableLVETISelection
>;

export type MutablePulseWaveLVETIAssessmentData = {
  average?: number;
  medianSSNSignalQuality?: number;
  modelNameAndVersion?: string;
  selections: MutablePulseWaveLVETISelections;
};

export type MutablePulseWaveAssessment = Omit<PulseWaveAssessment, "lveti"> & {
  lveti: MutablePulseWaveLVETIAssessmentData;
};

export enum LVETISelectionState {
  empty = "empty",
  proposed = "proposed",
  reviewed = "reviewed",
  incorrect = "incorrect",
}

export function isMutablePulseWaveAssessment(
  src: Assessment
): src is MutablePulseWaveAssessment {
  return src.itemTypeName === AssessmentTypeName.PulseWave;
}
