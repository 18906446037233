import { Typography, TypographyProps } from "../Typography";
import styles from "./styles.module.sass";
import { RiskLevel } from "@cur8/measurements";

type Variant = "normal" | "monitor";

const VariantMap: Record<RiskLevel, Variant> = {
  [RiskLevel.Unknown]: "monitor",
  [RiskLevel.Optimal]: "normal",
  [RiskLevel.Normal]: "normal",
  [RiskLevel.LowRisk]: "monitor",
  [RiskLevel.ModerateRisk]: "monitor",
  [RiskLevel.Risk]: "monitor",
  [RiskLevel.HighRisk]: "monitor",
  [RiskLevel.ImmediateRisk]: "monitor",
};

const LabelMap: Record<RiskLevel, string> = {
  [RiskLevel.Unknown]: "Unknown",
  [RiskLevel.Optimal]: "Optimal",
  [RiskLevel.Normal]: "Normal",
  [RiskLevel.LowRisk]: "Monitor",
  [RiskLevel.Risk]: "Monitor",
  [RiskLevel.ModerateRisk]: "Monitor",
  [RiskLevel.HighRisk]: "Monitor",
  [RiskLevel.ImmediateRisk]: "Monitor",
};

const TypographyVariantMap: Record<Variant, TypographyProps["variant"]> = {
  normal: "body-m",
  monitor: "eyebrow-m",
};

interface MetricStatusBadgeProps {
  risk: RiskLevel;
}

export function MetricStatusBadge({ risk }: MetricStatusBadgeProps) {
  const variant = VariantMap[risk];
  return (
    <div className={styles.MetricStatusBadge} data-variant={variant}>
      <Typography as="span" variant={TypographyVariantMap[variant]}>
        {LabelMap[risk]}
      </Typography>
    </div>
  );
}
