import React, { createContext, useContext, useMemo } from "react";
import { useRenderAspect } from "render/hooks/useRenderAspect";
import { useRenderScale } from "render/hooks/useRenderScale";
import * as THREE from "three";

function createRenderer(canvas: HTMLCanvasElement) {
  const renderer = new THREE.WebGLRenderer({
    alpha: true,
    antialias: true,
    canvas,
    logarithmicDepthBuffer: false,
  });

  renderer.setClearColor(0xffffff, 0);
  renderer.outputColorSpace = THREE.SRGBColorSpace;

  return renderer;
}

type ContextValue = {
  renderer: THREE.WebGLRenderer;
};

const Context = createContext<ContextValue | null>(null);

interface RendererProps {
  canvas: HTMLCanvasElement;
  scene: THREE.Scene;
  camera: THREE.PerspectiveCamera;
  children: React.ReactNode;
}

export function Renderer({ canvas, camera, children }: RendererProps) {
  const renderer = useMemo(() => {
    return createRenderer(canvas);
  }, [canvas]);

  useRenderAspect(renderer, camera);

  useRenderScale(renderer, 1);

  return <Context.Provider value={{ renderer }}>{children}</Context.Provider>;
}

export function useRenderer() {
  const context = useContext(Context);
  if (!context) {
    throw new Error("useRenderer without Renderer");
  }
  return context;
}
