import * as THREE from "three";
import { createDotPlane } from "../geometry/dot-plane";
import { AvatarDotGridMaterial } from "../materials/AvatarDotGridMaterial";

const MAP_RES = 2400;

class DotGridCamera extends THREE.OrthographicCamera {
  setFrustumSize(side: number) {
    const semiEdge = side * 0.5;
    this.left = -semiEdge;
    this.right = semiEdge;
    this.top = semiEdge;
    this.bottom = -semiEdge;
    this.near = -semiEdge;
    this.far = semiEdge;
    this.updateProjectionMatrix();
  }
}

type DotGridParams = {
  dotTexture: THREE.Texture;
  size: number;
  res: number;
};

export function setupDotGrid({ dotTexture, size, res }: DotGridParams) {
  const camera = new DotGridCamera();
  camera.setFrustumSize(size);
  camera.lookAt(0, 1, 0);

  const w = MAP_RES;
  const h = MAP_RES;

  const normalMapTarget = new THREE.WebGLRenderTarget(w, h);
  const normalMap = normalMapTarget.texture;
  normalMap.minFilter = THREE.NearestFilter;
  normalMap.magFilter = THREE.NearestFilter;

  const bodyPartsMapTarget = new THREE.WebGLRenderTarget(w, h);
  const bodyPartsMap = bodyPartsMapTarget.texture;
  bodyPartsMap.minFilter = THREE.NearestFilter;
  bodyPartsMap.magFilter = THREE.NearestFilter;

  const bodyColorMapTarget = new THREE.WebGLRenderTarget(w, h);
  const bodyColorMap = bodyColorMapTarget.texture;
  bodyColorMap.colorSpace = THREE.LinearSRGBColorSpace;

  bodyColorMap.minFilter = THREE.NearestFilter;
  bodyColorMap.magFilter = THREE.NearestFilter;

  const depthMap = new THREE.DepthTexture(w, h);
  depthMap.format = THREE.DepthStencilFormat;
  depthMap.type = THREE.FloatType;
  normalMapTarget.depthTexture = depthMap;

  const geometry = createDotPlane(size, res);

  const material = new AvatarDotGridMaterial({
    camera,
    pointMap: dotTexture,
    normalMap,
    depthMap,
    bodyPartsMap,
    bodyColorMap,
  });

  const points = new THREE.Points(geometry, material);

  const view = new THREE.Group();
  view.add(camera);

  return {
    render(renderer: THREE.WebGLRenderer, scene: THREE.Object3D) {
      view.updateMatrixWorld(true);
      renderer.setRenderTarget(normalMapTarget);
      renderer.render(scene, camera);
      renderer.setRenderTarget(null);
    },
    renderBodyparts(renderer: THREE.WebGLRenderer, scene: THREE.Object3D) {
      view.updateMatrixWorld(true);
      renderer.setRenderTarget(bodyPartsMapTarget);
      renderer.render(scene, camera);
      renderer.setRenderTarget(null);
    },
    renderBodyColor(renderer: THREE.WebGLRenderer, scene: THREE.Object3D) {
      //view.updateMatrixWorld(true);
      renderer.setRenderTarget(bodyColorMapTarget);
      renderer.render(scene, camera);
      renderer.setRenderTarget(null);
    },
    view,
    points,
    camera,
    normalMap,
    depthMap,
    bodyPartsMap,
    bodyColorMap,
    normalMapTarget,
    bodyPartsMapTarget,
    bodyColorMapTarget,
  };
}
