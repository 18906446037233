import { RiskMetricTool } from "lib/risk/tools";
import { useMemo } from "react";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import {
  Diff,
  DiffValue,
  MainTitle,
  MetricResultHeader,
  Subtitle,
  Titles,
} from "render/ui/presentation/MetricResultHeader";
import {
  RangeChart,
  RangeChartDataComposer,
} from "render/ui/presentation/RangeChart";
import { toChartRanges } from "render/ui/presentation/RangeChart/lib/risk";

const getTitlesForValueChange = (value?: number, previousValue?: number) => {
  if (value === undefined || previousValue === undefined) {
    return {
      title: "Non-HDL",
      subtitle: "",
    };
  }

  if (value === previousValue) {
    return {
      title: "Maintained",
      subtitle: "Non-HDL",
    };
  }

  if (value > previousValue) {
    return {
      title: "Increased",
      subtitle: "Non-HDL",
    };
  }

  return {
    title: "Decreased",
    subtitle: "Non-HDL",
  };
};

export function NonHDLItem() {
  const { metrics } = usePatientData();

  const nonHDLMetric = metrics.bloodwork.nonHDL;
  const latest = nonHDLMetric?.at(0);
  const previous = nonHDLMetric?.at(1);

  const placeholderRanges = useMemo(() => {
    const values = nonHDLMetric?.map((metric) => metric.unit["mmol/L"]) ?? [];
    const result = RiskMetricTool.generateUnknownInterval({
      max: Math.max(...values),
      min: Math.min(...values),
    });

    return result;
  }, [nonHDLMetric]);

  const riskRanges = useMemo(() => {
    const riskIntervals = latest?.riskRanges.riskIntervals;
    const ranges =
      riskIntervals && riskIntervals.length > 0
        ? riskIntervals
        : placeholderRanges;

    const result = toChartRanges(ranges);

    return result;
  }, [latest?.riskRanges.riskIntervals, placeholderRanges]);

  // https://dev.azure.com/cur8/Engineering/_workitems/edit/26137
  const riskRangesCapLow = 1.2;
  const riskRangesCapHigh = 6.4;

  const value = latest?.unit["mmol/L"];
  const previousValue = previous?.unit["mmol/L"];

  const { title, subtitle } = getTitlesForValueChange(value, previousValue);

  return (
    <div>
      <MetricResultHeader>
        <Titles>
          <MainTitle>{title}</MainTitle>
          <Subtitle>{subtitle}</Subtitle>
        </Titles>
        <Diff>
          <DiffValue
            valuesDiff={
              previousValue !== undefined && value !== undefined
                ? value - previousValue
                : undefined
            }
            formatValue={(value) => value?.toFixed(2)}
            unit="mmol/L"
          />
        </Diff>
      </MetricResultHeader>
      <RangeChartDataComposer
        ranges={riskRanges}
        rangesCapLow={riskRangesCapLow}
        rangesCapHigh={riskRangesCapHigh}
        value={value}
        previousValue={previousValue}
      >
        {({ ranges, values }) => (
          <RangeChart ranges={ranges} values={values} showEmpty />
        )}
      </RangeChartDataComposer>
    </div>
  );
}
