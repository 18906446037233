import { findObjectBFS } from "lib/three";
import * as THREE from "three";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader.js";

function setupHeart(mesh: THREE.Mesh) {
  const material = mesh.material as THREE.MeshStandardMaterial;

  const normalMaterial = new THREE.MeshNormalMaterial({});
  normalMaterial.normalMapType = THREE.ObjectSpaceNormalMap;

  return {
    mesh,
    material,
    normalMaterial,
  };
}

function setupAnimator(source: GLTF) {
  const scene = source.scene;
  const anims = source.animations;

  const mixer = new THREE.AnimationMixer(scene);

  const clips = anims.map((anim) => {
    return mixer.clipAction(anim);
  });

  clips.forEach((clip) => {
    clip.time = 0;
    clip.play();
  });

  return {
    mixer,
    update(deltaTime: number) {
      mixer.update(deltaTime);
    },
  };
}

export function setupHeartSource(source: GLTF) {
  console.debug("Using Heart GLTF", source);

  const scene = source.scene;

  const mesh = findObjectBFS(scene, (object) => {
    return object.userData["neko.identifier"] === "avatar_heart";
  });

  if (!(mesh instanceof THREE.Mesh)) {
    console.warn(`Could not find neko.identifier "avatar_heart"`, source);
    throw new Error("Could not find expected object");
  }

  const heart = setupHeart(mesh);

  const animator = setupAnimator(source);

  return {
    animator,
    ...heart,
    scene,
  };
}
