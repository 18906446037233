import { folder, useControls } from "leva";

const MAGIC_DOT_GRID_ROTATION = 0.53407;

export function useSettings() {
  const [body] = useControls(() => ({
    Body: folder(
      {
        globalParticleSize: { value: 0.19, min: -2, max: 2, step: 0.001 },
        depthFactor: { value: 0.3, min: 0, max: 1, step: 0.001 },
        depthLimit: { value: 0.03, min: 0, max: 1, step: 0.001 },
        depthLimitFalloff: { value: 0.05, min: 0, max: 1, step: 0.001 },
        light1SizeFactor: { value: -5, min: -40, max: 40, step: 0.01 },
        light2SizeFactor: { value: -8.7, min: -40, max: 40, step: 0.01 },
        normalSizeFactor: { value: 0.33, min: -2, max: 2, step: 0.01 },
        normalFresnelIntensity: { value: 1, min: 0, max: 3, step: 0.01 },
        normalFresnelPower: { value: 2, min: 0, max: 8, step: 0.01 },
        bodyNoise: { value: 0.62, min: 0, max: 4, step: 0.01 },
        bodyNoiseTimescale: { value: 0.39, min: -1, max: 10, step: 0.01 },
        bodyNoiseCalmTorso: { value: 0, min: 0, max: 1, step: 0.01 },
        bodyNoiseScale: { value: 0.01, min: 0, max: 0.1, step: 0.01 },
      },
      {
        collapsed: true,
      }
    ),
  }));

  const [light] = useControls(() => ({
    Light: folder(
      {
        light1Position: {
          value: { x: -753, y: -117, z: 1579 },
          min: -3000,
          max: 3000,
          step: 10,
        },
        light1Target: {
          value: { x: -138, y: -390, z: 1323 },
          min: -3000,
          max: 3000,
          step: 10,
        },
        light1Intensity: { value: 0.1, min: 0, max: 2, step: 0.01 },
        light1Angle: { value: 1, min: 0, max: Math.PI / 2, step: 0.01 },

        light2Position: {
          value: { x: 317, y: -444, z: 2330 },
          min: -3000,
          max: 3000,
          step: 10,
        },
        light2Target: {
          value: { x: -321, y: -63, z: 722 },
          min: -3000,
          max: 3000,
          step: 10,
        },
        light2Intensity: { value: 0.48, min: 0, max: 2, step: 0.01 },
        light2Angle: { value: 0.68, min: 0, max: Math.PI / 2, step: 0.01 },
      },
      {
        collapsed: true,
      }
    ),
  }));

  const [grid] = useControls(() => ({
    Grid: folder(
      {
        projectionAngle: {
          value: MAGIC_DOT_GRID_ROTATION,
          min: Math.PI * -0.5,
          max: Math.PI * 0.5,
          step: 0.01,
        },
        map: {
          options: [
            "none",
            "normal",
            "depth",
            "body-parts",
            "body-color",
          ] as const,
        },
      },
      {
        collapsed: true,
      }
    ),
  }));

  const [reveal] = useControls(() => ({
    Reveal: folder(
      {
        noise1multiplier: { value: 7, min: 0, max: 100, step: 0.001 },
        noise2multiplier: { value: 12, min: 0, max: 100, step: 0.001 },
        bodyEdgeFalloff: { value: 0.08, min: 0, max: 1, step: 0.001 },
        armsEdgeFalloff: { value: 0.21, min: 0, max: 1, step: 0.001 },
        bodyCenterX: { value: 30, min: -1000, max: 1000, step: 0.001 },
        bodyCenterY: { value: 0.79, min: 0, max: 10, step: 0.001 },
        bodyCenterZ: { value: 30, min: -1000, max: 1000, step: 0.001 },
      },
      {
        collapsed: true,
      }
    ),
  }));

  return {
    body,
    grid,
    light,
    reveal,
  };
}
