import { Patient, ThermalAssessment } from "@cur8/rich-entity";
import { RecordingURI } from "@cur8/uri";
import { Typography } from "render/ui/presentation/Typography";
import { ThermalPage } from "./ThermalPage";

interface ThermalLoaderProps {
  patient: Patient;
  selected: ThermalAssessment;
}

export function ThermalLoader({ patient, selected }: ThermalLoaderProps) {
  const recUri = RecordingURI.parse(selected.sourceUris[0]);

  if (!recUri) {
    return (
      <Typography variant="title-m">
        No recording information found...
      </Typography>
    );
  }
  return <ThermalPage patient={patient} recordingUri={recUri} />;
}
