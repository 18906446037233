import * as THREE from "three";

class BodyPartsMaterial extends THREE.MeshBasicMaterial {
  constructor(parameters: any) {
    super(parameters);

    this.onBeforeCompile = (shader) => {
      shader.vertexShader = `
        
        varying vec4 vSkinIndex;
        varying vec4 vSkinWeight;
        varying vec3 vTposeInfo;
        

        ${shader.vertexShader}
      `.replace(
        "#include <begin_vertex>",
        `
        #include <begin_vertex>
        vSkinIndex = skinIndex;
        vSkinWeight = skinWeight;
        vec3 positionNormalized = position / 1000.0;
        //adjust the gradient here to include more/less from arm to avoid the side of the body
        vTposeInfo = vec3(smoothstep(0.2,1.0,-positionNormalized.x),smoothstep(0.13,1.0,positionNormalized.x),positionNormalized.z);
        `
      );

      shader.fragmentShader = `
        varying vec4 vSkinIndex;
        varying vec4 vSkinWeight;
        varying vec3 vTposeInfo;

        ${shader.fragmentShader}
      `.replace(
        "vec3 outgoingLight = reflectedLight.indirectDiffuse;",
        `
        vec3 outgoingLight = reflectedLight.indirectDiffuse;
        
        //vec4 sum = vSkinIndex * vSkinWeight;
        //float color = sum.x + sum.y + sum.z + sum.w;
        //color = color / 4.0;
        outgoingLight = vec3(vTposeInfo.z, vTposeInfo.x, vTposeInfo.y);
        `
      );
    };
  }
}

export { BodyPartsMaterial };
