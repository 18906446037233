import { ImmutableScan } from "@cur8/rich-entity";
import { AssessmentHandler } from "render/pages/AssessmentPage/components/AssessmentHandler";
import { useAssessmentContext } from "render/pages/AssessmentPage/context/AssessmentContext";
import { Property } from "render/pages/TissuePages/lib/types";
import { Typography } from "render/ui/presentation/Typography";
import {
  propertiesPAD,
  useChromophoreToRatio,
} from "../../hooks/useChromophoreToRatio";
import { RegionTracker } from "../RegionTracker";
import styles from "./styles.module.sass";

interface SideboxProps {
  indexRemap: number[];
  onPropertySelect: (property: Property) => void;
  onRoiSelect: (roi: string) => void;
  pixelsPerCm?: number;
  scan: ImmutableScan;
  selectedRoi?: string;
  property: Property;
  timestamps: number[];
}

export function Sidebox({
  indexRemap,
  onPropertySelect,
  onRoiSelect,
  pixelsPerCm,
  scan,
  selectedRoi,
  property,
  timestamps,
}: SideboxProps) {
  const { mutablePAD } = useAssessmentContext();
  const { ratioBlood, ratioOxygen } = useChromophoreToRatio(
    scan,
    mutablePAD!,
    indexRemap,
    timestamps
  );

  return (
    <div className={styles.Sidebox}>
      <div className={styles.PropertyRatio}>
        <Typography variant="title-s">Ratios</Typography>
        {ratioBlood ? (
          <button
            onClick={() => onPropertySelect(Property.t1BloodVolume)}
            data-selected={property === Property.t1BloodVolume}
          >
            <label>{propertiesPAD.get(Property.t1BloodVolume)}</label>
            <div className={styles.numerals}>{ratioBlood.toFixed(2)}</div>
          </button>
        ) : (
          <Typography variant="title-s">Loading blood concentration</Typography>
        )}
        {ratioOxygen ? (
          <button
            onClick={() => onPropertySelect(Property.t1Oxygenation)}
            data-selected={property === Property.t1Oxygenation}
          >
            <label>{propertiesPAD.get(Property.t1Oxygenation)}</label>
            <div className={styles.numerals}>{ratioOxygen.toFixed(2)}</div>
          </button>
        ) : (
          <Typography variant="title-s">Loading oxygenation</Typography>
        )}
      </div>

      <RegionTracker
        assessment={mutablePAD!}
        onRoiSelect={onRoiSelect}
        pixelsPerCm={pixelsPerCm}
        selectedRoi={selectedRoi}
      />
      <div className={styles.assessmentWrap}>
        <AssessmentHandler />
      </div>
    </div>
  );
}
