import { APITypesV1 } from "@cur8/api-client";
import styles from "./RiskLabel.module.sass";

interface RiskLabelProps {
  risk: APITypesV1.RiskType;
}

export function RiskLabel({ risk }: RiskLabelProps) {
  if (risk === APITypesV1.RiskType.Normal) {
    return (
      <div className={styles.RiskLabel} data-variant="normal">
        Normal
      </div>
    );
  }

  if (risk === APITypesV1.RiskType.Optimal) {
    return (
      <div className={styles.RiskLabel} data-variant="optimal">
        Optimal
      </div>
    );
  }

  if (risk !== APITypesV1.RiskType.Unknown) {
    return (
      <div className={styles.RiskLabel} data-variant="monitor">
        Monitor
      </div>
    );
  }
  return null;
}
