import { Dimension } from "@cur8/rich-entity";
import { useMemo } from "react";
import { toFixedWithSign, truncate } from "../../helpers";
import styles from "./BloodPressureView.module.sass";

export function BloodPressureView({
  dimensions,
  previousDimensions,
  decimalPlaces,
}: {
  dimensions: Dimension[];
  previousDimensions: Dimension[] | undefined;
  decimalPlaces: number;
}) {
  const currentLabel = useMemo(() => {
    const systolic = dimensions.find((d) => d.name === "systolic");
    const diastolic = dimensions.find((d) => d.name === "diastolic");

    if (systolic && diastolic) {
      return (
        <div className={styles.current}>
          <div className={styles.systolic}>
            {truncate(systolic.value, decimalPlaces)}
          </div>
          <div className={styles.diastolic}>
            /&nbsp;{truncate(diastolic.value, decimalPlaces)}&nbsp;
            {systolic.unit}
          </div>
        </div>
      );
    }
    return null;
  }, [dimensions, decimalPlaces]);

  const diffLabel = useMemo(() => {
    if (dimensions && previousDimensions) {
      const systolic = dimensions.find((d) => d.name === "systolic");
      const diastolic = dimensions.find((d) => d.name === "diastolic");
      const previousSystolic = previousDimensions.find(
        (d) => d.name === "systolic"
      );
      const previousDiastolic = previousDimensions.find(
        (d) => d.name === "diastolic"
      );
      if (systolic && diastolic && previousSystolic && previousDiastolic) {
        const systolicDiff = systolic.value - previousSystolic.value;
        const diastolicDiff = diastolic.value - previousDiastolic.value;
        return (
          <div className={styles.diff}>
            Change:&nbsp;
            {toFixedWithSign(systolicDiff)}/{toFixedWithSign(diastolicDiff)}
          </div>
        );
      }
    }
    return null;
  }, [dimensions, previousDimensions]);

  return (
    <div className={styles.BloodPressureView}>
      {currentLabel}
      {diffLabel}
    </div>
  );
}
