import { PathLink } from "@pomle/react-router-paths";
import { useDeviceStorage } from "render/hooks/deviceStorage/useDeviceStorage";
import styles from "./styles.module.sass";
import { useSites } from "render/hooks/api/useSites";
import { useSiteRooms } from "render/pages/SchedulePage/components/Calendar/hooks/useSiteRooms";
import { ReactNode } from "react";
import { paths } from "render/routes/paths";
import {
  AllCalendarPreference,
  NotSetCalendarPreference,
  SingleSiteCalendarPreference,
} from "render/hooks/deviceStorage/lib/calendarPreference";
import { FullDate } from "render/ui/format/FullDate";

function CalendarSettingsLayout(props: {
  header: ReactNode;
  label?: ReactNode;
}) {
  return (
    <section className={styles.fields}>
      <div className={styles.field}>
        <div />
        <p className={styles.label}>{props.header}</p>
      </div>
      <div className={styles.field}>
        <div />
        <p className={styles.label}>{props.label}</p>
      </div>
    </section>
  );
}

function NoPreference() {
  return <CalendarSettingsLayout header="No Settings selected" />;
}

function AllSitesPreference(props: { preference: AllCalendarPreference }) {
  return (
    <CalendarSettingsLayout
      header="Full Schedule"
      label={`Resets at ${props.preference.expiresAt.toFormat(
        "yyyy-mm-dd hh:ss"
      )}`}
    />
  );
}

function SingleSitePreference(props: {
  preference: SingleSiteCalendarPreference;
}) {
  const preference = props.preference;
  const siteId = preference.siteId;
  const roomId = preference.roomId;
  const expiresAt = preference.expiresAt;

  const sites = useSites();
  const rooms = useSiteRooms(siteId);

  const site = sites?.find((site) => site.siteId === siteId);
  const room = rooms.data?.find((room) => room.id === roomId);

  return (
    <CalendarSettingsLayout
      header={`${site?.siteName ?? "--"}, ${room?.displayName ?? "--"}`}
      label={
        <>
          Expires: <FullDate date={expiresAt} />
        </>
      }
    />
  );
}

export function CalendarSettings() {
  const deviceStorage = useDeviceStorage();
  const calendarSettings = deviceStorage.calendarSettings.data;

  return (
    <div className={styles.CalendarSettings}>
      <h2 className={styles.header}>Calendar settings</h2>
      <div className={styles.box}>
        <p className={styles.name}>Preferred calendar</p>
        {calendarSettings instanceof NotSetCalendarPreference ? (
          <NoPreference />
        ) : null}
        {calendarSettings instanceof AllCalendarPreference ? (
          <AllSitesPreference preference={calendarSettings} />
        ) : null}
        {calendarSettings instanceof SingleSiteCalendarPreference ? (
          <SingleSitePreference preference={calendarSettings} />
        ) : null}
        <PathLink className={styles.cta} to={paths.settings.calendar.build({})}>
          {calendarSettings instanceof NotSetCalendarPreference ? (
            <>Set preferences</>
          ) : (
            <>Edit preferences</>
          )}
        </PathLink>
      </div>
    </div>
  );
}
