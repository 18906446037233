import { RiskMetric } from "lib/metric";
import { RiskMetricTool } from "lib/risk/tools";
import { useMemo } from "react";
import { MetricRange } from "render/ui/presentation/MetricRange";
import {
  selectChartRanges,
  toChartRanges,
} from "render/ui/presentation/RangeChart/lib/risk";

interface EcgQTCRangeProps {
  metrics: RiskMetric<"cardio.ecg.segments.qtc">[] | undefined;
}

export function EcgQTCRange({ metrics }: EcgQTCRangeProps) {
  const latest = metrics?.at(0);
  const entries = useMemo(() => {
    if (!metrics) {
      return [];
    }

    return metrics.map((metric) => {
      return {
        risk: metric.riskRanges.riskLevel,
        timestamp: metric.measurement.timestampStart,
        value: metric.unit["ms"],
      };
    });
  }, [metrics]);

  const currentValue = entries.at(0)?.value;

  const ranges = useMemo(() => {
    let intervals = latest?.riskRanges.riskIntervals ?? [];
    if (latest && intervals.length === 0) {
      intervals = RiskMetricTool.generateUnknownInterval({
        min: latest.unit.ms,
        max: latest.unit.ms,
      });
    }

    const chartRanges = toChartRanges(intervals);

    return selectChartRanges(chartRanges, {
      mode: "dynamic",
      currentValue,
      maxNumOfRanges: 4,
    });
  }, [latest, currentValue]);

  return (
    <MetricRange
      title="Contract and reset time"
      subtitle="[QTc interval]"
      unit="ms"
      entries={entries}
      ranges={ranges}
      rangesCapLow={180}
      rangesCapHigh={600}
      formatValue={(value) => value?.toFixed(0)}
      showChange={false}
      showEmptyRanges
    />
  );
}
