import { APITypesV1 } from "@cur8/api-client";
import { DateTime } from "luxon";
import { paths } from "render/routes/paths";
import { query } from "render/routes/querys";
import { record, string } from "@pomle/shapes";

export type UserDeviceCalendarPreferenceStorage = {
  siteId?: string;
  roomId?: string;
  expiresAt: string;
};

export const userDeviceCalendarPreferenceStorageValidator =
  record<UserDeviceCalendarPreferenceStorage>({
    roomId: string(undefined),
    siteId: string(undefined),
    expiresAt: string(""),
  });

// this is needed because if the class is empty typescript loosely types
// and allows anything to pass the validation
class BasePreference {
  protected type = "calendar_preference/v1";
}

export class AllCalendarPreference extends BasePreference {
  public expiresAt: DateTime;
  constructor(preferences: { expiresAt: DateTime }) {
    super();
    this.expiresAt = preferences.expiresAt;
  }
}
export class SingleSiteCalendarPreference extends BasePreference {
  public siteId: string;
  public roomId: string;
  public expiresAt: DateTime;
  constructor(preferences: {
    siteId: string;
    roomId: string;
    expiresAt: DateTime;
  }) {
    super();
    this.siteId = preferences.siteId;
    this.roomId = preferences.roomId;
    this.expiresAt = preferences.expiresAt;
  }
}
export class NotSetCalendarPreference extends BasePreference {}

export type DeviceCalendarPreference =
  | AllCalendarPreference
  | SingleSiteCalendarPreference
  | NotSetCalendarPreference;

export function createDeviceCalendarPreference(
  userPref: UserDeviceCalendarPreferenceStorage | undefined,
  time: DateTime
) {
  if (userPref == null) {
    return new NotSetCalendarPreference();
  }
  const expiresAt = DateTime.fromISO(userPref.expiresAt);
  if (!expiresAt.isValid) {
    return new NotSetCalendarPreference();
  }
  if (expiresAt < time) {
    return new NotSetCalendarPreference();
  }

  if (userPref.siteId != null && userPref.roomId != null) {
    return new SingleSiteCalendarPreference({
      siteId: userPref.siteId,
      roomId: userPref.roomId,
      expiresAt,
    });
  }
  return new AllCalendarPreference({
    expiresAt,
  });
}

export function getPreferredCalendarEntry(
  preference: DeviceCalendarPreference,
  params?: Partial<{
    from: DateTime;
    to: DateTime;
    assignPatientId: string;
    slotManagement: boolean;
    roomId: string;
    medicalExams: APITypesV1.MedicalExam;
    censorAppointments: boolean;
  }>
) {
  const toParam = <T>(param: T | undefined) => (param == null ? [] : [param]);
  const buildQueryParams = (
    defaults: Partial<{ roomId: string; censorAppointments: boolean }>
  ) =>
    query.schedule.build({
      assignPatientId: toParam(params?.assignPatientId),
      censorAppointments: toParam(
        params?.censorAppointments ?? defaults.censorAppointments
      ),
      from: toParam(params?.from),
      roomId: toParam(defaults.roomId),
      medicalExams: toParam(params?.medicalExams),
      slotManagement: toParam(params?.slotManagement),
      to: toParam(params?.to),
    });
  const buildPath = (base: string, queryParams: string) =>
    `${base}?${queryParams}`;

  switch (true) {
    case preference instanceof AllCalendarPreference:
      return buildPath(paths.schedule.sites.build({}), buildQueryParams({}));
    case preference instanceof SingleSiteCalendarPreference:
      return buildPath(
        paths.schedule.site.build({ siteId: preference.siteId }),
        buildQueryParams({
          roomId: preference.roomId,
          censorAppointments: true,
        })
      );
    case preference instanceof NotSetCalendarPreference:
    default:
      return buildPath(paths.settings.calendar.build({}), buildQueryParams({}));
  }
}
