import { useMemo, useState } from "react";
import { BulletEditor as BulletEditorWithLinks } from "../BulletEditorWithLinks";
import { LinkInfo } from "../BulletEditorWithLinks/components/LinkTooltip/components/SelectionActionPlugin";
import { SectionMeasurements, SectionTitle } from "../SummaryEditor/sections";
import styles from "./styles.module.sass";

interface BulletSectionEditorProps {
  title: SectionTitle;
  content: string[] | undefined;
  measurements: SectionMeasurements;
  extraLinks: LinkInfo[];
  onChange: (value: string[]) => void;
  editorKey: string;
}

export function BulletSectionEditor({
  title,
  content,
  measurements,
  extraLinks,
  onChange,
  editorKey,
}: BulletSectionEditorProps) {
  const [isActive, setIsActive] = useState(false);

  const myMeasurements: SectionMeasurements = useMemo(() => {
    return {
      current: measurements.current.filter((m) => m.sectionTitle === title),
      previous: measurements.previous.filter((m) => m.sectionTitle === title),
    };
  }, [measurements, title]);

  return (
    <div
      className={styles.BulletSectionEditor}
      onMouseOver={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
    >
      <h3 className={styles.title}>{title}</h3>
      <BulletEditorWithLinks
        placeholder={"Enter key points here..."}
        value={content}
        onChange={onChange}
        measurements={myMeasurements}
        extraLinks={extraLinks}
        isActive={isActive}
        editorKey={editorKey}
      />
    </div>
  );
}
