import { getHighestRisk } from "@cur8/measurements";
import { RiskMetric } from "lib/metric";
import { useMemo } from "react";
import { MetricStatus } from "render/ui/presentation/MetricStatus";
import { MetricStatusBadge } from "render/ui/presentation/MetricStatusBadge";
import BloodFatsIcon from "./assets/blood-fats.svg?react";

interface BloodFatsItemProps {
  bloodFats: {
    cholesterol: RiskMetric<"bloodwork.cholesterol">[] | undefined;
    hdl: RiskMetric<"bloodwork.hdl">[] | undefined;
    ldl: RiskMetric<"bloodwork.ldl">[] | undefined;
    nonHDL: RiskMetric<"bloodwork.non_hdl">[] | undefined;
    triglycerides: RiskMetric<"bloodwork.triglycerides">[] | undefined;
  };
}

function isDefined<T>(value: T | undefined): value is T {
  return value != null;
}

export function BloodFatsItem({
  bloodFats: { cholesterol, hdl, ldl, nonHDL, triglycerides },
}: BloodFatsItemProps) {
  const risk = useMemo(() => {
    const risks = [
      cholesterol?.at(0)?.riskRanges.riskLevel,
      hdl?.at(0)?.riskRanges.riskLevel,
      ldl?.at(0)?.riskRanges.riskLevel,
      nonHDL?.at(0)?.riskRanges.riskLevel,
      triglycerides?.at(0)?.riskRanges.riskLevel,
    ].filter(isDefined);

    return getHighestRisk(risks);
  }, [cholesterol, hdl, ldl, nonHDL, triglycerides]);

  return (
    <MetricStatus
      caption="Blood fats"
      summaryContent={risk != null ? <MetricStatusBadge risk={risk} /> : "--"}
    >
      <BloodFatsIcon />
    </MetricStatus>
  );
}
