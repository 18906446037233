import { Table } from "render/ui/layouts/Table";
import { PatientEncyclopediaMetrics } from "../../hooks/usePatientEncyclopediaMetrics";
import { ResultRow } from "../ResultRow";
import { Country } from "lib/visit/visit";

interface WhiteBloodCellsTableProps {
  metrics: PatientEncyclopediaMetrics;
  country: Country | undefined;
}

export function WhiteBloodCellsTable({
  metrics,
  country,
}: WhiteBloodCellsTableProps) {
  const bloodwork = metrics.bloodwork;

  return (
    <Table>
      <thead>
        <tr>
          <th>White blood cells</th>
          <th>Result</th>
          <th>
            Risk {country != null ? <>(based on {country})</> : undefined}
          </th>
          <th>Units</th>
        </tr>
      </thead>
      <tbody>
        <ResultRow
          caption="WBC Count"
          value={bloodwork.whiteBloodCells?.at(0)}
          format={(metric) => metric.unit["x10⁹/L"].toFixed(2)}
          unit="x10⁹/L"
          summarize={(metric) => {
            return {
              risk: metric.riskRanges.riskLevel,
            };
          }}
        />
        <ResultRow
          caption="Neutrophils"
          value={bloodwork.neutrophils?.at(0)}
          format={(metric) => metric.unit["x10⁹/L"].toFixed(2)}
          unit="x10⁹/L"
          summarize={(metric) => {
            return {
              risk: metric.riskRanges.riskLevel,
            };
          }}
        />
        <ResultRow
          caption="Basophils"
          value={bloodwork.basophils?.at(0)}
          format={(metric) => metric.unit["x10⁹/L"].toFixed(2)}
          unit="x10⁹/L"
          summarize={(metric) => {
            return {
              risk: metric.riskRanges.riskLevel,
            };
          }}
        />
        <ResultRow
          caption="Monocytes"
          value={bloodwork.monocytes?.at(0)}
          format={(metric) => metric.unit["x10⁹/L"].toFixed(2)}
          unit="x10⁹/L"
          summarize={(metric) => {
            return {
              risk: metric.riskRanges.riskLevel,
            };
          }}
        />
        <ResultRow
          caption="Eosinophils"
          value={bloodwork.eosinophils?.at(0)}
          format={(metric) => metric.unit["x10⁹/L"].toFixed(2)}
          unit="x10⁹/L"
          summarize={(metric) => {
            return {
              risk: metric.riskRanges.riskLevel,
            };
          }}
        />
        <ResultRow
          caption="Lymphocytes"
          value={bloodwork.lymphocytes?.at(0)}
          format={(metric) => metric.unit["x10⁹/L"].toFixed(2)}
          unit="x10⁹/L"
          summarize={(metric) => {
            return {
              risk: metric.riskRanges.riskLevel,
            };
          }}
        />
      </tbody>
    </Table>
  );
}
