import { HDLItem } from "./components/HDLItem";
import { NonHDLItem } from "./components/NonHDL/NonHDLItem";
import { TriglyceridesItem } from "./components/Triglycerides";
import styles from "./styles.module.sass";

export function BloodFatsLeftSection() {
  return (
    <div className={styles.BloodFatsSection}>
      <div className={styles.items}>
        <HDLItem />
        <NonHDLItem />
        <TriglyceridesItem />
      </div>
    </div>
  );
}
