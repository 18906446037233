import { APITypesV1 } from "@cur8/api-client";
import { CreatePadAssessmentRequestBox } from "render/pages/AssessmentPage/context/AssessmentContext";
import {
  CreatePulseWaveAssessmentRequestBox,
  CreateThermalAssessmentRequestBox,
} from "render/pages/AssessmentPage/context/AssessmentContext/types";

export interface PlotData {
  range: APITypesV1.Range;
  signal: number[];
  timestamps: number[];
}

export type GraphData = {
  signal: PlotData;
  label: string;
  color: string;
};

export enum PulsewaveType {
  lveti = "lveti",
  pulseWaveVelocity = "pulseWaveVelocity",
  handAsymmetry = "handAsymmetry",
  footAsymmetry = "footAsymmetry",
}

export const PulsewaveTypes = new Map<PulsewaveType, string>([
  [PulsewaveType.lveti, "lveti"],
  [PulsewaveType.pulseWaveVelocity, "pulseWaveVelocity"],
  [PulsewaveType.handAsymmetry, "handAsymmetry"],
  [PulsewaveType.footAsymmetry, "footAsymmetry"],
]);

export function isCreatePWAssessment(
  src:
    | CreatePadAssessmentRequestBox
    | CreatePulseWaveAssessmentRequestBox
    | CreateThermalAssessmentRequestBox
): src is CreatePulseWaveAssessmentRequestBox {
  return src.hasOwnProperty("lveti");
}
