import { VisitDate, VisitHistorySummary } from "@cur8/rich-entity";

export interface VisitStatus {
  label: "baseline" | "follow-up";
  count?: number;
}

export function getVisitOrdinal(
  visitHistorySummary: VisitHistorySummary,
  { date, visitId }: VisitDate
) {
  //make sure list is in ascending order
  const nekoBodyScan =
    visitHistorySummary.medicalExamVisitTimeSeries.nekoBodyScan?.toSorted(
      (a, b) => a.date.toUnixInteger() - b.date.toUnixInteger()
    ) ?? [];

  if (nekoBodyScan.length === 0) {
    return 1;
  }

  {
    const index = nekoBodyScan.findIndex((scan) => scan.visitId === visitId);
    if (index !== -1) {
      return index + 1;
    }
  }

  {
    const index = nekoBodyScan.findIndex(
      (scan) =>
        scan.date.startOf("day").toUnixInteger() >=
        date.startOf("day").toUnixInteger()
    );
    if (index !== -1) {
      return nekoBodyScan.length - index;
    }
  }

  return nekoBodyScan.length + 1;
}
