import { MetricName, RiskLevel, Unit } from "@cur8/measurements";
import { VisitSummary } from "@cur8/rich-entity";
import {
  Entry,
  ExtendedMetricName,
  MergedEntry,
  MergedRecord,
  Result,
  ResultRecord,
} from "lib/doctor-scribe/types";
import { Metric } from "lib/metric";
import { DateTime } from "luxon";

export function notEmpty<T>(arr: T[]): boolean {
  return arr.some((el) => el !== null && el !== undefined);
}

export function remapName(name: MetricName): ExtendedMetricName {
  switch (name) {
    case "bloodwork.cholesterol_hdl":
      return "bloodwork.cholesterol_hdl_ratio"; // The original name confuses the LLM
    default:
      return name;
  }
}

export function uniqueByDay(m: Metric[]): Metric[] {
  return m.reduce((acc: Metric[], curr: Metric) => {
    const index = acc.findIndex(
      (x: Metric) =>
        x.measurement.timestampStart.toFormat("yyyy-dd-MM") ===
        curr.measurement.timestampStart.toFormat("yyyy-dd-MM")
    );

    if (index === -1) {
      acc.push(curr);
    } else if (
      curr.measurement.timestampStart > acc[index].measurement.timestampStart
    ) {
      acc[index] = curr;
    }

    return acc;
  }, []);
}

export function removeUndefined<T extends Record<string, unknown>>(
  input: T
): T {
  const result: Partial<T> = {};
  Object.keys(input).forEach((key) => {
    const value = input[key as keyof T];
    if (Array.isArray(value)) {
      result[key as keyof T] = value.map((item) =>
        typeof item === "object" && item !== null
          ? removeUndefined(item as Record<string, unknown>)
          : item
      ) as T[keyof T];
    } else if (typeof value === "object" && value !== null) {
      result[key as keyof T] = removeUndefined(
        value as Record<string, unknown>
      ) as T[keyof T];
    } else if (value !== undefined) {
      result[key as keyof T] = value;
    }
  });

  return result as T;
}

function checkRelevanceForObject(object: Object): boolean {
  let isRelevant = false;
  Object.values(object).forEach((v) => {
    if (v) {
      isRelevant = true;
    }
  });
  return isRelevant;
}

// ** Remove all entries that do not contain any relevant information (in order to send less stuff to the model...) */
function isRelevant(name: string, measurements: Entry[]): boolean {
  if (name.startsWith("lifestyle.physical-activity")) {
    // Include even if 0 since may be relevant to know...
    return true;
  }
  if (name.startsWith("lifestyle")) {
    for (const m of measurements) {
      if (checkRelevanceForObject(m.value as Object)) {
        return true;
      }
    }
    return false;
  } else if (name.startsWith("risk_assessment")) {
    if (!name.startsWith("risk_assessment.eye_pressure")) {
      for (const m of measurements) {
        if ((m.value as boolean) === true) {
          return true;
        }
      }
      return false;
    }
  }

  return true;
}

export function getResultRecord(results: Result[]): ResultRecord {
  return removeUndefined(
    results.reduce((acc, result) => {
      const { name, measurements } = result;
      if (isRelevant(name, measurements)) {
        acc[name] = measurements;
      }
      return acc;
    }, {} as ResultRecord)
  );
}

export function latestOnly(results: MergedRecord) {
  const filteredRecord: any = {};
  Object.keys(results).forEach((key) => {
    const value = (results as any)[key];
    const subKeys = Object.keys(value || {});
    if (subKeys.length > 0 && subKeys[0] === "0") {
      filteredRecord[key] = [value[subKeys[0]]]; // keep only latest
    } else {
      filteredRecord[key] = value;
    }
  });
  return filteredRecord as MergedRecord;
}

export function hasPreviousScan(results: MergedRecord | undefined) {
  if (!results) {
    return false;
  }
  let N = 0;
  let NHist = 0;
  Object.keys(results).forEach((key) => {
    const value = (results as any)[key];
    const subKeys = Object.keys(value || {});
    if (subKeys.length > 0 && subKeys[0] === "0") {
      N++;
      if (subKeys.length > 1) {
        NHist++;
      }
    }
  });
  return NHist / N > 0.3;
}

export function latestMeasurementDaysAgo(results: MergedRecord | undefined) {
  let days: number | undefined = undefined;

  if (!results) {
    return undefined;
  }

  const now = DateTime.local();

  Object.keys(results).forEach((key) => {
    const value = (results as any)[key];
    const subKeys = Object.keys(value || {});
    if (subKeys.length > 0 && subKeys[0] === "0") {
      const timestamp = DateTime.fromISO(
        value[subKeys[0]]["timestamp"] as string
      );
      const daysAgo = now.diff(timestamp, "days").toObject().days;
      days =
        days === undefined || (daysAgo && days && daysAgo < days)
          ? daysAgo
          : days;
    }
  });

  return days;
}

export function latestVisitSummaryDaysAgo(visits: VisitSummary[]) {
  if (visits.length > 0) {
    const now = DateTime.local();
    return now.diff(visits[0].visitDate, "days").toObject().days;
  } else {
    return undefined;
  }
}

export function addVisits(results: MergedRecord, visits: VisitSummary[]) {
  return {
    ...results,
    previousVisitSummaries: visits.map((v) => ({
      date: v.visitDate.toISODate(),
      summary: v.summaryText,
    })),
  };
}

const RiskMap: Record<RiskLevel, string> = {
  [RiskLevel.Risk]: "Risk",
  [RiskLevel.HighRisk]: "High Risk",
  [RiskLevel.ImmediateRisk]: "Immediate Risk",
  [RiskLevel.Optimal]: "Optimal",
  [RiskLevel.Normal]: "Normal",
  [RiskLevel.Unknown]: "Unknown",
  [RiskLevel.LowRisk]: "Low Risk",
  [RiskLevel.ModerateRisk]: "Moderate Risk",
};

function reducePrecision(key: string, entry: MergedEntry): MergedEntry {
  switch (key) {
    case "cardio.ecg.heart_rate":
      const hr = entry.value as Unit<"cardio.ecg.heart_rate">;
      return { ...entry, value: { bpm: Math.round(hr.bpm) } };
    case "cardio.respiratory_rate":
      const rr = entry.value as Unit<"cardio.respiratory_rate">;
      return { ...entry, value: { bpm: Math.round(rr.bpm) } };
    default:
      return entry;
  }
}

export function mapMergedRecord(record: MergedRecord) {
  const mapped: any = {};
  for (const [key, entries] of Object.entries(record)) {
    if (Array.isArray(entries)) {
      mapped[key] = entries.map((entry) => {
        return {
          ...reducePrecision(key, entry),
          risk: entry.risk !== undefined ? RiskMap[entry.risk] : undefined,
          deviation: undefined,
        };
      });
    } else {
      mapped[key] = entries;
    }
  }
  return mapped;
}

export function serializeMergedRecord(
  record: MergedRecord,
  indentJSON: boolean
) {
  return indentJSON
    ? JSON.stringify(mapMergedRecord(record), null, 2)
    : JSON.stringify(mapMergedRecord(record));
}
