import { Patient } from "@cur8/rich-entity";
import { clamp, mapLinear } from "lib/math";
import { RiskMetric } from "lib/metric";
import { HbA1cProjection } from "lib/projections";
import { useEffect, useMemo } from "react";
import { useAppInsights } from "render/context/AppInsightsContext";
import { useAge } from "render/hooks/patient/useAge";
import {
  AuxTitle,
  MainTitle,
  MetricResultHeader,
  Subtitle,
  Titles,
  Unit,
} from "render/ui/presentation/MetricResultHeader";
import { Description } from "render/ui/presentation/MetricResultHeader/MetricResultHeader";
import type { Highlight as MarkerHighlight } from "render/ui/presentation/ProjectionGraph/components/ProjectionMarker";
import type { Point } from "render/ui/presentation/ProjectionGraph/lib/svg";
import {
  AxisLabel,
  ProjectionGraph,
  ProjectionGraphRange,
} from "render/ui/presentation/ProjectionGraph/ProjectionGraph";
import { Highlight } from "render/ui/presentation/ChartMarker/Marker";
import styles from "./styles.module.sass";
import {
  RiskLabel,
  RiskLevel,
  UnitOfMeasurement,
  getRiskWeight,
} from "@cur8/measurements";

const MarkerHighlightMap: Record<RiskLevel, MarkerHighlight> = {
  [RiskLevel.Unknown]: "normal",
  [RiskLevel.Optimal]: "normal",
  [RiskLevel.Normal]: "normal",
  [RiskLevel.LowRisk]: "warning",
  [RiskLevel.Risk]: "warning",
  [RiskLevel.ModerateRisk]: "danger",
  [RiskLevel.HighRisk]: "danger",
  [RiskLevel.ImmediateRisk]: "danger",
};

interface HbA1cProjectionProps {
  patient: Patient;
  metrics: RiskMetric<"bloodwork.hba1c">[];
}

const HBA1C_PROJECTION_FLAGS = {
  PREVIOUS_SCAN_PROJECTION_ENABLED: false,
};

export function HbA1cProjectionGraph({
  patient,
  metrics,
}: HbA1cProjectionProps) {
  const patientAge = useAge(patient);
  const patientId = patient.patientId;

  const latestMetric = metrics[0];
  const previousMetric = metrics[1];
  const currentValue = latestMetric.unit["mmol/mol"];

  const appInsights = useAppInsights();

  /*
   * Current value projection
   */
  const projectionResult = useMemo(
    () =>
      HbA1cProjection.project({
        age: patientAge,
        hba1c: latestMetric,
      }),
    [patientAge, latestMetric]
  );

  useEffect(() => {
    appInsights.trackEvent({
      name: "doc-ui-dashboard-projections",
      properties: {
        patientId,
        type: "hba1c",
        params: {
          age: patientAge,
          hba1c: { "mmol/mol": currentValue },
        },
        result: projectionResult,
      },
    });
  }, [appInsights, projectionResult, patientId, patientAge, currentValue]);

  const projectedValue = projectionResult.hba1c["mmol/mol"];
  const projectionEndAge = projectionResult.age;

  const projectionPoints: Point[] = useMemo(
    () => [
      { x: patientAge, y: currentValue },
      { x: projectionEndAge, y: projectedValue },
    ],
    [patientAge, currentValue, projectedValue, projectionEndAge]
  );

  const currentValueRisk = latestMetric.riskRanges.riskLevel;

  /**
   * x-axis labels based on the patient's age.
   * The labels are generated in 10-year increments, up to 30 years from the patient's age
   * The last label is capped at the maximum age as defined by the projection logic (= projectionEndAge)
   * e.g. x = 33 => labels = [33, 43, 53, 63] | x = 90 => labels = [90, 100]
   */
  const xLabels: AxisLabel[] = useMemo(() => {
    const defaultLabels: AxisLabel[] = [
      { value: patientAge, label: String(patientAge) },
      {
        value: patientAge + 10,
        label: String(patientAge + 10),
      },
      {
        value: patientAge + 20,
        label: String(patientAge + 20),
      },
      {
        value: patientAge + 30,
        label: String(patientAge + 30),
      },
      {
        value: projectionEndAge,
        label: `Age ${projectionEndAge}`,
      },
    ];

    const TERMINAL_LABEL_MIN_AGE_PADDING = { years: 4 };

    const isLabelWithinAgeBounds = (
      label: AxisLabel,
      index: number,
      list: AxisLabel[]
    ) => {
      const isLastLabel = index === list.length - 1;

      if (isLastLabel) {
        return true;
      } else {
        return (
          label.value >= patientAge &&
          label.value <= projectionEndAge - TERMINAL_LABEL_MIN_AGE_PADDING.years
        );
      }
    };

    const labels = defaultLabels.filter(isLabelWithinAgeBounds);

    return labels;
  }, [patientAge, projectionEndAge]);

  const yRanges: ProjectionGraphRange[] | undefined = useMemo(() => {
    const TERMINAL_RANGE_MIN_PADDING: UnitOfMeasurement<"mmol/mol"> = {
      "mmol/mol": 3,
    };

    const riskRanges = latestMetric.riskRanges.riskIntervals;

    const getRiskRangeByLabel = (label: RiskLabel) => {
      return riskRanges.find((r) => r.riskLabel === label);
    };

    const lowRange = getRiskRangeByLabel(RiskLabel.Low);
    const optimalRange = getRiskRangeByLabel(RiskLabel.Optimal);
    const normalRange = getRiskRangeByLabel(RiskLabel.Normal);
    const preDiabetesRange = getRiskRangeByLabel(RiskLabel.PreDiabetes);
    const diabetesRange = getRiskRangeByLabel(RiskLabel.Diabetes);

    if (
      !lowRange ||
      !optimalRange ||
      !normalRange ||
      !preDiabetesRange ||
      !diabetesRange
    ) {
      return undefined;
    }

    const createProjectionGraphRange = (
      params: {
        labelLeft?: string;
        labelRight?: string;
        highlight?: Highlight;
      },
      ranges: { from: number; to: number }
    ) => {
      const isToInfinity = !isFinite(ranges.to);
      const isFromInfinity = !isFinite(ranges.from);

      const result: ProjectionGraphRange = {
        from: isFromInfinity ? ranges.to * 0.9 : ranges.from,
        to: isToInfinity ? ranges.from * 1.1 : ranges.to,
        labelLeft: params.labelLeft,
        labelRight: params.labelRight,
        highlight: params.highlight,
      };

      return result;
    };

    let ranges: ProjectionGraphRange[] = [
      createProjectionGraphRange(
        {
          labelLeft: optimalRange.start.value.toString(),
          labelRight: "Optimal",
        },
        { from: optimalRange.start.value, to: optimalRange.end.value }
      ),
      createProjectionGraphRange(
        { labelLeft: normalRange.start.value.toString(), labelRight: "Normal" },
        { from: normalRange.start.value, to: normalRange.end.value }
      ),
      createProjectionGraphRange(
        {
          labelLeft: preDiabetesRange.start.value.toString(),
          labelRight: "Pre-Diabetes",
          highlight: "warning",
        },
        { from: preDiabetesRange.start.value, to: preDiabetesRange.end.value }
      ),
      createProjectionGraphRange(
        {
          labelLeft: diabetesRange.start.value.toString(),
          labelRight: "Diabetes",
          highlight: "warning",
        },
        { from: diabetesRange.start.value, to: diabetesRange.end.value }
      ),
    ];

    /**
     * Edge cases
     */
    const firstRange = ranges[0];
    const lastRange = ranges[ranges.length - 1];

    const isCurrentValueTooCloseOrOutsideMinRange =
      currentValue - TERMINAL_RANGE_MIN_PADDING["mmol/mol"] < firstRange.from;
    const isProjectedValueTooCloseOrOutsideMaxRange =
      projectedValue + TERMINAL_RANGE_MIN_PADDING["mmol/mol"] > lastRange.to;

    // Very low values
    // If the current value is to close or outside the first (optimal) range, add the low hba1c range to the list for padding/better visualization
    if (isCurrentValueTooCloseOrOutsideMinRange) {
      const lowRangeFrom = clamp(
        currentValue - TERMINAL_RANGE_MIN_PADDING["mmol/mol"],
        0,
        lowRange.end.value - TERMINAL_RANGE_MIN_PADDING["mmol/mol"]
      );

      ranges.unshift({
        from: lowRangeFrom,
        to: lowRange.end.value,
      });
    }

    // Very high values
    // If the value is too close or outside the last (diabetes) range, increase the last range limit
    if (isProjectedValueTooCloseOrOutsideMaxRange) {
      lastRange.to = projectedValue + TERMINAL_RANGE_MIN_PADDING["mmol/mol"];
    }

    return ranges;
  }, [latestMetric.riskRanges.riskIntervals, currentValue, projectedValue]);

  /*
   * Previous value projection
   */
  const previousScanValue = previousMetric?.unit["mmol/mol"];
  const hasPreviousScanValue = previousScanValue != null;

  const patientAgeAtPreviousScan: number | null = useMemo(() => {
    if (!hasPreviousScanValue) {
      return null;
    }

    const currentScanDate = latestMetric.measurement.timestampStart;
    const previousScanDate = previousMetric.measurement.timestampStart;

    const yearsBetweenCurrentAndPreviousScan = Math.round(
      currentScanDate.diff(previousScanDate, "years").years
    );

    return patientAge - yearsBetweenCurrentAndPreviousScan;
  }, [latestMetric, previousMetric, hasPreviousScanValue, patientAge]);

  const previousScanProjectionResult = useMemo(() => {
    if (!hasPreviousScanValue || !patientAgeAtPreviousScan) {
      return null;
    }

    return HbA1cProjection.project({
      age: patientAgeAtPreviousScan,
      hba1c: previousMetric,
    });
  }, [hasPreviousScanValue, previousMetric, patientAgeAtPreviousScan]);

  const previousScanProjectedValue =
    previousScanProjectionResult?.hba1c["mmol/mol"];

  const previousScanProjectionPoints: Point[] = useMemo(() => {
    if (!previousScanProjectedValue || !patientAgeAtPreviousScan) {
      return [];
    }

    return [
      { x: patientAgeAtPreviousScan, y: previousScanValue },
      {
        x: projectionEndAge,
        y: previousScanProjectedValue,
      },
    ];
  }, [
    previousScanValue,
    previousScanProjectedValue,
    patientAgeAtPreviousScan,
    projectionEndAge,
  ]);

  // only show the previous scan projection if the value is within the y-axis bounds
  // otherwise, the previous scan projection is not relevant to the current projection, and should not be shown
  const showPreviousScanProjection = useMemo(() => {
    if (!HBA1C_PROJECTION_FLAGS.PREVIOUS_SCAN_PROJECTION_ENABLED) {
      return false;
    }
    if (
      !previousScanValue ||
      !previousScanProjectedValue ||
      !yRanges ||
      yRanges.length === 0
    ) {
      return false;
    }

    const yMin = Math.min(...yRanges.map((range) => range.from));
    const yMax = Math.max(...yRanges.map((range) => range.to));

    const isWithinYBounds = (value: number) => value >= yMin && value <= yMax;

    return (
      isWithinYBounds(previousScanValue) &&
      isWithinYBounds(previousScanProjectedValue)
    );
  }, [previousScanValue, previousScanProjectedValue, yRanges]);

  const xDomain: [number, number] = useMemo(
    () => [
      showPreviousScanProjection && patientAgeAtPreviousScan
        ? patientAgeAtPreviousScan
        : patientAge,
      Math.max(...xLabels.map((label) => label.value)),
    ],
    [showPreviousScanProjection, patientAgeAtPreviousScan, xLabels, patientAge]
  );

  const yDomain: [number, number] | undefined = useMemo(() => {
    if (!yRanges || yRanges.length === 0) {
      return undefined;
    }

    return [
      Math.min(...yRanges.map((range) => range.from)),
      Math.max(...yRanges.map((range) => range.to)),
    ];
  }, [yRanges]);

  const timelineSeek = mapLinear(patientAge, xDomain[0], xDomain[1], 0, 1, {
    clamp: true,
  });

  const isRiskThresholdAgeWithinBounds = useMemo(() => {
    if (!projectionResult.riskThreshold?.age) {
      return false;
    }

    // if the risk threshold age is too close to the projection start age or end age
    // don't show the marker to avoid clutter
    const TERMINAL_AGE_MIN_PADDING = { years: 1 };

    const isRiskThresholdAgeWithinBounds =
      projectionResult.riskThreshold.age >
        patientAge + TERMINAL_AGE_MIN_PADDING.years &&
      projectionResult.riskThreshold.age <
        projectionEndAge - TERMINAL_AGE_MIN_PADDING.years;

    return isRiskThresholdAgeWithinBounds;
  }, [patientAge, projectionEndAge, projectionResult.riskThreshold?.age]);

  if (!xDomain || !yDomain || !yRanges) {
    return null;
  }

  return (
    <div className={styles.HbA1cProjectionGraph}>
      <MetricResultHeader>
        <Titles>
          <MainTitle>Projected</MainTitle>
          <Subtitle>long-term blood sugar</Subtitle>
          <AuxTitle>[HbA1c]</AuxTitle>
        </Titles>
        <Unit>mmol/mol</Unit>
      </MetricResultHeader>
      <Description>
        30-year projection based on a long-running HbA1c study*
      </Description>

      <div className={styles.graph}>
        <ProjectionGraph
          xDomain={xDomain}
          yDomain={yDomain}
          xLabels={xLabels}
          yRanges={yRanges}
          timelineSeek={timelineSeek}
        >
          {showPreviousScanProjection && (
            <>
              <ProjectionGraph.Marker
                point={previousScanProjectionPoints[0]}
                variant="outlined"
                highlight={MarkerHighlightMap[currentValueRisk]}
              />

              <ProjectionGraph.Line
                points={previousScanProjectionPoints}
                highlight="none"
                variant="dashed"
              />
            </>
          )}

          <ProjectionGraph.Line
            points={projectionPoints}
            highlight={
              getRiskWeight(projectionResult.risk) >=
              getRiskWeight(RiskLevel.Risk)
                ? "warning"
                : "normal"
            }
          />

          <ProjectionGraph.Marker
            point={projectionPoints[0]}
            variant="primary"
            highlight={MarkerHighlightMap[currentValueRisk]}
          />

          <ProjectionGraph.TimelineRange to="timeline-start">
            {(isVisible) => (
              <ProjectionGraph.Label
                point={projectionPoints[0]}
                data-active={isVisible}
                data-strong={true}
                className={styles.currentValueLabel}
              >
                You are here
              </ProjectionGraph.Label>
            )}
          </ProjectionGraph.TimelineRange>

          {!!projectionResult.riskThreshold &&
            isRiskThresholdAgeWithinBounds && (
              <ProjectionGraph.RiskThresholdMarker
                age={projectionResult.riskThreshold.age}
                threshold={projectionResult.riskThreshold.value?.["mmol/mol"]}
              />
            )}
        </ProjectionGraph>
      </div>
    </div>
  );
}
