import { Preference } from "render/pages/SettingsPageCalendarSettings/types";
import { PageHeader } from "render/ui/layouts/PageHeader";
import { StepLayout } from "../../presentation/StepLayout/StepLayout";
import { RadioGroup } from "../../presentation/RadioGroup";
import styles from "./styles.module.sass";
import { useCallback } from "react";

export function SelectCalendarPreferenceStep(props: {
  onSelect: (preference: Preference) => void;
}) {
  const onSelect = props.onSelect;
  const submit = useCallback(
    (val: string) => {
      if (val === Preference.AllCalendars) {
        onSelect(Preference.AllCalendars);
      }
      if (val === Preference.CustomCalendar) {
        onSelect(Preference.CustomCalendar);
      }
    },
    [onSelect]
  );
  return (
    <StepLayout
      header={<PageHeader caption="Choose your calendar preference" />}
    >
      <div className={styles.SelectCalendarPreferenceStep}>
        <RadioGroup onChange={submit}>
          <RadioGroup.Radio
            label="Show full schedule"
            value={Preference.AllCalendars}
          />
          <RadioGroup.Radio
            label="Show room calendar"
            value={Preference.CustomCalendar}
          />
        </RadioGroup>
      </div>
    </StepLayout>
  );
}
