import { APITypesV1 } from "@cur8/api-client";
import { Visit } from "@cur8/rich-entity";

export enum ExamReasonLabel {
  BodyScan = "body-scan",
  FollowUp = "follow-up",
  Unknown = "unknown",
  NoShow = "no-show",
  Canceled = "canceled",
}

export function getMedicalExamReason(medicalExams: APITypesV1.MedicalExam[]) {
  if (medicalExams.length === 0) {
    return ExamReasonLabel.Unknown;
  }

  const isBodyScan = medicalExams.find(
    (value) => value === APITypesV1.MedicalExam.NekoBodyScan
  );
  if (isBodyScan) {
    return ExamReasonLabel.BodyScan;
  }
  return ExamReasonLabel.FollowUp;
}

export function getVisitReasonType(
  visit: Visit,
  medicalExams: APITypesV1.MedicalExam[]
): ExamReasonLabel {
  if (visit.status === APITypesV1.VisitStatus.Cancelled) {
    return ExamReasonLabel.Canceled;
  }
  if (visit.status === APITypesV1.VisitStatus.NoShow) {
    return ExamReasonLabel.NoShow;
  }

  return getMedicalExamReason(medicalExams);
}
