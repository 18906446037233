import { RiskLevel } from "@cur8/measurements";
import { RiskMetric } from "lib/metric";
import {
  FloatingValueTarget,
  RiskToHighlightMap,
} from "render/ui/presentation/FloatingValueTarget";
import { ValueItem } from "render/ui/symbol/ValueItem";

interface RightAnklePressureProps {
  anklePressure: RiskMetric<"cardio.ankle_pressure.right">[];
}

export function RightAnklePressure({ anklePressure }: RightAnklePressureProps) {
  const latest = anklePressure.at(0);
  const risk = latest?.riskRanges.riskLevel;
  const highlight = RiskToHighlightMap[risk ?? RiskLevel.Unknown];

  return (
    <FloatingValueTarget
      label="Right ankle"
      value={
        <ValueItem
          value={latest ? latest.unit.mmHg : "--"}
          unit="mmHg"
          size="huge"
          alignItems="baseline"
        />
      }
      width={210}
      highlight={highlight}
      align="bottom-left"
    />
  );
}
