import { Side } from "@cur8/api-client";
import { Sticky } from "@pomle/react-viewstack";
import { CameraView } from "lib/avatar/camera";
import { PodiumState, PodiumView } from "lib/avatar/podium";
import { Transform } from "lib/avatar/side";
import {
  byLinkDate,
  extractSide,
  isDermoscopyAnnotation,
  isPreferredLink,
} from "lib/lesion";
import { BodyAreas } from "lib/smpl";
import { useEffect, useMemo } from "react";
import { ScreenProjector } from "render/hooks/three/useProject";
import { useScreenAnchorsForLists } from "render/hooks/three/useScreenAnchors";
import { useDashboardContext } from "render/pages/DashboardPage/context/DashboardContext";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import { Layer } from "render/pages/DashboardPage/types";
import * as THREE from "three";
import { Overlay } from "../../components/Overlay";
import { DermaFlag } from "./components/DermaFlag";
import { LesionMarker } from "./components/LesionMarker";
import styles from "./styles.module.sass";

const PodiumViewMap: Record<Side, PodiumState> = {
  front: PodiumView.Front,
  back: PodiumView.Back,
};

interface TrackedLesionsLayerProps {
  layer: Layer;
  areas: BodyAreas;
  projector: ScreenProjector;
}

export function TrackedLesionsLayer({
  layer,
  projector,
}: TrackedLesionsLayerProps) {
  const active = layer === Layer.LesionMacro;

  const { camera, podium, ui } = useDashboardContext();

  const views = useMemo(() => {
    const macro = new CameraView();
    macro.pos.set(0, -2200, 1750);
    macro.focus.set(0, 0, 1300);

    return {
      macro,
    };
  }, []);

  const {
    skin: { lesions },
    scans,
  } = usePatientData();

  const latestSkinScan = scans?.skin.at(0);

  const physId = ui.physId;

  const spots = useMemo(() => {
    if (!latestSkinScan || !lesions) {
      return;
    }

    const spots = lesions
      .filter((lesion) => lesion.physicalId === physId)
      .map((lesion) => {
        const pos = new THREE.Vector3(0, 0, 0);
        const link = lesion.links
          .filter((link) => link.scan === latestSkinScan)
          .filter(isPreferredLink)
          .toSorted(byLinkDate("desc"))
          .at(0);

        const annotation = link?.annotation;
        const xyz = annotation?.classification.xyz;

        if (xyz) {
          const [x, y, z] = xyz;
          pos.set(x, y, z);
        }

        const side = annotation ? extractSide(annotation) : undefined;

        if (side) {
          pos.applyMatrix4(Transform[side]);
        }

        const derma = lesion.links
          .map((link) => link.annotation)
          .filter(isDermoscopyAnnotation)
          .at(0);

        return {
          derma,
          lesion,
          side,
          annotation,
          pos,
        };
      });

    return spots;
  }, [latestSkinScan, lesions, physId]);

  const positions = useMemo(() => {
    return spots ? spots.map((spot) => spot.pos) : [];
  }, [spots]);

  const artifactAnchors = useScreenAnchorsForLists(
    projector,
    positions,
    active
  );

  const selectedPhysId = ui.physId;

  const activeSpot = spots?.find((spot) => spot.lesion.physicalId === physId);

  const side = activeSpot?.side;

  useEffect(() => {
    if (!active) {
      return;
    }

    {
      const view = views.macro.clone();
      if (activeSpot) {
        const offset = 100;
        view.pos.z = activeSpot.pos.z - offset;
        view.focus.z = activeSpot.pos.z - offset;
      }
      camera.moveTo(view);
    }

    if (side) {
      const view = PodiumViewMap[side].clone();
      podium.moveTo(view);
    }
  }, [active, views, camera, podium, side, activeSpot]);

  return (
    <div className={styles.TrackedLesionsLayer} data-active={active}>
      {artifactAnchors?.map((anchor, index) => {
        const spot = spots?.at(index);
        const anno = spot?.annotation;
        const physId = anno?.physicalArtefactId;
        const derma = spot?.derma;

        return (
          <Overlay pos={anchor} key={anno?.id ?? index} zIndex={derma ? 2 : 1}>
            <div className={styles.lesionMarker} data-active={active}>
              <button
                onClick={() => {
                  ui.set({ physId });
                }}
              >
                <LesionMarker
                  active={active}
                  selected={physId === selectedPhysId}
                  annotation={anno}
                />
              </button>

              <Sticky>
                {derma && (
                  <div className={styles.dermaFlag}>
                    <button
                      data-role="derma-flag-button"
                      data-derma-id={derma.id}
                      onClick={() => {
                        ui.set({ dermaId: derma.id });
                      }}
                    >
                      <DermaFlag annotation={derma} />
                    </button>

                    <svg viewBox="0 0 100 100" width="72" height="72">
                      <line
                        x1={0}
                        x2={100}
                        y1={100}
                        y2={0}
                        className={styles.stroke}
                      />
                    </svg>
                  </div>
                )}
              </Sticky>
            </div>
          </Overlay>
        );
      })}
    </div>
  );
}
