import { Room, Site } from "@cur8/rich-entity";
import { RadioGroup } from "../../presentation/RadioGroup";
import { StepLayout } from "../../presentation/StepLayout";
import styles from "./styles.module.sass";

export function SelectRoomStep(props: {
  onSelect: (roomId: string) => void;
  site: Site | undefined;
  rooms: Room[] | undefined;
}) {
  return (
    <StepLayout
      header={"What room are you assigned today ?"}
      subHeader={props.site?.siteName}
    >
      <div>
        <RadioGroup onChange={props.onSelect}>
          {props.rooms?.map((room, index) => (
            <div
              className={styles.room}
              key={room.id}
              style={{ animationDelay: `${index * 0.025}s` }}
            >
              <RadioGroup.Radio label={room.displayName} value={room.id} />
            </div>
          ))}
        </RadioGroup>
      </div>
    </StepLayout>
  );
}
