import { DateTime } from "luxon";
import { useCallback } from "react";
import { useBookingSchedule } from "./useBookingSchedule";
import { useCalendarState } from "./useCalendarState";
import { APITypesV1 } from "@cur8/api-client";

export function useFetchSlots() {
  const { selectedPeriod, medicalExamsFilter } = useCalendarState();
  const { fetchSlots: fetchSlotsByRange } = useBookingSchedule();

  const fetchSlots = useCallback(
    async (
      start: DateTime,
      end: DateTime,
      filters?: { medicalExams?: APITypesV1.MedicalExam[] }
    ) => {
      return await fetchSlotsByRange(start, end, filters);
    },
    [fetchSlotsByRange]
  );

  const refetchCurrentSlots = useCallback(async () => {
    return fetchSlots(selectedPeriod.start, selectedPeriod.end, {
      medicalExams: medicalExamsFilter,
    });
  }, [
    fetchSlots,
    selectedPeriod.end,
    selectedPeriod.start,
    medicalExamsFilter,
  ]);

  return {
    fetchSlots,
    refetchCurrentSlots,
  };
}
