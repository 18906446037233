import { BodyAreas } from "lib/smpl";
import { toCenter } from "lib/three";
import { useMemo } from "react";
import { PulseWaveLocation } from "render/fragments/avatar/overlay/PulseWaveLocation";
import { ScreenProjector } from "render/hooks/three/useProject";
import { useScreenAnchorsForObjects } from "render/hooks/three/useScreenAnchors";
import { useDashboardContext } from "render/pages/DashboardPage/context/DashboardContext";
import { Layer } from "render/pages/DashboardPage/types";
import { Overlay } from "../../components/Overlay";
import styles from "./styles.module.sass";

const ACTIVE = new Set([Layer.Cardio]);

interface PulseWaveLayerProps {
  layer: Layer;
  areas: BodyAreas;
  projector: ScreenProjector;
}

export function PulseWaveLayer({
  layer,
  areas,
  projector,
}: PulseWaveLayerProps) {
  const { ui } = useDashboardContext();

  const positions = useMemo(() => {
    const aorta = toCenter(areas.heart);
    const femoral = toCenter(areas.rightHip);

    aorta.z += 50;

    return {
      aorta,
      femoral,
    };
  }, [areas]);

  const active = ACTIVE.has(layer);

  const anchors = useScreenAnchorsForObjects(projector, positions, active);

  return (
    <>
      <div className={styles.PulseWaveLayer} data-active={active}>
        <Overlay pos={anchors?.femoral}>
          <div className={styles.item}>
            <PulseWaveLocation caption="Hip" />
          </div>
        </Overlay>
      </div>

      <div
        className={styles.PulseWaveLayer}
        data-active={active && ui.layer === Layer.Cardio}
      >
        <Overlay pos={anchors?.aorta}>
          <div className={styles.item}>
            <PulseWaveLocation caption="Aorta" />
          </div>
        </Overlay>
      </div>
    </>
  );
}
