import { useCallback, useEffect, useState } from "react";
import { useBookingStore } from "render/hooks/api/useBookingStore";
import { useAsyncHandle } from "render/hooks/useAsyncHandle";
import { Checkbox } from "render/ui/form/Checkbox";
import { PopupDialog } from "render/ui/layouts/PopupDialog";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { HoverTextButton } from "render/ui/trigger/HoverTextButton";
import styles from "./styles.module.sass";

// TODO: move to /render/hooks/api
import { Slot } from "@cur8/rich-entity";
import { useReporting } from "render/hooks/useReporting";
import { LineThrobber } from "render/ui/throbber/LineThrobber";

interface Props {
  slot: Slot;
  onDone(): void;
  onCancel(): void;
}

export function CancelAppointmentPopup({ slot, onDone, onCancel }: Props) {
  const { logError } = useReporting();
  const { unBookSlot } = useBookingStore();
  const [refundAfterCancel, setRefundAfterCancel] = useState(true);
  const [reserveAfterCancel, setReserveAfterCancel] = useState(false);

  useEffect(() => {
    function keyDownHandler(ev: KeyboardEvent) {
      if (ev.key === "Escape") {
        onCancel();
      }
    }

    window.addEventListener("keydown", keyDownHandler);
    return () => {
      window.removeEventListener("keydown", keyDownHandler);
    };
  }, [onCancel]);

  const handleUnbookSlot = useCallback(() => {
    const DELAY_FOR_EVENTUAL_CONSISTENCY = 4000;
    const waitFor = (time: number) => () =>
      new Promise((resolve) => setTimeout(resolve, time));

    return unBookSlot(slot, {
      refundToken: refundAfterCancel,
      reserveSlot: reserveAfterCancel,
    })
      .result.then(waitFor(DELAY_FOR_EVENTUAL_CONSISTENCY))
      .then(onDone)
      .catch(logError);
  }, [
    unBookSlot,
    slot,
    refundAfterCancel,
    reserveAfterCancel,
    logError,
    onDone,
  ]);

  const unbookSlotHandler = useAsyncHandle(handleUnbookSlot);

  const isBusy = unbookSlotHandler.busy;

  const toggleRefundAfterCancelFlag = useCallback(() => {
    if (!isBusy) {
      setRefundAfterCancel((value) => !value);
    }
  }, [isBusy]);

  const toggleReserveAfterCancelFlag = useCallback(() => {
    if (!isBusy) {
      setReserveAfterCancel((value) => !value);
    }
  }, [isBusy]);

  return (
    <PopupDialog>
      <div className={styles.CancelAppointmentPopup}>
        <div className={styles.content}>
          <header>
            <h2>Do you want to cancel this appointment?</h2>
          </header>
          <div className={styles.checkboxContainer}>
            <Checkbox
              label={null}
              disabled={isBusy}
              checked={refundAfterCancel === true}
              onChange={toggleRefundAfterCancelFlag}
            />
            <label onClick={toggleRefundAfterCancelFlag}>
              Return Booking Token to Member?
            </label>
          </div>
          <div className={styles.checkboxContainer}>
            <Checkbox
              label={null}
              disabled={isBusy}
              checked={reserveAfterCancel === true}
              onChange={toggleReserveAfterCancelFlag}
            />
            <label onClick={toggleReserveAfterCancelFlag}>
              Reserve Slot After Cancelling?
            </label>
          </div>
          <footer className={styles.footer}>
            <HoverTextButton type="button" onClick={onDone}>
              No
            </HoverTextButton>

            <ActionButton
              data-variant="danger"
              disabled={isBusy}
              handle={unbookSlotHandler}
            >
              Cancel appointment
            </ActionButton>
          </footer>
          {isBusy && <LineThrobber />}
        </div>
      </div>
    </PopupDialog>
  );
}
