import { RiskMetric } from "lib/metric";
import { useMemo } from "react";
import { MetricStatus } from "render/ui/presentation/MetricStatus";
import { MetricStatusBadge } from "render/ui/presentation/MetricStatusBadge";
import { ValueItem } from "render/ui/symbol/ValueItem";

interface BMIItemProps {
  metrics: RiskMetric<"body.bmi">[] | undefined;
}

export function BMIItem({ metrics }: BMIItemProps) {
  const latest = metrics?.at(0);
  const previous = metrics?.at(1);

  const risk = latest?.riskRanges.riskLevel;

  const currentScore = latest?.unit["kg/m2"];
  const previousScore = previous?.unit["kg/m2"];

  const change = useMemo(() => {
    return currentScore == null || previousScore == null
      ? 0
      : Number((currentScore - previousScore).toFixed(1));
  }, [currentScore, previousScore]);

  const value = useMemo(() => {
    const result =
      currentScore != null && isFinite(currentScore)
        ? currentScore.toFixed(1)
        : "--";
    return result;
  }, [currentScore]);

  return (
    <MetricStatus
      caption={<>BMI</>}
      summaryContent={risk ? <MetricStatusBadge risk={risk} /> : "--"}
      differenceToPreviousValue={change}
    >
      <ValueItem value={value} />
    </MetricStatus>
  );
}
