import { APITypesV1 } from "@cur8/api-client";

export function toReadableState(state: APITypesV1.AssessmentState): string {
  switch (state) {
    case APITypesV1.AssessmentState.Cleared:
      return "Cleared";
    case APITypesV1.AssessmentState.FollowUpRequested:
      return "Follow up";
    case APITypesV1.AssessmentState.ReviewRequested:
      return "Review requested";
    case APITypesV1.AssessmentState.New:
      return "New";
    default:
      return state;
  }
}
