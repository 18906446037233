import { Layout, LayoutProps } from "../Layout";
import { SidePanel } from "../SidePanel";
import { ArterySection } from "./components/ArterySection";
import { BloodFatsLeftSection } from "./components/BloodFatsLeftSection";
import { BloodFatsRightSection } from "./components/BloodFatsRightSection";
import styles from "./styles.module.sass";

export function CholesterolLayout({ active }: LayoutProps) {
  return (
    <Layout active={active}>
      <div className={styles.BloodFatsPanel} data-active={active}>
        <SidePanel side="left">
          <BloodFatsLeftSection />
        </SidePanel>

        <div className={styles.ArteryPanel}>{<ArterySection />}</div>

        <SidePanel side="right">{<BloodFatsRightSection />}</SidePanel>
      </div>
    </Layout>
  );
}
