import { createQuery, codecs } from "@pomle/paths";
import { DateTime } from "luxon";

export enum Step {
  SelectCalendarPreference = "select-step-calendar-preference",
  SelectLocation = "select-step-location",
  SelectRoom = "select-step-room",
  Submit = "submit",
}
export enum Preference {
  AllCalendars = "all-calendars",
  CustomCalendar = "custom-calendar",
}

export const queryParams = createQuery({
  locationId: codecs.string,
  roomId: codecs.string,
  step: codecs.oneOf([
    Step.SelectLocation,
    Step.SelectRoom,
    Step.SelectCalendarPreference,
    Step.Submit,
  ] as const),
});

export interface CalendarPreferenceFormData {
  siteId: string | undefined;
  roomId: string | undefined;
  expiresAt: DateTime;
}
