import { Dimension } from "@cur8/rich-entity";
import { useMemo } from "react";
import { toFixedWithSign, truncate } from "../../helpers";

import styles from "./DimensionView.module.sass";

export function DimensionView({
  dimension,
  previousDimension,
  decimalPlaces,
}: {
  dimension: Dimension;
  previousDimension: Dimension | undefined;
  decimalPlaces: number;
}) {
  const diffLabel = useMemo(() => {
    if (dimension && previousDimension) {
      const diff = previousDimension
        ? dimension.value - previousDimension.value
        : null;

      if (diff === null) {
        return null;
      }
      return <>Change: {toFixedWithSign(diff)}</>;
    }
    return null;
  }, [dimension, previousDimension]);

  const dname = dimension.name === "value" ? "" : dimension.name;

  return (
    <div className={styles.DimensionView}>
      <div className={styles.body}>
        <div className={styles.current}>
          <span className={styles.unit}>{dname}</span>
          <span className={styles.value}>
            {truncate(dimension.value, decimalPlaces)}
          </span>
          <span className={styles.unit}>{dimension.unit}</span>
        </div>
        <div className={styles.current}>
          <div className={styles.unit}>{diffLabel}</div>
        </div>
      </div>
    </div>
  );
}
