import * as THREE from "three";

export function createLights() {
  const lights = new THREE.Group();

  const spotLight1 = new THREE.SpotLight("#ff0000");
  spotLight1.intensity = 0.8;
  //spotLight1.distance = 1000;
  spotLight1.decay = 0;
  spotLight1.position.set(0, 0, 0);
  spotLight1.layers.set(1);

  const spotLight2 = new THREE.SpotLight("#00ff00");
  spotLight2.intensity = 0.6;
  spotLight2.distance = 1000;
  spotLight2.decay = 0;
  spotLight2.position.set(0, 0, 0);
  spotLight2.layers.set(1);

  //left side
  const spotlightBlue1 = new THREE.SpotLight("#00f");
  spotlightBlue1.intensity = 2;
  //spotlightBlue1.penumbra = 0.2;
  spotlightBlue1.distance = 10000;
  spotlightBlue1.decay = 0.1;
  spotlightBlue1.position.set(-730, -600, 2029);
  spotlightBlue1.target.position.set(0, 0, 0);
  spotlightBlue1.layers.set(1);

  //rim
  const spotlightBlue2 = new THREE.SpotLight("#00f");
  spotlightBlue2.intensity = 1;
  //spotlightBlue1.penumbra = 0.2;
  spotlightBlue2.distance = 10000;
  spotlightBlue2.decay = 0;
  spotlightBlue2.position.set(0, 1330, 1629);
  spotlightBlue2.target.position.set(0, 0, 0);
  spotlightBlue2.layers.set(1);

  //fill
  const spotlightBlue3 = new THREE.SpotLight("#00f");
  spotlightBlue3.intensity = 0.2;
  //spotlightBlue3.angle = 1;
  //spotlightBlue3.penumbra = 1;
  spotlightBlue3.distance = 10000;
  spotlightBlue3.decay = 0;
  spotlightBlue3.position.set(700, -630, 1329);
  spotlightBlue3.target.position.set(0, 0, 0);
  spotlightBlue3.layers.set(1);

  const ambientLight = new THREE.AmbientLight("#ffffff", 10);
  //ambientLight.layers.set(1);

  lights.add(
    spotLight1,
    spotLight2,
    spotlightBlue1,
    spotlightBlue2,
    spotlightBlue3,
    ambientLight
  );

  return {
    group: lights,
    spotLight1,
    spotLight2,
    spotlightBlue1,
    spotlightBlue2,
    spotlightBlue3,
    ambientLight,
  };
}
