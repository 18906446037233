import { Table } from "render/ui/layouts/Table";
import { ResultRow } from "../ResultRow";
import { PatientEncyclopediaMetrics } from "../../hooks/usePatientEncyclopediaMetrics";
import { Country } from "lib/visit/visit";

interface BloodworkTableProps {
  metrics: PatientEncyclopediaMetrics;
  country: Country | undefined;
}

export function BloodworkTable({ metrics, country }: BloodworkTableProps) {
  const bloodwork = metrics.bloodwork;

  return (
    <Table>
      <thead>
        <tr>
          <th>Bloodwork</th>
          <th>Result</th>
          <th>
            Risk {country != null ? <>(based on {country})</> : undefined}
          </th>
          <th>Units</th>
        </tr>
      </thead>
      <tbody>
        <ResultRow
          caption="CRP"
          value={bloodwork.crp?.at(0)}
          format={(metric) => metric.unit["mg/L"].toFixed(2)}
          unit="mg/L"
          summarize={(metric) => {
            return {
              risk: metric.riskRanges.riskLevel,
            };
          }}
        />
        <ResultRow
          caption="HbA1C"
          value={bloodwork.hba1c?.at(0)}
          format={(metric) => metric.unit["mmol/mol"].toFixed(2)}
          unit="mmol/mol"
          summarize={(metric) => {
            return {
              risk: metric.riskRanges.riskLevel,
            };
          }}
        />
        <ResultRow
          caption="Haemoglobin"
          value={bloodwork.haemoglobin?.at(0)}
          format={(metric) => metric.unit["g/L"].toFixed(2)}
          unit="g/L"
          summarize={(metric) => {
            return {
              risk: metric.riskRanges.riskLevel,
            };
          }}
        />
        <ResultRow
          caption="Glucose"
          value={bloodwork.glucose?.at(0)}
          format={(metric) => metric.unit["mmol/L"].toFixed(2)}
          unit="mmol/L"
          summarize={(metric) => {
            return {
              risk: metric.riskRanges.riskLevel,
            };
          }}
        />
      </tbody>
    </Table>
  );
}
