import { useCallback } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useReporting } from "render/hooks/useReporting";

export const MEMBER_SUMMARY_RESULT_NAME = "linkedMemberSummary";

export function useCreateLLMScan() {
  const api = useAPIClient();
  const { handleError } = useReporting();

  const createLLMScan = useCallback(
    async (patientId: string) => {
      const timestamp = new Date();

      const primarySourceUri = `cur8:imported-device-data:llm:mic:${timestamp.getTime()}`;

      try {
        const scan = await api.scan.createPatientScan({
          patientId,
          primarySourceUri,
          sourceUris: [],
          scanType: "llm/1",
          pendingResults: [
            "audioFiles",
            "audioRecordingCount",
            "transcriptionFiles",
            "transcription",
            MEMBER_SUMMARY_RESULT_NAME,
            "reviewResult",
          ],
          recordingTimestamp: timestamp.toISOString(),
          isInternalScan: true,
        }).result;

        return scan;
      } catch (e) {
        handleError(e);
        return undefined;
      }
    },
    [api.scan, handleError]
  );

  return {
    createLLMScan,
  };
}
