import { Patient } from "@cur8/rich-entity";
import { MAX_PROJECTION_AGE } from "lib/projections/hba1c/hba1c.projection";
import { useMemo } from "react";
import { useAge } from "render/hooks/patient/useAge";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import { Typography } from "render/ui/presentation/Typography";
import { GlucoseSegment } from "./components/GlucoseSegment";
import { HbA1cBenchmark } from "./components/HbA1cBenchmark";
import { Header as BenchmarkHeader } from "./components/HbA1cBenchmark/components/Header";
import { HbA1cProjectionGraph } from "./components/HbA1cProjectionGraph";
import { HbA1cSegment } from "./components/HbA1cSegment";
import styles from "./styles.module.sass";

interface DiabetesSectionProps {
  patient: Patient;
}

export function DiabetesSection({ patient }: DiabetesSectionProps) {
  const {
    metrics: { bloodwork },
  } = usePatientData();
  const age = useAge(patient);

  /**
   * If the latest HbA1c value is in the diabetes or low hba1c range, the hba1c projection graph is not relevant/should not be shown.
   */
  const isCurrentValueWithinHba1cProjectionBounds = useMemo(() => {
    const latestMetric = bloodwork.hba1c?.[0];

    if (!latestMetric) {
      return false;
    }

    const hba1cRiskRanges = latestMetric.riskRanges.riskIntervals;
    const hba1cDiabetesRange = hba1cRiskRanges.find(
      (range) => range.riskLabel === "diabetes"
    );

    if (!hba1cDiabetesRange) {
      return false;
    }

    // values confirmed with MedOps
    const projectionBounds = {
      min: {
        inclusive: true,
        value: 25,
      },
      max: hba1cDiabetesRange.start,
    };

    const value = latestMetric.unit["mmol/mol"];
    if (projectionBounds.min.inclusive && projectionBounds.min.value > value) {
      return false;
    }
    if (projectionBounds.max.inclusive && projectionBounds.max.value < value) {
      return false;
    }

    return (
      value >= projectionBounds.min.value && value <= projectionBounds.max.value
    );
  }, [bloodwork]);

  return (
    <div className={styles.DiabetesSection}>
      <section>
        <Typography as="h2" variant="display-s">
          Diabetes factors
        </Typography>
        <hr />
      </section>
      {!!bloodwork.hba1c?.length && !!bloodwork.glucose?.length && (
        <section className={styles.rangesSection}>
          <HbA1cSegment metrics={bloodwork.hba1c} />
          <GlucoseSegment metrics={bloodwork.glucose} />
        </section>
      )}

      {patient &&
        !!bloodwork.hba1c?.length &&
        isCurrentValueWithinHba1cProjectionBounds &&
        age < MAX_PROJECTION_AGE && (
          <section>
            <HbA1cProjectionGraph patient={patient} metrics={bloodwork.hba1c} />
          </section>
        )}

      {patient && !!bloodwork.hba1c?.length && (
        <section>
          <BenchmarkHeader
            metrics={bloodwork.hba1c}
            sex={patient.sex}
            age={age}
          />
          <HbA1cBenchmark patient={patient} metrics={bloodwork.hba1c} />
        </section>
      )}
    </div>
  );
}
