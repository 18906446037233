import { RiskMetric } from "lib/metric";
import { RampUpNumber } from "render/ui/format/RampUpNumber";
import {
  FloatingValueTarget,
  type FloatingValueTargetProps,
} from "render/ui/presentation/FloatingValueTarget";
import { ValueItem } from "render/ui/symbol/ValueItem";
import { RampUpNumberAnimationConfig } from "../HeartEcgOverlay.animations";

interface EcgSegmentPProps {
  active?: boolean;
  align: FloatingValueTargetProps["align"];
  width?: FloatingValueTargetProps["width"];
  p: RiskMetric<"cardio.ecg.segments.p">[] | undefined;
}

export function EcgSegmentP({
  active = true,
  p,
  width,
  align,
}: EcgSegmentPProps) {
  const latest = p?.at(0);

  return (
    <FloatingValueTarget
      label={<>Atrial</>}
      value={
        <ValueItem
          value={
            latest && active ? (
              <RampUpNumber
                value={latest.unit.ms}
                precision={0}
                duration={RampUpNumberAnimationConfig.duration}
                ease={RampUpNumberAnimationConfig.ease}
              />
            ) : (
              "--"
            )
          }
          unit="ms"
          size="huge"
          alignItems="baseline"
        />
      }
      align={align}
      width={width}
    />
  );
}
