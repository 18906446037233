import { RiskMetric } from "lib/metric";
import { useMemo } from "react";
import { MetricRange } from "render/ui/presentation/MetricRange";
import { toChartRanges } from "render/ui/presentation/RangeChart/lib/risk";

interface CRPRangeProps {
  metrics: RiskMetric<"bloodwork.crp">[];
}

export function CRPRange({ metrics }: CRPRangeProps) {
  const latest = metrics?.at(0);

  const entries = useMemo(() => {
    return metrics.map((metric) => {
      return {
        risk: metric.riskRanges.riskLevel,
        timestamp: metric.measurement.timestampStart,
        value: metric.unit["mg/L"],
      };
    });
  }, [metrics]);

  const ranges = useMemo(() => {
    const riskIntervals = latest?.riskRanges.riskIntervals ?? [];
    const result = toChartRanges(riskIntervals);
    return result;
  }, [latest?.riskRanges.riskIntervals]);

  // https://dev.azure.com/cur8/Engineering/_workitems/edit/26137
  const rangesCapHigh = 4;

  return (
    <MetricRange
      title="Inflammation"
      subtitle="[Hs-CRP]"
      unit="mg/L"
      entries={entries}
      ranges={ranges}
      rangesCapHigh={rangesCapHigh}
      formatValue={(value) => value?.toFixed(1)}
      showEmptyRanges
    />
  );
}
