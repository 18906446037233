import {
  AuthenticationResult,
  InteractionRequiredAuthError,
  PublicClientApplication,
} from "@azure/msal-browser";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAppInsights } from "../AppInsightsContext";
import { useConfig } from "../ConfigContext";

export enum SessionState {
  Pending,
  Busy,
  Settled,
}

type MSALAuth = {
  instance: PublicClientApplication;
  auth?: AuthenticationResult;
  session: {
    state: SessionState;
    logIn(): void;
    logOut(): void;
  };
};

export const Context = createContext<MSALAuth | null>(null);

let redirectHandleCount = 0;

interface MSALContextProps {
  children: ReactNode;
}

export function MSALContext({ children }: MSALContextProps) {
  const config = useConfig();
  const appInsights = useAppInsights();

  const instance = useMemo(() => {
    return new PublicClientApplication(config.msal);
  }, [config]);

  const [state, setState] = useState(SessionState.Pending);

  const [auth, setAuth] = useState<AuthenticationResult>();

  const logIn = useCallback(() => {
    setState(SessionState.Busy);
    const request = { scopes: config.msal.scopes.token };
    instance.loginRedirect(request);
  }, [instance, config]);

  const logOut = useCallback(() => {
    setState(SessionState.Busy);
    instance.logoutRedirect();
  }, [instance]);

  useEffect(() => {
    if (redirectHandleCount > 0) {
      return;
    }
    redirectHandleCount++;

    setState(SessionState.Busy);

    instance
      .handleRedirectPromise()
      .then((auth) => {
        if (auth) {
          console.debug("Auth from Redirect", auth);
          return auth;
        }

        const request = { scopes: config.msal.scopes.token };
        return instance.ssoSilent(request).then((auth) => {
          console.debug("Auth from Silent", auth);
          return auth;
        });
      })
      .then((auth) => {
        if (auth) {
          setAuth(auth);
        } else {
          console.debug("No auth found");
        }
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          console.debug("Silent sign in attempt not possible", error);
          return;
        }
        console.error("MSAL Error", error);
      })
      .finally(() => {
        setState(SessionState.Settled);
      });
  }, [config, instance]);

  useEffect(() => {
    if (!auth) {
      return;
    }

    appInsights.setAuthenticatedUserContext(
      `${auth.tenantId}:${auth.uniqueId}`
    );

    return () => {
      appInsights.clearAuthenticatedUserContext();
    };
  }, [auth, appInsights]);

  const session = {
    state,
    logOut,
    logIn,
  };

  return (
    <Context.Provider value={{ instance, auth, session }}>
      {children}
    </Context.Provider>
  );
}

export function useMSAL() {
  const context = useContext(Context);
  if (!context) {
    throw new Error("useMSAL without MSALContext");
  }
  return context;
}
