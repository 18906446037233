import { Patient } from "@cur8/rich-entity";
import { BloodOxygenRange } from "render/fragments/metric/range-charts/BloodOxygenRange";
import { CRPRange } from "render/fragments/metric/range-charts/CRPRange";
import { ArterialHealthItem } from "render/fragments/metric/summary/ArterialHealthItem";
import { BloodFatsItem } from "render/fragments/metric/summary/BloodFatsItem";
import { HeartRhythmItem as HeartRhythmItem2 } from "render/fragments/metric/summary/HeartRhythmItem2";
import { useDashboardContext } from "render/pages/DashboardPage/context/DashboardContext";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import { Layer } from "render/pages/DashboardPage/types";
import { SummarySet } from "render/pages/DashboardPage/ui/SummarySet";
import { Typography } from "render/ui/presentation/Typography";
import { HeartAgeSection } from "./components/HeartAgeSection";
import styles from "./styles.module.sass";

interface CardioSectionProps {
  patient: Patient;
}

export function CardioSection({ patient }: CardioSectionProps) {
  const { ui } = useDashboardContext();

  const {
    metrics: { cardio, bloodwork },
  } = usePatientData();

  return (
    <div className={styles.CardioSection}>
      <Typography as="h2" variant="display-s">
        Heart & Circulation
      </Typography>

      <hr />

      <div className={styles.heartAgeSection}>
        <HeartAgeSection patient={patient} />
      </div>

      <hr />

      <SummarySet>
        <button
          type="button"
          onClick={() => {
            ui.set({ layer: Layer.ArterialHealth });
          }}
        >
          <ArterialHealthItem
            patient={patient}
            abi={cardio.abi}
            brachialPressure={cardio.brachialPressure}
          />
        </button>

        <button
          type="button"
          onClick={() => {
            ui.set({ layer: Layer.Heart });
          }}
        >
          <HeartRhythmItem2
            pr={cardio.ecg.pr}
            qrs={cardio.ecg.qrs}
            qtc={cardio.ecg.qtc}
            heartRate={cardio.heartRate}
          />
        </button>

        <button
          type="button"
          onClick={() => {
            ui.set({ layer: Layer.Cholesterol });
          }}
        >
          <BloodFatsItem bloodFats={bloodwork} />
        </button>
      </SummarySet>

      <div
        className={styles.rangesSection}
        data-has-crp={!!bloodwork.crp}
        data-has-blood-oxygen={!!cardio.bloodOxygen}
      >
        {!!bloodwork.crp && <CRPRange metrics={bloodwork.crp} />}
        {!!cardio.bloodOxygen && (
          <BloodOxygenRange metrics={cardio.bloodOxygen} />
        )}
      </div>
    </div>
  );
}
