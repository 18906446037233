import { APITypesV1 } from "@cur8/api-client";
import { Measurement } from "@cur8/rich-entity";
export type SectionInfo = {
  sectionTitle: SectionTitle | null;
  friendlyName: string;
  decimalPlaces: number;
};
export type SectionMeasurement = Measurement &
  SectionInfo & { risk: APITypesV1.RiskType };

export type SectionMeasurements = {
  current: SectionMeasurement[];
  previous: SectionMeasurement[];
};

export const NO_MEASUREMENTS: SectionMeasurements = {
  current: [],
  previous: [],
};

export enum SectionTitle {
  overview = "Overview",
  heart = "Heart & circulation",
  body = "Body",
  skin = "Skin",
  recommendations = "Recommendations",
}

export const sectionInfo: Record<string, SectionInfo> = {
  // Heart section
  "cardio.ankle_pressure": {
    sectionTitle: SectionTitle.heart,
    friendlyName: "Ankle pressure",
    decimalPlaces: 0,
  },
  "cardio.heart_rate": {
    sectionTitle: SectionTitle.heart,
    friendlyName: "Heart rate",
    decimalPlaces: 0,
  },
  "cardio.oxygen_saturation": {
    sectionTitle: SectionTitle.heart,
    friendlyName: "Oxygen saturation",
    decimalPlaces: 0,
  },
  "cardio.brachial_pressure": {
    sectionTitle: SectionTitle.heart,
    friendlyName: "Brachial pressure",
    decimalPlaces: 0,
  },
  "cardio.abi": {
    sectionTitle: SectionTitle.heart,
    friendlyName: "ABI",
    decimalPlaces: 2,
  },
  "bloodwork.cholesterol": {
    sectionTitle: SectionTitle.heart,
    friendlyName: "Cholesterol",
    decimalPlaces: 1,
  },
  "bloodwork.crp": {
    sectionTitle: SectionTitle.heart,
    friendlyName: "hsCRP",
    decimalPlaces: 1,
  },
  "bloodwork.non_hdl": {
    sectionTitle: SectionTitle.heart,
    friendlyName: "Non-HDL",
    decimalPlaces: 1,
  },
  "bloodwork.ldl": {
    sectionTitle: SectionTitle.heart,
    friendlyName: "LDL",
    decimalPlaces: 1,
  },
  "bloodwork.triglycerides": {
    sectionTitle: SectionTitle.heart,
    friendlyName: "Triglycerides",
    decimalPlaces: 1,
  },
  "bloodwork.hdl": {
    sectionTitle: SectionTitle.heart,
    friendlyName: "HDL",
    decimalPlaces: 1,
  },
  "bloodwork.cholesterol_hdl": {
    sectionTitle: SectionTitle.heart,
    friendlyName: "Cholesterol/HDL",
    decimalPlaces: 1,
  },

  // Body section
  "body.bmi": {
    sectionTitle: SectionTitle.body,
    friendlyName: "BMI",
    decimalPlaces: 1,
  },
  "body.grip_strength": {
    sectionTitle: SectionTitle.body,
    friendlyName: "Grip strength",
    decimalPlaces: 0,
  },
  "bloodwork.white_blood_cells.total": {
    sectionTitle: SectionTitle.body,
    friendlyName: "WBC (Total)",
    decimalPlaces: 1,
  },
  "bloodwork.white_blood_cells.monocytes": {
    sectionTitle: SectionTitle.body,
    friendlyName: "Monocytes",
    decimalPlaces: 1,
  },
  "bloodwork.white_blood_cells.neutrophils": {
    sectionTitle: SectionTitle.body,
    friendlyName: "Neutrophils",
    decimalPlaces: 1,
  },
  "bloodwork.white_blood_cells.eosinophils": {
    sectionTitle: SectionTitle.body,
    friendlyName: "Eosinophils",
    decimalPlaces: 1,
  },
  "bloodwork.hba1c": {
    sectionTitle: SectionTitle.body,
    friendlyName: "HbA1c",
    decimalPlaces: 0,
  },
  "bloodwork.glucose": {
    sectionTitle: SectionTitle.body,
    friendlyName: "Glucose",
    decimalPlaces: 1,
  },
  "bloodwork.white_blood_cells.basophils": {
    sectionTitle: SectionTitle.body,
    friendlyName: "Basophils",
    decimalPlaces: 1,
  },
  "bloodwork.haemoglobin": {
    sectionTitle: SectionTitle.body,
    friendlyName: "Haemoglobin",
    decimalPlaces: 0,
  },
  "risk_assessment.eye_pressure.difference": {
    sectionTitle: SectionTitle.body,
    friendlyName: "Eye pressure difference",
    decimalPlaces: 1,
  },
  "risk_assessment.eye_pressure": {
    sectionTitle: SectionTitle.body,
    friendlyName: "Eye pressure",
    decimalPlaces: 0,
  },
};
