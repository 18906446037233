type Params = {
  // Time in seconds since timer mounted
  accTime: number;

  // Time in seconds since previous callback execution
  deltaTime: number;

  // Time as supplied by RAF but in seconds
  time: number;
};

export function createSimTimer(onStep: (params: Params) => void) {
  let stopped = false;
  let lastTime = NaN;

  const maxStep = 1 / 240;
  const maxDelta = 0.25;

  let accTime = 0;

  function update(ms: number) {
    if (stopped) {
      return;
    }

    const time = ms / 1000;

    if (lastTime) {
      const deltaTime = Math.min(maxDelta, time - lastTime);

      let acc = deltaTime;

      while (acc > 0) {
        const step = Math.min(maxStep, acc);

        accTime += step;

        onStep({
          accTime,
          time,
          deltaTime: step,
        });

        acc -= step;
      }
    }

    lastTime = time;

    requestAnimationFrame(update);
  }

  requestAnimationFrame(update);

  return () => {
    stopped = true;
  };
}

export function createRenderTimer(onRender: (params: Params) => void) {
  let stopped = false;
  let lastTime = NaN;

  let accTime = 0;

  function update(ms: number) {
    if (stopped) {
      return;
    }

    const time = ms / 1000;
    const deltaTime = lastTime ? time - lastTime : 0;

    accTime += deltaTime;

    onRender({
      accTime,
      deltaTime,
      time,
    });

    lastTime = time;

    requestAnimationFrame(update);
  }

  requestAnimationFrame(update);

  return () => {
    stopped = true;
  };
}
