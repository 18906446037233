import { APITypesV1 } from "@cur8/api-client";
import { fromAPI, RoomWithScheduleSlots } from "@cur8/rich-entity";
import { APIClient } from "lib/api/client";
import { DateTime } from "luxon";
import { useCallback } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useStore } from "render/context/StoreContext";
import { useReporting } from "render/hooks/useReporting";
import { useSchedule } from "render/pages/SchedulePage/context/ScheduleContext";

function periodKey(siteId: string, dateFrom: DateTime, dateTo: DateTime) {
  return `${siteId}-${dateFrom.toFormat("YYYYmmdd")}-${dateTo.toFormat(
    "YYYYmmdd"
  )}`;
}

export async function fetchRoomWithScheduleViewSlot(
  api: APIClient,
  siteId: string,
  options: {
    start: DateTime;
    end: DateTime;
    medicalExams?: APITypesV1.MedicalExam[];
  }
) {
  let nextPage: string | undefined;
  let result = new Map<string, RoomWithScheduleSlots>();
  do {
    const response = await api.bookingV2.getScheduleViewForSite(
      {
        siteId,
      },
      {
        medicalExams: options.medicalExams,
        start: options.start.toISO(),
        end: options.end.toISO(),
        continuationToken: nextPage,
      }
    ).result;
    nextPage = response.nextPage;
    response.items.forEach((item) => {
      const room = fromAPI.toRoomWithScheduleViewSlot(item);
      const roomWithSlots = result.get(room.id);
      if (!roomWithSlots) {
        result.set(room.id, room);
        return;
      }
      if (!roomWithSlots.slots) {
        roomWithSlots.slots = [];
      }
      if (room.slots) {
        roomWithSlots.slots.push(...room.slots);
      }
    });
  } while (nextPage != null);

  return [...result.values()];
}

export function useBookingSchedule() {
  const { siteId } = useSchedule();

  const {
    entries: { set, get },
  } = useStore().bookingSchedule;

  const { handleError } = useReporting();

  const api = useAPIClient();

  const fetchSlots = useCallback(
    async (
      dateFrom: DateTime,
      dateTo: DateTime,
      filters?: { medicalExams?: APITypesV1.MedicalExam[] }
    ) => {
      try {
        const result = await fetchRoomWithScheduleViewSlot(api, siteId, {
          start: dateFrom,
          end: dateTo,
          medicalExams: filters?.medicalExams,
        });

        set(periodKey(siteId, dateFrom, dateTo), Array.from(result.values()));

        return result;
      } catch (e) {
        handleError(e);
      }
    },
    [set, api, siteId, handleError]
  );

  const getSlots = useCallback(
    (siteId: string, dateFrom: DateTime, dateTo: DateTime) => {
      const key = periodKey(siteId, dateFrom, dateTo);
      return get(key);
    },
    [get]
  );

  return {
    getSlots,
    fetchSlots,
  };
}
