import { RiskMetric } from "lib/metric";
import { RampUpNumber } from "render/ui/format/RampUpNumber";
import {
  FloatingValueTarget,
  RiskToHighlightMap,
  type FloatingValueTargetProps,
} from "render/ui/presentation/FloatingValueTarget";
import { ValueItem } from "render/ui/symbol/ValueItem";
import { RampUpNumberAnimationConfig } from "../HeartEcgOverlay.animations";
import { RiskLevel } from "@cur8/measurements";
interface EcgSegmentQTCProps {
  active?: boolean;
  align: FloatingValueTargetProps["align"];
  width?: FloatingValueTargetProps["width"];
  qtc: RiskMetric<"cardio.ecg.segments.qtc">[] | undefined;
}

export function EcgSegmentQTC({
  active = true,
  qtc,
  width,
  align,
}: EcgSegmentQTCProps) {
  const latest = qtc?.at(0);
  const risk = latest?.riskRanges.riskLevel;
  const highlight = RiskToHighlightMap[risk ?? RiskLevel.Unknown];

  return (
    <FloatingValueTarget
      label={<>Reset</>}
      value={
        <ValueItem
          value={
            latest && active ? (
              <RampUpNumber
                value={latest.unit.ms}
                precision={0}
                duration={RampUpNumberAnimationConfig.duration}
                ease={RampUpNumberAnimationConfig.ease}
              />
            ) : (
              "--"
            )
          }
          unit="ms"
          size="huge"
          alignItems="baseline"
        />
      }
      align={align}
      highlight={highlight}
      width={width}
    />
  );
}
