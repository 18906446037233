import { fromAPI } from "@cur8/rich-entity";
import { skipToken, useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { ExtractBody, ExtractKey, QueryOptions } from "lib/types/query";
import { useAPIClient } from "render/context/APIContext";

export function useVisitHistoryQuery<T = Body>({
  patientId,
  options = {},
}: {
  patientId: string | undefined;
  options?: QueryOptions<Body, Key, T>;
}) {
  const apiClient = useAPIClient();
  return useQuery({
    queryKey: queryKey(patientId),
    queryFn: patientId != null ? queryFn(apiClient, patientId) : skipToken,
    ...options,
  });
}
function queryFn(apiClient: APIClient, patientId: string) {
  return async function () {
    const visitHistoryDTO = await apiClient.visit.fetchVisitHistory({
      patientId,
    }).result;
    return fromAPI.toVisitHistory(visitHistoryDTO);
  };
}
function queryKey(patientId: string | undefined) {
  return ["visit-history", patientId];
}
type Body = ExtractBody<typeof queryFn>;
type Key = ExtractKey<typeof queryKey>;
