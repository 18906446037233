import { CameraView, DashboardCamera } from "lib/avatar/camera";
import { getCameraDistanceForViewportHeight } from "lib/three";
import { useEffect, useMemo } from "react";
import { Layer } from "render/pages/DashboardPage/types";
import * as THREE from "three";
import { useAvatarContext } from "../../../../../../../../context/AvatarContext";

type Locations = Partial<Record<Layer, CameraView>>;

interface CameraControlProps {
  camera: DashboardCamera;
  layer: Layer;
}

export function CameraControl({ camera, layer }: CameraControlProps) {
  const { smpl } = useAvatarContext();

  const config = useMemo(() => {
    return {
      friction: 35,
      mass: 250,
      stiffness: 50,
      precision: 1,
    };
  }, []);

  const views = useMemo(() => {
    const areas = smpl.front.areas;
    if (!areas) {
      return;
    }

    const eyePos = areas.leftEye.getCenter(new THREE.Vector3());
    const top = areas.top.getCenter(new THREE.Vector3());

    const eyeLevel = eyePos.z;
    const height = top.z;

    const portrait = new CameraView();
    {
      const framingHeight = height * 0.5;
      portrait.focus.z = height - framingHeight * 0.46;
      portrait.pos.z = portrait.focus.z - 0;
      portrait.pos.y = -getCameraDistanceForViewportHeight(
        portrait.fov,
        framingHeight
      );
    }

    const fullbody = new CameraView();
    fullbody.focus.z = height * 0.5 + 30;
    fullbody.pos.z = height * 0.5 + 30;
    fullbody.pos.y = -getCameraDistanceForViewportHeight(
      fullbody.fov,
      eyeLevel * 1.346
    );

    const halfbody = new CameraView();
    {
      const framingHeight = height * 0.44;
      halfbody.focus.z = height - framingHeight * 0.44;
      halfbody.pos.z = halfbody.focus.z - 0;
      halfbody.pos.y = -getCameraDistanceForViewportHeight(
        halfbody.fov,
        framingHeight
      );
    }

    const lesionMacro = new CameraView();
    areas.forehead.getCenter(lesionMacro.focus);
    lesionMacro.focus.z -= 450;
    lesionMacro.focus.x += 175;
    lesionMacro.focus.x += 540;
    lesionMacro.pos.set(0, -2200, 1750);

    const lesion = lesionMacro.clone();
    lesion.focus.x += 2000;

    const skin = new CameraView();
    skin.focus.z = eyeLevel - 500;
    skin.pos.z = eyeLevel - 400;
    skin.pos.y = -getCameraDistanceForViewportHeight(skin.fov, height * 0.75);

    return {
      [Layer.Body]: halfbody,
      [Layer.Cardio]: fullbody,
      [Layer.Circulation]: fullbody,
      [Layer.ArterialHealth]: fullbody,
      [Layer.Cholesterol]: fullbody,
      [Layer.Diabetes]: halfbody,
      [Layer.Identity]: portrait,
      [Layer.Skin]: fullbody,
      [Layer.LesionLibrary]: fullbody,
    } as Locations;
  }, [smpl]);

  useEffect(() => {
    camera.reconfigure(config);
  }, [camera, config]);

  useEffect(() => {
    const view = views?.[layer];
    if (!view) {
      return;
    }

    camera.moveTo(view);
  }, [camera, layer, views]);

  return null;
}
