import { APITypesV1 } from "@cur8/api-client";
import { ImmutableScan } from "@cur8/rich-entity";
import { MEMBER_SUMMARY_RESULT_NAME } from "lib/doctor-scribe/useCreateLLMScan";
import { sleep } from "lib/doctor-scribe/utils";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useVisits } from "render/hooks/api/useVisits";
import { useLatestLLMScan } from "./useLatestLLMScan";
import { ensureOne } from "./utils";

export function useLLMScan(patientId: string | undefined, visitId: string) {
  const isMountedRef = useRef(false);

  const api = useAPIClient();
  const visits = useVisits(patientId);

  const { latestLLMScan, reloadLatestLLMScan } = useLatestLLMScan(
    patientId,
    visitId
  );
  const scanRef = useRef(latestLLMScan);

  const ongoingVisit = useMemo(() => {
    const ongoing =
      visits !== undefined
        ? visits?.filter((v) => {
            return (
              v.status === APITypesV1.VisitStatus.Ongoing ||
              v.status === APITypesV1.VisitStatus.CheckedIn
            );
          })
        : [];
    return ongoing.length > 0 ? ongoing[0] : undefined;
  }, [visits]);

  const completeResult = useCallback(
    (scan: APITypesV1.ImmutableScan, resultName: string) => {
      return api.scan.createScanResult({
        patientId: scan.patientId,
        scanId: scan.id,
        scanVersion: scan.version,
        resultName,
        state: APITypesV1.ResultState.Complete,
      }).result;
    },
    [api]
  );

  const saveAudioRecordingCount = useCallback(
    async (scan: APITypesV1.ImmutableScan, count: number, duration: number) => {
      await api.scan.createScanResult({
        patientId: scan.patientId,
        scanId: scan.id,
        scanVersion: scan.version,
        resultName: "audioRecordingCount",
        state: APITypesV1.ResultState.Complete,
        data: {
          $type: "AudioRecordingCount",
          count: count.toString(),
          duration,
        },
      }).result;
    },
    [api]
  );

  const triggerMemberSummaryGenerationWithoutTranscription = useCallback(
    async (scan: APITypesV1.ImmutableScan) => {
      const p1 = completeResult(scan, "audioFiles");
      const p2 = completeResult(scan, "transcriptionFiles");
      const p3 = completeResult(scan, "transcription");
      const p4 = saveAudioRecordingCount(scan, 0, 0);
      await Promise.all([p1, p2, p3, p4]);
    },
    [completeResult, saveAudioRecordingCount]
  );

  const pollForNewScan = async () => {
    await sleep(1000);
    reloadLatestLLMScan();
    while (
      isMountedRef.current &&
      (!scanRef.current ||
        scanRef.current.resultStateSummary[MEMBER_SUMMARY_RESULT_NAME] !==
          "complete")
    ) {
      await sleep(1000);
      reloadLatestLLMScan();
    }
  };

  const saveReviewResult = useCallback(
    async (
      scan: ImmutableScan,
      internalData: APITypesV1.InternalReviewResult
    ) => {
      await api.scan.createScanResult({
        patientId: scan.patientId,
        scanId: scan.id,
        scanVersion: scan.version,
        resultName: "reviewResult",
        state: APITypesV1.ResultState.Complete,
        internalData,
      }).result;
    },
    [api]
  );

  useEffect(() => {
    scanRef.current = latestLLMScan;
  }, [latestLLMScan]);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const fetchGeneratedContent = useCallback(
    async (scan: ImmutableScan): Promise<null | APITypesV1.MemberSummary> => {
      return api.scan
        .fetchScanResult({
          patientId: scan.patientId,
          scanId: scan.id,
          scanVersion: scan.version,
          resultName: MEMBER_SUMMARY_RESULT_NAME,
        })
        .result.then((result) => {
          const memberSummary = result.data as APITypesV1.MemberSummary;
          if (memberSummary.sections) {
            const sections = {
              introduction: memberSummary.sections.introduction,
              heart: ensureOne(memberSummary.sections.heart),
              body: ensureOne(memberSummary.sections.body),
              skin: ensureOne(memberSummary.sections.skin),
              recommendations: ensureOne(
                memberSummary.sections.recommendations
              ),
            };
            return { ...memberSummary, sections };
          } else {
            console.warn("no sections in MemberSummary");
            return null;
          }
        })
        .catch((e) => {
          console.error("error fetching summary: ", e);
          return null;
        });
    },
    [api]
  );

  const fetchTranscription = useCallback(
    async (
      scan: ImmutableScan
    ): Promise<null | APITypesV1.AudioTranscription> => {
      return api.scan
        .fetchScanResult({
          patientId: scan.patientId,
          scanId: scan.id,
          scanVersion: scan.version,
          resultName: "transcription",
        })
        .result.then((result) => {
          return result.data as APITypesV1.AudioTranscription;
        })
        .catch((e) => {
          console.error("error fetching transcription: ", e);
          return null;
        });
    },
    [api]
  );

  return {
    latestLLMScan,
    reloadLatestLLMScan,
    ongoingVisit,
    saveAudioRecordingCount,
    triggerMemberSummaryGenerationWithoutTranscription,
    pollForNewScan,
    saveReviewResult,
    fetchGeneratedContent,
    fetchTranscription,
  };
}

export type LLMScanType = ReturnType<typeof useLLMScan>;
