import { RiskMetric } from "lib/metric";
import { MetricStatus } from "render/ui/presentation/MetricStatus";
import { MetricStatusBadge } from "render/ui/presentation/MetricStatusBadge";
import DiabetesIcon from "./assets/diabetes.svg?react";
import { RiskLevel } from "@cur8/measurements";

interface DiabetesItemItemProps {
  bloodwork: {
    hba1c: RiskMetric<"bloodwork.hba1c">[] | undefined;
  };
}

export function DiabetesItem({ bloodwork: { hba1c } }: DiabetesItemItemProps) {
  const latestHba1c = hba1c?.at(0);
  const risk = latestHba1c?.riskRanges.riskLevel ?? RiskLevel.Unknown;

  return (
    <MetricStatus
      caption="Diabetes factors"
      summaryContent={risk != null ? <MetricStatusBadge risk={risk} /> : "--"}
    >
      <DiabetesIcon />
    </MetricStatus>
  );
}
