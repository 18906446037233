import { useNav } from "@pomle/react-router-paths";
import { useCallback } from "react";
import BackIcon from "render/assets/nav/back.svg?react";
import { paths } from "render/routes/paths";
import { Typography } from "render/ui/presentation/Typography";
import styles from "./styles.module.sass";

interface AssessmentHeaderProps {
  patientId: string;
}

export function AssessmentHeaderEmpty({ patientId }: AssessmentHeaderProps) {
  const patientNav = useNav(paths.patient.detail);

  /**
   * Name-link router
   * If referrer is AdminUI, go back in history
   *  - else -
   * Go to PatientDetailPage
   */
  const goBack = useCallback(() => {
    const ref = document.referrer;
    if (ref.includes("admin.") && ref.includes("cur8.co")) {
      // Coming from adminUI
      window.history.go(-1);
    } else {
      patientNav.go({ patientId });
    }
  }, [patientId, patientNav]);

  return (
    <header className={styles.AssessmentHeader}>
      <div className={styles.patientCol} onClick={goBack}>
        <BackIcon />
        <Typography variant="title-m">Go back</Typography>
      </div>
    </header>
  );
}
