import { codecs, createQuery } from "@pomle/paths";
import { useNav, useQueryParams } from "@pomle/react-router-paths";
import { Country, getCountryFromVisit } from "lib/visit/visit";
import { useEffect } from "react";
import { PatientName } from "render/fragments/patient/PatientName";
import { usePatient } from "render/hooks/api/usePatient";
import { useQuestionnaire } from "render/hooks/api/useQuestionnaire";
import { useVisitHistoryQuery } from "render/hooks/queries/useVisitHistoryQuery";
import { useVisitQuery } from "render/hooks/queries/useVisitQuery";
import { paths } from "render/routes/paths";
import { FramedPage } from "render/ui/layouts/FramedPage/FramedPage";
import { PageHeader } from "render/ui/layouts/PageHeader";
import { Typography } from "render/ui/presentation/Typography";
import { BackButtonLink } from "render/ui/trigger/BackButtonLink";
import { PatientEncyclopediaComponent } from "./components/PatientEncyclopediaComponent";
import { usePatientEncyclopediaMetrics } from "./components/PatientEncyclopediaComponent/hooks/usePatientEncyclopediaMetrics";
import styles from "./styles.module.sass";

interface PatientEncyclopediaPageProps {
  patientId: string;
}

const params = createQuery({
  country: codecs.oneOf([Country.GB, Country.Sweden, Country.Unknown]),
});

export function PatientEncyclopediaPage({
  patientId,
}: PatientEncyclopediaPageProps) {
  const [uriParams, setURIParams] = useQueryParams(params);
  const country = uriParams.country.at(0);

  const visitHistory = useVisitHistoryQuery({ patientId });

  const latestVisit = useVisitQuery({
    patientId,
    visitId:
      visitHistory.data?.medicalExamVisitTimeSeries.nekoBodyScan?.at(0)
        ?.visitId,
  });

  const patient = usePatient(patientId);
  const patientMetrics = usePatientEncyclopediaMetrics(patientId, country);
  // fetch latest questionnaire not tied to visit
  const questionnaire = useQuestionnaire({ patientId, visitId: undefined });

  const nav = {
    patient: useNav(paths.patient.detail),
  };

  useEffect(() => {
    if (country) {
      return;
    }
    if (!latestVisit.data) {
      return;
    }

    const result = !latestVisit.data
      ? Country.Unknown
      : getCountryFromVisit(latestVisit.data);
    setURIParams({ country: [result] });
  }, [country, latestVisit.data, setURIParams]);

  return (
    <FramedPage>
      <div className={styles.PatientEncyclopediaPage}>
        <BackButtonLink to={nav.patient.to({ patientId })}>
          {patient ? <PatientName patient={patient} /> : "Back"}
        </BackButtonLink>

        <div className={styles.header}>
          <PageHeader caption="Data Table" />

          <div className={styles.source}>
            <div className={styles.sourceLabel}>
              <Typography variant="body-m">Risk source:</Typography>
            </div>

            <select
              value={country}
              onChange={(e) =>
                setURIParams({ country: [e.currentTarget.value as Country] })
              }
            >
              <option value={Country.Sweden}>Sweden</option>
              <option value={Country.GB}>UK</option>
            </select>
          </div>
        </div>

        <PatientEncyclopediaComponent
          country={country}
          metrics={patientMetrics}
          questionnaire={questionnaire.data}
        />
      </div>
    </FramedPage>
  );
}
