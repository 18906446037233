import { RiskLevel, UnitOfMeasurement } from "@cur8/measurements";
import { RiskMetric } from "lib/metric";
import { IFCCtoNGSP, NGSPtoIFCC } from "./hba1c-unit-conversion";

// see: Projections.specs.md#hba1c-projection for more info

/**
 * !IMPORTANT
 * Our HbA1c measurements are in IFCC (International Federation of Clinical Chemistry) units (= mmol/mol) ...
 * ...but the underlying hba1c study data and corresponding hba1c increase per year factor are in NGSP (National Glycohemoglobin Standardization Programme) units (= %)
 * We need to convert between the two units to make the projection work
 * reference: https://www.hba1cnet.com/hba1c-calculator/
 */

export const MAX_PROJECTION_AGE = 100;
export const HBA1C_UNIT_INCREASE_PER_YEAR: UnitOfMeasurement<"%"> = {
  "%": 0.012,
} as const;

export interface HbA1cProjectionParams {
  age: number;
  hba1c: RiskMetric<"bloodwork.hba1c">;
}

export interface HbA1cProjectionResult {
  /**
   * Projected risk
   */
  risk: RiskLevel;
  /**
   * Projected HbA1c value
   */
  hba1c: UnitOfMeasurement<"mmol/mol">;
  /**
   * Projection end age
   */
  age: number;
  /**
   * Projected age and value at which the risk threshold is reached
   */
  riskThreshold: {
    value: UnitOfMeasurement<"mmol/mol">;
    age: number;
  } | null;
}

function project({
  age: startAge,
  hba1c,
}: HbA1cProjectionParams): HbA1cProjectionResult {
  const endAge = Math.min(startAge + 30, MAX_PROJECTION_AGE);
  const projectionLengthInYears = endAge - startAge;

  const currentHba1cValue: UnitOfMeasurement<"mmol/mol" | "%"> = {
    "mmol/mol": hba1c.unit["mmol/mol"],
    "%": IFCCtoNGSP(hba1c.unit)["%"],
  };

  const projectedHba1cInPercentageUnits =
    currentHba1cValue["%"] +
    projectionLengthInYears * HBA1C_UNIT_INCREASE_PER_YEAR["%"];

  const projectedHba1cValue: UnitOfMeasurement<"mmol/mol" | "%"> = {
    "%": projectedHba1cInPercentageUnits,
    "mmol/mol": NGSPtoIFCC({ "%": projectedHba1cInPercentageUnits })[
      "mmol/mol"
    ],
  };

  const lowerBound = hba1c.riskRanges.riskIntervals
    // get riskLevel interval
    .filter((a) => a.riskLevel === RiskLevel.Risk)
    //get the higher one if there are multiple ones
    .toSorted((a, b) => b.start.value - a.start.value)
    .at(0)?.start.value;

  if (lowerBound == null) {
    return {
      risk: RiskLevel.Unknown,
      hba1c: projectedHba1cValue,
      age: endAge,
      riskThreshold: null,
    };
  }

  const highRiskBoundaryValue: UnitOfMeasurement<"mmol/mol"> = {
    "mmol/mol": lowerBound,
  };

  const projectedHba1cRiskRange = hba1c.riskRanges.findIntervalFor({
    "mmol/mol": projectedHba1cValue["mmol/mol"],
  });

  const riskThresholdInMmolUnits = projectedHba1cRiskRange?.start.value;

  // very low hba1c values are also marked as high risk
  // but they are not relevant for the projection
  // so execute an early return and don't calculate age at which risk threshold is reached if the projected risk threshold value is below the high risk threshold
  if (
    !riskThresholdInMmolUnits ||
    riskThresholdInMmolUnits < highRiskBoundaryValue["mmol/mol"]
  ) {
    return {
      risk: projectedHba1cRiskRange?.riskLevel ?? RiskLevel.Unknown,
      hba1c: projectedHba1cValue,
      age: endAge,
      riskThreshold: null,
    };
  }

  const riskThresholdHba1cValue: UnitOfMeasurement<"mmol/mol" | "%"> = {
    "mmol/mol": riskThresholdInMmolUnits,
    "%": IFCCtoNGSP({ "mmol/mol": riskThresholdInMmolUnits })["%"],
  };

  let ageAtWhichRiskThresholdIsReached =
    (riskThresholdHba1cValue["%"] - currentHba1cValue["%"]) /
      HBA1C_UNIT_INCREASE_PER_YEAR["%"] +
    startAge;

  // discard projected risk threshold if the risk threshold age is out of projection bounds
  const isRiskThresholdAgeOutOfRange =
    ageAtWhichRiskThresholdIsReached > endAge ||
    ageAtWhichRiskThresholdIsReached < startAge;

  const riskThreshold: HbA1cProjectionResult["riskThreshold"] =
    isRiskThresholdAgeOutOfRange
      ? null
      : {
          value: riskThresholdHba1cValue,
          age: ageAtWhichRiskThresholdIsReached,
        };

  return {
    risk: projectedHba1cRiskRange.riskLevel,
    hba1c: projectedHba1cValue,
    age: endAge,
    riskThreshold,
  };
}

export const HbA1cProjection = {
  project,
};
