import { APITypesV1 } from "@cur8/api-client";
import { fromAPI, ImmutableScan, Visit } from "@cur8/rich-entity";
import { BULLET_MARKER } from "lib/doctor-scribe/constants";
import { ScribeFlavor } from "lib/doctor-scribe/types";
import { useLLMConsent } from "lib/doctor-scribe/useLLMConsent";
import { useLLMResults } from "lib/doctor-scribe/useLLMResults";
import {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useAPIClient } from "render/context/APIContext";
import { PatientName } from "render/fragments/patient/PatientName";
import { SummaryEditor } from "render/fragments/visit/Editors/SummaryEditor";
import { usePatient } from "render/hooks/api/usePatient";
import { useFormHandle } from "render/hooks/useFormHandle";
import { useReporting } from "render/hooks/useReporting";
import { DayMonthYearDate } from "render/ui/format/DayMonthYearDate";
import { PageHeader } from "render/ui/layouts/PageHeader";
import { BackButtonClick } from "render/ui/trigger/BackButtonClick";
import { SubmitButton } from "render/ui/trigger/SubmitButton";
import { useFeedbackDialog } from "./components/FeedbackModal/useFeedbackDialog";
import { ReviewMessage } from "./components/ReviewMessage";
import { Scribe } from "./components/Scribe";
import { EMPTY_SECTIONS, isEmptySections } from "./components/Scribe/utils";
import { MIN_LENGTH, MIN_REVIEW_TIME } from "./constants";
import styles from "./styles.module.sass";

interface MemberSummaryCreateProps {
  patientId: string;
  visitId: string;
  hide: (event: MouseEvent) => void;
}

function hasContent(v: string | string[]) {
  if (typeof v === "string") {
    return v.length > MIN_LENGTH;
  } else {
    return v.every((item) => item.length > MIN_LENGTH);
  }
}

function stripNewLines(text: string) {
  return text.replace(/[\r\n]/g, "");
}

export function toImmutableVisitSections(
  sections: APITypesV1.SummarySections,
  language: string | null
): APITypesV1.ImmutableVisitSummarySection[] {
  // NB. we rely on the order here when converting in the other direction!
  return [
    {
      title: language === "sv" ? "Översikt" : "Overview",
      id: APITypesV1.SectionId.Introduction,
      body: sections.introduction,
    },
    {
      title: language === "sv" ? "Hjärta & cirkulation" : "Heart & circulation",
      id: APITypesV1.SectionId.Heart,
      body: sections.heart
        .map((l) => BULLET_MARKER + stripNewLines(l))
        .join("\n"),
    },
    {
      title: language === "sv" ? "Kropp" : "Body",
      id: APITypesV1.SectionId.Body,
      body: sections.body
        .map((l) => BULLET_MARKER + stripNewLines(l))
        .join("\n"),
    },
    {
      title: language === "sv" ? "Hud" : "Skin",
      id: APITypesV1.SectionId.Skin,
      body: sections.skin
        .map((l) => BULLET_MARKER + stripNewLines(l))
        .join("\n"),
    },
    {
      title: language === "sv" ? "Rekommendationer" : "Recommendations",
      id: APITypesV1.SectionId.Recommendations,
      body: sections.recommendations
        .map((l) => BULLET_MARKER + stripNewLines(l))
        .join("\n"),
    },
  ];
}

export function MemberSummaryCreate({
  patientId,
  visitId,
  hide,
}: MemberSummaryCreateProps) {
  const api = useAPIClient();
  const patient = usePatient(patientId);

  const [visit, setVisit] = useState<Visit>();
  const [llmScan, setLlmScan] = useState<ImmutableScan>();
  const [sections, setSections] = useState<APITypesV1.SummarySections>();
  const [customSections, setCustomSections] =
    useState<APITypesV1.SummarySections>(EMPTY_SECTIONS);
  const [generatedSections, setGeneratedSections] =
    useState<APITypesV1.SummarySections>(EMPTY_SECTIONS);
  const [originalSections, setOriginalSections] =
    useState<APITypesV1.SummarySections>();
  const [flavor, setFlavor] = useState<ScribeFlavor>(ScribeFlavor.Tuned);
  const [editorKey, setEditorKey] = useState("");
  const [summaryLanguage, setSummaryLanguage] = useState<string | null>();
  const [reviewSeconds, setReviewSeconds] = useState(0);
  const [isSent, setIsSent] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const timerRef = useRef<NodeJS.Timeout>();
  const startTimeRef = useRef<number>(new Date().getTime());
  const { canUseSummaries } = useLLMConsent(patientId);

  const { saveProblemReportResult, saveReviewResult } = useLLMResults();
  const { logError } = useReporting();
  const { emitFeedbackDialog } = useFeedbackDialog();

  const computeReviewData = useCallback(
    (
      generated: APITypesV1.SummarySections,
      saved: APITypesV1.SummarySections
    ) => {
      const toImmutableVisitSectionString = (
        s: APITypesV1.ImmutableVisitSummarySection
      ) => {
        return "# " + s.title + "\n" + s.body + "\n\n";
      };

      const savedSummary = toImmutableVisitSections(
        saved,
        summaryLanguage || null
      )
        .map(toImmutableVisitSectionString)
        .join("\n");

      const textLength = (s: APITypesV1.SummarySections) => {
        const ivs = toImmutableVisitSections(s, summaryLanguage || null);
        return ivs.map((s) => s.body.length).reduce((a, c) => a + c, 0);
      };

      const diffCount = textLength(saved) - textLength(generated);

      return { savedSummary, diffCount };
    },
    [summaryLanguage]
  );

  const handleFeedback = useCallback(
    ({ feedback, isLiked }: { feedback: string; isLiked: boolean }) => {
      if (llmScan && originalSections && sections) {
        const { savedSummary, diffCount } = computeReviewData(
          originalSections,
          sections
        );

        const secondsSinceGenerated = Math.round(
          (new Date().getTime() - startTimeRef.current) / 1000
        );

        saveReviewResult(
          llmScan,
          savedSummary,
          flavor,
          secondsSinceGenerated,
          diffCount,
          isLiked
        );

        if (feedback.length > 0) {
          const category = isLiked ? "positive" : "negative";
          saveProblemReportResult(llmScan, { category, feedback });
        }
      }
    },
    [
      flavor,
      llmScan,
      originalSections,
      saveProblemReportResult,
      saveReviewResult,
      sections,
      computeReviewData,
    ]
  );

  const submit = useCallback(async () => {
    if (visitId && sections) {
      // If no language is available from transcription, use the member's preferred language
      const language =
        (summaryLanguage ? summaryLanguage : patient?.preferredLanguage) ||
        null;

      const immutableVisitSummarySections = toImmutableVisitSections(
        sections,
        summaryLanguage || null
      );

      await api.immutableVisitSummary
        .createSummary(
          { patientId },
          {
            visitId,
            immutableVisitSummarySections,
            sendNotification: true,
            summaryLanguage: language,
          }
        )
        .result.catch(logError);

      if (canUseSummaries) {
        emitFeedbackDialog(handleFeedback);
      }

      setIsSent(true);
    }
  }, [
    api.immutableVisitSummary,
    emitFeedbackDialog,
    handleFeedback,
    patientId,
    logError,
    patient?.preferredLanguage,
    sections,
    summaryLanguage,
    visitId,
    canUseSummaries,
  ]);

  const formHandle = useFormHandle(submit);

  const updateEditorKey = useCallback((flavor: ScribeFlavor) => {
    setEditorKey(flavor + "-" + Date.now().toString());
  }, []);

  const handleOnSummary = useCallback(
    (s: APITypesV1.SummarySections) => {
      if (originalSections === undefined) {
        setOriginalSections(s);
      }

      if (flavor === ScribeFlavor.None) {
        setCustomSections(s);
      } else if (flavor === ScribeFlavor.Tuned) {
        setGeneratedSections(s);
      }

      if (!isEmptySections(s)) {
        // Start timer if something was generated
        startTimeRef.current = new Date().getTime();
        timerRef.current = setInterval(() => {
          const reviewTime = Math.round(
            (new Date().getTime() - startTimeRef.current) / 1000
          );
          setReviewSeconds(reviewTime);
        }, 1000);
      }
      setSections(s);
      updateEditorKey(flavor);
    },
    [flavor, originalSections, updateEditorKey]
  );

  const handleOnFlavor = useCallback(
    (f: ScribeFlavor) => {
      if (!sections) {
        return;
      }
      if (f === ScribeFlavor.None) {
        setGeneratedSections(sections);
        setSections(customSections);
      } else if (f === ScribeFlavor.Tuned) {
        setCustomSections(sections);
        setSections(generatedSections);
      }
      updateEditorKey(f);
      setFlavor(f);
    },
    [sections, customSections, generatedSections, updateEditorKey]
  );

  const remainingReviewTime = useMemo(() => {
    return timerRef.current ? Math.max(MIN_REVIEW_TIME - reviewSeconds, 0) : 0;
  }, [reviewSeconds]);

  const isEdited = useMemo(() => {
    return sections ? Object.values(sections).every(hasContent) : false;
  }, [sections]);

  const isValid = !isSent && remainingReviewTime === 0 && isEdited;

  useEffect(() => {
    api.visit
      .fetchVisit({ patientId, visitId })
      .result.then((v) => setVisit(fromAPI.toVisit(v)))
      .catch(logError);
  }, [api.visit, logError, patientId, visitId]);

  useEffect(() => {
    if (timerRef.current && remainingReviewTime === 0) {
      clearInterval(timerRef.current);
      timerRef.current = undefined;
    }
  }, [remainingReviewTime]);

  return (
    <div className={styles.MemberSummaryCreate}>
      <div className={styles.container} data-drawervisible={drawerVisible}>
        <BackButtonClick onClick={hide}>
          {patient ? <PatientName patient={patient} /> : "Back"}
        </BackButtonClick>
        <div className={styles.title}>
          <PageHeader caption="Visit Summary" />
          {visit?.startTime && <DayMonthYearDate date={visit.startTime} />}
        </div>
        <Scribe
          patientId={patientId}
          visitId={visitId}
          showDetailsOnly={false}
          flavor={flavor}
          currentEditorSections={sections}
          onFlavor={handleOnFlavor}
          onSummary={handleOnSummary}
          onSummaryLanguage={setSummaryLanguage}
          onScan={setLlmScan}
          onDrawer={setDrawerVisible}
        />
        <div className={styles.content}>
          <form onSubmit={formHandle.onSubmit}>
            <SummaryEditor
              patientId={patientId}
              visitId={visitId}
              sections={sections}
              onChange={setSections}
              editorKey={editorKey}
            />
            <div className={styles.submit}>
              <SubmitButton handle={formHandle} disabled={!isValid}>
                Send summary
              </SubmitButton>
              <ReviewMessage
                isSent={isSent}
                remainingReviewTime={remainingReviewTime}
                isInvalid={!isValid}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
