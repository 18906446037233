import { getHighestRisk, RiskLevel } from "@cur8/measurements";
import { RiskMetric } from "lib/metric";
import { useMemo } from "react";
import {
  FloatingValueTarget,
  RiskToHighlightMap,
} from "render/ui/presentation/FloatingValueTarget";
import { ValueItem } from "render/ui/symbol/ValueItem";
import { BloodPressure } from "../components/BloodPressure";

interface RightArmBloodpressureProps {
  brachialPressure: RiskMetric<"cardio.brachial_pressure.right">[];
}

export function RightArmBloodpressure({
  brachialPressure,
}: RightArmBloodpressureProps) {
  const brachial = brachialPressure.at(0);

  const risk = useMemo(() => {
    if (!brachial) {
      return RiskLevel.Unknown;
    }
    const { diastolic, systolic } = brachial.riskRanges;
    return getHighestRisk([diastolic.riskLevel, systolic.riskLevel]);
  }, [brachial]);

  const highlight = RiskToHighlightMap[risk];

  return (
    <FloatingValueTarget
      label="Right arm"
      value={
        <ValueItem
          value={brachial ? <BloodPressure brachial={brachial.unit} /> : "--"}
          alignItems="baseline"
        />
      }
      highlight={highlight}
      width={180}
      align="top-left"
    />
  );
}
