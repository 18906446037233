import { Patient, Sex } from "@cur8/rich-entity";
import { patientAge } from "lib/datetime";
import {
  ResultRecord,
  OnboardingData,
  RefinedRecord,
} from "lib/doctor-scribe/types";
import { DateTime } from "luxon";

export function refineRecord(
  record: ResultRecord,
  patient: Patient,
  onboardingQuestionnaire: OnboardingData | undefined
): RefinedRecord {
  const age = patientAge(patient, DateTime.now());
  return {
    age,
    sex: Sex[patient.sex],
    allergies: patient.allergies,
    onboardingQuestionnaire,
    ...record,
  };
}
