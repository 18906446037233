const hostnameMap: Record<string, string> = {
  localhost: "admin.dev.cur8.co",
  "dev.cur8.co": "admin.dev.cur8.co",
  "preprod.cur8.co": "admin.preprod.cur8.co",
  "cur8.co": "admin.cur8.co",
};

function extractMainDomain(hostname: string): string {
  const parts = hostname.split(".");
  // Match PR-domains to dev
  return parts.length > 2 ? parts.slice(-3).join(".") : hostname;
}

function getAssessmentUrl(path: string) {
  const mainDomain = extractMainDomain(window.location.hostname);
  // Get mapped hostname for Admin UI or default to PROD.
  const mappedHostname = hostnameMap[mainDomain] || hostnameMap["cur8.co"];
  return `https://${mappedHostname}/${path}`;
}

export function getEcgAssessmentAdminUrl(
  patientId: string,
  assessmentId: string
) {
  const path = `patient/${patientId}/assessments/ecg/doc-ui/${assessmentId}`;
  return getAssessmentUrl(path);
}
