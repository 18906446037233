import { BrachialPressure } from "render/ui/format/BrachialPressure";
import { Table } from "render/ui/layouts/Table";
import { PatientEncyclopediaMetrics } from "../../hooks/usePatientEncyclopediaMetrics";
import { ResultRow } from "../ResultRow";
import { getRiskWeight } from "@cur8/measurements";
import { Country } from "lib/visit/visit";

interface CardioTableProps {
  metrics: PatientEncyclopediaMetrics;
  country: Country | undefined;
}

export function CardioTable({ metrics, country }: CardioTableProps) {
  const cardio = metrics.cardio;
  return (
    <Table>
      <thead>
        <tr>
          <th>Cardio</th>
          <th>Result</th>
          <th>
            Risk {country != null ? <>(based on {country})</> : undefined}
          </th>
          <th>Units</th>
        </tr>
      </thead>
      <tbody>
        <ResultRow
          caption="Brachial Pressure (L)"
          unit="mmHg"
          value={cardio?.brachialPressure?.left?.at(0)}
          format={(val) => <BrachialPressure brachial={val.unit} />}
          summarize={(val) => {
            const { diastolic, systolic } = val.riskRanges;
            const risk = [diastolic.riskLevel, systolic.riskLevel].sort(
              (a, b) => getRiskWeight(b) - getRiskWeight(a)
            )[0];
            return {
              risk,
            };
          }}
        />

        <ResultRow
          caption="Brachial Pressure (R)"
          unit="mmHg"
          value={cardio?.brachialPressure?.right?.at(0)}
          format={(val) => <BrachialPressure brachial={val.unit} />}
          summarize={(val) => {
            const { diastolic, systolic } = val.riskRanges;
            const risk = [diastolic.riskLevel, systolic.riskLevel].sort(
              (a, b) => getRiskWeight(b) - getRiskWeight(a)
            )[0];
            return {
              risk,
            };
          }}
        />

        <ResultRow
          caption="Ankle Pressure (L)"
          unit="mmHg"
          value={cardio?.anklePressure?.left?.at(0)}
          format={(val) => val.unit.mmHg.toFixed(0)}
          summarize={(val) => {
            return {
              risk: val.riskRanges.riskLevel,
            };
          }}
        />
        <ResultRow
          caption="Ankle Pressure (R)"
          unit="mmHg"
          value={cardio?.anklePressure?.right?.at(0)}
          format={(val) => val.unit.mmHg.toFixed(0)}
          summarize={(val) => {
            return {
              risk: val.riskRanges.riskLevel,
            };
          }}
        />

        <ResultRow
          caption="ABI (L)"
          value={cardio.abi.left?.at(0)}
          format={(metric) => metric.unit.ratio.toFixed(2)}
          summarize={(metric) => {
            return {
              risk: metric.riskRanges.riskLevel,
            };
          }}
        />
        <ResultRow
          caption="ABI (R)"
          value={cardio.abi?.right?.at(0)}
          format={(metric) => metric.unit.ratio.toFixed(2)}
          summarize={(metric) => {
            return {
              risk: metric.riskRanges.riskLevel,
            };
          }}
        />

        <ResultRow
          caption="Saturation"
          unit="%"
          value={cardio.bloodOxygen?.at(0)}
          format={(metric) => metric.unit.percent.toFixed(1)}
          summarize={(metric) => {
            return {
              risk: metric.riskRanges.riskLevel,
            };
          }}
        />
        <ResultRow
          caption="Heart rate"
          unit="bpm"
          value={cardio.heartRate?.at(0)}
          format={(metric) => metric.unit.bpm.toFixed(0)}
          summarize={(metric) => {
            return {
              risk: metric.riskRanges.riskLevel,
            };
          }}
        />
      </tbody>
    </Table>
  );
}
