import { ImmutableScan } from "@cur8/rich-entity";
import { createFakeSMPLPositions, findSMPLAreas } from "lib/smpl";
import { useEffect, useMemo, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import {
  Pose,
  useSMPLPointCloud,
} from "render/hooks/api/meshuga/useSMPLPointCloud";
import { GLTF, GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";

function parseGLTF(buffer: ArrayBuffer) {
  const loader = new GLTFLoader();

  return loader.parseAsync(buffer, "");
}

function useAvatarMesh(skinScan?: ImmutableScan) {
  const api = useAPIClient();

  const [result, setResult] = useState<AsyncResult<GLTF>>();

  useEffect(() => {
    if (!skinScan) {
      return;
    }

    const request = api.scan.fetchScanBlob(
      {
        patientId: skinScan.patientId,
        scanId: skinScan.id,
        scanVersion: skinScan.version,
        resultName: "smplx_animation",
        path: "animations.glb",
      },
      (response) => response.arrayBuffer()
    );

    request.result
      .then(parseGLTF)
      .then((gltf) => {
        setResult({ data: gltf });
      })
      .catch((error) => {
        setResult({ error });
      });

    return () => {
      request.abandon();
      setResult(undefined);
    };
  }, [api, skinScan]);

  return result;
}

function useSMPL(pose: Pose, skinScan?: ImmutableScan | Error) {
  const pointsResult = useSMPLPointCloud(pose, skinScan);

  const areas = useMemo(() => {
    if (!pointsResult) {
      return;
    }

    if (pointsResult.error) {
      return createFakeSMPLPositions();
    }

    return findSMPLAreas(pointsResult.data);
  }, [pointsResult]);

  return {
    points: pointsResult?.data,
    areas,
  };
}

export type SkinScanSpatial = ReturnType<typeof useAvatar>;

export function useAvatar(skinScan?: ImmutableScan) {
  return {
    mesh: useAvatarMesh(skinScan),

    smpl: {
      front: useSMPL("front", skinScan),
      back: useSMPL("back", skinScan),
      apose: useSMPL("apose", skinScan),
    },
  };
}
