import { useMemo } from "react";
import styles from "./RollingNumber.module.sass";

const DIGIT_POOL = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
const DIGIT_ROTATION_STEP = { degrees: 360 / DIGIT_POOL.length };

type DigitPlace = 1 | 10 | 100 | 1000;

function getDigitPlace(numOfDigits: number, position: number): DigitPlace {
  const digitIndex = numOfDigits - 1 - position;
  return Math.pow(10, digitIndex) as DigitPlace;
}

export interface RollingNumberProps {
  value: number;
  formatValue?: (value: number) => string;
  debug?: boolean;
  animationDuration?: number;
  animationEase?: `cubic-bezier(${number}, ${number}, ${number}, ${number})`;
}

export function RollingNumber({
  debug = false,
  value,
  formatValue,
  animationDuration,
  animationEase,
}: RollingNumberProps) {
  const digits = useMemo(() => {
    const defaultFormatValue = (value: number) => Math.abs(value).toString();

    const formattedValue = formatValue
      ? formatValue(value)
      : defaultFormatValue(value);

    return formattedValue.split("");
  }, [value, formatValue]);

  return (
    <div
      className={styles.RollingNumber}
      data-debug={debug}
      style={{
        "--animation-duration": `${animationDuration}s`,
        "--animation-ease": animationEase,
      }}
    >
      <div
        className={styles.digits}
        data-num-of-digits={digits.length}
        aria-hidden="true"
      >
        {digits.map((_, index) => (
          <Digit
            key={getDigitPlace(digits.length, index)}
            place={getDigitPlace(digits.length, index)}
            totalValue={value}
          />
        ))}
      </div>
      <div className={styles.visuallyHidden}>{value}</div>
    </div>
  );
}

interface DigitProps {
  place: DigitPlace;
  totalValue: number;
}

function Digit({ totalValue, place = 1 }: DigitProps) {
  const rotation = useMemo(() => {
    return (
      Math.floor(totalValue / place) *
      DIGIT_ROTATION_STEP.degrees *
      -1 *
      Math.sign(totalValue)
    );
  }, [totalValue, place]);

  return (
    <div className={styles.digit} style={{ "--rotation": `${rotation}deg` }}>
      {DIGIT_POOL.map((digit) => (
        <div key={digit} className={styles.number} style={{ "--index": digit }}>
          {digit}
        </div>
      ))}
    </div>
  );
}
