import { getHighestRisk, RiskLevel } from "@cur8/measurements";
import { RiskMetric } from "lib/metric";
import { useMemo } from "react";
import {
  FloatingValueTarget,
  RiskToHighlightMap,
} from "render/ui/presentation/FloatingValueTarget";
import { ValueItem } from "render/ui/symbol/ValueItem";
import { BloodPressure } from "../components/BloodPressure";

interface LeftArmBloodPressureProps {
  brachialPressure: RiskMetric<"cardio.brachial_pressure.left">[];
}

export function LeftArmBloodPressure({
  brachialPressure,
}: LeftArmBloodPressureProps) {
  const brachial = brachialPressure.at(0);

  const risk = useMemo(() => {
    if (!brachial) {
      return RiskLevel.Unknown;
    }

    const { diastolic, systolic } = brachial.riskRanges;
    return getHighestRisk([diastolic.riskLevel, systolic.riskLevel]);
  }, [brachial]);

  const highlight = RiskToHighlightMap[risk ?? RiskLevel.Unknown];

  return (
    <FloatingValueTarget
      label="Left arm"
      value={
        <ValueItem
          value={brachial ? <BloodPressure brachial={brachial.unit} /> : "--"}
        />
      }
      highlight={highlight}
      align="top-right"
      width={170}
    />
  );
}
