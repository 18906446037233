import { AvatarPodium } from "lib/avatar/podium";
import { createSimTimer } from "lib/timer";
import { useEffect, useMemo } from "react";

export function usePodium() {
  const podium = useMemo(() => {
    return new AvatarPodium();
  }, []);

  useEffect(() => {
    return createSimTimer(({ deltaTime }) => {
      podium.update(deltaTime);
    });
  }, [podium]);

  return podium;
}
