import {
  RadioGroupProps,
  Radio as ReactAriaRadio,
  RadioGroup as ReactAriaRadioGroup,
} from "react-aria-components";
import styles from "./styles.module.sass";
import { Typography } from "render/ui/presentation/Typography";

type RadioGroupPropsType = {
  children: React.ReactNode;
} & RadioGroupProps;

export function RadioGroup({ children, ...props }: RadioGroupPropsType) {
  return (
    <ReactAriaRadioGroup {...props} className={styles.RadioGroup}>
      {children}
    </ReactAriaRadioGroup>
  );
}

RadioGroup.Radio = Radio;

function Radio({ value, label }: { value: string; label: React.ReactNode }) {
  return (
    <ReactAriaRadio value={value} className={styles.Radio}>
      <div className={styles.checkboxIcon} />
      <div className={styles.textWrapper}>
        <div className={styles.textRowWrapper}>
          <Typography variant="label-m">{label}</Typography>
        </div>
      </div>
    </ReactAriaRadio>
  );
}
