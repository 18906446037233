import { mapLinear } from "lib/math";
import { useMemo } from "react";
import { useDelay } from "render/hooks/useDelay";
import { Marker } from "render/ui/presentation/ChartMarker";
import { Highlight } from "render/ui/presentation/ChartMarker/Marker";
import { Typography } from "render/ui/presentation/Typography";
import type { HeartAgeSectionState } from "../../HeartAgeSection";
import styles from "./styles.module.sass";

const DIAL_ROTATION: Bounds = { min: -130, max: 130 };

interface Bounds {
  min: number;
  max: number;
}
export interface HeartAgeGraphProps {
  age: number;
  heartAge: number | undefined;
  bounds: Bounds;
  state?: HeartAgeSectionState;
  debug?: boolean;
  dialAnimation: { duration: number; delay: number };
  ageDiffTextReveal: { duration: number; delay: number };
}

export function HeartAgeGraph({
  age,
  heartAge,
  state = "idle",
  debug = false,
  bounds,
  dialAnimation,
  ageDiffTextReveal,
}: HeartAgeGraphProps) {
  const ageDiff = useMemo(() => {
    if (heartAge == null) {
      return undefined;
    } else {
      return heartAge - age;
    }
  }, [age, heartAge]);

  const highlight: Highlight = useMemo(() => {
    if (ageDiff == null) {
      return "none";
    }

    return ageDiff > 0 ? "warning" : "normal";
  }, [ageDiff]);

  const ageDiffLabels: {
    age: string;
    years: string;
  } = useMemo(() => {
    if (ageDiff == null || state === "locked") {
      return {
        age: "",
        years: "N/A",
      };
    }

    if (ageDiff === 0) {
      return {
        age: "",
        years: "Aligned",
      };
    }

    return {
      age: toSignedString(ageDiff),
      years: Math.abs(ageDiff) === 1 ? "year" : "years",
    };
  }, [state, ageDiff]);

  const dialRotation = useMemo(() => {
    if (ageDiff == null) {
      return 0;
    }

    return mapLinear(
      ageDiff,
      bounds.min,
      bounds.max,
      DIAL_ROTATION.min,
      DIAL_ROTATION.max,
      { clamp: true }
    );
  }, [ageDiff, bounds.min, bounds.max]);

  const highlightMarker = useDelay({
    active: state === "active",
    delay: dialAnimation.delay * 1000,
  });

  return (
    <div
      className={styles.HeartAgeGraph}
      data-state={state}
      data-age-diff={ageDiff}
      data-highlight={highlight}
      style={{
        "--dial-animation-duration": `${dialAnimation.duration}s`,
        "--dial-animation-delay": `${dialAnimation.delay}s`,
        "--dial-rotation": `${dialRotation}deg`,
        "--age-diff-text-reveal-duration": `${ageDiffTextReveal.duration}s`,
        "--age-diff-text-reveal-delay": `${ageDiffTextReveal.delay}s`,
      }}
    >
      <div className={styles.graph}>
        <div className={styles.dial}>
          <div className={styles.marker}>
            <Marker
              highlight={highlightMarker ? highlight : "none"}
              variant="primary"
            />
          </div>
        </div>

        <svg
          viewBox="0 0 334 334"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            data-role="arc"
            data-side="left"
            d="M76.9988 243.999C37.2909 197.532 39.4148 127.585 83.3704 83.6296C107.175 59.8249 138.603 48.2891 169.795 49.022"
            stroke="#C5D9DD"
            strokeWidth="6"
            strokeLinecap="round"
          />
          <path
            data-role="arc"
            data-side="right"
            d="M257.001 244C296.709 197.532 294.585 127.586 250.629 83.6304C228.045 61.0455 198.598 49.5043 169 49.0068"
            stroke="#FFCB7C"
            strokeWidth="6"
            strokeLinecap="round"
          />
          <rect
            data-role="needle-mask"
            x="165.315"
            y="38.6602"
            width="8"
            height="20"
            fill="#F0F0F0"
          />
          <rect
            data-role="needle"
            x="168.315"
            y="38.6602"
            width="2"
            height="20"
          />

          {debug && (
            <>
              <rect
                x={0}
                y={0}
                width={334 * Math.sqrt(2) * 0.5}
                height={334 * Math.sqrt(2) * 0.5}
                stroke="red"
                transform={`translate(${334 * 0.5}, 0) rotate(45)`}
              />
              <circle
                cx={334 * 0.5}
                cy={334 * 0.5}
                r={334 * 0.3535}
                fill="none"
                stroke="red"
                strokeWidth="4"
              />
            </>
          )}
        </svg>
      </div>

      <div className={styles.ui}>
        <div className={styles.age}>
          <div className={styles.placeholder}>
            <Typography variant="numeral-m">0</Typography>
            <div className={styles.ageLabel}>
              <Typography variant="label-m">years</Typography>
            </div>
          </div>
          <div className={styles.result}>
            <Typography variant="numeral-m">{ageDiffLabels.age}</Typography>
            <div className={styles.ageLabel}>
              <Typography variant="label-m">{ageDiffLabels.years}</Typography>
            </div>
          </div>
        </div>
        <div className={styles.labels}>
          <span className={styles.label} data-side="left">
            Younger
          </span>
          <span className={styles.label} data-side="right">
            Older
          </span>
        </div>
      </div>
    </div>
  );
}

function toSignedString(num: number) {
  return (num >= 0 ? "+" : "") + num.toString();
}
