import * as THREE from "three";
import fragmentShader from "./shaders/dot-grid.fragment.glsl?raw";
import vertexShader from "./shaders/dot-grid.vertex.glsl?raw";

type AvatarDotGridMaterialParams = {
  camera: THREE.OrthographicCamera;
  pointMap: THREE.Texture;
  normalMap: THREE.Texture;
  depthMap: THREE.Texture;
  bodyPartsMap: THREE.Texture;
  bodyColorMap: THREE.Texture;
};

export class AvatarDotGridMaterial extends THREE.RawShaderMaterial {
  color = new THREE.Color("#008BB7").convertLinearToSRGB();

  digitalEffect = {
    progression: 0,
  };

  debug = {
    showLeftArm: 0,
    showRightArm: 0,
  };

  revealEffect = {
    normalizedRevealTime: 0,
    bodyProgression: 0,
    leftArmProgression: 0,
    rightArmProgression: 0,
    noise1multiplier: 40,
    noise2multiplier: 40,
    bodyCenterX: 1,
    bodyCenterY: 1,
    bodyCenterZ: 1,
    bodyEdgeFalloff: 0.07,
    armsEdgeFalloff: 0.21,
  };

  scanEffect = {
    color: new THREE.Color("#fff").convertSRGBToLinear(),
    length: 1,
    intensity: 0,
    position: new THREE.Vector3(0, 0, 0),
  };

  bodyEffect = {
    colorGradient1: new THREE.Color().setHex(0x0277a6).convertLinearToSRGB(),
    colorGradient2: new THREE.Color().setHex(0x019dc9).convertLinearToSRGB(),
    colorGradient3: new THREE.Color().setHex(0x86d4e9).convertLinearToSRGB(),
    globalParticleSize: 1,
    depthLimit: 0.0,
    depthLimitFalloff: 0.2,
    depthFactor: 0,
    light1SizeFactor: 0,
    light2SizeFactor: 0,
    fillLightIntensity: 0,
    normalSizeFactor: 0,
    normalFresnelIntensity: 0,
    normalFresnelPower: 0,
    bodyNoise: 0,
    bodyNoiseTimescale: 0,
    bodyNoiseCalmTorso: 0,
    bodyNoiseScale: 0.01,
  };

  constructor({
    camera,
    pointMap,
    normalMap,
    depthMap,
    bodyPartsMap,
    bodyColorMap,
  }: AvatarDotGridMaterialParams) {
    super({
      vertexShader,
      fragmentShader,
      alphaHash: true,
      alphaTest: 0,
      alphaToCoverage: false,
      clipping: false,
      depthTest: true,
      depthWrite: false,
      fog: false,
      forceSinglePass: true,
      lights: false,
      side: THREE.FrontSide,
      transparent: true,
      visible: true,
    });

    Object.assign(this.uniforms, {
      tDepthMap: { value: depthMap },
      tNormalMap: { value: normalMap },
      tBodyColorMap: { value: bodyColorMap },
      tBodyPartsMap: { value: bodyPartsMap },
      tPointTexture: { value: pointMap },
      uColor: { value: this.color },
      uDigitalEffect: { value: this.digitalEffect },
      uDebug: { value: this.debug },
      uBodyEffect: { value: this.bodyEffect },
      uFrustum: { value: camera },
      uOpacity: { value: this.opacity },
      uPointSize: { value: 32 },
      uRevealEffect: { value: this.revealEffect },
      uScale: { value: 1 },
      uScanEffect: { value: this.scanEffect },
    });
  }

  onBeforeRender() {
    this.uniforms.uOpacity.value = this.opacity;
  }

  set scale(scale: number) {
    this.uniforms.uScale.value = scale;
  }

  set pointSize(size: number) {
    this.uniforms.uPointSize.value = size;
  }
}
