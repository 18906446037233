import { RiskMetric } from "lib/metric";
import { useMemo } from "react";
import {
  AuxTitle,
  Diff,
  DiffValue,
  MainTitle,
  MetricResultHeader,
  Titles,
} from "render/ui/presentation/MetricResultHeader";
import {
  RangeChart,
  RangeChartDataComposer,
} from "render/ui/presentation/RangeChart";
import {
  selectChartRanges,
  toChartRanges,
} from "render/ui/presentation/RangeChart/lib/risk";

interface ABIResultItemProps {
  currentMetric: {
    left: RiskMetric<"cardio.abi.left">;
    right: RiskMetric<"cardio.abi.right">;
  };
  previousMetric?: {
    left: RiskMetric<"cardio.abi.left">;
    right: RiskMetric<"cardio.abi.right">;
  };
}

interface Abi {
  left: RiskMetric<"cardio.abi.left">;
  right: RiskMetric<"cardio.abi.right">;
}

function getHighestAbi(abi: Abi) {
  const { left, right } = abi;

  return left.unit.ratio > right.unit.ratio ? left : right;
}

export function ABIResultItem({
  currentMetric,
  previousMetric,
}: ABIResultItemProps) {
  const currentHighestAbi = getHighestAbi(currentMetric);
  const previousHighestAbi = previousMetric
    ? getHighestAbi(previousMetric)
    : undefined;

  const riskRanges = useMemo(() => {
    const intervals = currentHighestAbi.riskRanges.riskIntervals;
    const chartRanges = toChartRanges(intervals);

    return selectChartRanges(chartRanges, {
      mode: "dynamic",
      currentValue: currentHighestAbi.unit.ratio,
    });
  }, [currentHighestAbi]);

  // https://dev.azure.com/cur8/Engineering/_workitems/edit/26137
  const riskRangesCapLow = 0.5;
  const riskRangesCapHigh = 1.6;

  const currentValue = currentHighestAbi.unit.ratio;
  const previousValue = previousHighestAbi?.unit.ratio;

  return (
    <div>
      <MetricResultHeader>
        <Titles>
          <MainTitle>Ankle brachial index</MainTitle>
          <AuxTitle>[ABI]</AuxTitle>
        </Titles>
        <Diff>
          <DiffValue
            valuesDiff={previousValue && currentValue - previousValue}
            formatValue={(n) => n?.toFixed(1)}
          />
        </Diff>
      </MetricResultHeader>
      <RangeChartDataComposer
        ranges={riskRanges}
        rangesCapLow={riskRangesCapLow}
        rangesCapHigh={riskRangesCapHigh}
        value={currentValue}
        previousValue={previousValue}
      >
        {({ ranges, values }) => (
          <RangeChart ranges={ranges} values={values} showEmpty />
        )}
      </RangeChartDataComposer>
    </div>
  );
}
