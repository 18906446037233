import { RiskMetric } from "lib/metric";
import { useMemo } from "react";
import { MetricRange } from "render/ui/presentation/MetricRange";
import { toChartRanges } from "render/ui/presentation/RangeChart/lib/risk";

interface TotalCholesterolRangeProps {
  metrics: RiskMetric<"bloodwork.cholesterol">[] | undefined;
}

export function TotalCholesterolRange({ metrics }: TotalCholesterolRangeProps) {
  const latest = metrics?.at(0);

  const ranges = useMemo(() => {
    const intervals = latest?.riskRanges.riskIntervals ?? [];
    const result = toChartRanges(intervals);

    return result;
  }, [latest]);

  // https://dev.azure.com/cur8/Engineering/_workitems/edit/26137
  const rangesCapLow = 3.5;
  const rangesCapHigh = 9.5;

  const entries = useMemo(() => {
    if (!metrics) {
      return [];
    }

    return metrics.map((metric) => {
      return {
        risk: metric.riskRanges.riskLevel,
        timestamp: metric.measurement.timestampStart,
        value: metric.unit["mmol/L"],
      };
    });
  }, [metrics]);

  return (
    <MetricRange
      title="Total cholesterol"
      unit="mmol/L"
      entries={entries}
      ranges={ranges}
      rangesCapLow={rangesCapLow}
      rangesCapHigh={rangesCapHigh}
      formatValue={(value) => value?.toFixed(2)}
      showEmptyRanges
    />
  );
}
