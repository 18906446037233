import { APITypesV2 } from "@cur8/api-client";
import { fetchLatestOnboardingQuestionnaireResponse } from "lib/questionnaire/api";
import { useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useReporting } from "render/hooks/useReporting";

export type Questionnaire = APITypesV2.QuestionnaireResponse;

export function useQuestionnaire({
  patientId,
  visitId,
}: {
  patientId: string;
  visitId: string | undefined;
}) {
  const api = useAPIClient();
  const { handleError } = useReporting();

  const [isLoaded, setIsLoaded] = useState(false);
  const [result, setResult] = useState<Partial<AsyncResult<Questionnaire>>>({});

  useEffect(() => {
    const request = fetchLatestOnboardingQuestionnaireResponse(
      api,
      patientId,
      visitId
    );

    request.result
      .then((q) => setResult({ data: q }))
      .catch(handleError)
      .finally(() => setIsLoaded(true));

    return () => {
      request.abandon();
      setResult({});
    };
  }, [api, handleError, patientId, visitId]);

  return { ...result, isLoaded };
}
