import { useNav } from "@pomle/react-router-paths";
import { PropsWithChildren, ReactNode, useCallback, useMemo } from "react";
import { useSelectedPatient } from "render/pages/SchedulePage/hooks/useSelectedPatient";
import { CalendarEvent } from "render/pages/SchedulePage/reducer/slotManagementUtils";
import { paths } from "render/routes/paths";
import { useCalendarInteraction } from "../../hooks/useCalendarInteraction";
import { ViewRangeMode, useCalendarState } from "../../hooks/useCalendarState";
import { useSlotManagementPopup } from "../../hooks/useSlotManagementPopup";
import { BookedSlot } from "./BookedSlot";
import { ClosedSlot } from "./ClosedSlot";
import { OpenSlot } from "./OpenSlot";
import { ReservedSlot } from "./ReservedSlot";
import styles from "./styles.module.sass";
import { SlotLengthVariant } from "./constants";

interface Props {
  isCensored: boolean;
  slot: CalendarEvent;
  selected?: boolean;
  viewRange: ViewRangeMode;
}

interface ConditionalWrapperProps {
  condition: boolean;
  wrapIfTrue: (children: ReactNode) => ReactNode;
  wrapIfFalse: (children: ReactNode) => ReactNode;
}
function ConditionalWrapper({
  condition,
  wrapIfTrue,
  wrapIfFalse,
  children,
}: PropsWithChildren<ConditionalWrapperProps>) {
  return <>{condition ? wrapIfTrue(children) : wrapIfFalse(children)}</>;
}

function WrappedSlot({ slot, isCensored, viewRange }: Props) {
  if (!slot.slot) {
    return <ClosedSlot timeWindow={slot.interval} />;
  }

  if (slot.slot.isBooked) {
    return (
      <BookedSlot
        slot={slot.slot}
        isAppointmentsCensored={isCensored}
        viewRange={viewRange}
      />
    );
  }
  if (slot.slot.isReserved) {
    return (
      <ReservedSlot
        slot={slot.slot}
        isAppointmentsCensored={isCensored}
        viewRange={viewRange}
      />
    );
  }
  if (!slot.slot.isBooked) {
    return (
      <OpenSlot
        slot={slot.slot}
        isAppointmentsCensored={isCensored}
        viewRange={viewRange}
      />
    );
  }

  return null;
}

export function Timeslot({ slot, isCensored, viewRange }: Props) {
  const { emitDialog } = useSlotManagementPopup();
  const { calendarInteractionMode, bulkSelectedSlots } = useCalendarState();
  const { removeSlotFromBulkSelection, addSlotToBulkSelection } =
    useCalendarInteraction();

  const selectedPatient = useSelectedPatient();
  const navPatientDetails = useNav(paths.patient.appointment.view);

  const isPatientSelected = useMemo(() => !!selectedPatient, [selectedPatient]);
  const isSlotSelected = useMemo(() => {
    return bulkSelectedSlots.some((event) => {
      if (slot.slot && event.slot) {
        return event.slot.id === slot.slot.id;
      }

      return event.interval.start.equals(slot.interval.start);
    });
  }, [slot, bulkSelectedSlots]);

  const handleClick = useCallback(() => {
    switch (calendarInteractionMode) {
      case "editing":
        if (isSlotSelected) {
          removeSlotFromBulkSelection(slot);
          break;
        }

        addSlotToBulkSelection(slot);
        break;
      case "booking":
        if (!isPatientSelected) {
          console.warn("unable to book slot - member not selected");
          break;
        }

        if (!slot.slot) {
          console.warn("unable to book slot - no slot selected");
          break;
        }

        return emitDialog(selectedPatient!, slot.slot);

      case "viewing":
        if (slot.slot?.id && slot?.slot.patientId) {
          return navPatientDetails.go({
            patientId: slot.slot.patientId,
            bookingSlotId: slot.slot.id,
          });
        }
        return;
      default:
        return;
    }
  }, [
    calendarInteractionMode,
    isSlotSelected,
    slot,
    isPatientSelected,
    selectedPatient,
    emitDialog,
    navPatientDetails,
    addSlotToBulkSelection,
    removeSlotFromBulkSelection,
  ]);

  const isTimeSlotInteractive = useMemo(() => {
    switch (calendarInteractionMode) {
      case "editing":
        // Are we in the calendar management view
        return !slot?.slot?.isBooked;
      case "booking":
        // Are we selecting a patient & is the slot not busy
        // TODO: move isPatientSelected to reducer (mode === 'booking')
        return isPatientSelected && !slot?.slot?.isBooked;
      case "viewing":
        return Boolean(slot?.slot?.isBooked);
      default:
        return false;
    }
  }, [isPatientSelected, slot, calendarInteractionMode]);

  const slotLengthVariant = useMemo(() => {
    const lengthInMinutes = slot.interval.length("minutes");
    if (lengthInMinutes <= 15) {
      return SlotLengthVariant.Short;
    }
    if (lengthInMinutes <= 30) {
      return SlotLengthVariant.Medium;
    }
    return SlotLengthVariant.Long;
  }, [slot.interval]);

  return (
    <ConditionalWrapper
      condition={isTimeSlotInteractive}
      wrapIfTrue={(children) => (
        <button
          data-slotid={slot.slot?.id}
          data-variant={slotLengthVariant}
          data-selectable={true}
          className={styles.TimeSlot}
          onClick={handleClick}
          data-selected={isSlotSelected}
          data-mode={viewRange}
        >
          {children}
        </button>
      )}
      wrapIfFalse={(children) => (
        <div
          data-mode={viewRange}
          data-variant={slotLengthVariant}
          data-slotid={slot.slot?.id}
          className={styles.TimeSlot}
        >
          {children}
        </div>
      )}
    >
      <WrappedSlot
        viewRange={viewRange}
        slot={slot}
        isCensored={isCensored}
        selected={isSlotSelected}
      />
    </ConditionalWrapper>
  );
}
