import { RiskLabel, RiskLevel, Weight } from "@cur8/measurements";
import { easeOutCubic } from "lib/ease";
import { Metric } from "lib/metric";
import { resolveHighestSide } from "lib/types/body";
import { useMemo } from "react";
import { PrefixedNumber } from "render/ui/format/PrefixedNumber";
import { RampUpNumber } from "render/ui/format/RampUpNumber";
import { BarRangeGraph } from "render/ui/presentation/BarRangeGraph";
import { RangeLineEntry } from "render/ui/presentation/RangeLine/lib";
import { ValueItem } from "render/ui/symbol/ValueItem";
import {
  DEFAULT_POINT_COLOR,
  DEFAULT_RANGE_COLOR,
  RiskToColorMap,
  RiskToHighlightMap,
  getMarkerVariant,
} from "./colors";
import styles from "./styles.module.sass";

const DEFAULT_RANGES = [
  { from: 0, to: 30, color: DEFAULT_RANGE_COLOR, risk: RiskLevel.Risk },
  { from: 30, to: 80, color: DEFAULT_RANGE_COLOR, risk: RiskLevel.Normal },
];

export declare type RiskRange = {
  from: number;
  to: number;
  risk: RiskLevel;
  label?: RiskLabel;
};

function createPointNormalizer(
  riskRanges: RiskRange[],
  latestValue?: number,
  previousValue?: number
) {
  return function (
    point:
      | Metric<"body.grip_strength.left">
      | Metric<"body.grip_strength.right">
  ) {
    const value = point.unit.kilograms;
    const risk = riskRanges.find((range) => {
      return value >= range.from && value < range.to;
    });

    return {
      color: DEFAULT_POINT_COLOR,
      value,
      previousValue: value !== previousValue ? previousValue : undefined,
      title: point.unit.kilograms.toString(),
      variant: getMarkerVariant({
        areValuesEqual: latestValue === previousValue,
        isLatestValue: value === latestValue,
      }),
      highlight: risk ? RiskToHighlightMap[risk.risk] : "normal",
    };
  };
}

function createRangeNormalizer(value: number | undefined) {
  return function (range: (typeof DEFAULT_RANGES)[0]): RangeLineEntry {
    let isActive = false;
    if (value && value >= range.from && value < range.to) {
      isActive = true;
    }

    return {
      from: {
        value: range.from,
        label: range.from.toString(),
      },
      to: {
        value: range.to,
        label: range.to.toString(),
      },
      color: isActive ? RiskToColorMap[range.risk] : range.color,
    };
  };
}

interface GripStrengthProps {
  gripStrength: {
    left: Metric<"body.grip_strength.left">[] | undefined;
    right: Metric<"body.grip_strength.right">[] | undefined;
  };
  populationMedian?: Weight;
}

export function GripStrength({
  gripStrength,
  populationMedian,
}: GripStrengthProps) {
  const { left, right } = gripStrength;

  const thresholds = useMemo(() => {
    if (populationMedian == null) {
      return [];
    }

    return [
      {
        value: populationMedian.kilograms,
        label: populationMedian.kilograms.toFixed(0),
        content: "Median",
      },
    ];
  }, [populationMedian]);

  const ranges = useMemo(() => {
    if (!populationMedian) {
      return DEFAULT_RANGES;
    }

    const latestLeft = left?.at(0)?.unit.kilograms;
    const previousLeft = left?.at(1)?.unit.kilograms;
    const latestRight = right?.at(0)?.unit.kilograms;
    const previousRight = right?.at(1)?.unit.kilograms;

    const median = populationMedian.kilograms;

    return [
      {
        from: median,
        to: Math.max(
          median + 5,
          (latestLeft ?? 60) + 5,
          (previousLeft ?? 60) + 5,
          (latestRight ?? 60) + 5,
          (previousRight ?? 60) + 5
        ),
        color: DEFAULT_RANGE_COLOR,
        risk: RiskLevel.Normal,
      },
      {
        from: Math.min(
          median - 8,
          (latestLeft ?? median) - 8,
          (previousLeft ?? median) - 8,
          (latestRight ?? median) - 8,
          (previousRight ?? median) - 8
        ),
        to: median,
        color: DEFAULT_RANGE_COLOR,
        risk: RiskLevel.Risk,
      },
    ];
  }, [left, populationMedian, right]);

  const entries = useMemo(() => {
    const latestLeft = left?.at(0)?.unit.kilograms;
    const previousLeft = left?.at(1)?.unit.kilograms;
    const latestRight = right?.at(0)?.unit.kilograms;
    const previousRight = right?.at(1)?.unit.kilograms;
    const higherSide = resolveHighestSide(latestLeft, latestRight);

    let leftOffset = undefined;
    let rightOffset = undefined;

    if (latestLeft !== undefined && previousLeft !== undefined) {
      leftOffset = latestLeft - previousLeft;
    }

    if (latestRight !== undefined && previousRight !== undefined) {
      rightOffset = latestRight - previousRight;
    }

    const toLeftPoint = createPointNormalizer(ranges, latestLeft, previousLeft);
    const toRightPoint = createPointNormalizer(
      ranges,
      latestRight,
      previousRight
    );
    const normalizeLeftRange = createRangeNormalizer(latestLeft);
    const normalizeRightRange = createRangeNormalizer(latestRight);

    const leftPoints = left ? left.slice(0, 2).map(toLeftPoint) : [];
    const leftRanges = ranges.map(normalizeLeftRange);

    const rightPoints = right ? right.slice(0, 2).map(toRightPoint) : [];
    const rightRanges = ranges.map(normalizeRightRange);

    return [
      {
        ranges: leftRanges,
        points: leftPoints,
        label: (
          <div className={styles.label}>
            <ValueItem
              value={
                <span
                  className={styles.offsetNumber}
                  data-bold={higherSide === "left"}
                >
                  {latestLeft !== undefined && isFinite(latestLeft) ? (
                    <RampUpNumber
                      value={latestLeft}
                      duration={2}
                      delay={1}
                      ease={easeOutCubic}
                    />
                  ) : (
                    "--"
                  )}
                </span>
              }
              unit={
                <div className={styles.offsetContainer}>
                  {higherSide === "left" && leftOffset !== undefined ? (
                    <PrefixedNumber value={leftOffset} precision={1} />
                  ) : null}
                  <span>L</span>
                </div>
              }
            />
          </div>
        ),
      },
      {
        ranges: rightRanges,
        points: rightPoints,
        label: (
          <span className={styles.label}>
            <ValueItem
              value={
                <span
                  className={styles.offsetNumber}
                  data-bold={higherSide === "right"}
                >
                  {latestRight !== undefined && isFinite(latestRight) ? (
                    <RampUpNumber
                      value={latestRight}
                      duration={2}
                      delay={1}
                      ease={easeOutCubic}
                    />
                  ) : (
                    "--"
                  )}
                </span>
              }
              unit={
                <div className={styles.offsetContainer}>
                  {higherSide === "right" && rightOffset !== undefined ? (
                    <PrefixedNumber value={rightOffset} precision={1} />
                  ) : null}
                  <span>R</span>
                </div>
              }
            />
          </span>
        ),
      },
    ];
  }, [left, ranges, right]);

  return (
    <div className={styles.GripStrength}>
      <BarRangeGraph entries={entries} thresholds={thresholds} />
    </div>
  );
}
