import { Side } from "@cur8/api-client";
import { lerp, norm } from "lib/anim";
import * as THREE from "three";

export const Transform: Record<Side, THREE.Matrix4> = {
  front: new THREE.Matrix4(),

  back: new THREE.Matrix4().compose(
    new THREE.Vector3(0, 0, 0),
    new THREE.Quaternion().setFromAxisAngle(
      new THREE.Vector3(0, 0, 1),
      Math.PI
    ),
    new THREE.Vector3(1, 1, 1)
  ),
};

const LAP = Math.PI * 2;

export function toSide(rotation: number) {
  const absRotation = (rotation % LAP) / LAP;
  const range = [0.25, 0.75] as const;
  const margin = 0.15;

  if (absRotation < range[0] - margin) {
    return 1;
  }

  if (absRotation < range[0] + margin) {
    const n = norm(range[0] - margin, range[0] + margin, absRotation);
    return lerp(1, 0, n);
  }

  if (absRotation < range[1] - margin) {
    return 0;
  }

  if (absRotation < range[1] + margin) {
    const n = norm(range[1] - margin, range[1] + margin, absRotation);
    return lerp(0, 1, n);
  }

  return 1;
}
