import { APITypesV1 } from "@cur8/api-client";
import {
  Assessment,
  AssessmentTypeName,
  Box,
  isPadAssessment,
  isPulseWaveAssessment,
  isThermalAssessment,
  Patient,
  toTypeName,
} from "@cur8/rich-entity";
import { useNav } from "@pomle/react-router-paths";
import { toRect } from "lib/api/adapter/annotation";
import { useCallback, useMemo, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { usePatientAssessments } from "render/hooks/api/usePatientAssessments";
import { paths } from "render/routes/paths";
import { query } from "render/routes/querys";
import { useAssessmentNav } from "../../hooks/useAssessmentNav";
import {
  isMutablePulseWaveAssessment,
  LVETISelectionState,
  MutablePulseWaveLVETIAssessmentData,
  PulseWaveData,
} from "./types";
import { initPAD, initPW, initThermal, mutableLVETIToAPIType } from "./utils";

interface AssessmentProps {
  assessments: Assessment[];
  patient: Patient;
  selected: Assessment;
}

export function useAssessment({
  assessments,
  patient,
  selected,
}: AssessmentProps) {
  const api = useAPIClient().assessment;
  const { unhandled } = usePatientAssessments(patient.patientId);
  const { setVersionId, unhandledQueue } = useAssessmentNav();
  const [isModified, setModified] = useState<boolean>(false);

  const [comment, setComment] = useState<string>();
  const [assessmentState, setAssessmentState] =
    useState<APITypesV1.AssessmentState>();
  const [tags, setTags] = useState<string[]>();

  const [mutablePAD, setMutablePAD] = useState(() => initPAD(selected));
  const [mutablePW, setMutablePW] = useState(() => initPW(selected));
  const [mutableThermal, setMutableThermal] = useState(() =>
    initThermal(selected)
  );
  const active = useMemo(() => {
    let ass;
    switch (selected.itemTypeName) {
      case AssessmentTypeName.Pad:
        ass = mutablePAD;
        break;
      case AssessmentTypeName.PulseWave:
        ass = mutablePW;
        break;
      case AssessmentTypeName.Thermal:
        ass = mutableThermal;
        break;
      default:
        return undefined;
    }
    return {
      ...ass,
      assessmentState: assessmentState,
      comment: comment,
      tags: tags,
    };
  }, [
    assessmentState,
    comment,
    mutablePAD,
    mutablePW,
    mutableThermal,
    selected.itemTypeName,
    tags,
  ]);

  const markedAsIncomplete = useMemo(() => {
    if (active && active.tags && active.tags.includes("incomplete")) {
      return true;
    }
  }, [active]);

  /**
   * Detect if we're allowed to save the assessment
   */
  const isSavable = useMemo(() => {
    if (markedAsIncomplete) {
      // Allow to save if marked as incomplete
      return true;
    }
    if (!markedAsIncomplete && !assessmentState) {
      return false;
    }

    if (
      !selected.isLatestVersion ||
      active?.assessmentState === APITypesV1.AssessmentState.New ||
      !isModified
    ) {
      return false;
    }

    if (isPadAssessment(selected) && mutablePAD) {
      if (mutablePAD.ratios && mutablePAD.regionsOfInterest) {
        return true;
      }
    } else if (isPulseWaveAssessment(selected) && mutablePW) {
      if (
        mutablePW.footAsymmetry &&
        mutablePW.handAsymmetry &&
        Object.values(mutablePW.lveti.selections).every(
          (s) => s.state === LVETISelectionState.reviewed
        ) &&
        mutablePW.pulseWaveVelocity
      ) {
        return true;
      }
    } else if (isThermalAssessment(selected) && mutableThermal) {
      if (mutableThermal.status) {
        return true;
      }
    }
    return false;
  }, [
    active?.assessmentState,
    assessmentState,
    isModified,
    markedAsIncomplete,
    mutablePAD,
    mutablePW,
    mutableThermal,
    selected,
  ]);

  const nav = {
    assessment: useNav(paths.patient.assessment, query.assessment),
    detailPage: useNav(paths.patient.detail),
  };

  const type = useMemo(() => {
    return toTypeName(selected.itemTypeName ?? "");
  }, [selected]);

  /**
   * Determine what to do next (when unhandledQueue is activated)
   */
  const jumpToNextUnhandled = useCallback(
    (aid: string) => {
      if (!unhandled || unhandled.length <= 0) {
        // TODO: Go back to PatientDetailsPage
        nav.detailPage.go({ patientId: patient.patientId });
        return;
      }
      const next = unhandled.find(
        (ass) =>
          ass.assessmentState === APITypesV1.AssessmentState.New &&
          ass.itemId !== aid
      );
      if (next) {
        // Go to next unhandled assessment
        nav.assessment.go(
          {
            patientId: patient.patientId,
            assessmentId: next.itemId,
            versionId: next.itemVersion,
          },
          { unhandledQueue: [true] }
        );
        return;
      } else {
        // Go back to patient page
        nav.detailPage.go({ patientId: patient.patientId });
      }
    },
    [nav.assessment, nav.detailPage, patient.patientId, unhandled]
  );

  const save = useCallback(() => {
    if (!isSavable) {
      return;
    }

    let res;
    if (isPadAssessment(selected) && mutablePAD) {
      res = api.createPadAssessments(
        {
          patientId: patient.patientId,
          assessmentId: selected.itemId,
        },
        {
          ...mutablePAD,
          assessmentState: assessmentState ?? mutablePAD.assessmentState,
          comment: comment,
          tags: tags,
          regionsOfInterest: {
            forearm: toRect(mutablePAD.regionsOfInterest!.forearm),
            hypothenar: toRect(mutablePAD.regionsOfInterest!.hypothenar),
          },
        }
      ).result;
    } else if (isMutablePulseWaveAssessment(selected) && mutablePW) {
      const lveti = mutableLVETIToAPIType(mutablePW.lveti);
      if (!lveti && !markedAsIncomplete) {
        throw new Error("Missing data for LVETI");
      }

      res = api.createPulseWaveAssessments(
        {
          patientId: patient.patientId,
          assessmentId: mutablePW.itemId,
        },
        {
          ...mutablePW,
          assessmentState: assessmentState ?? mutablePW.assessmentState,
          comment: comment,
          tags: tags,
          lveti: lveti,
        }
      ).result;
    } else if (isThermalAssessment(selected) && mutableThermal) {
      res = api.createThermalAssessments(
        {
          patientId: patient.patientId,
          assessmentId: selected.itemId,
        },
        {
          ...mutableThermal,
          assessmentState: assessmentState ?? mutableThermal.assessmentState,
          comment: comment,
          tags: tags,
        }
      ).result;
    } else {
      throw new Error("Unsupported assessment");
    }

    res.then((ass) => {
      console.debug("Created assessment", ass);
      try {
        setModified(false);
        if (unhandledQueue) {
          jumpToNextUnhandled(ass.itemId);
        } else {
          // Stay on this assessment, but show latest
          setVersionId(ass.itemVersion);
        }
      } catch (err) {
        console.error(err);
      }
    });
  }, [
    api,
    assessmentState,
    comment,
    isSavable,
    jumpToNextUnhandled,
    markedAsIncomplete,
    mutablePAD,
    mutablePW,
    mutableThermal,
    patient.patientId,
    selected,
    setVersionId,
    tags,
    unhandledQueue,
  ]);

  const loadLatest = useCallback(() => {
    const latest = assessments[0];
    try {
      setVersionId(latest.itemVersion);
      setModified(false);
    } catch (err) {
      console.error(err);
    }
  }, [assessments, setVersionId]);

  const setPulseWaveData = useCallback(
    (key: keyof PulseWaveData, val: PulseWaveData[keyof PulseWaveData]) => {
      setMutablePW((ass) => {
        if (!ass || !(key in ass)) {
          throw new Error("Not a PW Assessment");
        }
        return {
          ...ass,
          [key]: val,
        };
      });
      setModified(true);
    },
    []
  );

  const setLvetiPulseWaveData = useCallback(
    (data: MutablePulseWaveLVETIAssessmentData) => {
      setMutablePW((ass) => {
        if (!ass || !("lveti" in ass)) {
          throw new Error("Not a PW Assessment");
        }
        return {
          ...ass,
          lveti: data,
        };
      });
      setModified(true);
    },
    []
  );

  const setThermalStatus = useCallback(
    (status: APITypesV1.ThermalAssessmentStatus) => {
      setMutableThermal((ass) => {
        if (!ass) {
          throw new Error("Undefined thermal assessment");
        }
        return {
          ...ass,
          status: status,
        };
      });
      setModified(true);
    },
    []
  );

  const setState = useCallback((state: APITypesV1.AssessmentState) => {
    setAssessmentState(state);
    setModified(true);
  }, []);

  const addTag = useCallback((tag: string) => {
    setTags((prev) => {
      const ts = prev || [];
      if (!ts.includes(tag)) {
        ts.push(tag);
      }
      return ts;
    });
    setModified(true);
  }, []);

  const removeTag = useCallback((tag: string) => {
    setTags((prev) => {
      if (!prev) {
        return prev;
      }
      const ts = prev.filter((item) => item !== tag);
      if (ts.length === 0) {
        return undefined;
      }
      return ts;
    });
    setModified(true);
  }, []);

  /**
   * PAD-only
   * Move a Region of Interest
   */
  const moveRoI = useCallback(
    (id: string, box: Box) => {
      if (selected.itemTypeName !== AssessmentTypeName.Pad) {
        console.debug("Wrong type");
        return;
      }
      setMutablePAD((ass) => {
        if (!ass || !ass.regionsOfInterest || !(id in ass.regionsOfInterest)) {
          throw new Error("Empty PAD Assessment");
        }
        return {
          ...ass,
          regionsOfInterest: {
            ...ass.regionsOfInterest,
            [id]: box,
          },
        };
      });
      setModified(true);
    },
    [selected.itemTypeName]
  );
  // Set initial RoI:s from annotations
  const setInitialRoIs = useCallback((forearm: Box, hypothenar: Box) => {
    setMutablePAD((ass) => {
      if (!ass) {
        throw new Error("Empty PAD Assessment");
      }
      return {
        ...ass,
        regionsOfInterest: {
          forearm,
          hypothenar,
        },
      };
    });
    setModified(true);
  }, []);

  const setPadRatios = useCallback(
    (bloodVolume: number, oxygenation: number) => {
      setMutablePAD((ass) => {
        if (!ass) {
          throw new Error("Invalid PAD Assessment ratio");
        }
        return {
          ...ass,
          ratios: {
            bloodVolumeFractionT1: bloodVolume,
            oxygenationT1: oxygenation,
          },
        };
      });
    },
    []
  );

  return {
    active,
    addTag,
    assessments,
    fetch,
    isModified,
    isSavable,
    loadLatest,
    moveRoI,
    mutablePAD,
    mutablePW,
    mutableThermal,
    removeTag,
    save,
    selected,
    setComment,
    setInitialRoIs,
    setLvetiPulseWaveData,
    setMutablePW,
    setPadRatios,
    setPulseWaveData,
    setState,
    setThermalStatus,
    type,
  };
}

export type AssessmentContextValue = ReturnType<typeof useAssessment>;
