import { APITypesV1 } from "@cur8/api-client";
import { fromAPI } from "@cur8/rich-entity";
import { silenceAbort } from "lib/error";
import { useCallback, useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useVisits } from "render/hooks/api/useVisits";
import {
  SectionInfo,
  SectionMeasurement,
  SectionMeasurements,
  sectionInfo,
} from "./sections";

interface SectionMetricProps {
  patientId: string;
  visitId: string;
}

export function stripLeftRight(input: string): string {
  return input.replace(/(\.left|\.right)$/, "");
}

export function useSectionMeasurements({
  patientId,
  visitId,
}: SectionMetricProps): SectionMeasurements {
  const [current, setCurrent] = useState<SectionMeasurement[]>([]);
  const [previous, setPrevious] = useState<SectionMeasurement[]>([]);
  const [prevVisitId, setPrevVisitId] = useState<string | null>(null);
  const [country, setCountry] = useState<string>();

  const api = useAPIClient();
  const visits = useVisits(patientId);

  const toSectionMeasurement = useCallback(
    (m: APITypesV1.VisitMeasurement): SectionMeasurement => {
      const risk =
        m.riskRanges?.find((r) => r.country === country)?.riskLevel ||
        APITypesV1.RiskType.Unknown;

      const name = stripLeftRight(m.metricName); // remove left/right
      const si: SectionInfo = sectionInfo[name] || {
        sectionTitle: null,
        friendlyName: m.metricName,
        decimalPlaces: 1,
      };

      return {
        ...fromAPI.toVisitMeasurement(m),
        sectionTitle: si.sectionTitle,
        friendlyName: si.friendlyName,
        risk,
        decimalPlaces: si.decimalPlaces,
      };
    },
    [country]
  );

  useEffect(() => {
    if (visits && visits.length) {
      const cc = visits[0].site.address.countryCode;
      if (cc) {
        setCountry(cc);
      }
      if (visits.length > 1) {
        setPrevVisitId(visits[1].visitId);
      }
    }
  }, [visits]);

  // Fetch current measurements
  useEffect(() => {
    if (country) {
      const request = api.measurement.fetchVisitMeasurements(
        patientId,
        visitId
      );
      request.result
        .then((r) => {
          if (r.measurements) {
            setCurrent(r.measurements.map(toSectionMeasurement));
          }
        })
        .catch(silenceAbort);

      return () => {
        request.abandon();
      };
    }
  }, [patientId, visitId, api, country, toSectionMeasurement]);

  // Fetch previous measurements (if exists)
  useEffect(() => {
    if (country && prevVisitId) {
      const request = api.measurement.fetchVisitMeasurements(
        patientId,
        prevVisitId
      );
      request.result
        .then((r) => {
          if (r.measurements) {
            setPrevious(r.measurements.map(toSectionMeasurement));
          }
        })
        .catch(silenceAbort);

      return () => {
        request.abandon();
      };
    }
  }, [patientId, prevVisitId, api, country, toSectionMeasurement]);

  return { current, previous };
}
