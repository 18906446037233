import { RiskMetric } from "lib/metric";
import { useMemo } from "react";
import {
  AuxTitle,
  Diff,
  DiffValue,
  MainTitle,
  MetricResultHeader,
  Titles,
} from "render/ui/presentation/MetricResultHeader";
import { RangeChartDataComposer } from "render/ui/presentation/RangeChart";
import { toChartRanges } from "render/ui/presentation/RangeChart/lib/risk";
import { RangeChartCompact } from "render/ui/presentation/RangeChart/RangeChartCompact";
import styles from "./styles.module.sass";

interface GlucoseSegmentProps {
  metrics: RiskMetric<"bloodwork.glucose">[];
}

export function GlucoseSegment({ metrics }: GlucoseSegmentProps) {
  const latestMetric = metrics[0];
  const currentValue = latestMetric.unit["mmol/L"];

  const riskRanges = useMemo(() => {
    const riskIntervals = latestMetric.riskRanges.riskIntervals;

    const result = toChartRanges(riskIntervals);
    return result;
  }, [latestMetric.riskRanges]);

  return (
    <div className={styles.GlucoseSegment}>
      <MetricResultHeader>
        <Titles>
          <MainTitle>Short term</MainTitle>
          <AuxTitle>[Glucose]</AuxTitle>
        </Titles>
        <Diff>
          <DiffValue unit="mmol/L" />
        </Diff>
      </MetricResultHeader>
      <RangeChartDataComposer ranges={riskRanges} value={currentValue}>
        {({ ranges, values }) => {
          return (
            <RangeChartCompact
              ranges={ranges}
              values={values}
              bounds={{ min: 4, max: 9 }}
              labels={{ from: "L", to: "H" }}
            />
          );
        }}
      </RangeChartDataComposer>
    </div>
  );
}
