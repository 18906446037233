import { LinkNode } from "@lexical/link";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { SectionMeasurements } from "../SummaryEditor/sections";
import styles from "./BulletEditor.module.sass";
import { LinkTooltip } from "./components/LinkTooltip";
import { LinkInfo } from "./components/LinkTooltip/components/SelectionActionPlugin";
import { BulletEditorPlugins } from "./components/plugins/BulletEditorPlugins";

export interface BulletEditorProps {
  value: string[] | undefined;
  onChange: (bullets: string[]) => void;
  placeholder: string;
  measurements: SectionMeasurements;
  extraLinks: LinkInfo[];
  isActive: boolean;
  editorKey: string;
}

export function BulletEditor({
  value,
  onChange,
  placeholder,
  measurements,
  extraLinks,
  isActive,
  editorKey,
}: BulletEditorProps) {
  const initialConfig = {
    namespace: "BulletEditor",
    theme: {},
    onError: (error: Error) => {
      throw error;
    },
    nodes: [LinkNode],
  };

  return (
    <div className={styles.BulletEditor}>
      <div className={styles.editorContainer}>
        <LexicalComposer initialConfig={initialConfig} key={editorKey}>
          <LinkTooltip
            measurements={measurements}
            extraLinks={extraLinks}
            isActive={isActive}
          >
            <RichTextPlugin
              contentEditable={
                <ContentEditable className={styles.contentEditable} />
              }
              placeholder={
                <div className={styles.placeholder}>{placeholder}</div>
              }
              ErrorBoundary={LexicalErrorBoundary}
            />
            <BulletEditorPlugins value={value} onChange={onChange} />
          </LinkTooltip>
        </LexicalComposer>
      </div>
    </div>
  );
}
