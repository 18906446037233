import { Layout, LayoutProps } from "../Layout";
import { SidePanel } from "../SidePanel";
import { BloodworkSection } from "./components/BloodworkSection";
import styles from "./styles.module.sass";

interface BloodworkLayoutProps extends LayoutProps {}

export function BloodworkLayout({ active }: BloodworkLayoutProps) {
  return (
    <Layout active={active}>
      <div className={styles.BloodworkLayout} data-active={active}>
        <SidePanel>
          <BloodworkSection />
        </SidePanel>
      </div>
    </Layout>
  );
}
