import type { PropsWithChildren } from "react";
import { BasophilsRange } from "render/fragments/metric/range-charts/BasophilsRange";
import { CRPRange } from "render/fragments/metric/range-charts/CRPRange";
import { EosinophilsRange } from "render/fragments/metric/range-charts/EosinophilsRange";
import { GlucoseRange } from "render/fragments/metric/range-charts/GlucoseRange";
import { HDLRange } from "render/fragments/metric/range-charts/HDLRange";
import { HaemoglobinRange } from "render/fragments/metric/range-charts/HaemoglobinRange";
import { HbA1cRange } from "render/fragments/metric/range-charts/HbA1cRange";
import { LDLRange } from "render/fragments/metric/range-charts/LDLRange";
import { LymphocytesRange } from "render/fragments/metric/range-charts/LymphocytesRange";
import { NeutrophilsRange } from "render/fragments/metric/range-charts/NeutrophilsRange";
import { NonHDLRange } from "render/fragments/metric/range-charts/NonHDLRange";
import { TotalCholesterolRange } from "render/fragments/metric/range-charts/TotalCholesterolRange";
import { TriglyceridesRange } from "render/fragments/metric/range-charts/TriglyceridesRange";
import { WhiteBloodCellsRange } from "render/fragments/metric/range-charts/WhiteBloodCellsRange";
import type { PatientMetrics } from "render/hooks/patient/usePatientMetrics";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import { Typography } from "render/ui/presentation/Typography";
import { MetricValueChangeToggler } from "../MetricValueChangeToggler";
import styles from "./styles.module.sass";

export type BloodworkSectionMetrics = Pick<
  PatientMetrics["bloodwork"],
  | "cholesterol"
  | "hdl"
  | "nonHDL"
  | "triglycerides"
  | "ldl"
  | "whiteBloodCells"
  | "neutrophils"
  | "basophils"
  | "eosinophils"
  | "lymphocytes"
  | "glucose"
  | "hba1c"
  | "haemoglobin"
  | "crp"
>;

export function pickBloodworkSectionMetrics(
  bloodwork: PatientMetrics["bloodwork"]
): BloodworkSectionMetrics {
  return {
    cholesterol: bloodwork.cholesterol,
    hdl: bloodwork.hdl,
    nonHDL: bloodwork.nonHDL,
    triglycerides: bloodwork.triglycerides,
    ldl: bloodwork.ldl,
    whiteBloodCells: bloodwork.whiteBloodCells,
    neutrophils: bloodwork.neutrophils,
    basophils: bloodwork.basophils,
    eosinophils: bloodwork.eosinophils,
    lymphocytes: bloodwork.lymphocytes,
    glucose: bloodwork.glucose,
    hba1c: bloodwork.hba1c,
    haemoglobin: bloodwork.haemoglobin,
    crp: bloodwork.crp,
  };
}

export function BloodworkSection() {
  const { metrics } = usePatientData();
  const bloodwork = metrics.bloodwork;

  return (
    <div className={styles.BloodworkSection}>
      <div className={styles.metricGroups}>
        <div className={styles.metricGroup}>
          <MetricGroupHeader>Blood fats</MetricGroupHeader>
          {!!bloodwork.cholesterol && (
            <TotalCholesterolRange
              metrics={bloodwork.cholesterol.slice(0, 2)}
            />
          )}

          {!!bloodwork.hdl && <HDLRange metrics={bloodwork.hdl.slice(0, 2)} />}

          {!!bloodwork.nonHDL && (
            <NonHDLRange metrics={bloodwork.nonHDL.slice(0, 2)} />
          )}

          {!!bloodwork.triglycerides && (
            <TriglyceridesRange metrics={bloodwork.triglycerides.slice(0, 2)} />
          )}

          {!!bloodwork.ldl && <LDLRange metrics={bloodwork.ldl.slice(0, 2)} />}
        </div>

        <div className={styles.vl} />

        <div className={styles.metricGroup}>
          <MetricGroupHeader>White blood cells</MetricGroupHeader>

          {!!bloodwork.whiteBloodCells && (
            <MetricValueChangeToggler
              metrics={bloodwork.whiteBloodCells}
              content={(metrics) => <WhiteBloodCellsRange metrics={metrics} />}
            />
          )}

          {!!bloodwork.neutrophils && (
            <MetricValueChangeToggler
              metrics={bloodwork.neutrophils}
              content={(metrics) => <NeutrophilsRange metrics={metrics} />}
            />
          )}

          {!!bloodwork.basophils && (
            <MetricValueChangeToggler
              metrics={bloodwork.basophils}
              content={(metrics) => <BasophilsRange metrics={metrics} />}
            />
          )}

          {!!bloodwork.eosinophils && (
            <MetricValueChangeToggler
              metrics={bloodwork.eosinophils}
              content={(metrics) => <EosinophilsRange metrics={metrics} />}
            />
          )}

          {!!bloodwork.lymphocytes && (
            <MetricValueChangeToggler
              metrics={bloodwork.lymphocytes}
              content={(metrics) => <LymphocytesRange metrics={metrics} />}
            />
          )}
        </div>

        <div className={styles.vl} />

        <div className={styles.metricGroup}>
          <MetricGroupHeader>Other</MetricGroupHeader>

          {!!bloodwork.glucose && (
            <MetricValueChangeToggler
              metrics={bloodwork.glucose}
              content={(metrics) => <GlucoseRange metrics={metrics} />}
            />
          )}

          {!!bloodwork.hba1c && (
            <HbA1cRange metrics={bloodwork.hba1c.slice(0, 2)} />
          )}

          {!!bloodwork.haemoglobin && (
            <HaemoglobinRange metrics={bloodwork.haemoglobin.slice(0, 2)} />
          )}

          {!!bloodwork.crp && <CRPRange metrics={bloodwork.crp.slice(0, 2)} />}
        </div>
      </div>
    </div>
  );
}

function MetricGroupHeader({ children }: PropsWithChildren) {
  return (
    <div className={styles.metricGroupHeader}>
      <Typography as="h2" variant="title-m">
        {children}
      </Typography>
      <hr />
    </div>
  );
}
