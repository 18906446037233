import { RiskMetric } from "lib/metric";
import { useMemo } from "react";
import { MetricRange } from "render/ui/presentation/MetricRange";
import {
  selectChartRanges,
  toChartRanges,
} from "render/ui/presentation/RangeChart/lib/risk";

interface HaemoglobinRangeProps {
  metrics: RiskMetric<"bloodwork.haemoglobin">[];
}

export function HaemoglobinRange({ metrics }: HaemoglobinRangeProps) {
  const latest = metrics.at(0);

  const entries = useMemo(() => {
    return metrics.map((metric) => {
      return {
        risk: metric.riskRanges.riskLevel,
        timestamp: metric.measurement.timestampStart,
        value: metric.unit["g/L"],
      };
    });
  }, [metrics]);

  const currentValue = entries.at(0)?.value;

  const ranges = useMemo(() => {
    const riskIntervals = latest?.riskRanges.riskIntervals;
    const chartRanges = toChartRanges(riskIntervals ?? []);

    return selectChartRanges(chartRanges, {
      mode: "dynamic",
      currentValue,
    });
  }, [currentValue, latest?.riskRanges.riskIntervals]);

  return (
    <MetricRange
      title="Haemoglobin"
      unit="g/L"
      entries={entries}
      ranges={ranges}
      showEmptyRanges
    />
  );
}
