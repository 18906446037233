import { noop } from "lib/noop";
import { BodyAreas } from "lib/smpl";
import { HeartEcgOverlay } from "render/fragments/avatar/overlay/HeartEcgOverlay";
import { ScreenProjector } from "render/hooks/three/useProject";
import { useDelay } from "render/hooks/useDelay";
import { useDashboardContext } from "render/pages/DashboardPage/context/DashboardContext";
import { Layer } from "render/pages/DashboardPage/types";
import * as THREE from "three";
import { Overlay } from "../../components/Overlay";
import heartDotGridImage from "./assets/heart-dot-grid.png";
import styles from "./styles.module.sass";

const ACTIVE = new Set([Layer.Heart]);

const HEART_POS = new THREE.Vector2(1920 / 2, 1080 / 2);

interface HeartLayerProps {
  layer: Layer;
  areas: BodyAreas;
  projector: ScreenProjector;
}

export function HeartLayer({ layer }: HeartLayerProps) {
  const { heartAudio } = useDashboardContext();

  const active = ACTIVE.has(layer);

  const showHeartLayer = useDelay({
    active,
    delay: 300,
  });

  const hideHeartDotGrid = useDelay({
    active: showHeartLayer,
    delay: 1800,
  });

  const showHeartDotGrid = showHeartLayer && hideHeartDotGrid === false;
  const showHeartOverlay = showHeartLayer && hideHeartDotGrid === true;

  return (
    <div className={styles.HeartLayer} data-active={showHeartLayer}>
      <Overlay pos={HEART_POS}>
        <div className={styles.heartContainer}>
          <img
            className={styles.heartDotGrid}
            data-active={showHeartDotGrid}
            src={heartDotGridImage}
            alt="Heart dot grid"
          />
          <div
            className={styles.heartOverlay}
            data-active={showHeartOverlay}
            onClick={showHeartLayer ? heartAudio.toggleMuted : noop}
          >
            <HeartEcgOverlay active={showHeartOverlay} />
          </div>
        </div>
      </Overlay>
    </div>
  );
}
