import { APITypesV1 } from "@cur8/api-client";
import { useCallback, useMemo, useState } from "react";

// Minimum quality threshold for heart audio to be enabled, confirmed by Medops and Algo team
const HEART_AUDIO_MINIMUM_QUALITY_THRESHOLD = 0.3;

export function useHeartAudio() {
  const [muted, setMuted] = useState(true);
  const [quality, setQuality] =
    useState<APITypesV1.QualityResultData["quality"]>(1);
  const toggleMuted = useCallback(() => setMuted((prev) => !prev), []);
  const enabled =
    quality != null && quality >= HEART_AUDIO_MINIMUM_QUALITY_THRESHOLD;

  return useMemo(
    () => ({
      muted,
      setMuted,
      toggleMuted,
      quality,
      enabled,
      setQuality,
    }),
    [muted, setMuted, toggleMuted, quality, enabled, setQuality]
  );
}
