import { RiskMetric } from "lib/metric";
import { useMemo } from "react";
import { MetricRange } from "render/ui/presentation/MetricRange";
import { toChartRanges } from "render/ui/presentation/RangeChart/lib/risk";

interface WhiteBloodCellsRangeProps {
  metrics: RiskMetric<"bloodwork.white_blood_cells.total">[];
}

export function WhiteBloodCellsRange({ metrics }: WhiteBloodCellsRangeProps) {
  const latest = metrics.at(0);

  const ranges = useMemo(() => {
    const intervals = latest?.riskRanges.riskIntervals ?? [];

    const result = toChartRanges(intervals);
    return result;
  }, [latest?.riskRanges.riskIntervals]);

  const entries = useMemo(() => {
    return metrics.map((metric) => {
      return {
        risk: metric.riskRanges.riskLevel,
        timestamp: metric.measurement.timestampStart,
        value: metric.unit["x10⁹/L"],
      };
    });
  }, [metrics]);

  return (
    <MetricRange
      title="White blood cells"
      unit="x10⁹/L"
      entries={entries}
      ranges={ranges}
      formatValue={(value) => value?.toFixed(1)}
      showEmptyRanges
    />
  );
}
