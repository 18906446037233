import { RiskMetric } from "lib/metric";
import { useMemo } from "react";
import {
  Diff,
  DiffValue,
  MainTitle,
  MetricResultHeader,
  Titles,
} from "render/ui/presentation/MetricResultHeader";
import { RangeChartDataComposer } from "render/ui/presentation/RangeChart";
import { toChartRanges } from "render/ui/presentation/RangeChart/lib/risk";
import { RangeChartCompact } from "render/ui/presentation/RangeChart/RangeChartCompact";
import styles from "./styles.module.sass";

interface BloodOxygenRangeProps {
  metrics: RiskMetric<"cardio.oxygen_saturation">[];
}

export function BloodOxygenRange({ metrics }: BloodOxygenRangeProps) {
  const latest = metrics.at(0);
  const currentValue = latest?.unit.percent;

  const rangesForValue = useMemo(() => {
    return toChartRanges(latest?.riskRanges.riskIntervals ?? []);
  }, [latest?.riskRanges.riskIntervals]);

  return (
    <div className={styles.BloodOxygenRange}>
      <MetricResultHeader>
        <Titles>
          <MainTitle>Blood oxygen</MainTitle>
        </Titles>
        <Diff>
          <DiffValue unit="%" />
        </Diff>
      </MetricResultHeader>
      <RangeChartDataComposer
        ranges={rangesForValue}
        value={currentValue}
        formatValue={(value) => value?.toFixed(0)}
      >
        {({ ranges, values }) => {
          return (
            <RangeChartCompact
              ranges={ranges}
              values={values}
              bounds={{ min: 88, max: 100 }}
              labels={{ from: "L", to: "H" }}
              fillRange
            />
          );
        }}
      </RangeChartDataComposer>
    </div>
  );
}
