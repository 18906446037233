import { useNav, useQueryParams } from "@pomle/react-router-paths";
import { DateTime } from "luxon";
import { useCallback, useMemo } from "react";
import { useSites } from "render/hooks/api/useSites";
import { useDeviceStorage } from "render/hooks/deviceStorage/useDeviceStorage";
import { paths } from "render/routes/paths";
import { FramedPage } from "render/ui/layouts/FramedPage/FramedPage";
import { useSiteRooms } from "../SchedulePage/components/Calendar/hooks/useSiteRooms";
import { SelectCalendarPreferenceStep } from "./components/steps/SelectCalendarPreferenceStep";
import { SelectLocationStep } from "./components/steps/SelectLocationStep";
import { SelectRoomStep } from "./components/steps/SelectRoomStep";
import { SubmitStep } from "./components/steps/SubmitStep";
import {
  CalendarPreferenceFormData,
  Preference,
  Step,
  queryParams,
} from "./types";
import styles from "./styles.module.sass";
import { DeviceCalendarPreference } from "render/hooks/deviceStorage/lib/calendarPreference";

const stepOrder = [
  Step.SelectCalendarPreference,
  Step.SelectLocation,
  Step.SelectRoom,
  Step.Submit,
] as const;

export function SettingsPageCalendarSettings() {
  const deviceSettings = useDeviceStorage();
  const [params, setParams] = useQueryParams(queryParams);

  const step = params.step.at(0) ?? Step.SelectCalendarPreference;

  const formData: CalendarPreferenceFormData = {
    siteId: params.locationId.at(0),
    roomId: params.roomId.at(0),
    expiresAt: useMemo(() => DateTime.now().endOf("day"), []),
  };

  const sites = useSites();
  const rooms = useSiteRooms(formData.siteId);

  const nav = {
    settings: useNav(paths.settings.overview),
  };

  const navControls = {
    goToNextStep: useCallback(() => {
      const index = stepOrder.indexOf(step) + 1;
      if (index > stepOrder.length) {
        return;
      }
      setParams({ step: [stepOrder[index]] });
    }, [step, setParams]),
    goToStep: useCallback(
      (step: Step) => {
        setParams({ step: [step] });
      },
      [setParams]
    ),
  };

  const formControls = {
    submit: useCallback(
      (data: DeviceCalendarPreference) => {
        const submit = deviceSettings.calendarSettings.set;
        submit(data);
        nav.settings.go({});
      },
      [deviceSettings.calendarSettings.set, nav.settings]
    ),
    onSetLocation: useCallback(
      (locationId: (typeof Step)[keyof typeof Step]) => {
        setParams({ locationId: [locationId] });
      },
      [setParams]
    ),
    onSetCalendarPreference: useCallback(
      (preference: Preference) => {
        switch (preference) {
          case Preference.CustomCalendar:
            const goToNextStep = navControls.goToNextStep;
            goToNextStep();
            return;
          case Preference.AllCalendars:
            const goToStep = navControls.goToStep;
            goToStep(Step.Submit);
            return;
        }
      },
      [navControls.goToNextStep, navControls.goToStep]
    ),
    onLocationSelect: useCallback(
      (locationId: string) => {
        setParams({ locationId: [locationId] });
        const goToNextStep = navControls.goToNextStep;
        goToNextStep();
      },
      [navControls.goToNextStep, setParams]
    ),
    onRoomSelect: useCallback(
      (roomId: string) => {
        setParams({ roomId: [roomId] });
        const goToNextStep = navControls.goToNextStep;
        goToNextStep();
      },
      [navControls.goToNextStep, setParams]
    ),
  };

  return (
    <FramedPage>
      <div className={styles.SettingsPageCalendarSettings}>
        {step === Step.SelectCalendarPreference && (
          <SelectCalendarPreferenceStep
            onSelect={formControls.onSetCalendarPreference}
          />
        )}
        {step === Step.SelectLocation && (
          <SelectLocationStep
            onSelect={formControls.onLocationSelect}
            sites={sites}
          />
        )}
        {step === Step.SelectRoom && (
          <SelectRoomStep
            onSelect={formControls.onRoomSelect}
            site={sites?.find((site) => site.siteId === formData.siteId)}
            rooms={rooms.data}
          />
        )}
        {step === Step.Submit && (
          <SubmitStep
            formData={formData}
            onSubmit={formControls.submit}
            rooms={rooms.data}
            sites={sites}
          />
        )}
      </div>
    </FramedPage>
  );
}
