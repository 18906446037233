import { RiskMetric } from "lib/metric";
import { useMemo } from "react";
import { MetricRange } from "render/ui/presentation/MetricRange";
import { toChartRanges } from "render/ui/presentation/RangeChart/lib/risk";

interface HDLRangeProps {
  metrics: RiskMetric<"bloodwork.hdl">[] | undefined;
}

export function HDLRange({ metrics }: HDLRangeProps) {
  const latest = metrics?.at(0);

  const ranges = useMemo(() => {
    const riskIntervals = latest?.riskRanges.riskIntervals ?? [];

    return toChartRanges(riskIntervals);
  }, [latest]);

  // https://dev.azure.com/cur8/Engineering/_workitems/edit/26137
  const rangesCapLow = 0.9;

  const entries = useMemo(() => {
    if (!metrics) {
      return [];
    }

    return metrics.map((metric) => {
      const unit = metric.unit;
      return {
        risk: metric.riskRanges.riskLevel,
        timestamp: metric.measurement.timestampStart,
        value: unit["mmol/L"],
      };
    });
  }, [metrics]);

  return (
    <MetricRange
      title="HDL"
      unit="mmol/L"
      entries={entries}
      ranges={ranges}
      rangesCapLow={rangesCapLow}
      formatValue={(value) => value?.toFixed(2)}
      showEmptyRanges
    />
  );
}
