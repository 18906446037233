import { Sex } from "@cur8/rich-entity";
import { useMemo } from "react";
import {
  LVETISelectionState,
  MutableLVETISelection,
} from "render/pages/AssessmentPage/context/AssessmentContext/types";
import { Typography } from "render/ui/presentation/Typography";
import { Checkmark, CheckmarkType } from "render/ui/symbol/Checkmark";
import { getLVETIrange, RangeLabel } from "../../lib/calculations";
import styles from "./styles.module.sass";

interface SelectionBoxProps {
  idx: number;
  data: MutableLVETISelection;
  selected: boolean;
  setSelected: () => void;
  sex?: Sex;
}

export function SelectionBox({
  idx,
  data,
  selected,
  setSelected,
  sex,
}: SelectionBoxProps) {
  const boundryLabel = useMemo(() => {
    if (!sex || !data || !data.lveti) {
      return;
    }
    return getLVETIrange(data.lveti, sex);
  }, [data, sex]);

  const indicator = useMemo(() => {
    switch (boundryLabel) {
      case RangeLabel.above:
        return "↑";
      case RangeLabel.below:
        return "↓";
      default:
        return;
    }
  }, [boundryLabel]);

  const checkbox = useMemo((): { type: CheckmarkType; label: string } => {
    switch (data.state) {
      case LVETISelectionState.proposed:
        return { type: "notfilled", label: "Proposed" };
      case LVETISelectionState.reviewed:
        return { type: "filled", label: "Reviewed" };
      case LVETISelectionState.incorrect:
        return { type: "incorrect", label: "Incorrect" };
      case LVETISelectionState.empty:
      default:
        return { type: "unchecked", label: "No selection" };
    }
  }, [data]);

  return (
    <div
      className={styles.SelectionBox}
      data-selected={selected}
      onClick={setSelected}
    >
      <Typography variant="body-m" as="h4">
        Selection {idx + 1}
      </Typography>
      <div className={styles.data}>
        <label>LVETI</label>
        <span data-boundry={boundryLabel}>
          {data?.lveti ? Math.round(data?.lveti) : "-"} <b>ms</b>
          <i>{indicator}</i>
        </span>
        <label>HR</label>
        <span>
          {data?.hr ? Math.round(data?.hr) : "-"} <b>bpm</b>
        </span>
        <label>LVET</label>
        <span>
          {data?.lvet ? Math.round(data?.lvet) : "-"} <b>ms</b>
        </span>
      </div>
      <div className={styles.state}>
        <Checkmark type={checkbox.type} />
        <label>{checkbox.label}</label>
      </div>
    </div>
  );
}
