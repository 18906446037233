import {
  isPadAssessment,
  isPulseWaveAssessment,
  isThermalAssessment,
} from "@cur8/rich-entity";
import { useAssessment } from "render/hooks/api/useAssessment";
import { usePatient } from "render/hooks/api/usePatient";
import { PageFrame } from "render/ui/layouts/PageFrame";
import { Typography } from "render/ui/presentation/Typography";
import { AssessmentHeader } from "./components/AssessmentHeader";
import { AssessmentHeaderEmpty } from "./components/AssessmentHeader/AssessmentHeaderEmpty";
import { PadAnnotationLoader } from "./components/PadPage/PadAnnotationLoader";
import { PulseWavePage } from "./components/PulseWavePage";
import { ThermalLoader } from "./components/ThermalPage/ThermalLoader";
import { AssessmentContext } from "./context/AssessmentContext";
import styles from "./styles.module.sass";

interface AssessmentPageProps {
  patientId: string;
  assessmentId: string;
  versionId: string;
}

export function AssessmentPage({
  patientId,
  assessmentId,
  versionId,
}: AssessmentPageProps) {
  const patient = usePatient(patientId);

  const { selected, versions } = useAssessment(
    patientId,
    assessmentId,
    versionId
  );

  if (!selected && versions) {
    return (
      <PageFrame>
        <div className={styles.AssessmentPage}>
          <AssessmentHeaderEmpty patientId={patientId} />
          <div className={styles.notfound}>
            <Typography variant="title-m">
              Selected version not found
            </Typography>
            <div className={styles.colinfo}>
              <span>Patient Id:</span>
              <code>{patientId}</code>
              <span>Assessment Id:</span>
              <code>{assessmentId}</code>
              <span>Version Id:</span>
              <code>{versionId}</code>
            </div>
          </div>
        </div>
      </PageFrame>
    );
  }

  if (!selected || !versions || !patient) {
    return (
      <PageFrame>
        <div className={styles.AssessmentPage}>
          <Typography variant="title-m">Loading assessment</Typography>
          <p>
            For: {patientId}
            <br />
            id: {assessmentId}
            <br />
          </p>
        </div>
      </PageFrame>
    );
  }

  return (
    <PageFrame>
      {/* The key is needed to force a new instance when assessment queue is active */}
      <div className={styles.AssessmentPage} key={assessmentId}>
        <AssessmentHeader assessment={selected} patient={patient} />
        <AssessmentContext
          assessments={versions}
          patient={patient}
          selected={selected}
        >
          {isPadAssessment(selected) && (
            <PadAnnotationLoader patient={patient} />
          )}
          {isPulseWaveAssessment(selected) && (
            <PulseWavePage assessment={selected} patient={patient} />
          )}
          {isThermalAssessment(selected) && (
            <ThermalLoader selected={selected} patient={patient} />
          )}
        </AssessmentContext>
      </div>
    </PageFrame>
  );
}
