import { MetricRiskInterval, RiskLabel, RiskLevel } from "@cur8/measurements";
import { easeOutCubic } from "lib/ease";
import { RiskMetric } from "lib/metric";
import { useMemo } from "react";
import { PrefixedNumber } from "render/ui/format/PrefixedNumber";
import { RampUpNumber } from "render/ui/format/RampUpNumber";
import { BarRangeGraph } from "render/ui/presentation/BarRangeGraph";
import { PositionalNote } from "render/ui/presentation/BarRangeGraph/BarRangeGraph";
import { ConnectorHighlightToColorMap } from "render/ui/presentation/BarRangeGraph/colors";
import { RangeLineEntry } from "render/ui/presentation/RangeLine/lib";
import { ValueItem } from "render/ui/symbol/ValueItem";
import { EyePressureStatus, getEyePressureStatus } from "./balance";
import {
  DEFAULT_POINT_COLOR,
  RiskToColorMap,
  getHighlight,
  getMarkerVariant,
} from "./colors";
import styles from "./styles.module.sass";

function clamp(value: number) {
  return Math.max(0, Math.min(value, 28));
}

function toBarRangeRange(range: MetricRiskInterval) {
  const isFromFinite = isFinite(range.start.value);
  const isToFinite = isFinite(range.end.value);

  return {
    from: clamp(isFromFinite ? range.start.value : range.end.value * 0.6),
    to: clamp(isToFinite ? range.end.value : range.start.value * 1.1),
    color: RiskToColorMap[RiskLevel.Unknown],
  };
}

function createPointNormalizer(
  status?: EyePressureStatus,
  latestValue?: number,
  previousValue?: number
) {
  return function (
    point:
      | RiskMetric<"risk_assessment.eye_pressure.left">
      | RiskMetric<"risk_assessment.eye_pressure.right">
  ) {
    const value = point.unit.mmHg;
    const risk = point.riskRanges.riskLevel;

    return {
      color: DEFAULT_POINT_COLOR,
      value: clamp(value),
      previousValue: value !== previousValue ? previousValue : undefined,
      title: point.unit.mmHg.toString(),
      variant: getMarkerVariant({
        areValuesEqual: latestValue === previousValue,
        isLatestValue: value === latestValue,
      }),
      highlight: getHighlight({
        isUnbalanced: status === "unbalanced",
        risk,
      }),
    };
  };
}

function createRangeNormalizer(
  value: number | undefined,
  riskForValue?: RiskLevel,
  status?: EyePressureStatus
) {
  return function (range: ReturnType<typeof toBarRangeRange>): RangeLineEntry {
    if (!value) {
      return {
        from: {
          value: range.from,
          label: range.from.toString(),
        },
        to: {
          value: range.to,
          label: range.to.toString(),
        },
        color: range.color,
      };
    }

    if (status === "unbalanced") {
      return {
        from: {
          value: range.from,
          label: range.from.toString(),
        },
        to: {
          value: range.to,
          label: range.to.toString(),
        },
        color: RiskToColorMap[RiskLevel.Risk],
      };
    }

    const isActive = value >= range.from && value <= range.to;

    return {
      from: {
        value: range.from,
        label: range.from.toString(),
      },
      to: {
        value: range.to,
        label: range.to.toString(),
      },
      color:
        isActive && riskForValue ? RiskToColorMap[riskForValue] : range.color,
    };
  };
}

const getRiskIntervalByLabel = (
  riskIntervals: MetricRiskInterval[] | undefined,
  label: RiskLabel
) => {
  return riskIntervals?.find(
    (riskInterval) => riskInterval.riskLabel === label
  );
};

interface EyePressureProps {
  eyePressure: {
    left: RiskMetric<"risk_assessment.eye_pressure.left">[] | undefined;
    right: RiskMetric<"risk_assessment.eye_pressure.right">[] | undefined;
    difference:
      | RiskMetric<"risk_assessment.eye_pressure.difference">[]
      | undefined;
  };
}

export function EyePressure({ eyePressure }: EyePressureProps) {
  const { left, right, difference } = eyePressure;

  const status = getEyePressureStatus({
    current: {
      left: left?.at(0),
      right: right?.at(0),
      difference: difference?.at(0),
    },
  });

  const thresholds: PositionalNote[] = useMemo(() => {
    const eyePressureRiskIntervals =
      left?.at(0)?.riskRanges.riskIntervals ??
      right?.at(0)?.riskRanges.riskIntervals;

    const eyePressureNormalRiskInterval = getRiskIntervalByLabel(
      eyePressureRiskIntervals,
      RiskLabel.Normal
    );

    if (!eyePressureNormalRiskInterval) {
      return [];
    }

    return [
      {
        value: eyePressureNormalRiskInterval.end.value,
        label: eyePressureNormalRiskInterval.end.value.toString(),
        content: "",
        highlight: "warning",
      },
      {
        value: eyePressureNormalRiskInterval.start.value,
        label: eyePressureNormalRiskInterval.start.value.toString(),
        content: "",
      },
    ];
  }, [left, right]);

  const entries = useMemo(() => {
    const latestLeft = left?.at(0);
    const previousLeft = left?.at(1);
    const latestRight = right?.at(0);
    const previousRight = right?.at(1);

    const higherSide =
      (latestLeft?.unit.mmHg ?? 0) > (latestRight?.unit.mmHg ?? 0)
        ? "left"
        : "right";

    let leftOffset = undefined;
    let rightOffset = undefined;

    if (latestLeft !== undefined && previousLeft !== undefined) {
      leftOffset = latestLeft.unit.mmHg - previousLeft.unit.mmHg;
    }

    if (latestRight !== undefined && previousRight !== undefined) {
      rightOffset = latestRight.unit.mmHg - previousRight.unit.mmHg;
    }

    const toLeftPoint = createPointNormalizer(
      status,
      latestLeft?.unit.mmHg,
      previousLeft?.unit.mmHg
    );
    const toRightPoint = createPointNormalizer(
      status,
      latestRight?.unit.mmHg,
      previousRight?.unit.mmHg
    );

    const normalizeLeftRange = createRangeNormalizer(
      latestLeft?.unit.mmHg,
      latestLeft?.riskRanges.riskLevel,
      status
    );
    const normalizeRightRange = createRangeNormalizer(
      latestRight?.unit.mmHg,
      latestRight?.riskRanges.riskLevel,
      status
    );

    const leftPoints = left ? left.slice(0, 2).map(toLeftPoint) : [];
    const leftRanges =
      latestLeft?.riskRanges.riskIntervals
        .map(toBarRangeRange)
        .map(normalizeLeftRange) ?? [];

    const rightPoints = right ? right.slice(0, 2).map(toRightPoint) : [];
    const rightRanges =
      latestRight?.riskRanges.riskIntervals
        .map(toBarRangeRange)
        .map(normalizeRightRange) ?? [];

    return [
      {
        ranges: leftRanges,
        points: leftPoints,
        label: (
          <div className={styles.label}>
            <ValueItem
              value={
                <span
                  className={styles.offsetNumber}
                  data-bold={higherSide === "left"}
                >
                  {latestLeft ? (
                    <RampUpNumber
                      value={latestLeft.unit.mmHg}
                      duration={2}
                      delay={1}
                      ease={easeOutCubic}
                    />
                  ) : (
                    "--"
                  )}
                </span>
              }
              unit={
                <div className={styles.offsetContainer}>
                  {higherSide === "left" &&
                  leftOffset !== undefined &&
                  leftOffset !== 0 ? (
                    <PrefixedNumber value={leftOffset} precision={1} />
                  ) : null}
                  <span>L</span>
                </div>
              }
            />
          </div>
        ),
      },
      {
        ranges: rightRanges,
        points: rightPoints,
        label: (
          <span className={styles.label}>
            <ValueItem
              value={
                <span
                  className={styles.offsetNumber}
                  data-bold={higherSide === "right"}
                >
                  {latestRight ? (
                    <RampUpNumber
                      value={latestRight.unit.mmHg}
                      duration={2}
                      delay={1}
                      ease={easeOutCubic}
                    />
                  ) : (
                    "--"
                  )}
                </span>
              }
              unit={
                <div className={styles.offsetContainer}>
                  {higherSide === "right" &&
                  rightOffset !== undefined &&
                  rightOffset !== 0 ? (
                    <PrefixedNumber value={rightOffset} precision={1} />
                  ) : null}
                  <span>R</span>
                </div>
              }
            />
          </span>
        ),
      },
    ];
  }, [left, right, status]);

  return (
    <div className={styles.EyePressure}>
      <BarRangeGraph entries={entries} thresholds={thresholds}>
        {({ bounds }) => {
          const latestLeft = left?.at(0)?.unit.mmHg;
          const latestRight = right?.at(0)?.unit.mmHg;

          if (!latestLeft || !latestRight || status !== "unbalanced") {
            return null;
          }

          const from = bounds.lerp(latestLeft) * 100;
          const to = bounds.lerp(latestRight) * 100;

          return (
            <svg
              className={styles.svg}
              strokeDasharray="2,3"
              viewBox="0 0 100 100"
            >
              <path
                d={`M5 ${100 - from}, 95 ${100 - to}`}
                stroke={ConnectorHighlightToColorMap["warning"]}
                fill="tranparent"
              />
            </svg>
          );
        }}
      </BarRangeGraph>
    </div>
  );
}
