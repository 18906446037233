import { ScheduleViewSlot } from "@cur8/rich-entity";
import { classNames } from "@pomle/classnames";
import { Interval } from "luxon";
import { MedicalExamsTags } from "./components/MedicalExamsTags";
import { SlotTime } from "./fragments/SlotTime";
import styles from "./styles.module.sass";
import { ViewRangeMode } from "../../hooks/useCalendarState";

interface Props {
  slot: ScheduleViewSlot;
  isAppointmentsCensored: boolean;
  viewRange: ViewRangeMode;
}

function toInterval(slot: ScheduleViewSlot): Interval {
  return Interval.fromDateTimes(slot.startTime, slot.endTime);
}

export function OpenSlot({ slot, isAppointmentsCensored, viewRange }: Props) {
  const supportedMedicalExams = slot.supportedMedicalExams;

  return (
    <div className={classNames(styles.open, styles.slot)}>
      <div className={styles.title}>Open</div>
      <div className={styles.subtitle}>
        <SlotTime timeWindow={toInterval(slot)} />
      </div>
      {!isAppointmentsCensored && (
        <>
          <MedicalExamsTags
            variant="light"
            medicalExams={supportedMedicalExams}
            viewRange={viewRange}
          />
        </>
      )}
    </div>
  );
}
