import { APITypesV1 } from "@cur8/api-client";
import { useCallback, useEffect, useState } from "react";
import { useAssessmentContext } from "render/pages/AssessmentPage/context/AssessmentContext";
import { TimeSpentTracker } from "../../../TimeSpentTracker";
import { CardioSignals } from "../../hooks/useCardioSignalsTimestamps";
import { calculateTimeRangeToDistanceMS } from "../../lib/calculations";
import { PulsewaveType } from "../../lib/types";
import { getPWADrange } from "../../lib/utils";
import { ECG_COLOR, LEFT_COLOR, RIGHT_COLOR } from "../shared/constants";
import { JSXResult } from "../shared/fragments";
import { GraphWithRuler } from "../shared/GraphWithRuler";

interface FootAsymmetryProps {
  signals: CardioSignals;
}

export function FootAsymmetry({ signals }: FootAsymmetryProps) {
  const { mutablePW, setPulseWaveData, type } = useAssessmentContext();

  const assessmentSelection = () => {
    if (!mutablePW) {
      return undefined;
    }
    return getPWADrange(mutablePW, PulsewaveType.footAsymmetry);
  };
  const [currentSelection, setCurrentSelection] = useState<
    APITypesV1.Range | undefined
  >(assessmentSelection);
  const [resultElement, setResultElement] = useState<JSX.Element | null>(
    JSXResult("", "Make a selection in the plot to measure delay")
  );

  useEffect(() => {
    if (!signals || !currentSelection) {
      setResultElement(
        JSXResult("", "Make a selection in the plot to measure delay")
      );
      return;
    }
  }, [currentSelection, signals]);

  const onSelectionRange = useCallback(
    (range: APITypesV1.Range, complete: boolean = false) => {
      let setRange: APITypesV1.Range | undefined = range;
      if (!range || range.to - range.from <= 0) {
        setRange = undefined;
      }
      setCurrentSelection(setRange);
      if (!setRange) {
        if (complete) {
          setPulseWaveData("footAsymmetry", undefined);
        }
        return;
      }
      // OK to round to ms
      const delay = Math.round(calculateTimeRangeToDistanceMS(setRange));
      setResultElement(JSXResult(`Delay: ${Math.round(delay)} ms`));
      if (complete && delay) {
        setPulseWaveData("footAsymmetry", {
          value: delay,
          range,
        });
      }
    },
    [setPulseWaveData]
  );

  return signals ? (
    <div>
      <GraphWithRuler
        data={[
          {
            label: "Cardiac Rhythm",
            signal: signals.bioamp_ecg,
            color: ECG_COLOR,
          },
          {
            label: "Left",
            signal: signals.piezo_foot_left,
            color: LEFT_COLOR,
          },
          {
            label: "Right",
            signal: signals.piezo_foot_right,
            color: RIGHT_COLOR,
          },
        ]}
        diffData={[
          {
            label: "Left Acceleration",
            signal: signals.piezo_foot_left_acceleration,
            color: LEFT_COLOR,
          },
          {
            label: "Right Acceleration",
            signal: signals.piezo_foot_right_acceleration,
            color: RIGHT_COLOR,
          },
        ]}
        onSelectionRange={onSelectionRange}
        selectionRange={currentSelection}
      />
      {resultElement}
      <TimeSpentTracker page={type} label="FootAsymmetry" />
    </div>
  ) : null;
}
