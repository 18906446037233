import { Table } from "render/ui/layouts/Table";
import { ResultRow } from "../ResultRow";
import { PatientEncyclopediaMetrics } from "../../hooks/usePatientEncyclopediaMetrics";
import { Country } from "lib/visit/visit";

interface BodyTableProps {
  metrics: PatientEncyclopediaMetrics;
  country: Country | undefined;
}

export function BodyTable({ metrics, country }: BodyTableProps) {
  const body = metrics.body;

  return (
    <Table>
      <thead>
        <tr>
          <th>Body</th>
          <th>Result</th>
          <th>
            Risk {country != null ? <>(based on {country})</> : undefined}
          </th>
          <th>Units</th>
        </tr>
      </thead>
      <tbody>
        <ResultRow
          caption="Weight"
          unit="kg"
          value={body.weight?.at(0)}
          format={(metric) => metric.unit.kilograms.toFixed(1)}
        />

        <ResultRow
          caption="Height"
          unit="cm"
          value={body.height?.at(0)}
          format={(metric) => (metric.unit.meters * 100).toFixed(1)}
        />

        <ResultRow
          caption="BMI"
          value={body.bmi?.at(0)}
          format={(metric) => metric.unit["kg/m2"].toFixed(1)}
          summarize={(metric) => {
            return {
              risk: metric.riskRanges.riskLevel,
            };
          }}
        />

        <ResultRow
          caption="Waist"
          unit="cm"
          value={body.waist?.at(0)}
          format={(metric) => (metric.unit.meters * 100).toFixed(1)}
          summarize={(metric) => {
            return {
              risk: metric.riskRanges.riskLevel,
            };
          }}
        />

        <ResultRow
          caption="Strength (L)"
          unit="kg"
          value={body.gripStrength.left?.at(0)}
          format={(metric) => metric.unit.kilograms.toFixed(1)}
        />

        <ResultRow
          caption="Strength (R)"
          unit="kg"
          value={body.gripStrength.right?.at(0)}
          format={(metric) => metric.unit.kilograms.toFixed(1)}
        />

        <ResultRow
          caption="Eye pressure (L)"
          unit="kg"
          value={body.eyePressure.left?.at(0)}
          format={(metric) => metric.unit.mmHg.toFixed(1)}
          summarize={(metric) => {
            return {
              risk: metric.riskRanges.riskLevel,
            };
          }}
        />
        <ResultRow
          caption="Eye pressure (R)"
          unit="kg"
          value={body.eyePressure.right?.at(0)}
          format={(metric) => metric.unit.mmHg.toFixed(1)}
          summarize={(metric) => {
            return {
              risk: metric.riskRanges.riskLevel,
            };
          }}
        />
      </tbody>
    </Table>
  );
}
