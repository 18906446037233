import { Interval } from "luxon";
import { useCalendarState } from "../../hooks/useCalendarState";
import { SlotTime } from "./fragments/SlotTime";
import styles from "./styles.module.sass";

interface Props {
  timeWindow: Interval;
}

export function ClosedSlot({ timeWindow }: Props) {
  const { calendarInteractionMode } = useCalendarState();

  if (calendarInteractionMode !== "editing") {
    return null;
  }

  return (
    <div className={styles.slot}>
      <div className={styles.title}>New slot</div>
      <div className={styles.subtitle}>
        <SlotTime timeWindow={timeWindow} />
      </div>
    </div>
  );
}
