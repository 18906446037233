import { Marker } from "render/ui/presentation/ChartMarker/Marker";
import { Typography } from "../Typography";
import { inRange } from "./inRange";
import { concatPercent, normalize } from "./lib/util";
import styles from "./styles.module.sass";
import { MarkerData, RangeData } from "./types";

interface RangeChartProps {
  ranges: RangeData[];
  values: MarkerData[];
  showEmpty?: boolean;
}

export function RangeChart({
  ranges,
  values,
  showEmpty = false,
}: RangeChartProps) {
  const sortedRanges = ranges.sort((a, b) => a.to - b.to);
  const gridTemplateColumns = sortedRanges
    .map((range) => `${range.width}fr`)
    .join(" ");

  if (ranges.length === 0 && showEmpty) {
    return (
      <div className={styles.RangeChart}>
        <div className={styles.Empty}></div>
      </div>
    );
  }

  return (
    <div
      className={styles.RangeChart}
      style={{
        gridTemplateColumns,
      }}
    >
      {sortedRanges.map((range, indexOfRange) => {
        const markersWithinRange = values.filter((marker) =>
          inRange(
            marker.value,
            {
              value: range.from,
              inclusive: range.fromIsInclusive,
            },
            {
              value: range.to,
              inclusive: range.toIsInclusive,
            }
          )
        );

        const hasPrimaryMarkerWithinRange = markersWithinRange.some(
          ({ variant }) =>
            variant === "primary" || variant === "primary-outlined"
        );

        return (
          <div className={styles.Container} key={indexOfRange}>
            <div
              className={styles.Range}
              data-variant={
                hasPrimaryMarkerWithinRange ? range.variant : "none"
              }
            >
              {markersWithinRange.map((marker, index) => {
                const ratioToTotal =
                  (marker.value - range.from) / (range.to - range.from);
                const shouldClampRight = ratioToTotal > 0.9;
                const makerPositionStyle = shouldClampRight
                  ? { right: 0 }
                  : {
                      left: concatPercent(
                        normalize(marker.value, {
                          min: range.from,
                          max: range.to,
                        }) * 100
                      ),
                    };

                return (
                  <div
                    className={styles.Marker}
                    style={makerPositionStyle}
                    key={index}
                  >
                    <Marker
                      variant={marker.variant}
                      highlight={
                        hasPrimaryMarkerWithinRange ? range.variant : "none"
                      }
                    />
                    <div className={styles.value} data-variant={range.variant}>
                      <Typography variant="label-m">{marker.label}</Typography>
                    </div>
                  </div>
                );
              })}
            </div>
            <div
              className={styles.RangeLabels}
              data-variant={
                hasPrimaryMarkerWithinRange ? range.variant : "none"
              }
            >
              <div className={styles.LeftLabel}>
                <Typography variant="label-m">{range.leftLabel}</Typography>
              </div>
              <div className={styles.MiddleLabel}>
                <Typography variant="label-m">{range.label}</Typography>
              </div>
              <div className={styles.RightLabel}>
                <Typography variant="label-m">{range.rightLabel}</Typography>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
