import { useCallback } from "react";
import { PatientName } from "render/fragments/patient/PatientName";
import { usePatient } from "render/hooks/api/usePatient";
import { useAsyncHandle } from "render/hooks/useAsyncHandle";
import { PopupDialog } from "render/ui/layouts/PopupDialog";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { HoverTextButton } from "render/ui/trigger/HoverTextButton";
import styles from "./styles.module.sass";

interface Props {
  patientId: string;
  onClose: () => void;
  onSuccess: () => void;
}

export function DeleteAnnotationConfirmationPopup({
  patientId,
  onClose,
  onSuccess,
}: Props) {
  const patient = usePatient(patientId);

  const handleDelete = useCallback(async () => {
    onSuccess();
    setTimeout(onClose, 200);
  }, [onSuccess, onClose]);

  const submitHandler = useAsyncHandle(handleDelete);

  return (
    <PopupDialog>
      <div className={styles.DeleteAnnotationConfirmationPopup}>
        <div className={styles.content}>
          <header>
            <h2>Lesion will be deleted</h2>
          </header>
          <section className={styles.body}>
            <p>Are you sure you want to delete this lesion?</p>
            <p>
              Deleting this lesion will also remove all associated notes and
              state history for the patient{" "}
              {patient ? <PatientName patient={patient} /> : patientId}.{" "}
            </p>
            <p>This action cannot be undone.</p>
          </section>
          <footer className={styles.footer}>
            <HoverTextButton type="button" onClick={onClose}>
              Cancel
            </HoverTextButton>

            <ActionButton disabled={submitHandler.busy} handle={submitHandler}>
              Delete Lesion
            </ActionButton>
          </footer>
        </div>
      </div>
    </PopupDialog>
  );
}
