import { RiskLabel, RiskLevel } from "@cur8/measurements";

export interface Range {
  min: number;
  max: number;
}

function fraction(numerator: number, denominator: number) {
  return numerator / denominator;
}

export class RiskMetricTool {
  public static generateUnknownInterval({ min, max }: Range) {
    const denominator = 8;
    const result = [
      {
        start: { value: min - fraction(min, denominator), inclusive: true },
        end: { value: max + fraction(max, denominator), inclusive: true },
        riskLevel: RiskLevel.Unknown,
        riskLabel: RiskLabel.Undefined,
      },
    ];
    return result;
  }
}
