import { APITypesV1 } from "@cur8/api-client";
import { Assessment, isPulseWaveAssessment, Sex } from "@cur8/rich-entity";
import { CreatePadAssessmentRequestBox } from "render/pages/AssessmentPage/context/AssessmentContext";
import {
  CreatePulseWaveAssessmentRequestBox,
  CreateThermalAssessmentRequestBox,
  LVETISelectionState,
  MutablePulseWaveAssessment,
  MutablePulseWaveLVETIAssessmentData,
  MutablePulseWaveLVETISelections,
} from "render/pages/AssessmentPage/context/AssessmentContext/types";
import { calculateLVETI } from "./calculations";
import { isCreatePWAssessment, PulsewaveType } from "./types";

/**
 * Find the start- and end-indexes in the timestamp array
 * that fits the time window
 */
export function findTimeIndexes(
  timestamps: number[],
  win: APITypesV1.Range
): APITypesV1.Range {
  // Find the first index >= target
  const findStartIndex = (array: number[], target: number): number => {
    let low = 0,
      high = array.length - 1;
    while (low < high) {
      const mid = Math.floor((low + high) / 2);
      if (array[mid] < target) {
        low = mid + 1;
      } else {
        high = mid;
      }
    }
    return array[low] >= target ? low : array.length;
  };

  // Find the last index <= target
  const findEndIndex = (array: number[], target: number): number => {
    let low = 0,
      high = array.length - 1;
    while (low < high) {
      const mid = Math.ceil((low + high) / 2);
      if (array[mid] > target) {
        high = mid - 1;
      } else {
        low = mid;
      }
    }
    return array[high] <= target ? high : -1;
  };

  const from = findStartIndex(timestamps, win.from);
  const to = findEndIndex(timestamps, win.to);

  if (from <= to && from < timestamps.length && to >= 0) {
    return { from, to };
  } else {
    // Fallback to full range
    return { from: 0, to: timestamps.length - 1 };
  }
}

/**
 * Make LVETI-calculations and return Selections
 */
export function assessmentToSelection(
  selections: APITypesV1.LVETIRangeData[],
  sex: Sex
): MutablePulseWaveLVETISelections {
  const sels = {} as MutablePulseWaveLVETISelections;
  for (let i = 0; i <= 2; i++) {
    sels[i] = calculateLVETI(selections[i].ssn, selections[i].rrInterval, sex);
  }
  return sels;
}

/**
 * Get selection from assessment (can be unsaved)
 */
export function getLvetiSelection(
  createAssessment:
    | CreatePadAssessmentRequestBox
    | CreatePulseWaveAssessmentRequestBox
    | CreateThermalAssessmentRequestBox,
  selected: Assessment
) {
  if (
    createAssessment &&
    isCreatePWAssessment(createAssessment) &&
    createAssessment.lveti?.selections
  ) {
    // Unsaved changes in Create assessment
    return createAssessment.lveti.selections;
  }
  if (
    selected &&
    isPulseWaveAssessment(selected) &&
    selected.lveti?.selections
  ) {
    // Latest saved assessment
    return selected.lveti.selections;
  }
  return undefined;
}

/**
 * Get PulseWave Assessment Data from either unsaved
 * createAssessment or from latest saved.
 */
export function getPWADrange(
  pwa: MutablePulseWaveAssessment,
  pwt: PulsewaveType
): APITypesV1.PulseWaveAssessmentData["range"] | undefined {
  if (pwa && pwa[pwt] && pwt !== PulsewaveType.lveti && pwa[pwt]?.range) {
    // Unsaved changes in Create assessment
    return pwa[pwt]?.range;
  }
  return undefined;
}

/**
 * Ranges for window (zoom dataplot)
 * Checks in unsaved data first, then saved assessment
 */
export function getRangesForWindow(
  pwa: MutablePulseWaveAssessment | undefined,
  pulsewaveType: PulsewaveType,
  lvetiIdx = 0
) {
  if (!pwa) {
    return undefined;
  }
  if (pulsewaveType !== PulsewaveType.lveti) {
    return getPWADrange(pwa, pulsewaveType);
  }
  if (pwa.lveti && pwa.lveti.selections) {
    return pwa.lveti.selections[lvetiIdx].rrInterval;
  }
}

/**
 * Checks if all measurements has been made for PWA
 */
export function hasAllMeasurements(
  pwa: MutablePulseWaveAssessment | undefined
) {
  if (
    pwa &&
    pwa.lveti &&
    pwa.pulseWaveVelocity &&
    pwa.footAsymmetry &&
    pwa.handAsymmetry
  ) {
    return true;
  }
  return false;
}

/**
 * Get the lowest state of LVETI-selections
 */
export function aggregatedLVETIState(
  lveti: MutablePulseWaveLVETIAssessmentData | undefined
): LVETISelectionState {
  if (!lveti) {
    return LVETISelectionState.empty;
  }
  const arrsels = Object.values(lveti.selections);
  if (arrsels.some((s) => s.state === LVETISelectionState.incorrect)) {
    return LVETISelectionState.incorrect;
  }
  if (arrsels.some((s) => s.state === LVETISelectionState.empty)) {
    return LVETISelectionState.empty;
  }
  if (arrsels.some((s) => s.state === LVETISelectionState.proposed)) {
    return LVETISelectionState.proposed;
  }
  return LVETISelectionState.reviewed;
}
