import { APITypesV1, APITypesV2 } from "@cur8/api-client";
import {
  DiscoverResult,
  ErrorResponse,
  ExposedError,
  IPaymentIntent,
  ISdkManagedPaymentIntent,
  InternetMethodConfiguration,
  Reader,
  Terminal,
  loadStripeTerminal,
} from "@stripe/terminal-js";
import { useCallback, useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useAppInsights } from "render/context/AppInsightsContext";
import { useConfig } from "render/context/ConfigContext";

const key_selected_terminal = "stripeSelectedTerminal";
const key_selected_location = "stripeSelectedLocation";

export type Location = {
  stripeId: string; // Stripe Location id
  label: string;
  address: string;
  country: string;
  countryCode: string;
};

export const availableLocations: Location[] = [
  {
    stripeId: "tml_FDzPTw9U6nArK0",
    label: "SE-ARN10",
    address: "Regeringsgatan 61B, 111 56 Stockholm",
    country: "Sweden",
    countryCode: "SE",
  },
  {
    stripeId: "tml_FePhrgsgxobbit",
    label: "SE-ARN12",
    address: "Sibyllegatan 35, 114 42 Stockholm",
    country: "Sweden",
    countryCode: "SE",
  },
  {
    stripeId: "tml_FkftSAwmuiwbkx",
    label: "GB-LHR01",
    address: "9 St Vincent St, W1U 4DB, London",
    country: "United Kingdom",
    countryCode: "GB",
  },
  {
    stripeId: "tml_F9YwNgy5RghrUx",
    label: "GB-LHR02",
    address: "1 Lamb St, E1 6EA, London",
    country: "United Kingdom",
    countryCode: "GB",
  },
];

export const hasExposedError = (exc: any): exc is ExposedError => !!exc.message;
export const hasErrorResponse = (exc: any): exc is ErrorResponse => !!exc.error;
export const hasDiscoverResult = (exc: any): exc is DiscoverResult =>
  !!exc.discoveredReaders;

export enum ConnectionStatus {
  NOT_CONNECTED = "not_connected",
  CONNECTED = "connected",
  CONNECTING = "connecting",
}

export enum ReaderStatus {
  Initial = "Initial",
  TerminalLoaded = "TerminalLoaded",
  ReadersLoaded = "ReadersLoaded",
  SelectReader = "SelectReader",
  ReaderConnected = "ReaderConnected",
  CollectPaymentMethod = "CollectPaymentMethod",
  PaymentProcessed = "PaymentProcessed",
  Completed = "Completed",
  Failure = "Failure",
}

type StripeISdkManagedPaymentIntent = {
  paymentIntent: ISdkManagedPaymentIntent;
};
type StripeIPaymentIntent = { paymentIntent: IPaymentIntent };

export function useStripeTerminal() {
  const config = useConfig();
  const appInsights = useAppInsights();
  const api = useAPIClient();

  const [readerStatus, setReaderStatus] = useState<ReaderStatus>(
    ReaderStatus.Initial
  );
  const [readerConnection, setReaderConnection] = useState<ConnectionStatus>(
    ConnectionStatus.NOT_CONNECTED
  );
  const [terminal, setTerminal] = useState<Terminal | undefined>(undefined);
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);
  const [readersAvailable, setReadersAvailable] = useState<Reader[]>([]);

  const unexpectedDisconnect = useCallback(() => {
    setErrorMsg("Disconnected from reader");
    setReaderConnection(ConnectionStatus.NOT_CONNECTED);
  }, []);

  const logFailure = useCallback(
    (err: ExposedError | unknown, msg: string = "") => {
      if (hasExposedError(err)) {
        msg = err.message;
      }
      setErrorMsg(msg);
      appInsights.trackException(err as any, { errorMsg: msg });
      console.warn("Failure: " + msg);
    },
    [appInsights, setErrorMsg]
  );

  const [location, setLocation] = useState(() => {
    const locId = localStorage.getItem(key_selected_location);
    const loc = availableLocations.find((al) => al.stripeId === locId);
    if (loc) {
      return loc;
    }

    return undefined;
  });

  const setLocationById = useCallback(
    (id: Location["stripeId"] | undefined) => {
      const loc = availableLocations.find((al) => al.stripeId === id);

      if (loc) {
        setLocation(loc);
        localStorage.setItem(key_selected_location, id!);
      } else {
        setLocation(undefined);
        localStorage.removeItem(key_selected_location);
      }
    },
    []
  );

  const setupTerminal = useCallback(() => {
    try {
      if (!location) {
        throw new Error("Country is not set before terminal setup");
      }
      loadStripeTerminal().then((term) => {
        if (term) {
          setReaderStatus(ReaderStatus.Initial);
          setReaderConnection(ConnectionStatus.NOT_CONNECTED);
          setErrorMsg(undefined);
          setReadersAvailable([]);

          setTerminal(
            term.create({
              onFetchConnectionToken: () => {
                return api.billingV2
                  .createStripeConnectionToken({
                    country: location.countryCode,
                  })
                  .result.then((token: APITypesV1.StripeConnectionSecret) => {
                    if (token.secret) {
                      return token.secret;
                    } else {
                      throw new Error("Invalid token returned");
                    }
                  });
              },
              onUnexpectedReaderDisconnect: unexpectedDisconnect,
            })
          );
        }
      });
    } catch (err) {
      logFailure(err, "Failed to load terminal");
    }
  }, [api.billingV2, location, unexpectedDisconnect, logFailure]);

  const [preferredTerminalId, setPreferredTerminalId] = useState(() => {
    try {
      return localStorage.getItem(key_selected_terminal);
    } catch (error) {
      console.error(error);
      return null;
    }
  });
  const setPreferredTerminal = useCallback((id: string | null) => {
    setPreferredTerminalId(id);
    if (id) {
      localStorage.setItem(key_selected_terminal, id);
    } else {
      localStorage.removeItem(key_selected_terminal);
    }
  }, []);

  const connectReader = useCallback(
    async (reader: Reader) => {
      if (!terminal) {
        console.warn("No terminal");
        return;
      }
      await terminal.disconnectReader();
      setReaderConnection(ConnectionStatus.CONNECTING);
      terminal.connectReader(reader).then((connectResult) => {
        if (hasErrorResponse(connectResult)) {
          logFailure(connectResult.error);
        } else {
          setReaderConnection(ConnectionStatus.CONNECTED);
          setReaderStatus(ReaderStatus.ReaderConnected);
          console.debug(`reader "${reader.label}" (${reader.id}) connected`);
        }
      });
    },
    [logFailure, setReaderConnection, setReaderStatus, terminal]
  );

  const disconnectReader = useCallback(async () => {
    if (!terminal) {
      return;
    }
    if (terminal.getConnectedReader()) {
      await terminal.disconnectReader();
    }
    setReaderConnection(ConnectionStatus.NOT_CONNECTED);
  }, [setReaderConnection, terminal]);

  const _useFakeReader = useCallback(() => {
    if (config.apiBaseUrl.endsWith("dev.cur8.co")) {
      return true;
    }
    return false;
  }, [config.apiBaseUrl]);

  const methodConfig = useCallback(() => {
    let imConfig = {} as InternetMethodConfiguration;
    if (_useFakeReader()) {
      console.warn("Using simulated reader - w fake card");
      imConfig = { simulated: true };
    } else {
      imConfig = { simulated: false };
    }
    if (location) {
      imConfig.location = location.stripeId;
    }
    console.debug("methodConfig", imConfig);
    return imConfig;
  }, [_useFakeReader, location]);

  const fetchReaders = useCallback(async () => {
    if (!terminal) {
      return;
    }
    const discRes = await terminal.discoverReaders(methodConfig());
    if (hasErrorResponse(discRes)) {
      logFailure(discRes.error);
    } else if (hasDiscoverResult(discRes)) {
      if (discRes.discoveredReaders.length <= 0) {
        logFailure("" as unknown, "No card readers found");
      }
      let readers = discRes.discoveredReaders;
      if (_useFakeReader()) {
        // Adding extra simulated readers
        console.warn("WARNING - duplicating readers");
        const duplicateFake = structuredClone(discRes.discoveredReaders);
        duplicateFake[0].id += "2";
        readers = [...discRes.discoveredReaders, ...duplicateFake];
      }
      console.debug("fetchReaders", readers);
      setReadersAvailable(readers);
      return readers;
    }
  }, [_useFakeReader, logFailure, methodConfig, terminal]);

  const setupReader = useCallback(
    async (terminalId?: string) => {
      if (!terminal) {
        return;
      }
      try {
        const preferredReader = terminalId ?? preferredTerminalId;
        const readers = await fetchReaders();
        if (readers && readers.length > 0) {
          const reader = readers.find((r) => r.id === preferredReader);
          if (reader) {
            await connectReader(reader); // Preferred reader
          } else if (readers.length === 1) {
            await connectReader(readers[0]); // Only one => select it
          } else {
            setReaderStatus(ReaderStatus.SelectReader);
          }
        }
        console.debug("setupReader", { preferredTerminalId, readers });
      } catch (err) {
        logFailure(err, "Failed to discover card readers");
      }
    },
    [terminal, fetchReaders, preferredTerminalId, connectReader, logFailure]
  );

  const capturePayment = useCallback(
    (patientId: string) => {
      return api.billingV2
        .capturePendingCardPayment(patientId)
        .result.then((token: APITypesV2.BookingTokenV2) => {
          setReaderStatus(ReaderStatus.Completed);
          console.debug("Payment captured, token: ", token);
          return token;
        })
        .catch((err) => {
          logFailure(err, "Failed to capture payment");
          return false;
        });
    },
    [api.billingV2, logFailure, setReaderStatus]
  );

  const collectPayment = useCallback(
    (patientId: string) => {
      if (!terminal || readerConnection === ConnectionStatus.NOT_CONNECTED) {
        return;
      }
      try {
        api.billing
          .getCardPaymentIntent(patientId)
          .result.then((intent: APITypesV1.StripePaymentIntent) => {
            if (!intent.clientSecret) {
              throw new Error("Failed to get intent for reader");
            }
            if (_useFakeReader()) {
              // Using fake card number
              terminal.setSimulatorConfiguration({
                testCardNumber: "4242424242424242",
              });
            }
            terminal
              .collectPaymentMethod(intent.clientSecret)
              .then((res: ErrorResponse | StripeISdkManagedPaymentIntent) => {
                if (hasErrorResponse(res)) {
                  const error = res.error;
                  if (error.code && error.code === "canceled") {
                    console.debug("Transaction cancelled");
                    return;
                  }
                  logFailure(error);
                } else {
                  const methodIntent = (res as StripeISdkManagedPaymentIntent)
                    .paymentIntent;
                  console.debug("terminal.collectPaymentMethod", intent);
                  setReaderStatus(ReaderStatus.CollectPaymentMethod);
                  terminal
                    .processPayment(methodIntent)
                    .then((res) => {
                      if (hasErrorResponse(res)) {
                        logFailure(res.error);
                      } else {
                        const paymentIntent = (res as StripeIPaymentIntent)
                          .paymentIntent;
                        console.debug("terminal.processPayment", paymentIntent);
                        setReaderStatus(ReaderStatus.PaymentProcessed);
                        capturePayment(patientId).then((res) => {
                          if (!res) {
                            setReaderStatus(ReaderStatus.Failure);
                          }
                        });
                      }
                    })
                    .catch((err) => {
                      logFailure(err, "Failed to process payment");
                    });
                }
              })
              .catch((err) => {
                logFailure(err);
              });
          });
      } catch (err) {
        logFailure(err);
      }
    },
    [
      terminal,
      readerConnection,
      api.billing,
      _useFakeReader,
      logFailure,
      capturePayment,
    ]
  );

  const cancelPayment = useCallback(async () => {
    if (!terminal) {
      return;
    }
    if (readerStatus === ReaderStatus.CollectPaymentMethod) {
      void terminal.cancelCollectPaymentMethod();
    }
    setReaderStatus(ReaderStatus.Initial);
    await disconnectReader();
  }, [disconnectReader, readerStatus, setReaderStatus, terminal]);

  // useEffect to update localStorage when terminal changes
  useEffect(() => {
    try {
      if (preferredTerminalId) {
        localStorage.setItem(key_selected_terminal, preferredTerminalId);
      } else {
        localStorage.removeItem(key_selected_terminal);
      }
    } catch (error) {
      console.error(error);
    }
  }, [preferredTerminalId]);

  // When location changes, we need to setup the terminal
  useEffect(() => {
    if (location) {
      setupTerminal();
    }
  }, [location, setupTerminal]);

  return {
    cancelPayment,
    collectPayment,
    disconnectReader,
    errorMsg,
    fetchReaders,
    location,
    logFailure,
    readerConnection,
    readerStatus,
    readersAvailable,
    setupReader,
    preferredTerminalId,
    setLocationById,
    setReaderStatus,
    setPreferredTerminal,
    terminal,
    _useFakeReader,
  };
}
