import { BodyAreas } from "lib/smpl";
import { toCurve } from "lib/svg";
import { toCenter } from "lib/three";
import { useEffect, useMemo, useState } from "react";
import { ScreenProjector } from "render/hooks/three/useProject";
import { useScreenAnchorsForObjects } from "render/hooks/three/useScreenAnchors";
import { useDashboardContext } from "render/pages/DashboardPage/context/DashboardContext";
import { Layer } from "render/pages/DashboardPage/types";
import styles from "./styles.module.sass";

const ACTIVE = new Set([Layer.Cardio, Layer.Circulation, Layer.ArterialHealth]);

interface CardioVascularSystemLayerProps {
  layer: Layer;
  areas: BodyAreas;
  projector: ScreenProjector;
}

export function CardioVascularSystemLayer({
  layer,
  areas,
  projector,
}: CardioVascularSystemLayerProps) {
  const { ui } = useDashboardContext();

  const positions = useMemo(() => {
    const pos = {
      heart: toCenter(areas.heart),

      leftShoulder: toCenter(areas.leftShoulder),
      leftElbow: toCenter(areas.leftElbow),
      leftCollarbone: toCenter(areas.leftCollarbone),
      leftHand: toCenter(areas.leftHand),
      leftAnkle: toCenter(areas.leftAnkle),
      leftHip: toCenter(areas.leftHip),
      leftToe: toCenter(areas.leftToe),
      leftKnee: toCenter(areas.leftKnee),
      leftFoot: toCenter(areas.leftFoot),
      leftHead: toCenter(areas.leftEye),

      rightShoulder: toCenter(areas.rightShoulder),
      rightElbow: toCenter(areas.rightElbow),
      rightCollarbone: toCenter(areas.rightCollarbone),
      rightHand: toCenter(areas.rightHand),
      rightAnkle: toCenter(areas.rightAnkle),
      rightHip: toCenter(areas.rightHip),
      rightToe: toCenter(areas.rightToe),
      rightKnee: toCenter(areas.rightKnee),
      rightFoot: toCenter(areas.rightFoot),
      rightHead: toCenter(areas.rightEye),
    };

    pos.leftCollarbone.x -= 35;
    pos.rightCollarbone.x += 35;
    pos.leftHead.x += 10;
    pos.leftHead.z -= 100;
    pos.rightHead.x -= 5;
    pos.rightHead.z -= 100;

    return pos;
  }, [areas]);

  const active = ACTIVE.has(layer);

  const [run, setRun] = useState(false);

  useEffect(() => {
    if (!active) {
      setRun(false);
      return;
    }

    const timer = setTimeout(setRun, 2500, active);

    return () => {
      clearTimeout(timer);
    };
  }, [active]);

  const anchors = useScreenAnchorsForObjects(projector, positions, run);

  return (
    <div className={styles.CVSLayer}>
      <svg data-layer={run ? ui.layer : "none"}>
        {/*[
          anchors.heart,

          anchors.leftCollarbone,
          anchors.leftElbow,
          anchors.leftFoot,
          anchors.leftHand,
          anchors.leftHip,
          anchors.leftKnee,
          anchors.leftShoulder,
          anchors.leftToe,
          anchors.leftHead,

          anchors.rightCollarbone,
          anchors.rightElbow,
          anchors.rightFoot,
          anchors.rightHand,
          anchors.rightHip,
          anchors.rightKnee,
          anchors.rightShoulder,
          anchors.rightToe,
          anchors.rightHead,
        ]
          .slice(0, 0)
          .map((point, index) => {
            return (
              <circle key={index} cx={point.x} cy={point.y} r={3} fill="none" />
            );
          })*/}

        <g className={styles.bloodVessels}>
          {anchors && (
            <>
              <path
                className={styles.artery}
                strokeWidth={4}
                d={toCurve(
                  { x: 1, y: 0 },
                  anchors.rightCollarbone,
                  { x: 1, y: 0.4 },
                  anchors.heart,
                  { x: -0.2, y: 0.8 },
                  anchors.rightHip
                )}
              />

              <path
                className={styles.artery}
                strokeWidth={2}
                d={toCurve(
                  { x: 1, y: 0.4 },
                  anchors.rightHip,
                  { x: -0.4, y: 0.5 },
                  anchors.rightKnee,
                  { x: 0, y: 0.4 },
                  anchors.rightAnkle
                )}
              />

              <path
                className={styles.artery}
                strokeWidth={2}
                d={toCurve(
                  { x: 0.5, y: 0.5 },
                  anchors.heart,
                  { x: 1, y: 0 },
                  anchors.rightHead
                )}
              />

              <path
                className={styles.vein}
                strokeWidth={2}
                d={toCurve(
                  { x: 0.5, y: 0.5 },
                  anchors.heart,
                  { x: 1.4, y: 0 },
                  anchors.rightHead
                )}
              />

              <path
                className={styles.artery}
                strokeWidth={2}
                d={toCurve(
                  { x: 0.6, y: 0.2 },
                  anchors.rightElbow,
                  { x: -0.1, y: 1.1 },
                  anchors.rightCollarbone
                )}
              />

              <path
                className={styles.vein}
                strokeWidth={2}
                d={toCurve(
                  { x: 0.1, y: 0.2 },
                  anchors.rightHand,
                  { x: -0.1, y: 0.4 },
                  anchors.rightElbow
                )}
              />

              <path
                className={styles.artery}
                strokeWidth={1}
                d={toCurve(
                  { x: 0, y: 0.1 },
                  anchors.rightHand,
                  { x: 0.4, y: 0.5 },
                  anchors.rightElbow,
                  { x: 0.1, y: 0.8 },
                  anchors.rightShoulder,
                  { x: 0.9, y: 2 },
                  anchors.rightCollarbone,
                  { x: 1, y: 0.4 },
                  anchors.heart,
                  { x: 0.2, y: 0.4 },
                  anchors.rightHip,
                  { x: 1, y: 1 },
                  anchors.rightAnkle,
                  { x: -0.1, y: 0.2 },
                  anchors.rightFoot
                )}
              />

              <path
                className={styles.artery}
                strokeWidth={0.4}
                d={toCurve(
                  { x: 0.1, y: 0.1 },
                  anchors.rightHand,
                  { x: 0.6, y: 0.5 },
                  anchors.rightElbow,
                  { x: 0.4, y: 0.8 },
                  anchors.rightShoulder,
                  { x: 0.9, y: 2 },
                  anchors.rightCollarbone,
                  { x: 1, y: 0 },
                  anchors.heart,
                  { x: -0.1, y: 0.4 },
                  anchors.rightHip,
                  { x: 1, y: 1 },
                  anchors.rightAnkle,
                  { x: -0.1, y: 0.2 },
                  anchors.rightFoot
                )}
              />

              <path
                className={styles.vein}
                strokeWidth={2}
                d={toCurve(
                  { x: 0.4, y: 0.2 },
                  anchors.rightElbow,
                  { x: 0.2, y: 0.8 },
                  anchors.rightShoulder,
                  { x: 1, y: 1.2 },
                  anchors.rightCollarbone,
                  { x: 1, y: 0 },
                  anchors.heart,
                  { x: -0.1, y: 0.6 },
                  anchors.rightHip,
                  { x: 0.1, y: 0.8 },
                  anchors.rightKnee
                )}
              />

              <path
                className={styles.vein}
                strokeWidth={1}
                d={toCurve(
                  { x: -0.1, y: 0.5 },
                  anchors.rightHip,
                  { x: -0.1, y: 0.8 },
                  anchors.rightFoot
                )}
              />

              <path
                className={styles.artery}
                strokeWidth={4}
                d={toCurve(
                  { x: 1, y: 0 },
                  anchors.leftCollarbone,
                  { x: 1, y: 0.3 },
                  anchors.heart,
                  { x: -0.2, y: 0.8 },
                  anchors.leftHip
                )}
              />

              <path
                className={styles.artery}
                strokeWidth={2}
                d={toCurve(
                  { x: 1.5, y: 0.5 },
                  anchors.heart,
                  { x: 1.8, y: 0 },
                  anchors.leftHead
                )}
              />

              <path
                className={styles.vein}
                strokeWidth={2}
                d={toCurve(
                  { x: 1.5, y: 0.5 },
                  anchors.heart,
                  { x: 1, y: 0 },
                  anchors.leftHead
                )}
              />

              <path
                className={styles.artery}
                strokeWidth={2}
                d={toCurve(
                  { x: 0.4, y: 0.4 },
                  anchors.leftHip,
                  { x: -0.2, y: 0.5 },
                  anchors.leftKnee,
                  { x: 0, y: 0.4 },
                  anchors.leftAnkle
                )}
              />

              <path
                className={styles.artery}
                strokeWidth={2}
                d={toCurve(
                  { x: 0.6, y: 0.2 },
                  anchors.leftElbow,
                  { x: -0.1, y: 1.1 },
                  anchors.leftCollarbone
                )}
              />

              <path
                className={styles.vein}
                strokeWidth={1}
                d={toCurve(
                  { x: 0.1, y: 0.2 },
                  anchors.leftHand,
                  { x: -0.1, y: 0.4 },
                  anchors.leftElbow
                )}
              />

              <path
                className={styles.artery}
                strokeWidth={0.4}
                d={toCurve(
                  { x: 0, y: 0.1 },
                  anchors.leftHand,
                  { x: 0.4, y: 0.5 },
                  anchors.leftElbow,
                  { x: 0.1, y: 0.8 },
                  anchors.leftShoulder,
                  { x: 0.9, y: 1 },
                  anchors.leftCollarbone,
                  { x: 1, y: 0 },
                  anchors.heart,
                  { x: 0.2, y: 0.2 },
                  anchors.leftHip,
                  { x: 1, y: 1 },
                  anchors.leftAnkle,
                  { x: -0.1, y: 0.2 },
                  anchors.leftFoot
                )}
              />

              <path
                className={styles.artery}
                strokeWidth={1}
                d={toCurve(
                  { x: 0, y: 0.3 },
                  anchors.leftHand,
                  { x: 0.8, y: 0.5 },
                  anchors.leftElbow,
                  { x: 0.2, y: 0.8 },
                  anchors.leftShoulder,
                  { x: 1, y: 1 },
                  anchors.leftCollarbone,
                  { x: 1.1, y: 0 },
                  anchors.heart,
                  { x: -0.1, y: 0.2 },
                  anchors.leftHip,
                  { x: 1.2, y: 1 },
                  anchors.leftAnkle,
                  { x: -0.1, y: 0.2 },
                  anchors.leftFoot
                )}
              />

              <path
                className={styles.vein}
                strokeWidth={2}
                d={toCurve(
                  { x: 0.4, y: 0.2 },
                  anchors.leftElbow,
                  { x: 0.2, y: 0.8 },
                  anchors.leftShoulder,
                  { x: 1, y: 1 },
                  anchors.leftCollarbone,
                  { x: 1.2, y: 0.2 },
                  anchors.heart,
                  { x: -0.1, y: 0.6 },
                  anchors.leftHip,
                  { x: 0.1, y: 0.8 },
                  anchors.leftKnee
                )}
              />

              <path
                className={styles.vein}
                strokeWidth={1}
                d={toCurve(
                  { x: -0.1, y: 0.5 },
                  anchors.leftHip,
                  { x: -0.1, y: 0.8 },
                  anchors.leftFoot
                )}
              />
            </>
          )}
        </g>
      </svg>
    </div>
  );
}
