import { RiskLevel } from "@cur8/measurements";
import { RiskMetric } from "lib/metric";
import { useMemo } from "react";
import { MetricRange } from "render/ui/presentation/MetricRange";
import {
  selectChartRanges,
  toChartRanges,
} from "render/ui/presentation/RangeChart/lib/risk";

interface HbA1cRangeProps {
  metrics: RiskMetric<"bloodwork.hba1c">[];
}

export function HbA1cRange({ metrics }: HbA1cRangeProps) {
  const latest = metrics.at(0);

  const entries = useMemo(() => {
    return metrics.map((metric) => {
      return {
        risk: metric.riskRanges.riskLevel,
        timestamp: metric.measurement.timestampStart,
        value: metric.unit["mmol/mol"],
      };
    });
  }, [metrics]);

  // https://dev.azure.com/cur8/Engineering/_workitems/edit/26137
  const ranges = useMemo(() => {
    const riskIntervals = latest?.riskRanges.riskIntervals ?? [];
    const chartRanges = toChartRanges(riskIntervals);

    return selectChartRanges(chartRanges, {
      mode: "static",
      startAt: RiskLevel.Optimal,
      endAt: RiskLevel.HighRisk,
    });
  }, [latest?.riskRanges.riskIntervals]);

  // https://dev.azure.com/cur8/Engineering/_workitems/edit/26137
  const rangesCapLow = 30;
  const rangesCapHigh = 50;

  return (
    <MetricRange
      title="Long-term blood sugar"
      subtitle="[HbA1c]"
      unit="mmol/mol"
      entries={entries}
      ranges={ranges}
      rangesCapLow={rangesCapLow}
      rangesCapHigh={rangesCapHigh}
      formatValue={(value) => value?.toFixed(2)}
      showEmptyRanges
    />
  );
}
