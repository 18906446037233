import { TOGGLE_LINK_COMMAND } from "@lexical/link";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useCallback, useEffect, useRef, useState } from "react";
import { AnchorPopUp } from "./components/AnchorPopUp";

import { SectionMeasurements } from "../../../SummaryEditor/sections";
import { stripLeftRight } from "../../../SummaryEditor/useSectionMeasurements";
import styles from "./LinkTooltip.module.sass";
import LinkIcon from "./assets/link.svg?react";
import { MeasurementPreview } from "./components/MeasurementPreview";
import {
  LinkInfo,
  SelectionActionPlugin,
} from "./components/SelectionActionPlugin";

interface LinkTooltipProps {
  measurements: SectionMeasurements;
  extraLinks: LinkInfo[];
  isActive: boolean;
  children: React.ReactNode;
}

function isMouseInside(event: React.MouseEvent, rect: DOMRect | null): boolean {
  const m = 8; // add margin to add some tolerance
  return (
    rect !== null &&
    event.clientX >= rect.left - m &&
    event.clientX <= rect.right + m &&
    event.clientY >= rect.top - m &&
    event.clientY <= rect.bottom + m
  );
}

function hasSelection() {
  const selection = window.getSelection();
  if (selection && !selection.isCollapsed) {
    return true;
  }
  return false;
}

export function LinkTooltip({
  measurements,
  extraLinks,
  isActive,
  children,
}: LinkTooltipProps) {
  const divRef = useRef<HTMLDivElement>(null);
  const [tooltip, setTooltip] = useState<React.ReactNode | null>(null);
  const [closePop, setClosePop] = useState<React.ReactNode | null>(null);

  const [editor] = useLexicalComposerContext();

  const resolveName = useCallback(
    (pathName: string) => {
      const name = pathName.split("/").filter(Boolean).pop();

      const m = measurements.current.find(
        (m) => m.metricName && stripLeftRight(m.metricName) === name
      );
      if (m) {
        return m.friendlyName;
      } else {
        return name;
      }
    },
    [measurements]
  );

  const handleMouseOver = useCallback(
    (event: React.MouseEvent) => {
      if (hasSelection() || closePop) {
        setTooltip(null);
        return;
      }

      if (!(event.target instanceof HTMLElement)) {
        return;
      }

      const anchorNode = event.target.closest("a");
      if (!anchorNode || !divRef.current) {
        return;
      }

      const linkedMeasurementName = anchorNode.pathname
        .split("/")
        .filter(Boolean)
        .pop();

      if (!linkedMeasurementName) {
        return;
      }

      setTooltip(
        <AnchorPopUp
          isLight={true}
          isUpper={false}
          parent={divRef.current}
          anchorRect={anchorNode.getBoundingClientRect()}
        >
          <MeasurementPreview
            measurementName={linkedMeasurementName}
            measurements={measurements}
          />
        </AnchorPopUp>
      );
    },
    [closePop, measurements]
  );

  const handleMouseOut = useCallback((event: React.MouseEvent) => {
    if (event.target instanceof HTMLElement) {
      const anchorNode = event.target.closest("a");
      if (anchorNode) {
        setTooltip(null);
      }
    }
  }, []);

  const handleRemoveLink = useCallback(() => {
    editor.update(() => {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    });
    setClosePop(null);
  }, [editor]);

  const handleMouseClick = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      if (hasSelection()) {
        setTooltip(null);
        setClosePop(null);
        return;
      }

      if (!(event.target instanceof HTMLElement)) {
        return;
      }

      const anchorNode = event.target.closest("a");
      if (!anchorNode || !divRef.current) {
        return;
      }

      const rect = anchorNode.getBoundingClientRect();
      if (isMouseInside(event, rect)) {
        setTooltip(null);
        setClosePop(
          <AnchorPopUp
            isLight={false}
            isUpper={false}
            parent={divRef.current}
            anchorRect={anchorNode.getBoundingClientRect()}
          >
            <div className={styles.RemoveLink}>
              <div className={styles.label}>
                <LinkIcon />
                {resolveName(anchorNode.pathname)}
              </div>
              <div className={styles.divider} />
              <button onClick={handleRemoveLink} className={styles.button}>
                Remove
              </button>
            </div>
          </AnchorPopUp>
        );
        return;
      }

      setClosePop(null);
    },
    [handleRemoveLink, resolveName]
  );

  useEffect(() => {
    if (!isActive) {
      setClosePop(null);
    }
  }, [isActive]);

  return (
    <div
      ref={divRef}
      onMouseOver={handleMouseOver}
      onMouseOutCapture={handleMouseOut}
      onClick={handleMouseClick}
    >
      {children}
      {tooltip}
      <>
        {closePop}
        {isActive && (
          <SelectionActionPlugin
            parent={divRef.current}
            measurements={measurements.current}
            extraLinks={extraLinks}
          />
        )}
      </>
    </div>
  );
}
