import { ScheduleViewSlot } from "@cur8/rich-entity";
import { classNames } from "@pomle/classnames";
import { DateTime, Interval } from "luxon";
import { useMemo } from "react";
import { useVisitHistoryQuery } from "render/hooks/queries/useVisitHistoryQuery";
import { useVisitQuery } from "render/hooks/queries/useVisitQuery";
import { ViewRangeMode } from "../../hooks/useCalendarState";
import { MedicalExamsTags } from "./components/MedicalExamsTags";
import { SlotTime } from "./fragments/SlotTime";
import { getVisitOrdinal } from "./lib/getVisitOrdinal";
import { ExamReasonLabel, getVisitReasonType } from "./lib/getVisitType";
import styles from "./styles.module.sass";

function toInterval(slot: ScheduleViewSlot): Interval {
  return Interval.fromDateTimes(slot.startTime, slot.endTime);
}

interface Props {
  slot: ScheduleViewSlot;
  isAppointmentsCensored: boolean;
  viewRange: ViewRangeMode;
}

function BodyScanLabel({
  patientId,
  visitId,
  slot,
}: {
  slot: ScheduleViewSlot;
  visitId?: string;
  patientId?: string;
}) {
  const visitHistoryQuery = useVisitHistoryQuery({ patientId });
  const visitOrdinal = useMemo(() => {
    return visitHistoryQuery.data == null || !visitId
      ? undefined
      : getVisitOrdinal(visitHistoryQuery.data, {
          visitId,
          date: slot.startTime,
        });
  }, [visitHistoryQuery, visitId, slot.startTime]);

  return <>Body scan: [{visitOrdinal}]</>;
}

export function BookedSlot({ slot, isAppointmentsCensored, viewRange }: Props) {
  const patientId = slot.patientId;
  const visitInfo = slot.visitInfo;
  const visitQuery = useVisitQuery({ patientId, visitId: visitInfo?.visitId });

  const visitReasonType = useMemo(() => {
    return !visitInfo?.medicalExams || !visitQuery.data
      ? undefined
      : getVisitReasonType(visitQuery.data, visitInfo.medicalExams);
  }, [visitInfo?.medicalExams, visitQuery.data]);

  const backgroundStyles = useMemo(() => {
    const now = DateTime.now();
    if (slot.startTime > now) {
      return styles.future;
    }
    if (slot.endTime < now) {
      return styles.past;
    }

    return styles.present;
  }, [slot.startTime, slot.endTime]);

  if (!slot.id) {
    return <div className={styles.busy}></div>;
  }

  const showOnboardingNotCompleted =
    slot.patientDetails &&
    !slot.patientDetails.finishedOnboarding &&
    !isAppointmentsCensored;

  const showIdentityNotVerified =
    slot.patientDetails?.hasVerifiedIdentity === false &&
    !isAppointmentsCensored;

  return (
    <div
      data-patient-id={patientId}
      data-visit-id={visitInfo?.visitId}
      data-slot-id={slot.id}
      className={classNames(styles.slot, backgroundStyles)}
    >
      <div className={styles.notifications}>
        {visitReasonType === ExamReasonLabel.BodyScan ? (
          <>
            <div
              className={styles.onboardingAttention}
              data-show={showOnboardingNotCompleted}
              title="Onboarding not completed"
            />
            <div
              className={styles.identityAttention}
              data-show={showIdentityNotVerified}
              title="Identity not verified"
            />
          </>
        ) : null}
      </div>
      <div className={styles.title}>
        {isAppointmentsCensored || !slot.patientDetails?.displayName
          ? "Booked"
          : slot.patientDetails.displayName}
      </div>
      <div className={styles.subtitle}>
        {isAppointmentsCensored ? (
          "Appointment"
        ) : (
          <SlotTime timeWindow={toInterval(slot)} />
        )}
      </div>
      <div data-visit-type={visitReasonType} className={styles.subtitle}>
        {visitReasonType != null && !isAppointmentsCensored && (
          <>
            {visitReasonType === ExamReasonLabel.BodyScan && (
              <BodyScanLabel
                patientId={patientId}
                visitId={visitInfo?.visitId}
                slot={slot}
              />
            )}
            {visitReasonType === ExamReasonLabel.FollowUp && <>Follow-up</>}
            {visitReasonType === ExamReasonLabel.NoShow && <>No show</>}
            {visitReasonType === ExamReasonLabel.Canceled && <>Canceled</>}
          </>
        )}
      </div>

      {visitReasonType === ExamReasonLabel.FollowUp &&
        !isAppointmentsCensored && (
          <MedicalExamsTags
            variant="dark"
            medicalExams={visitInfo?.medicalExams}
            viewRange={viewRange}
          />
        )}
    </div>
  );
}
