import { APITypesV1 } from "@cur8/api-client";
import { ImmutableScan } from "@cur8/rich-entity";
import { useCreateLLMScan } from "lib/doctor-scribe/useCreateLLMScan";
import { useLLMConsent } from "lib/doctor-scribe/useLLMConsent";
import { useCallback, useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { HoverTextButton } from "render/ui/trigger/HoverTextButton";
import { Chat } from "../Chat";
import { Divider } from "../Divider";
import { RightDrawer } from "../RightDrawer";
import { SummaryDetails } from "../SummaryDetails";
import AIIcon from "./ai.svg?react";
import styles from "./style.module.sass";
import { useLLMScan } from "./useLLMScan";
import {
  EMPTY_MEMBER_SUMMARY,
  EMPTY_SECTIONS,
  hasSummaryComplete,
  scanAgeMinutes,
  ScribeFlavor,
} from "./utils";

interface ScribeProps {
  patientId: string;
  visitId: string;
  flavor: ScribeFlavor;
  showDetailsOnly: boolean;
  currentEditorSections: APITypesV1.SummarySections | undefined;
  onFlavor: (flavor: ScribeFlavor) => void;
  onSummary: (summary: APITypesV1.SummarySections) => void;
  onSummaryLanguage: (language: string | null) => void;
  onScan: (scan: ImmutableScan) => void;
  onDrawer: (drawerVisible: boolean) => void;
}

enum State {
  init = "init",
  loading = "loading",
  processing = "processing",
  noConsent = "noConsent",
  hasNoSummary = "hasNoSummary",
  hasSummary = "hasSummary",
}

const MIN_RETRIGGER_MINUTES = 2;

export function Scribe({
  patientId,
  visitId,
  flavor,
  showDetailsOnly,
  currentEditorSections,
  onFlavor,
  onSummary,
  onSummaryLanguage,
  onScan,
  onDrawer,
}: ScribeProps) {
  const api = useAPIClient();
  const llmScan = useLLMScan(patientId, visitId);
  const {
    triggerMemberSummaryGenerationWithoutTranscription,
    latestLLMScan: scan,
    reloadLatestLLMScan,
    pollForNewScan,
    fetchGeneratedContent,
    fetchTranscription,
  } = llmScan;
  const { createLLMScan } = useCreateLLMScan();
  const [state, setState] = useState<State>(State.init);
  const [generatedContent, setGeneratedContent] =
    useState<APITypesV1.MemberSummary>(EMPTY_MEMBER_SUMMARY);
  const [showDetails, setShowDetails] = useState(false);
  const [showImprove, setShowImprove] = useState(false);

  const { canUseSummaries } = useLLMConsent(patientId);

  const handleGenerateNew = useCallback(async () => {
    createLLMScan(patientId).then(async (r) => {
      if (r) {
        triggerMemberSummaryGenerationWithoutTranscription(r).then(
          reloadLatestLLMScan
        );
      }
    });
  }, [
    patientId,
    createLLMScan,
    triggerMemberSummaryGenerationWithoutTranscription,
    reloadLatestLLMScan,
  ]);

  useEffect(() => {
    // Has consent?
    if (state === State.noConsent) {
      return;
    }

    if (canUseSummaries === false) {
      onSummary(EMPTY_SECTIONS);
      setState(State.noConsent);
      return;
    }

    // Are we still crunching?
    if (
      state !== State.processing &&
      scan &&
      (scan.state === APITypesV1.ScanState.Inprogress ||
        scan.state === APITypesV1.ScanState.Available) &&
      !hasSummaryComplete(scan)
    ) {
      pollForNewScan().then(() => {
        reloadLatestLLMScan();
      });
      setState(State.processing);
      return;
    }

    // Load generated content if exists
    if (
      state !== State.loading &&
      state !== State.hasSummary &&
      scan &&
      hasSummaryComplete(scan)
    ) {
      setState(State.loading);
      fetchGeneratedContent(scan).then((newContent) => {
        setState(State.hasSummary);
        if (newContent && newContent.sections) {
          setState(State.hasSummary);
          setGeneratedContent(newContent);
          onSummary(newContent.sections);
        } else {
          setState(State.hasNoSummary);
        }
        fetchTranscription(scan).then((newTranscription) => {
          onSummaryLanguage(newTranscription?.language || null);
        });
      });
      return;
    }

    if (scan === null) {
      setState(State.hasNoSummary);
      return;
    }
  }, [
    api,
    scan,
    onSummary,
    onSummaryLanguage,
    fetchGeneratedContent,
    fetchTranscription,
    pollForNewScan,
    reloadLatestLLMScan,
    state,
    canUseSummaries,
    showDetailsOnly,
    flavor,
  ]);

  useEffect(() => {
    if (scan) {
      onScan(scan);
    }
  }, [scan, onScan]);

  useEffect(() => {
    if (showDetails || showImprove) {
      onDrawer(true);
    } else {
      onDrawer(false);
    }
  }, [showDetails, showImprove, onDrawer]);

  function handleStartOver() {
    if (generatedContent.sections) {
      onSummary(generatedContent.sections);
    }
  }

  function renderComponent(state: State, showDetailsOnly: boolean) {
    switch (state) {
      case State.noConsent:
        return (
          <div className={styles.status}>
            <AIIcon className={styles.iconTwinkleNotAvailable} />
            <label>
              Can't use automatic summaries since the member has not given
              consent
            </label>
          </div>
        );
      case State.loading:
        return (
          <>
            <div className={styles.status}>
              <AIIcon className={styles.iconSpin} />
              <label>Scribe loading ...</label>
            </div>
            {scan && scanAgeMinutes(scan) > MIN_RETRIGGER_MINUTES ? (
              <button className={styles.linkButton} onClick={handleGenerateNew}>
                Re-trigger summary generation
              </button>
            ) : null}
          </>
        );
      case State.processing:
        return (
          <>
            <div className={styles.status}>
              <AIIcon className={styles.iconSpin} />
              <label>Scribe is generating a new summary ...</label>
            </div>
            {scan && scanAgeMinutes(scan) > MIN_RETRIGGER_MINUTES ? (
              <button className={styles.linkButton} onClick={handleGenerateNew}>
                Re-trigger summary generation
              </button>
            ) : null}
          </>
        );
      case State.hasNoSummary:
        return (
          <>
            <div className={styles.status}>
              <AIIcon className={styles.iconTwinkleNotAvailable} />
              <label>
                No pre-generated summary available (no audio transcription from
                a recent de-brief)
              </label>
            </div>
            <button className={styles.linkButton} onClick={handleGenerateNew}>
              Generate summary
            </button>
          </>
        );
      case State.hasSummary:
        return showDetailsOnly ? (
          <>
            <div className={styles.scribeAvailable}>
              <div className={styles.status}>
                <AIIcon className={styles.iconTwinkleNotAvailable} />
              </div>
              <button
                className={styles.linkButton}
                onClick={() => {
                  if (showImprove) {
                    setShowImprove(false);
                  }
                  setShowDetails((prev) => !prev);
                }}
              >
                View scribe details
              </button>
            </div>
          </>
        ) : (
          <>
            <div className={styles.scribeAvailable}>
              <div className={styles.status}>
                <AIIcon className={styles.iconTwinkle} />
                <label>Scribe available</label>
              </div>
              <Divider />
              <button
                className={styles.linkButton}
                onClick={() => {
                  if (showImprove) {
                    setShowImprove(false);
                  }
                  setShowDetails((prev) => !prev);
                }}
              >
                Details
              </button>
              <button
                className={styles.linkButton}
                onClick={() => {
                  if (showDetails) {
                    setShowDetails(false);
                  }
                  setShowImprove((prev) => !prev);
                }}
              >
                Improve
              </button>
            </div>

            <div className={styles.buttons}>
              <HoverTextButton
                onClick={() => onFlavor(ScribeFlavor.Tuned)}
                active={flavor === ScribeFlavor.Tuned}
              >
                Generated
              </HoverTextButton>
              <HoverTextButton
                onClick={() => onFlavor(ScribeFlavor.None)}
                active={flavor === ScribeFlavor.None}
              >
                Custom
              </HoverTextButton>
            </div>
          </>
        );
    }
  }

  return (
    <div
      className={styles.Scribe}
      data-state={state}
      data-showdetailsonly={showDetailsOnly}
    >
      {renderComponent(state, showDetailsOnly)}
      <RightDrawer
        title="Pre-generated summary details"
        active={showDetails}
        onClose={() => setShowDetails((prev) => !prev)}
      >
        <SummaryDetails
          patientId={patientId}
          memberSummary={generatedContent}
        />
      </RightDrawer>
      <RightDrawer
        title=""
        active={showImprove}
        onClose={() => setShowImprove((prev) => !prev)}
      >
        {currentEditorSections ? (
          <Chat
            currentEditorSections={currentEditorSections}
            onSectionsUpdated={onSummary}
            onReset={handleStartOver}
          />
        ) : null}
      </RightDrawer>
    </div>
  );
}
