import { useCallback, useEffect, useMemo, useState } from "react";
import { useMSAL } from "render/context/MSALContext";
import {
  DeviceCalendarPreference,
  createDeviceCalendarPreference,
  userDeviceCalendarPreferenceStorageValidator,
} from "./lib/calendarPreference";
import { useLiveTime } from "../useLiveTime";
import { listen } from "lib/listen";

function useCalendarSettings() {
  const msal = useMSAL();
  const username = msal.auth?.account?.username;
  const key = username ? `${username}-'calendar-settings` : undefined;
  const [storageData, setStorageData] = useState(() => {
    return key == null ? undefined : localStorage.getItem(key);
  });
  const liveTime = useLiveTime("minute");

  useEffect(() => {
    const unsubscribe = listen(window, "storage", (e) => {
      if (e.key !== key) {
        return;
      }
      setStorageData(e.newValue);
    });

    return unsubscribe;
  }, [key]);

  const data = useMemo(() => {
    if (storageData == null) {
      return createDeviceCalendarPreference(undefined, liveTime);
    }
    const validData = userDeviceCalendarPreferenceStorageValidator(
      JSON.parse(storageData)
    );
    return createDeviceCalendarPreference(validData, liveTime);
  }, [storageData, liveTime]);

  const set = useCallback(
    (data: DeviceCalendarPreference) => {
      if (key == null) {
        throw new Error(
          "Could not set calendar preferences, because storage key is null"
        );
      }
      const stringifiedData = JSON.stringify(data);
      localStorage.setItem(key, stringifiedData);
      return data;
    },
    [key]
  );

  return {
    data,
    set,
  };
}

export function useDeviceStorage() {
  const calendarSettings = useCalendarSettings();

  return {
    calendarSettings,
  };
}
