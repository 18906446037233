import { ScheduleViewSlot } from "@cur8/rich-entity";
import { classNames } from "@pomle/classnames";
import { Interval } from "luxon";
import { SlotTime } from "./fragments/SlotTime";
import styles from "./styles.module.sass";
import { MedicalExamsTags } from "./components/MedicalExamsTags";
import { ViewRangeMode } from "../../hooks/useCalendarState";

interface Props {
  slot: ScheduleViewSlot;
  isAppointmentsCensored: boolean;
  viewRange: ViewRangeMode;
}

function toInterval(slot: ScheduleViewSlot): Interval {
  return Interval.fromDateTimes(slot.startTime, slot.endTime);
}

export function ReservedSlot({
  slot,
  isAppointmentsCensored,
  viewRange,
}: Props) {
  const supportedMedicalExams = slot.supportedMedicalExams;

  return (
    <div className={classNames(styles.reserved, styles.slot)}>
      <div className={styles.title}>Reserved</div>
      <div className={styles.subtitle}>
        <SlotTime timeWindow={toInterval(slot)} />
      </div>
      {!isAppointmentsCensored && (
        <MedicalExamsTags
          variant="dark"
          medicalExams={supportedMedicalExams}
          viewRange={viewRange}
        />
      )}
    </div>
  );
}
