import { useEffect, useState } from "react";
import { GLTF, GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";

function loadGLTF(url: string) {
  const loader = new GLTFLoader();

  return loader.loadAsync(url);
}

export function useGLTF(url?: string) {
  const [gltf, setGLTF] = useState<GLTF>();

  useEffect(() => {
    if (!url) {
      return;
    }

    loadGLTF(url).then(setGLTF);

    return () => {
      setGLTF(undefined);
    };
  }, [url]);

  return gltf;
}
