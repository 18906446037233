import { useCallback, useState } from "react";
import { Typography } from "render/ui/presentation/Typography";
import { ProcessedErrorData, ProcessedErrors } from "../../lib/types";
import styles from "./styles.module.sass";

interface ProcessErrorsProps {
  processErrors?: ProcessedErrors<ProcessedErrorData>;
}

export function ProcessErrors({ processErrors }: ProcessErrorsProps) {
  const [showErrorMsg, setShowErrorMsg] = useState<"none" | "block">("none");
  const showErrors = useCallback(() => {
    setShowErrorMsg(showErrorMsg === "block" ? "none" : "block");
  }, [showErrorMsg]);

  return (
    <div className={styles.ProcessErrors}>
      {processErrors && Object.keys(processErrors).length > 0 && (
        <div className={styles.processErrors}>
          <div
            className={styles.icon}
            onClick={showErrors}
            title="Show process errors"
          >
            (e)
          </div>
          <div className={styles.msg} style={{ display: showErrorMsg }}>
            <Typography variant="title-s">Process errors</Typography>
            <div className={styles.wrapper}>
              {Object.entries(processErrors).map(([code, error], key) => (
                <div key={key}>
                  <span title={error.details}>{error.description}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
