import { Patient } from "@cur8/rich-entity";
import { useHighestBrachialPressure } from "render/hooks/api/metrics/useHighestBrachialPressure";
import { useAge } from "render/hooks/patient/useAge";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import { Typography } from "render/ui/presentation/Typography";
import { BloodPressureBalanceSegment } from "../BloodPressureBalanceSegment";
import { BloodPressureBenchmark } from "../BloodPressureBenchmark";
import { BloodPressureProjectionGraph } from "../BloodPressureProjectionGraph";
import { BloodPressureSegment } from "../BloodPressureSegment";
import { Header } from "../Header";
import styles from "./styles.module.sass";

interface ArterialHealthSectionProps {
  patient: Patient;
}

export function ArterialHealthSection({ patient }: ArterialHealthSectionProps) {
  const patientAge = useAge(patient);
  const {
    metrics: { cardio },
  } = usePatientData();

  const brachial = cardio.brachialPressure;

  const currentHighestBrachialPressure = useHighestBrachialPressure({
    left: brachial.left,
    right: brachial.right,
  });

  const previousHighestBrachialPressure = useHighestBrachialPressure({
    left: brachial.left?.slice(1),
    right: brachial.right?.slice(1),
  });

  const brachialPressureDifferenceMetric =
    cardio.brachialPressure.difference?.at(0);

  return (
    <>
      <div className={styles.ArterialHealthSection}>
        <section>
          <Typography as="h2" variant="display-s">
            Arterial health
          </Typography>
          <hr />
        </section>

        {currentHighestBrachialPressure && (
          <>
            <section className={styles.rangesSection}>
              <BloodPressureSegment
                currentMetric={currentHighestBrachialPressure}
                previousMetric={previousHighestBrachialPressure}
                bloodPressureType={"systolic_blood_pressure"}
              />
              <BloodPressureBalanceSegment
                brachialPressureDifferenceMetric={
                  brachialPressureDifferenceMetric
                }
              />
            </section>

            <section>
              <BloodPressureProjectionGraph
                patient={patient}
                currentValue={currentHighestBrachialPressure}
              />
            </section>

            <section>
              <Header
                title="Benchmark"
                subtitle="Systolic"
                age={patientAge}
                sex={patient.sex}
                bloodPressureType={"systolic_blood_pressure"}
                currentValue={currentHighestBrachialPressure.unit.systolic.mmHg}
              />
              <BloodPressureBenchmark
                bloodPressureType={"systolic_blood_pressure"}
                age={patientAge}
                sex={patient.sex}
                currentValue={currentHighestBrachialPressure.unit.systolic.mmHg}
              />
            </section>
          </>
        )}
      </div>
    </>
  );
}
