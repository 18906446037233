import { spring } from "@pomle/tween";
import { findObjectBFS } from "lib/three";
import * as THREE from "three";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader.js";
import { BodyPartsMaterial } from "./../materials/BodyPartsMaterial";

function setupSide(handle: THREE.Object3D, mesh: THREE.Mesh) {
  const material = (mesh.material as THREE.MeshStandardMaterial).clone();
  //material.color.set(0, 1, 0);
  material.emissive.setHex(0xf3f5f7);
  material.opacity = 0;
  material.transparent = true;

  const colorMaterial = new THREE.MeshStandardMaterial({ color: "#fff" });
  colorMaterial.metalness = 0;
  colorMaterial.roughness = 1;

  const bodyPartsMaterial = new BodyPartsMaterial({ color: "#fff" });

  const normalMaterial = new THREE.MeshNormalMaterial({});
  normalMaterial.normalMapType = THREE.ObjectSpaceNormalMap;

  //mesh.material = normalMaterial; //.color.setStyle("#fff", THREE.LinearSRGBColorSpace);
  //mesh.material.emissive.setStyle("#fff", THREE.LinearSRGBColorSpace);
  //mesh.material.emissiveIntensity = 0;
  return {
    handle,
    mesh,
    material,
    colorMaterial,
    normalMaterial,
    bodyPartsMaterial,
  };
}

function findSide(source: THREE.Object3D, side: "front" | "back") {
  const handleId = `handle_${side}`;
  const meshId = `mesh_obj_${side}`;

  const handle = findObjectBFS(source, (object) => {
    return object.userData["name"] === handleId;
  });

  if (!handle) {
    console.error(`Could not find neko.identifier "${handleId}"`, source);
    throw new Error("Could not find expected object");
  }

  const mesh = findObjectBFS(handle, (object) => {
    return object.userData["name"] === meshId;
  });

  if (!(mesh instanceof THREE.SkinnedMesh)) {
    console.error(`Could not find neko.identifier "${meshId}"`, source);
    throw new Error("Could not find avatar Skinned Mesh");
  }

  return setupSide(handle, mesh);
}

type AnimWeights = {
  relaxFront: 0 | 1;
  relaxBack: 0 | 1;
  reveal: 0 | 1;
  powerFront: 0 | 1;
  powerBack: 0 | 1;
  recposeFront: 0 | 1;
  recposeBack: 0 | 1;
  Apose: 0 | 1;
};

const ANIM_RELAX: AnimWeights = {
  relaxFront: 1,
  relaxBack: 1,
  reveal: 0,
  powerFront: 0,
  powerBack: 0,
  recposeFront: 0,
  recposeBack: 0,
  Apose: 0,
} as const;

const ANIM_REVEAL: AnimWeights = {
  relaxFront: 0,
  relaxBack: 0,
  reveal: 1,
  powerFront: 0,
  powerBack: 0,
  recposeFront: 0,
  recposeBack: 0,
  Apose: 0,
} as const;

const ANIM_POWER: AnimWeights = {
  relaxFront: 0,
  relaxBack: 0,
  reveal: 0,
  powerFront: 1,
  powerBack: 1,
  recposeFront: 0,
  recposeBack: 0,
  Apose: 0,
} as const;

const ANIM_RECPOSE: AnimWeights = {
  relaxFront: 0,
  relaxBack: 0,
  reveal: 0,
  powerFront: 0,
  powerBack: 0,
  recposeFront: 1,
  recposeBack: 1,
  Apose: 0,
} as const;

const ANIM_APOSE: AnimWeights = {
  relaxFront: 0,
  relaxBack: 0,
  reveal: 0,
  powerFront: 0,
  powerBack: 0,
  recposeFront: 0,
  recposeBack: 0,
  Apose: 1,
} as const;

export type AnimationPose = "relax" | "reveal" | "power" | "recpose" | "apose";

function setupAnimator(source: GLTF) {
  const scene = source.scene;
  const anims = source.animations;

  const mixer = new THREE.AnimationMixer(scene);

  function findClip(name: string) {
    const clip = anims.find((anim) => anim.name === name);
    if (!clip) {
      console.error("Animation clip not found", name, source);
      const fallbackClip = new THREE.AnimationClip(name + "Fallback", 1, []);
      return mixer.clipAction(fallbackClip);
    }

    return mixer.clipAction(clip);
  }

  const clip = {
    powerFront: findClip("power_front"),
    powerBack: findClip("power_back"),
    relaxFront: findClip("relax_front"),
    relaxBack: findClip("relax_back"),
    reveal: findClip("reveal"),
    recposeFront: findClip("recpose_front"),
    recposeBack: findClip("recpose_back"),
    Apose: findClip("Apose"),
  };

  clip.reveal.setLoop(THREE.LoopOnce, 0);

  const clips = [
    clip.relaxFront,
    clip.relaxBack,
    clip.reveal,
    clip.powerFront,
    clip.powerBack,
    clip.recposeFront,
    clip.recposeBack,
    clip.Apose,
  ];

  clips.forEach((clip) => {
    clip.time = 0;
    clip.play();
  });

  mixer.update(0);

  const AnimStates: Record<AnimationPose, AnimWeights> = {
    relax: ANIM_RELAX,
    reveal: ANIM_REVEAL,
    power: ANIM_POWER,
    recpose: ANIM_RECPOSE,
    apose: ANIM_APOSE,
  };

  const tween = spring(
    {
      relaxFront: 0,
      relaxBack: 0,
      reveal: 1,
      powerFront: 0,
      powerBack: 0,
      recposeFront: 0,
      recposeBack: 0,
      Apose: 0,
    },
    {
      friction: 70,
      mass: 1000,
      stiffness: 80,
      precision: 0.01,
    }
  );

  clips.forEach((clip) => {
    clip.time = 0;
  });

  return {
    clip,
    mixer,
    transitionTo(key: AnimationPose) {
      tween.to(AnimStates[key]);
    },
    update(deltaTime: number) {
      tween.update(deltaTime);

      clip.relaxFront.weight = tween.value.relaxFront;
      clip.relaxBack.weight = tween.value.relaxBack;
      clip.reveal.weight = tween.value.reveal;
      clip.powerFront.weight = tween.value.powerFront;
      clip.powerBack.weight = tween.value.powerBack;
      clip.recposeFront.weight = tween.value.recposeFront;
      clip.recposeBack.weight = tween.value.recposeBack;
      clip.Apose.weight = tween.value.Apose;

      mixer.update(deltaTime);
    },
  };
}

export function setupAvatarSource(source: GLTF) {
  console.debug("Using Avatar GLTF", source);

  const scene = source.scene;

  const front = findSide(scene, "front");
  const back = findSide(scene, "back");

  return {
    animator: setupAnimator(source),
    scene,
    front,
    back,
  };
}
