import { Visit, fromAPI } from "@cur8/rich-entity";
import { useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";

export function usePatientVisits(
  patientId: string,
  visitIds: string[] | undefined
) {
  const api = useAPIClient();
  const [result, setResult] = useState<Visit[]>();
  useEffect(() => {
    if (!visitIds) {
      return;
    }
    const requests = visitIds.map((visitId) =>
      api.visit.fetchVisit({ patientId, visitId })
    );

    Promise.all(requests.map((request) => request.result))
      .then((visits) => visits.map(fromAPI.toVisit))
      .then(setResult);

    return () => {
      requests.forEach((req) => req.abandon());
    };
  }, [visitIds, api, patientId]);

  return result;
}
