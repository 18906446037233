import { getHighestRisk } from "@cur8/measurements";
import { Patient } from "@cur8/rich-entity";
import { RiskMetric } from "lib/metric";
import { useMemo } from "react";
import { MetricStatus } from "render/ui/presentation/MetricStatus";
import { MetricStatusBadge } from "render/ui/presentation/MetricStatusBadge";
import BloodworkSymbol from "./assets/bloodwork.svg?react";

interface BloodworkItemProps {
  patient: Patient;
  bloodwork: {
    cholesterol: RiskMetric<"bloodwork.cholesterol">[] | undefined;
    hdl: RiskMetric<"bloodwork.hdl">[] | undefined;
    nonHDL: RiskMetric<"bloodwork.non_hdl">[] | undefined;
    triglycerides: RiskMetric<"bloodwork.triglycerides">[] | undefined;
    ldl: RiskMetric<"bloodwork.ldl">[] | undefined;
    whiteBloodCells:
      | RiskMetric<"bloodwork.white_blood_cells.total">[]
      | undefined;
    neutrophils:
      | RiskMetric<"bloodwork.white_blood_cells.neutrophils">[]
      | undefined;
    basophils:
      | RiskMetric<"bloodwork.white_blood_cells.basophils">[]
      | undefined;
    eosinophils:
      | RiskMetric<"bloodwork.white_blood_cells.eosinophils">[]
      | undefined;
    lymphocytes:
      | RiskMetric<"bloodwork.white_blood_cells.lymphocytes">[]
      | undefined;
    glucose: RiskMetric<"bloodwork.glucose">[] | undefined;
    hba1c: RiskMetric<"bloodwork.hba1c">[] | undefined;
    haemoglobin: RiskMetric<"bloodwork.haemoglobin">[] | undefined;
    crp: RiskMetric<"bloodwork.crp">[] | undefined;
  };
}

function isDefined<T>(value: T | undefined): value is T {
  return value != null;
}

export function BloodworkItem({ bloodwork }: BloodworkItemProps) {
  const risk = useMemo(() => {
    const risks = Object.values(bloodwork)
      .map((metric) => metric?.at(0))
      .filter(isDefined)
      .map((metric) => metric.riskRanges.riskLevel);
    return getHighestRisk(risks);
  }, [bloodwork]);

  return (
    <MetricStatus
      caption="Bloodwork"
      summaryContent={risk != null ? <MetricStatusBadge risk={risk} /> : "--"}
    >
      <BloodworkSymbol />
    </MetricStatus>
  );
}
