import * as THREE from "three";
import fragmentShader from "./shaders/depth.fragment.glsl?raw";
import vertexShader from "./shaders/flat.vertex.glsl?raw";

export class DepthMaterial extends THREE.ShaderMaterial {
  constructor(camera: THREE.OrthographicCamera, depthMap: THREE.Texture) {
    super({
      vertexShader,
      fragmentShader,
      uniforms: {
        cameraNear: { value: camera.near },
        cameraFar: { value: camera.far },
        tDepth: { value: depthMap },
      },
    });
  }
}
