import { Site } from "@cur8/rich-entity";
import { StepLayout } from "../../presentation/StepLayout";
import styles from "./styles.module.sass";
import { RadioGroup } from "../../presentation/RadioGroup";

export function SelectLocationStep(props: {
  onSelect: (locationId: string) => void;
  sites: Site[] | undefined;
}) {
  return (
    <StepLayout
      header="What clinic are you in today ?"
      subHeader="Room calendar"
    >
      <RadioGroup onChange={props.onSelect}>
        {props.sites?.map((site, index) => (
          <div
            key={site.siteId}
            className={styles.location}
            style={{ animationDelay: `${index * 0.025}s` }}
          >
            <RadioGroup.Radio label={site.siteName} value={site.siteId} />
          </div>
        ))}
      </RadioGroup>
    </StepLayout>
  );
}
