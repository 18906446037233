import { BloodworkTable } from "./components/BloodworkTable";
import { BodyTable } from "./components/BodyTable";
import { CardioTable } from "./components/CardioTable";
import { LipidPanelTable } from "./components/LipidPanelTable";
import { QuestionnaireOverview } from "./components/QuestionnaireOverview";
import { QuestionnaireOverviewV1 } from "./components/QuestionnaireOverviewV1";
import { WhiteBloodCellsTable } from "./components/WhiteBloodCellsTable";
import styles from "./styles.module.sass";
import { PatientEncyclopediaMetrics } from "./hooks/usePatientEncyclopediaMetrics";
import { Questionnaire } from "render/hooks/api/useQuestionnaire";
import { Country } from "lib/visit/visit";

interface PatientEncyclopediaComponentProps {
  metrics: PatientEncyclopediaMetrics;
  questionnaire: Questionnaire | undefined;
  country?: Country;
}

export function PatientEncyclopediaComponent({
  metrics,
  questionnaire,
  country,
}: PatientEncyclopediaComponentProps) {
  return (
    <div className={styles.PatientEncyclopediaPage}>
      <section>
        <CardioTable metrics={metrics} country={country} />
      </section>

      <section>
        <BodyTable metrics={metrics} country={country} />
      </section>

      <section>
        <LipidPanelTable metrics={metrics} country={country} />
      </section>

      <section>
        <WhiteBloodCellsTable metrics={metrics} country={country} />
      </section>

      <section>
        <BloodworkTable metrics={metrics} country={country} />
      </section>

      <section>
        {questionnaire ? (
          <>
            {questionnaire?.questionnaireType === "onboarding/1" ? (
              <QuestionnaireOverviewV1 questionnaireResponse={questionnaire} />
            ) : (
              <QuestionnaireOverview questionnaireResponse={questionnaire} />
            )}
          </>
        ) : null}
      </section>
    </div>
  );
}
