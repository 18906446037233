import { APITypesV1 } from "@cur8/api-client";
import { RequestEnvelope } from "@cur8/api-client/dist/api/types";
import { ImmutableScan } from "@cur8/rich-entity";
import { useCallback, useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useReporting } from "render/hooks/useReporting";
import {
  CreatePadAssessmentRequestBox,
  useAssessmentContext,
} from "../../../context/AssessmentContext";
import { useTissueImageContext } from "../context/TissueImageContext";
import { LCJSPoint, Property } from "../lib/types";
import { cleanAndTrimSeries } from "../lib/utils";

/**
 * Function to calculate the mean y-value excluding points with y = 0
 */
function seriesMean(series: LCJSPoint[]): number {
  if (series.length <= 0) {
    throw new Error(`No points in series, length: ${series.length}`);
  }
  const nonZeroPoints = series.filter((point) => point.y !== 0);
  if (nonZeroPoints.length === 0) {
    throw new Error("No Y-values");
  }

  const sumY = nonZeroPoints.reduce((acc, point) => acc + point.y, 0);
  return sumY / nonZeroPoints.length;
}

export const propertiesPAD = new Map<Property, string>([
  [Property.t1BloodVolume, "Shallow Blood Concentration"],
  [Property.t1Oxygenation, "Shallow Oxygenation"],
]);

type RoiAndProperty = {
  roi: keyof APITypesV1.RegionsOfInterest;
  prop: Property;
};
type RoiAndPropertyMean = { mean: number } & RoiAndProperty;
const roiAndProperty: RoiAndProperty[] = [
  { roi: "forearm", prop: Property.t1BloodVolume },
  { roi: "hypothenar", prop: Property.t1BloodVolume },
  { roi: "forearm", prop: Property.t1Oxygenation },
  { roi: "hypothenar", prop: Property.t1Oxygenation },
];

export function useChromophoreToRatio(
  scan: ImmutableScan,
  assessment: CreatePadAssessmentRequestBox,
  indexRemap: number[],
  timestamps: number[]
) {
  const api = useAPIClient().transcode;
  const { handleError } = useReporting();
  const { setPadRatios } = useAssessmentContext();
  const { bloodVesselsMask } = useTissueImageContext();

  const [ratioBlood, setRatioBlood] = useState<number>(0);
  const [ratioOxygen, setRatioOxygen] = useState<number>(0);

  const fetch = useCallback(() => {
    const proms = [];
    const requests: RequestEnvelope<number[]>[] = [];

    for (var i = 0; i < roiAndProperty.length; i++) {
      const { roi, prop } = roiAndProperty[i];
      const req = api.fetchChromophoreTimeseries(
        {
          patientId: scan.patientId,
          scanId: scan.id,
          scanVersion: scan.version,
          chromophore: prop,
        },
        assessment.regionsOfInterest![roi],
        bloodVesselsMask?.threshold,
        bloodVesselsMask?.extract
      );
      requests.push(req);

      let prom = req.result
        .then((res) => {
          const trim = cleanAndTrimSeries(
            res,
            indexRemap,
            timestamps,
            undefined
          );
          return {
            roi,
            prop,
            mean: seriesMean(trim),
          } as RoiAndPropertyMean;
        })
        .catch(handleError);
      proms.push(prom);
    }

    Promise.all(proms).then((res) => {
      let bloodVolumeRatio = 0;
      let oxygenationRatio = 0;
      // T1 Blood volume ratio
      try {
        const bloodForearm = res.find(
          (rpm) =>
            rpm && rpm.prop === Property.t1BloodVolume && rpm.roi === "forearm"
        );
        const bloodHypothenar = res.find(
          (rpm) =>
            rpm &&
            rpm.prop === Property.t1BloodVolume &&
            rpm.roi === "hypothenar"
        );
        if (bloodForearm && bloodHypothenar) {
          bloodVolumeRatio = bloodHypothenar.mean / bloodForearm.mean;
          setRatioBlood(bloodVolumeRatio);
        }
      } catch (err) {
        setRatioBlood(0);
        console.error(err);
      }
      // T1 Oxygenation ratio
      try {
        const oxyForearm = res.find(
          (rpm) =>
            rpm && rpm.prop === Property.t1Oxygenation && rpm.roi === "forearm"
        );
        const oxyHypothenar = res.find(
          (rpm) =>
            rpm &&
            rpm.prop === Property.t1Oxygenation &&
            rpm.roi === "hypothenar"
        );
        if (oxyForearm && oxyHypothenar) {
          oxygenationRatio = oxyHypothenar.mean / oxyForearm.mean;
          setRatioOxygen(oxygenationRatio);
        }
      } catch (err) {
        setRatioOxygen(0);
        console.error(err);
      }

      if (bloodVolumeRatio && oxygenationRatio) {
        setPadRatios(bloodVolumeRatio, oxygenationRatio);
      }
    });

    return () => {
      requests.forEach((req) => req.abandon());
    };
  }, [
    api,
    assessment.regionsOfInterest,
    bloodVesselsMask?.extract,
    bloodVesselsMask?.threshold,
    handleError,
    indexRemap,
    scan.id,
    scan.patientId,
    scan.version,
    setPadRatios,
    timestamps,
  ]);

  useEffect(() => {
    if (timestamps.length <= 0 || indexRemap.length <= 0) {
      return;
    }
    fetch();
  }, [fetch, indexRemap.length, timestamps.length]);

  return {
    ratioBlood,
    ratioOxygen,
  };
}
