import { RiskMetric } from "lib/metric";
import { useMemo } from "react";
import { MetricRange } from "render/ui/presentation/MetricRange";
import {
  selectChartRanges,
  toChartRanges,
} from "render/ui/presentation/RangeChart/lib/risk";

interface GlucoseRangeProps {
  metrics: RiskMetric<"bloodwork.glucose">[];
}

export function GlucoseRange({ metrics }: GlucoseRangeProps) {
  const latest = metrics.at(0);

  const entries = useMemo(() => {
    return metrics.map((metric) => {
      return {
        risk: metric.riskRanges.riskLevel,
        timestamp: metric.measurement.timestampStart,
        value: metric.unit["mmol/L"],
      };
    });
  }, [metrics]);

  const currentValue = entries.at(0)?.value;

  const ranges = useMemo(() => {
    const intervals = latest?.riskRanges.riskIntervals ?? [];
    const chartRanges = toChartRanges(intervals);

    return selectChartRanges(chartRanges, {
      mode: "dynamic",
      currentValue,
    });
  }, [latest?.riskRanges.riskIntervals, currentValue]);

  return (
    <MetricRange
      title="Glucose"
      unit="mmol/L"
      entries={entries}
      ranges={ranges}
      formatValue={(value) => value?.toFixed(0)}
      showEmptyRanges
    />
  );
}
