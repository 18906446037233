import { RiskMetricTool } from "lib/risk/tools";
import { useMemo } from "react";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import {
  Diff,
  DiffValue,
  MainTitle,
  MetricResultHeader,
  Subtitle,
  Titles,
} from "render/ui/presentation/MetricResultHeader";
import {
  RangeChart,
  RangeChartDataComposer,
} from "render/ui/presentation/RangeChart";
import { toChartRanges } from "render/ui/presentation/RangeChart/lib/risk";

const getTitlesForValueChange = (value?: number, previousValue?: number) => {
  if (value === undefined || previousValue === undefined) {
    return {
      title: "LDL",
      subtitle: "",
    };
  }

  if (value === previousValue) {
    return {
      title: "Maintained",
      subtitle: "LDL",
    };
  }

  if (value > previousValue) {
    return {
      title: "Increased",
      subtitle: "LDL",
    };
  }

  return {
    title: "Decreased",
    subtitle: "LDL",
  };
};

export function LDLItem() {
  const { metrics } = usePatientData();

  const cholesterolMetric = metrics.bloodwork.ldl;
  const latest = cholesterolMetric?.at(0);
  const previous = cholesterolMetric?.at(1);

  const latestValue = latest?.unit["mmol/L"];
  const previousValue = previous?.unit["mmol/L"];

  const placeholderRanges = useMemo(() => {
    const values =
      cholesterolMetric?.map((metric) => metric.unit["mmol/L"]) ?? [];
    const result = RiskMetricTool.generateUnknownInterval({
      max: Math.max(...values),
      min: Math.min(...values),
    });

    return result;
  }, [cholesterolMetric]);

  const ranges = useMemo(() => {
    const riskIntervals = latest?.riskRanges.riskIntervals;

    const ranges =
      riskIntervals && riskIntervals.length > 0
        ? riskIntervals
        : placeholderRanges;

    const result = toChartRanges(ranges);

    return result;
  }, [latest?.riskRanges.riskIntervals, placeholderRanges]);

  // https://dev.azure.com/cur8/Engineering/_workitems/edit/26137
  const rangesCapLow = 1.5;
  const rangesCapHigh = 8.5;

  const { title, subtitle } = getTitlesForValueChange(
    latestValue,
    previousValue
  );

  return (
    <div>
      <MetricResultHeader>
        <Titles>
          <MainTitle>{title}</MainTitle>
          <Subtitle>{subtitle}</Subtitle>
        </Titles>
        <Diff>
          <DiffValue
            valuesDiff={
              previousValue !== undefined && latestValue !== undefined
                ? latestValue - previousValue
                : undefined
            }
            formatValue={(value) => value?.toFixed(2)}
            unit="mmol/L"
          />
        </Diff>
      </MetricResultHeader>
      <RangeChartDataComposer
        ranges={ranges}
        rangesCapLow={rangesCapLow}
        rangesCapHigh={rangesCapHigh}
        value={latestValue}
        previousValue={previousValue}
      >
        {({ ranges, values }) => (
          <RangeChart ranges={ranges} values={values} showEmpty />
        )}
      </RangeChartDataComposer>
    </div>
  );
}
