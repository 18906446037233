import { APITypesV1 } from "@cur8/api-client";
import { Assessment } from "@cur8/rich-entity";
import { useCallback, useEffect, useState } from "react";
import { useAppInsights } from "render/context/AppInsightsContext";
import { Typography } from "render/ui/presentation/Typography";
import { useAssessmentContext } from "../../context/AssessmentContext";
import { DisplayAssessment } from "./DisplayAssessment";
import { HistoryList } from "./HistoryList";
import { MakeAssessment } from "./MakeAssessment";
import styles from "./styles.module.sass";

export function AssessmentHandler() {
  const appInsights = useAppInsights();

  const { assessments, selected, type } = useAssessmentContext();
  const [showAssessment, setShowAssessment] = useState<boolean>(true);
  const [showHistory, setShowHistory] = useState<boolean>();
  const toggleHistory = useCallback(() => {
    setShowHistory((sh) => !!!sh);
  }, []);
  const [history, setHistory] = useState<Assessment[]>([]);

  useEffect(() => {
    if (!assessments) {
      return;
    }
    const filtered = assessments.filter(
      (ass) => ass.assessmentState !== APITypesV1.AssessmentState.New
    );
    setHistory(filtered);
  }, [assessments]);

  /**
   * Timespent tracking on a full assessment
   */
  useEffect(() => {
    const trackName = `TimeSpent_${type}`;
    appInsights.startTrackPage(trackName);
    return () => {
      appInsights.stopTrackPage(trackName);
    };
  }, [appInsights, type]);

  const trackAssessmentTime = useCallback(() => {
    const trackName = `TimeSpent_${type}`;
    appInsights.stopTrackPage(trackName);
  }, [appInsights, type]);

  if (!assessments) {
    return <Typography variant="title-s">Assessment missing</Typography>;
  }

  return (
    <div className={styles.AssessmentHandler}>
      {showAssessment && history.length > 0 && (
        <>
          <div className={styles.header}>
            <Typography variant="title-s">Assessment</Typography>
            {history.length > 1 && (
              <div className={styles.histLink} onClick={toggleHistory}>
                &raquo; {showHistory ? "Hide" : "Show"} history
              </div>
            )}
          </div>
          <DisplayAssessment assessment={selected} />
        </>
      )}
      <MakeAssessment
        setShowAssessment={setShowAssessment}
        trackSave={trackAssessmentTime}
      />
      {showHistory && (
        <HistoryList hide={toggleHistory} assessments={history} />
      )}
    </div>
  );
}
