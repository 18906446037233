import { Room, Site } from "@cur8/rich-entity";
import { useCallback } from "react";
import { CalendarPreferenceFormData } from "../../../types";
import { StepLayout } from "../../presentation/StepLayout/StepLayout";
import { HoverTextButton } from "render/ui/trigger/HoverTextButton";
import { Typography } from "render/ui/presentation/Typography";
import styles from "./styles.module.sass";
import { FullDate } from "render/ui/format/FullDate";
import {
  DeviceCalendarPreference,
  createDeviceCalendarPreference,
} from "render/hooks/deviceStorage/lib/calendarPreference";
import { DateTime } from "luxon";

export function SubmitStep(props: {
  formData: CalendarPreferenceFormData;
  sites: Site[] | undefined;
  rooms: Room[] | undefined;
  onSubmit: (data: DeviceCalendarPreference) => void;
}) {
  const site = props.sites?.find((s) => s.siteId === props.formData.siteId);
  const room = props.rooms?.find((room) => room.id === props.formData.roomId);

  const onSubmit = props.onSubmit;

  const submit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      onSubmit(
        createDeviceCalendarPreference(
          {
            roomId: props.formData.roomId,
            siteId: props.formData.siteId,
            expiresAt: props.formData.expiresAt.toISO(),
          },
          DateTime.now()
        )
      );
    },
    [
      onSubmit,
      props.formData.roomId,
      props.formData.siteId,
      props.formData.expiresAt,
    ]
  );

  return (
    <StepLayout header="Confirm" subHeader="Your selection">
      <form className={styles.form} onSubmit={submit}>
        <div>
          <div className={styles.field}>
            <Typography variant="label-m">Location:</Typography>
            <Typography variant="label-m">
              {props.formData.siteId ? site?.siteName ?? "--" : "All sites"}
            </Typography>
          </div>
          <div className={styles.field}>
            <Typography variant="label-m">Room:</Typography>
            <Typography variant="label-m">
              {props.formData.roomId ? room?.displayName ?? "--" : "All rooms"}
            </Typography>
          </div>
          <div className={styles.field}>
            <Typography variant="label-m">Expires at:</Typography>
            <Typography variant="label-m">
              <FullDate date={props.formData.expiresAt} />
            </Typography>
          </div>
        </div>
        <div>
          <HoverTextButton onClick={submit}>Confirm</HoverTextButton>
        </div>
      </form>
    </StepLayout>
  );
}
