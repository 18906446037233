import { ReactNode } from "react";
import styles from "./styles.module.sass";

interface SidePanelProps {
  children: ReactNode;
  side?: "left" | "right";
}

export function SidePanel({ children, side }: SidePanelProps) {
  return (
    <div className={styles.SidePanel} data-side={side}>
      <div className={styles.content}>{children}</div>
    </div>
  );
}
