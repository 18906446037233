import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_NORMAL,
  ElementNode,
  KEY_BACKSPACE_COMMAND,
  ParagraphNode,
  TextNode,
} from "lexical";
import { useEffect } from "react";

// Helper that recursively finds the text node corresponding to the given offset.
function findTextNodeAtOffset(
  node: ParagraphNode | ElementNode | TextNode,
  offset: number
): { textNode: TextNode; offsetInNode: number } | null {
  if (node instanceof TextNode) {
    return { textNode: node, offsetInNode: offset };
  }
  let current = 0;
  for (const child of node.getChildren()) {
    // Narrow the type: only proceed if child is a TextNode or ElementNode.
    if (child instanceof TextNode || child instanceof ElementNode) {
      const childText = child.getTextContent();
      const childLength = childText.length;
      if (current + childLength >= offset) {
        // Safe to cast child because of our type check.
        return findTextNodeAtOffset(
          child as ParagraphNode | ElementNode | TextNode,
          offset - current
        );
      }
      current += childLength;
    }
  }
  return null;
}

// Helper to set selection at a given character offset in a ParagraphNode.
function setSelectionAtParagraphOffset(
  paragraph: ParagraphNode,
  offset: number
) {
  const sel = $getSelection();
  if ($isRangeSelection(sel)) {
    const result = findTextNodeAtOffset(paragraph, offset);
    if (result) {
      sel.setTextNodeRange(
        result.textNode,
        result.offsetInNode,
        result.textNode,
        result.offsetInNode
      );
    }
  }
}

export function BulletBackspacePlugin() {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerCommand(
      KEY_BACKSPACE_COMMAND,
      (event: KeyboardEvent) => {
        const selection = $getSelection();
        if (!$isRangeSelection(selection) || !selection.isCollapsed()) {
          return false;
        }
        let node = selection.anchor.getNode();
        // Ensure we have a ParagraphNode.
        while (!(node instanceof ParagraphNode)) {
          const parent = node.getParent();
          if (!parent) {
            return false;
          }
          node = parent;
        }
        const currentBullet = node as ParagraphNode;
        if (selection.anchor.offset !== 0) {
          return false;
        }
        event.preventDefault();
        editor.update(() => {
          const previous = currentBullet.getPreviousSibling();
          if (!previous) {
            return;
          }
          // Save the length of previous bullet's text content.
          const prevTextLength = previous.getTextContent().length;
          if (currentBullet.getTextContent() === "") {
            // If current bullet is empty, simply remove it.
            currentBullet.remove();
            setSelectionAtParagraphOffset(
              previous as ParagraphNode,
              prevTextLength
            );
          } else {
            // Move each child from current bullet into the previous bullet,
            // preserving structure (including any links).
            const currentChildren = currentBullet.getChildren();
            for (const child of currentChildren) {
              child.remove();
              (previous as ElementNode).append(child);
            }
            currentBullet.remove();
            setSelectionAtParagraphOffset(
              previous as ParagraphNode,
              prevTextLength
            );
          }
        });
        return true;
      },
      COMMAND_PRIORITY_NORMAL
    );
  }, [editor]);

  return null;
}
