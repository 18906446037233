import { RiskMetric } from "lib/metric";
import { useMemo } from "react";
import { MetricStatus } from "render/ui/presentation/MetricStatus";
import { MetricStatusBadge } from "render/ui/presentation/MetricStatusBadge";
import HeartRhythmIcon from "./assets/heart-rhythm.svg?react";
import { getHighestRisk } from "@cur8/measurements";

export type HeartRhythmItemProps = {
  pr?: RiskMetric<"cardio.ecg.segments.pr">[] | undefined;
  qrs?: RiskMetric<"cardio.ecg.segments.qrs">[] | undefined;
  qtc?: RiskMetric<"cardio.ecg.segments.qtc">[] | undefined;
  heartRate?: RiskMetric<"cardio.ecg.heart_rate">[] | undefined;
};

export function HeartRhythmItem({
  pr,
  qrs,
  qtc,
  heartRate,
}: HeartRhythmItemProps) {
  const latestPR = pr?.at(0);
  const latestQRS = qrs?.at(0);
  const latestQTC = qtc?.at(0);
  const latestHeartRate = heartRate?.at(0);

  const risk = useMemo(() => {
    if (!latestPR || !latestQRS || !latestQTC || !latestHeartRate) {
      return;
    }

    return getHighestRisk([
      latestPR.riskRanges.riskLevel,
      latestQRS.riskRanges.riskLevel,
      latestQTC.riskRanges.riskLevel,
      latestHeartRate.riskRanges.riskLevel,
    ]);
  }, [latestPR, latestQRS, latestQTC, latestHeartRate]);

  return (
    <MetricStatus
      caption={<>Heart rhythm</>}
      summaryContent={risk != null ? <MetricStatusBadge risk={risk} /> : "--"}
    >
      <HeartRhythmIcon />
    </MetricStatus>
  );
}
