import { useMemo } from "react";
import { SectionMeasurement } from "render/fragments/visit/Editors/SummaryEditor/sections";
import { RiskLabel } from "../RiskLabel/RiskLabel";
import { BloodPressureView } from "./components/BloodPressureView/BloodPressureView";
import { DimensionView } from "./components/DimensionView/DimensionView";
import styles from "./SidePreview.module.sass";

interface SidePreviewProps {
  measurement: {
    current: SectionMeasurement | undefined;
    previous: SectionMeasurement | undefined;
  };
  side?: "left" | "right";
}

function sideLabel(side: "left" | "right") {
  return side === "left" ? " (L)" : " (R)";
}

export function SidePreview({ measurement, side }: SidePreviewProps) {
  const valueDim = useMemo(
    () => measurement.current?.dimensions.find((d) => d.name === "value"),
    [measurement]
  );

  if (!measurement.current) {
    return null;
  }

  const decimalPlaces = measurement.current.decimalPlaces;
  const name = side
    ? measurement.current.friendlyName + sideLabel(side)
    : measurement.current.friendlyName;

  // Special card for blood pressure
  if (measurement.current.metricName?.startsWith("cardio.brachial_pressure")) {
    return (
      <div className={styles.SidePreview}>
        <div className={styles.title}>
          {name}
          <RiskLabel risk={measurement.current.risk} />
        </div>
        <div>
          <BloodPressureView
            dimensions={measurement.current.dimensions}
            previousDimensions={measurement.previous?.dimensions}
            decimalPlaces={decimalPlaces}
          />
        </div>
      </div>
    );
  }

  if (valueDim) {
    const prevValueDim = measurement.previous?.dimensions.find(
      (pd) => pd.name === "value"
    );
    return (
      <div className={styles.SidePreview}>
        <div className={styles.title}>
          {name}
          <RiskLabel risk={measurement.current.risk} />
        </div>
        <div>
          <DimensionView
            dimension={valueDim}
            previousDimension={prevValueDim}
            decimalPlaces={decimalPlaces}
          />
        </div>
      </div>
    );
  }

  return null;
}
