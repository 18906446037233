import { PathRoute, mount } from "@pomle/react-router-paths";
import { AssessmentPage } from "render/pages/AssessmentPage/AssessmentPage";
import { AtlasPage } from "render/pages/AtlasPage";
import { BloodworkEntryPage } from "render/pages/BloodworkEntryPage";
import { CardioScanPage } from "render/pages/CardioScanPage/CardioScanPage";
import { DashboardPage } from "render/pages/DashboardPage";
import { DashboardStyleGuidePage } from "render/pages/DashboardStyleGuidePage";
import { InternalEntryPage } from "render/pages/InternalEntryPage";
import { LandingPage } from "render/pages/LandingPage";
import { LesionPage } from "render/pages/LesionPage";
import { LindaToolsPage } from "render/pages/LindaToolsPage";
import { NotificationPage } from "render/pages/NotificationPage";
import { PatientAppointmentPage } from "render/pages/PatientAppointmentPage";
import { PatientDetailPage } from "render/pages/PatientDetailPage";
import { PatientEditPage } from "render/pages/PatientEditPage";
import { PatientEncyclopediaPage } from "render/pages/PatientEncyclopediaPage/PatientEncyclopediaPage";
import { PatientFindPage } from "render/pages/PatientFindPage";
import { PatientInvitePage } from "render/pages/PatientInvitePage";
import { RiskAssessmentEntryPage } from "render/pages/RiskAssessmentEntryPage";
import { SchedulePage } from "render/pages/SchedulePage";
import { SettingsPage } from "render/pages/SettingsPage";
import { SettingsPageCalendarSettings } from "render/pages/SettingsPageCalendarSettings";
import { SiteSelectPage } from "render/pages/SiteSelectPage";
import { SkinCRMPage } from "render/pages/SkinCRMPage";
import { TissuePADPage } from "render/pages/TissuePages/TissuePADPage";
import { TissueTrackerPage } from "render/pages/TissuePages/TissueTrackerPage";
import { TrackedMarkingsPage } from "render/pages/TrackedMarkingsPage";
import { useTrackPage } from "./hooks/useTrackPage";
import { paths } from "./paths";

export function Router() {
  useTrackPage();

  return (
    <>
      <PathRoute path={paths.patient.appointment.view}>
        {mount(PatientAppointmentPage)}
      </PathRoute>

      <PathRoute path={paths.patient.assessment}>
        {mount(AssessmentPage)}
      </PathRoute>

      <PathRoute path={paths.patient.recording.atlas}>
        {mount(AtlasPage)}
      </PathRoute>

      <PathRoute path={paths.patient.scans.cardio}>
        {mount(CardioScanPage)}
      </PathRoute>

      <PathRoute path={paths.patient.scans.tissue.regular}>
        {mount(TissueTrackerPage)}
      </PathRoute>
      <PathRoute path={paths.patient.scans.tissue.mind}>
        {mount(TissueTrackerPage)}
      </PathRoute>
      <PathRoute path={paths.patient.scans.tissue.pad}>
        {mount(TissuePADPage)}
      </PathRoute>

      <PathRoute path={paths.patient.invite}>
        {mount(PatientInvitePage)}
      </PathRoute>

      <PathRoute path={paths.patient.edit}>{mount(PatientEditPage)}</PathRoute>

      <PathRoute path={paths.patient.bloodworkEntry}>
        {mount(BloodworkEntryPage)}
      </PathRoute>

      <PathRoute path={paths.patient.riskAssessmentEntry}>
        {mount(RiskAssessmentEntryPage)}
      </PathRoute>

      <PathRoute path={paths.patient.lesions}>{mount(LesionPage)}</PathRoute>

      <PathRoute path={paths.patient.overview}>
        {mount(PatientFindPage)}
      </PathRoute>

      <PathRoute path={paths.patient.detail}>
        {mount(PatientDetailPage)}
      </PathRoute>

      <PathRoute path={paths.patient.encyclopedia}>
        {mount(PatientEncyclopediaPage)}
      </PathRoute>

      <PathRoute path={paths.patient.dashboard}>
        {mount(DashboardPage)}
      </PathRoute>

      <PathRoute path={paths.dashboardStyleGuide}>
        {mount(DashboardStyleGuidePage)}
      </PathRoute>

      <PathRoute path={paths.patient.trackedMarkings}>
        {mount(TrackedMarkingsPage)}
      </PathRoute>

      <PathRoute path={paths.notifications}>
        {mount(NotificationPage)}
      </PathRoute>

      <PathRoute path={paths.schedule.sites}>{mount(SiteSelectPage)}</PathRoute>

      <PathRoute path={paths.schedule.site}>{mount(SchedulePage)}</PathRoute>

      <PathRoute path={paths.settings.overview}>
        {mount(SettingsPage)}
      </PathRoute>

      <PathRoute path={paths.settings.calendar}>
        {mount(SettingsPageCalendarSettings)}
      </PathRoute>

      <PathRoute path={paths.internalEntry}>
        {mount(InternalEntryPage)}
      </PathRoute>

      <PathRoute path={paths.linda}>{mount(LindaToolsPage)}</PathRoute>

      <PathRoute path={paths.root}>{mount(LandingPage)}</PathRoute>

      <PathRoute path={paths.skinCRM}>{mount(SkinCRMPage)}</PathRoute>
    </>
  );
}
