import { RiskLevel } from "@cur8/measurements";
import { Highlight, Variant } from "render/ui/presentation/ChartMarker/Marker";

export const DEFAULT_POINT_COLOR = "#019dc9";
export const DEFAULT_RANGE_COLOR = "#C5D9DD";

export const RiskToHighlightMap: Record<RiskLevel, Highlight> = {
  [RiskLevel.Unknown]: "normal",
  [RiskLevel.Optimal]: "normal",
  [RiskLevel.Normal]: "normal",
  [RiskLevel.Risk]: "warning",
  [RiskLevel.HighRisk]: "danger",
  [RiskLevel.ImmediateRisk]: "danger",
  [RiskLevel.LowRisk]: "warning",
  [RiskLevel.ModerateRisk]: "danger",
};

export const RiskToColorMap: Record<RiskLevel, string> = {
  [RiskLevel.Unknown]: "#59C4DD",
  [RiskLevel.Optimal]: "#59C4DD",
  [RiskLevel.Normal]: "#59C4DD",
  [RiskLevel.Risk]: "#FFB647",
  [RiskLevel.HighRisk]: "#F6A29D",
  [RiskLevel.ImmediateRisk]: "#F6A29D",
  [RiskLevel.LowRisk]: "#FFB647",
  [RiskLevel.ModerateRisk]: "#F6A29D",
};

interface GetMarkerVariantProps {
  areValuesEqual: boolean;
  isLatestValue: boolean;
}

export function getMarkerVariant({
  areValuesEqual,
  isLatestValue,
}: GetMarkerVariantProps): Variant {
  if (areValuesEqual) {
    return "primary-outlined";
  }

  if (isLatestValue) {
    return "primary";
  }

  return "outlined";
}

interface GetHighlightProps {
  isUnbalanced: boolean;
  risk?: RiskLevel;
}

export function getHighlight({
  isUnbalanced,
  risk,
}: GetHighlightProps): Highlight {
  if (isUnbalanced) {
    return "warning";
  }

  if (risk) {
    return RiskToHighlightMap[risk];
  }

  return "normal";
}
