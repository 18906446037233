import { useMemo } from "react";
import { SectionMeasurements } from "render/fragments/visit/Editors/SummaryEditor/sections";
import styles from "./MeasurementPreview.module.sass";
import { SidePreview } from "./components/SidePreview/SidePreview";

interface MeasurementPreviewProps {
  measurementName: string;
  measurements: SectionMeasurements;
}

function getMeasurement(
  measurementName: string,
  measurements: SectionMeasurements
) {
  const current = measurements.current.find(
    (m) => m.metricName === measurementName
  );
  const previous = measurements.previous.find(
    (m) => m.metricName === measurementName
  );
  return { current, previous };
}

export function MeasurementPreview({
  measurementName,
  measurements,
}: MeasurementPreviewProps) {
  const left = useMemo(() => {
    return getMeasurement(measurementName + ".left", measurements);
  }, [measurementName, measurements]);

  const right = useMemo(() => {
    return getMeasurement(measurementName + ".right", measurements);
  }, [measurementName, measurements]);

  const measurement = useMemo(() => {
    return getMeasurement(measurementName, measurements);
  }, [measurementName, measurements]);

  if (left.current && right.current) {
    return (
      <div className={styles.sides}>
        <SidePreview measurement={left} side="left" />
        <div className={styles.separator} />
        <SidePreview measurement={right} side="right" />
      </div>
    );
  }

  if (!measurement.current || measurement.current.dimensions.length < 1) {
    return <div className={styles.error}>Link: {measurementName}</div>;
  }

  return <SidePreview measurement={measurement} />;
}
