interface IntervalBoundary {
  value: number;
  inclusive?: boolean;
}

export const inRange = (
  val: number,
  start: IntervalBoundary,
  end: IntervalBoundary
) => {
  if (!start.inclusive && start.value >= val) {
    return false;
  }
  if (!end.inclusive && end.value <= val) {
    return false;
  }

  return start.value <= val && end.value >= val;
};
