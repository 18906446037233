import { toList } from "@pomle/react-flat-store";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { usePatient } from "render/hooks/api/usePatient";
import { useQuestionnaire } from "render/hooks/api/useQuestionnaire";
import { useVisitHistoryQuery } from "render/hooks/queries/useVisitHistoryQuery";
import { usePatientVisitsMetrics } from "render/hooks/patient/usePatientVisitsMetrics";
import { usePatientVisitSummaries } from "render/pages/PatientDetailPage/hooks/usePatientVisitSummaries";
import {
  compareSummaryRequest,
  latestSummaryRequest,
  questionRequest,
  suggestionRequest,
} from "../llmRequests";
import {
  addVisits,
  hasPreviousScan,
  latestOnly,
} from "../results/resultHelpers";
import { createMergedRecord } from "../results/results";
import { toContent } from "../utils";
import { usePatientVisits } from "render/hooks/api/usePatientVisits";

export function usePreBrief(patientId: string, visitId: string) {
  const api = useAPIClient();
  const visitHistory = useVisitHistoryQuery({ patientId });

  const patientVisitSummaries = usePatientVisitSummaries(patientId);
  const questionnaire = useQuestionnaire({ patientId, visitId });
  const patient = usePatient(patientId);

  const attendedVisitIds = useMemo(() => {
    if (visitHistory.data == null) {
      return undefined;
    }
    const result = [visitId];
    const isCurrentVisitAttended =
      visitHistory.data?.attendedVisitIds.includes(visitId);

    const previousVisitId =
      visitHistory.data?.medicalExamVisitTimeSeries.nekoBodyScan?.at(
        isCurrentVisitAttended ? 1 : 0
      )?.visitId;

    if (previousVisitId) {
      result.push(previousVisitId);
    }
    return result;
  }, [visitHistory.data, visitId]);

  const visits = usePatientVisits(patientId, attendedVisitIds);

  const patientVisitsMetrics = usePatientVisitsMetrics(patientId, visits);
  const visitSummaries = useMemo(
    () => toList(patientVisitSummaries.entries),
    [patientVisitSummaries.entries]
  );

  const mergedRecord = useMemo(() => {
    if (!patient) {
      return;
    }
    if (!patientVisitsMetrics) {
      return;
    }

    return createMergedRecord({
      patient,
      visitCount: 2,
      visitMetrics: patientVisitsMetrics,
      questionnaire: questionnaire.data,
    });
  }, [patient, patientVisitsMetrics, questionnaire.data]);

  const [latestSummary, setLatestSummary] = useState<string>();
  const [compareSummary, setCompareSummary] = useState<string>();
  const [suggestions, setSuggestions] = useState<string>();
  const [questions, setQuestions] = useState<string>();

  useEffect(() => {
    if (latestSummary) {
      return;
    }
    if (!mergedRecord) {
      return;
    }
    const latestOnlyResults = latestOnly(mergedRecord);
    const req = api.nlp.OAIChat(latestSummaryRequest(latestOnlyResults));
    req.result.then(toContent).then(setLatestSummary);
    return () => {
      req.abandon();
    };
  }, [api, latestSummary, mergedRecord]);

  useEffect(() => {
    if (compareSummary != null) {
      return;
    }
    if (!patientVisitSummaries.entries || mergedRecord == null) {
      return;
    }
    if (!hasPreviousScan(mergedRecord)) {
      setCompareSummary("");
      return;
    }

    const resultsWithSummaries = addVisits(mergedRecord, visitSummaries);
    const req = api.nlp.OAIChat(compareSummaryRequest(resultsWithSummaries));
    req.result.then(toContent).then(setCompareSummary);
    return () => {
      req.abandon();
    };
  }, [
    api.nlp,
    mergedRecord,
    patientVisitSummaries.entries,
    visitSummaries,
    compareSummary,
  ]);

  useEffect(() => {
    if (questions) {
      return;
    }
    if (latestSummary == null) {
      return;
    }
    const req = api.nlp.OAIChat(
      questionRequest(latestSummary, compareSummary ?? "")
    );
    req.result.then(toContent).then(setQuestions);

    return () => {
      req.abandon();
    };
  }, [api.nlp, compareSummary, latestSummary, questions]);

  useEffect(() => {
    if (suggestions) {
      return;
    }
    if (latestSummary == null || compareSummary == null) {
      return;
    }

    const req = api.nlp.OAIChat(
      suggestionRequest(latestSummary, compareSummary)
    );
    req.result.then(toContent).then(setSuggestions);
    return () => {
      req.abandon();
    };
  }, [api, compareSummary, latestSummary, mergedRecord, suggestions]);

  const regenerateBrief = useCallback(() => {
    setCompareSummary(undefined);
    setLatestSummary(undefined);
    setQuestions(undefined);
    setSuggestions(undefined);
  }, []);

  const generatedAt = useMemo(
    () => DateTime.now(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [latestSummary, compareSummary, suggestions, questions]
  );

  return useMemo(
    () => ({
      questionnaire,
      visitSummaries,
      generatedAt,
      results: mergedRecord,
      latestSummary,
      compareSummary,
      suggestions,
      questions,
      patientVisitsMetrics,
      regenerateBrief,
    }),
    [
      patientVisitsMetrics,
      questionnaire,
      visitSummaries,
      generatedAt,
      compareSummary,
      latestSummary,
      questions,
      suggestions,
      regenerateBrief,
      mergedRecord,
    ]
  );
}
