import { RiskMetric } from "lib/metric";
import { RiskMetricTool } from "lib/risk/tools";
import { useMemo } from "react";
import { MetricRange } from "render/ui/presentation/MetricRange";
import { toChartRanges } from "render/ui/presentation/RangeChart/lib/risk";

interface LDLRangeProps {
  metrics: RiskMetric<"bloodwork.ldl">[] | undefined;
}

export function LDLRange({ metrics }: LDLRangeProps) {
  const latest = metrics?.at(0);

  const entries = useMemo(() => {
    if (!metrics) {
      return [];
    }

    return metrics.map((metric) => {
      return {
        risk: metric.riskRanges.riskLevel,
        timestamp: metric.measurement.timestampStart,
        value: metric.unit["mmol/L"],
      };
    });
  }, [metrics]);

  const ranges = useMemo(() => {
    if (latest == null) {
      return [];
    }

    const riskIntervals = latest.riskRanges.riskIntervals;

    const intervals =
      riskIntervals.length > 0
        ? latest?.riskRanges.riskIntervals
        : RiskMetricTool.generateUnknownInterval({
            min: Math.min(...entries.map((entry) => entry.value)),
            max: Math.max(...entries.map((entry) => entry.value)),
          });

    return toChartRanges(intervals);
  }, [latest, entries]);

  // https://dev.azure.com/cur8/Engineering/_workitems/edit/26137
  const rangesCapLow = 1.5;
  const rangesCapHigh = 8.5;

  return (
    <MetricRange
      title="LDL"
      unit="mmol/L"
      entries={entries}
      ranges={ranges}
      rangesCapLow={rangesCapLow}
      rangesCapHigh={rangesCapHigh}
      formatValue={(value) => value?.toFixed(2)}
      showEmptyRanges
    />
  );
}
