import { RiskMetric } from "lib/metric";
import { BodySide } from "lib/types/body";
import { useMemo } from "react";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import { Typography } from "render/ui/presentation/Typography";
import { ABIResultItem } from "../ABIResultItem";
import { BloodPressureResultItem } from "../BloodPressureResultItem";
import styles from "./styles.module.sass";

interface CirculationSectionProps {
  side: BodySide;
}

const TileForSide: Record<BodySide, string> = {
  left: "Left",
  right: "Right",
};

function createAbi({
  left,
  right,
}: {
  left: RiskMetric<"cardio.abi.left"> | undefined;
  right: RiskMetric<"cardio.abi.right"> | undefined;
}) {
  if (!left || !right) {
    return undefined;
  }
  return {
    left,
    right,
  };
}

export function CirculationSection({ side }: CirculationSectionProps) {
  const {
    metrics: { cardio },
  } = usePatientData();
  const bloodPressureMetric = cardio.brachialPressure[side]?.at(0);
  const prevBloodPressureValue = cardio.brachialPressure[side]?.at(1);

  const currentAbiMetric = useMemo(
    () =>
      createAbi({
        left: cardio.abi.left?.at(0),
        right: cardio.abi.right?.at(0),
      }),
    [cardio.abi.left, cardio.abi.right]
  );
  const previousAbiMetric = useMemo(
    () =>
      createAbi({
        left: cardio.abi.left?.at(1),
        right: cardio.abi.right?.at(1),
      }),
    [cardio.abi.left, cardio.abi.right]
  );

  return (
    <div className={styles.CirculationSection}>
      <Typography variant="title-m">{TileForSide[side]}</Typography>

      <hr />

      <div className={styles.ResultContainer}>
        {bloodPressureMetric && (
          <BloodPressureResultItem
            currentMetric={bloodPressureMetric}
            previousMetric={prevBloodPressureValue}
            bloodPressureType={"systolic_blood_pressure"}
          />
        )}
        {currentAbiMetric && (
          <ABIResultItem
            currentMetric={currentAbiMetric}
            previousMetric={previousAbiMetric}
          />
        )}
      </div>
    </div>
  );
}
