import { RiskLabel, getRiskWeight } from "@cur8/measurements";
import { RiskMetric } from "lib/metric";

interface GetEyePressureStatusParams {
  current: {
    left: RiskMetric<"risk_assessment.eye_pressure.left"> | undefined;
    right: RiskMetric<"risk_assessment.eye_pressure.right"> | undefined;
    difference:
      | RiskMetric<"risk_assessment.eye_pressure.difference">
      | undefined;
  };
}
export type EyePressureStatus =
  | "no-data"
  | "unbalanced"
  | "elevated"
  | "low"
  | "normal"
  | "high";

function toEyePressureStatusAdapterFor(
  riskLabel: RiskLabel
): EyePressureStatus {
  const record: Record<RiskLabel, EyePressureStatus> = {
    [RiskLabel.Balanced]: "normal",
    [RiskLabel.Diabetes]: "unbalanced",
    [RiskLabel.Elevated]: "elevated",
    [RiskLabel.High]: "high",
    [RiskLabel.Low]: "low",
    [RiskLabel.Mild]: "unbalanced",
    [RiskLabel.Moderate]: "unbalanced",
    [RiskLabel.Normal]: "normal",
    [RiskLabel.Optimal]: "normal",
    [RiskLabel.PreDiabetes]: "unbalanced",
    [RiskLabel.Unbalanced]: "unbalanced",
    [RiskLabel.Undefined]: "no-data",
    [RiskLabel.VeryHigh]: "high",
    [RiskLabel.VeryLow]: "low",
    [RiskLabel.VeryUnbalanced]: "unbalanced",
    [RiskLabel.Prolonged]: "unbalanced",
    [RiskLabel.Shortened]: "unbalanced",
    [RiskLabel.LeftDeviation]: "unbalanced",
    [RiskLabel.RightDeviation]: "unbalanced",
  };
  return record[riskLabel] ?? "no-data";
}

export function getEyePressureStatus({
  current,
}: GetEyePressureStatusParams): EyePressureStatus {
  const { difference, left, right } = current;

  if (!difference || !left || !right) {
    return "no-data";
  }

  const [highest] = [difference, left, right].sort((a, b) => {
    return (
      getRiskWeight(b.riskRanges.riskLevel) -
      getRiskWeight(a.riskRanges.riskLevel)
    );
  });

  const interval = highest.riskRanges.findIntervalFor(highest.unit);
  const intervalLabel = interval?.riskLabel ?? RiskLabel.Undefined;

  const result = toEyePressureStatusAdapterFor(intervalLabel);

  return result;
}
