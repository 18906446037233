import {
  Assessment,
  isPadAssessment,
  isPulseWaveAssessment,
  isThermalAssessment,
} from "@cur8/rich-entity";
import { Typography } from "render/ui/presentation/Typography";
import { CloseIcon } from "render/ui/symbol/HoverIcon";
import { FigureButton } from "render/ui/trigger/FigureButton";
import { useAssessmentContext } from "../../context/AssessmentContext";
import { useAssessmentNav } from "../../hooks/useAssessmentNav";
import { toReadableState } from "../../lib/utils";
import { AssessedBy } from "./AssessedBy";
import styles from "./styles.module.sass";

interface HistoryListProps {
  assessments: Assessment[];
  hide: () => void;
}

export function HistoryList({ assessments, hide }: HistoryListProps) {
  const { selected } = useAssessmentContext();
  const { setVersionId } = useAssessmentNav();

  return (
    <div className={styles.HistoryList}>
      <div className={styles.header}>
        <Typography variant="title-s">All assessments</Typography>
        <FigureButton onClick={hide}>
          <CloseIcon color="black" />
        </FigureButton>
      </div>
      <div className={styles.scrollbox}>
        {assessments.map((ass) => (
          <div key={ass.itemVersion}>
            <div className={styles.topbar}>
              <div className={styles.byline}>
                <label>Created</label>
                <span>{ass.createdAt.toFormat("yyyy-LL-dd HH:mm")}</span>
              </div>
              {ass.itemVersion !== selected.itemVersion && (
                <button onClick={() => setVersionId(ass.itemVersion)}>
                  View version
                </button>
              )}
            </div>
            <div className={styles.version} data-type={ass.itemTypeName}>
              {isPadAssessment(ass) && (
                <div className={styles.data}>
                  <label>Shallow Blood Concentration</label>
                  <div className={styles.valueItem}>
                    <span className={styles.value}>
                      {ass.ratios?.bloodVolumeFractionT1.toFixed(2)}
                    </span>
                  </div>
                  <label>Shallow oxygenation</label>
                  <div className={styles.valueItem}>
                    <span className={styles.value}>
                      {ass.ratios?.oxygenationT1.toFixed(2)}
                    </span>
                  </div>
                </div>
              )}
              {isPulseWaveAssessment(ass) && (
                <div className={styles.data}>
                  <label>LVETI</label>
                  <div className={styles.valueItem}>
                    <span className={styles.value}>{ass.lveti?.average}</span>
                    <span className={styles.unit}>ms</span>
                  </div>
                  <label>Pulsewave Velocity</label>
                  <div className={styles.valueItem}>
                    <span className={styles.value}>
                      {ass.pulseWaveVelocity?.value}
                    </span>
                    <span className={styles.unit}>m/s</span>
                  </div>
                  <label>Hand asymmetry</label>
                  <div className={styles.valueItem}>
                    <span className={styles.value}>
                      {ass.handAsymmetry?.value}
                    </span>
                    <span className={styles.unit}>ms</span>
                  </div>
                  <label>Foot asymmetry</label>
                  <div className={styles.valueItem}>
                    <span className={styles.value}>
                      {ass.footAsymmetry?.value}
                    </span>
                    <span className={styles.unit}>ms</span>
                  </div>
                </div>
              )}
              {isThermalAssessment(ass) && (
                <div className={styles.data}>
                  <label>Status</label>
                  <div className={styles.valueItem}>
                    <span className={styles.valueText}>{ass.status}</span>
                  </div>
                </div>
              )}

              <div
                className={styles.assessmentInfo}
                data-state={ass.assessmentState}
              >
                <div className={styles.item}>
                  <label>State</label>
                  <span className={styles.state}>
                    {toReadableState(ass.assessmentState)}
                  </span>
                </div>
                {ass.comment && (
                  <div className={styles.item}>
                    <label>Comment</label>
                    <span className={styles.comment}>{ass.comment}</span>
                  </div>
                )}
                {ass.audit && (
                  <div className={styles.byline}>
                    <label>by</label>
                    <span>
                      <AssessedBy auditInfo={ass.audit} />
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
