import { APITypesV1 } from "@cur8/api-client";
import {
  Assessment,
  isPadAssessment,
  isPulseWaveAssessment,
  isThermalAssessment,
} from "@cur8/rich-entity";
import {
  CreatePadAssessmentRequestBox,
  CreateThermalAssessmentRequestBox,
  LVETISelectionState,
  MutablePulseWaveAssessment,
  MutablePulseWaveLVETIAssessmentData,
  MutablePulseWaveLVETISelections,
} from "./types";

export function toMutablePulseWaveLVETIAssessmentData(
  lveti?: APITypesV1.PulseWaveLVETIAssessmentData
): MutablePulseWaveLVETIAssessmentData {
  return {
    average: lveti?.average,
    medianSSNSignalQuality: lveti?.medianSSNSignalQuality ?? undefined,
    modelNameAndVersion: lveti?.modelNameAndVersion ?? undefined,
    selections: toMutablePulseWaveLVETISelections(lveti?.selections),
  };
}

function toMutablePulseWaveLVETISelections(
  sels?: APITypesV1.LVETIRangeData[]
): MutablePulseWaveLVETISelections {
  return {
    0: {
      lveti: sels && sels[0] ? sels[0].lveti : undefined,
      rrInterval: sels && sels[0].rrInterval ? sels[0].rrInterval : undefined,
      ssn: sels && sels[0].ssn ? sels[0].ssn : undefined,
      state: LVETISelectionState.empty,
    },
    1: {
      lveti: sels && sels[1] ? sels[1].lveti : undefined,
      rrInterval: sels && sels[1].rrInterval ? sels[1].rrInterval : undefined,
      ssn: sels && sels[1].ssn ? sels[1].ssn : undefined,
      state: LVETISelectionState.empty,
    },
    2: {
      lveti: sels && sels[2] ? sels[2].lveti : undefined,
      rrInterval: sels && sels[2].rrInterval ? sels[2].rrInterval : undefined,
      ssn: sels && sels[2].ssn ? sels[2].ssn : undefined,
      state: LVETISelectionState.empty,
    },
  };
}

/**
 * Return a fully valid LVETI assessment data or `undefined`
 * if any vital data is missing.
 */
export function mutableLVETIToAPIType(
  lveti: MutablePulseWaveLVETIAssessmentData
): APITypesV1.PulseWaveLVETIAssessmentData | undefined {
  const sels = lveti.selections;
  if (
    sels[0] &&
    sels[1] &&
    sels[2] &&
    sels[0].rrInterval &&
    sels[1].rrInterval &&
    sels[2].rrInterval &&
    sels[0].ssn &&
    sels[1].ssn &&
    sels[2].ssn
  ) {
    return {
      average: lveti.average,
      selections: [
        {
          lveti: sels[0].lveti,
          rrInterval: sels[0].rrInterval,
          ssn: sels[0].ssn,
        },
        {
          lveti: sels[1].lveti,
          rrInterval: sels[1].rrInterval,
          ssn: sels[1].ssn,
        },
        {
          lveti: sels[2].lveti,
          rrInterval: sels[2].rrInterval,
          ssn: sels[2].ssn,
        },
      ],
    };
  }
  return undefined;
}

export function initPAD(
  ass: Assessment
): CreatePadAssessmentRequestBox | undefined {
  if (isPadAssessment(ass)) {
    return {
      assessmentState: ass.assessmentState,
      regionsOfInterest: ass.regionsOfInterest,
      ratios: ass.ratios,
    };
  }
  return undefined;
}

export function initPW(
  ass: Assessment
): MutablePulseWaveAssessment | undefined {
  if (isPulseWaveAssessment(ass)) {
    return {
      ...ass,
      assessmentState: ass.assessmentState,
      lveti: toMutablePulseWaveLVETIAssessmentData(ass.lveti),
      footAsymmetry: ass.footAsymmetry,
      handAsymmetry: ass.handAsymmetry,
      pulseWaveVelocity: ass.pulseWaveVelocity,
    };
  }
  return undefined;
}

export function initThermal(
  ass: Assessment
): CreateThermalAssessmentRequestBox | undefined {
  if (isThermalAssessment(ass)) {
    return {
      assessmentState: ass.assessmentState,
      status: undefined,
    };
  }
  return undefined;
}
