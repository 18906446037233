import { APITypesV1 } from "@cur8/api-client";
import styles from "./styles.module.sass";
import CrossIcon from "./assets/cross-icon.svg?react";
import { useQueryParams } from "@pomle/react-router-paths";
import { query } from "render/routes/querys";
import { useCallback } from "react";

const medicalExamsTypeToLabelAdapter: Record<APITypesV1.MedicalExam, string> = {
  [APITypesV1.MedicalExam.Dermascope]: "Dermascope",
  [APITypesV1.MedicalExam.Dermatologist]: "Dermatologist",
  [APITypesV1.MedicalExam.ExternalReferral]: "ExternalReferral",
  [APITypesV1.MedicalExam.GeneralExam]: "GeneralExam",
  [APITypesV1.MedicalExam.NekoBodyScan]: "NekoBodyScan",
  [APITypesV1.MedicalExam.Perimed]: "Perimed",
  [APITypesV1.MedicalExam.TwelveLeadECG]: "TwelveLeadECG",
  [APITypesV1.MedicalExam.Ultrasound]: "Ultrasound",
};

export function MedicalExamsFilter({
  data,
}: {
  data: APITypesV1.MedicalExam[];
}) {
  const [, setQueryParams] = useQueryParams(query.schedule);
  const onClick = useCallback(
    () => setQueryParams({ medicalExams: [] }),
    [setQueryParams]
  );

  return (
    <div className={styles.MedicalExamsFilter}>
      Showing only slots with:{" "}
      <strong>
        [{data.map((type) => medicalExamsTypeToLabelAdapter[type]).join(", ")}]
      </strong>
      <button className={styles.button} onClick={onClick}>
        <CrossIcon />
      </button>
    </div>
  );
}
