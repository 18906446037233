import { RiskLevel } from "@cur8/measurements";
import { RiskMetric } from "lib/metric";
import { byPressure } from "lib/metric/sort";
import { useMemo } from "react";
import {
  FloatingValueTarget,
  RiskToHighlightMap,
} from "render/ui/presentation/FloatingValueTarget";
import { ValueItem } from "render/ui/symbol/ValueItem";

interface MaxAnklePressureProps {
  anklePressure: {
    left: RiskMetric<"cardio.ankle_pressure.left">[] | undefined;
    right: RiskMetric<"cardio.ankle_pressure.right">[] | undefined;
  };
}

export function MaxAnklePressure({
  anklePressure: { left, right },
}: MaxAnklePressureProps) {
  const highestPressure = useMemo(() => {
    if (!left || !right) {
      return;
    }

    return [...left.slice(0, 1), ...right.slice(0, 1)]
      .sort(byPressure("desc"))
      .at(0);
  }, [left, right]);

  const risk = highestPressure?.riskRanges.riskLevel;

  const highlight = RiskToHighlightMap[risk ?? RiskLevel.Unknown];

  return (
    <FloatingValueTarget
      label="Ankle Pressure"
      value={
        <ValueItem
          value={highestPressure ? highestPressure.unit.mmHg : "--"}
          unit="mmHg"
          size="huge"
          alignItems="baseline"
        />
      }
      align="bottom-right"
      width={210}
      highlight={highlight}
    />
  );
}
