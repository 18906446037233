import { MetricRiskInterval, RiskLevel } from "@cur8/measurements";
import { ChartRange } from "../types";

export function toChartRanges(ranges: MetricRiskInterval[]): ChartRange[] {
  return ranges.map((range) => {
    const isToInfinity = !isFinite(range.end.value);
    const isFromInfinity = !isFinite(range.start.value);

    return {
      fromIsInclusive: isFromInfinity ? true : range.start.inclusive ?? false,
      from: range.start.value,
      toIsInclusive: isToInfinity ? true : range.end.inclusive ?? false,
      to: range.end.value,
      risk: range.riskLevel,
      label: range.riskLabel,
    };
  });
}

interface IntervalBoundary {
  value: number;
  inclusive?: boolean;
}

function isInsideInterval(
  start: IntervalBoundary,
  end: IntervalBoundary,
  value: number
) {
  if (!start.inclusive && start.value >= value) {
    return false;
  }
  if (!end.inclusive && end.value <= value) {
    return false;
  }

  return start.value <= value && end.value >= value;
}

type SelectChartRangesStaticMode = {
  mode: "static";
  startAt: RiskLevel;
  endAt: RiskLevel;
};

type SelectChartRangesDynamicMode = {
  mode: "dynamic";
  currentValue: number | undefined;
  /**
   * Maximum number of ranges to display
   */
  maxNumOfRanges?: number;
  /**
   * Number of ranges to display before the active range (e.g. 1 means 1 range before the active range)
   */
  rangeLookbehindCount?: number;
};

/**
 * Selects a subset of chart ranges based on the provided options:
 *
 * - Static mode: Filters ranges based on the provided risk levels and labels
 * - Dynamic mode: dynamically selects ranges based on the current value and the max number of ranges to display.
 *
 * @returns {ChartRange[]} Subset of original chart ranges
 */
export function selectChartRanges(
  ranges: ChartRange[],
  options: SelectChartRangesStaticMode | SelectChartRangesDynamicMode
) {
  if (options.mode === "static") {
    const { startAt, endAt } = options;

    const sortedRanges = ranges.toSorted((a, b) => a.to - b.to);
    const sliceStartRange = sortedRanges.find(
      (range) => range.risk === startAt
    );
    const sliceEndRange = sortedRanges.findLast(
      (range) => range.risk === endAt
    );

    if (!sliceStartRange) {
      console.warn(
        `Select chart ranges: risk interval with level "${startAt} not found in ranges:"`,
        ranges
      );
    }

    if (!sliceEndRange) {
      console.warn(
        `Select chart ranges: risk interval with level "${endAt} not found in ranges:"`,
        ranges
      );
    }

    const sliceStartIndex =
      sliceStartRange != null ? sortedRanges.indexOf(sliceStartRange) : 0;

    const sliceEndIndex =
      sliceEndRange != null
        ? sortedRanges.indexOf(sliceEndRange) + 1
        : sortedRanges.length;

    if (sliceStartIndex >= sliceEndIndex) {
      console.warn(
        "Select chart ranges: invalid slice options, no range subset selected"
      );
      return ranges;
    }

    const selectedRanges = sortedRanges.slice(sliceStartIndex, sliceEndIndex);

    return selectedRanges;
  } else {
    const {
      currentValue,
      maxNumOfRanges = 3,
      rangeLookbehindCount = 1,
    } = options;

    if (!currentValue) {
      return ranges.slice(0, maxNumOfRanges);
    }

    const sortedRanges = ranges.toSorted((a, b) => a.to - b.to);

    const activeRange = sortedRanges.find((range, index) => {
      const isFirstRange = index === 0;
      const isLastRange = index === sortedRanges.length - 1;

      if (isFirstRange) {
        return currentValue < range.to;
      }

      if (isLastRange) {
        return currentValue >= range.from;
      }

      return isInsideInterval(
        { value: range.from, inclusive: range.fromIsInclusive },
        { value: range.to, inclusive: range.toIsInclusive },
        currentValue
      );
    });

    const index = activeRange ? sortedRanges.indexOf(activeRange) : 0;
    const len = sortedRanges.length;
    let start = index - rangeLookbehindCount;
    let end = start + maxNumOfRanges;

    if (end > len) {
      end = len;
      start = Math.max(0, end - maxNumOfRanges);
    }
    if (start < 0) {
      start = 0;
      end = Math.min(start + maxNumOfRanges, len);
    }

    return sortedRanges.slice(start, end);
  }
}
