import {
  $convertFromMarkdownString,
  $convertToMarkdownString,
  LINK,
} from "@lexical/markdown";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { $getRoot, EditorState } from "lexical";
import { useCallback, useEffect, useRef } from "react";
import { BulletBackspacePlugin } from "./BulletBackspacePlugin";
import { BulletReturnPlugin } from "./BulletReturnPlugin";
import { LinkBackspacePlugin } from "./LinkBackspacePlugin";

interface BulletEditorPluginsProps {
  value: string[] | undefined;
  onChange: (value: string[]) => void;
}

export function BulletEditorPlugins({
  value,
  onChange,
}: BulletEditorPluginsProps) {
  const [editor] = useLexicalComposerContext();
  const prevValueRef = useRef<string[] | null>(null);

  useEffect(() => {
    // Ensure we load content only once
    if (prevValueRef.current === null && value) {
      prevValueRef.current = value;
      editor.update(() => {
        const root = $getRoot();
        root.clear();
        const markdownText = value.join("\n\n");
        $convertFromMarkdownString(markdownText, [LINK]);
      });
    }
  }, [editor, value]);

  const handleChange = useCallback(
    (editorState: EditorState) => {
      editorState.read(() => {
        let markdown = $convertToMarkdownString([LINK]);
        // Remove any unintended newline characters from each bullet.
        markdown = markdown.replace(/\n+/g, "\n");
        const bullets = markdown
          .split("\n")
          .map((bullet) => bullet.replace(/\n/g, ""));
        onChange(bullets);
      });
    },
    [onChange]
  );

  return (
    <>
      <OnChangePlugin onChange={handleChange} />
      <HistoryPlugin />
      <BulletReturnPlugin />
      <BulletBackspacePlugin />
      <LinkPlugin />
      <LinkBackspacePlugin />
    </>
  );
}
