import { RiskMetric } from "lib/metric";
import { useMemo } from "react";
import { BalanceGraph } from "render/ui/presentation/BalanceGraph";
import {
  AuxTitle,
  Diff,
  DiffValue,
  MainTitle,
  MetricResultHeader,
  Titles,
} from "render/ui/presentation/MetricResultHeader";
import styles from "./styles.module.sass";
import { RiskLevel } from "@cur8/measurements";

const RiskToHighlightMap: Record<
  RiskLevel,
  "danger" | "warning" | "normal" | "none"
> = {
  [RiskLevel.Optimal]: "normal",
  [RiskLevel.Normal]: "normal",
  [RiskLevel.Risk]: "warning",
  [RiskLevel.LowRisk]: "warning",
  [RiskLevel.ModerateRisk]: "danger",
  [RiskLevel.HighRisk]: "danger",
  [RiskLevel.ImmediateRisk]: "danger",
  [RiskLevel.Unknown]: "normal",
};

interface BloodPressureBalanceSegmentProps {
  brachialPressureDifferenceMetric:
    | RiskMetric<"cardio.brachial_pressure.difference">
    | undefined;
}

export function BloodPressureBalanceSegment({
  brachialPressureDifferenceMetric,
}: BloodPressureBalanceSegmentProps) {
  const diff = brachialPressureDifferenceMetric?.unit.systolic.mmHg;
  const risk = useMemo(() => {
    const riskLevel =
      brachialPressureDifferenceMetric?.riskRanges.systolic.riskLevel ??
      RiskLevel.Unknown;

    return riskLevel;
  }, [brachialPressureDifferenceMetric?.riskRanges.systolic.riskLevel]);

  return (
    <div className={styles.BloodPressureBalanceSegment}>
      <MetricResultHeader>
        <Titles>
          <MainTitle>Balance</MainTitle>
          <AuxTitle>[Systolic]</AuxTitle>
        </Titles>
        <Diff>
          <DiffValue unit="mmHg" />
        </Diff>
      </MetricResultHeader>
      <div className={styles.graph}>
        <BalanceGraph
          value={diff ?? 0}
          highlight={RiskToHighlightMap[risk]}
          bounds={{ min: -20, max: 20 }}
          labels={{ from: "R", to: "L" }}
        />
      </div>
    </div>
  );
}
