import { FollowUpMessage } from "@cur8/rich-entity";
import { DateTime } from "luxon";
import { MouseEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { PatientName } from "render/fragments/patient/PatientName";
import { MarkdownEditor } from "render/fragments/visit/Editors/MarkdownEditor/MarkdownEditor";
import { usePatient } from "render/hooks/api/usePatient";
import { useFormHandle } from "render/hooks/useFormHandle";
import { useReporting } from "render/hooks/useReporting";
import { useImmutableVisitSummaries } from "render/pages/PatientDetailPage/hooks/useImmutableVisitSummaries"; // TODO: move this hook?
import { DayMonthYearDate } from "render/ui/format/DayMonthYearDate";
import { PageHeader } from "render/ui/layouts/PageHeader";
import { BackButtonClick } from "render/ui/trigger/BackButtonClick";
import { SubmitButton } from "render/ui/trigger/SubmitButton";
import styles from "./styles.module.sass";

interface FollowUpMessageProps {
  patientId: string;
  visitId: string;
  notificationId?: string; // if present: we are updating the message!
  hide: (event: MouseEvent) => void;
}

export function FollowUpMessageComponent({
  patientId,
  visitId,
  notificationId,
  hide,
}: FollowUpMessageProps) {
  const api = useAPIClient();
  const { logError } = useReporting();

  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [originalMessage, setOriginalMessage] = useState<FollowUpMessage>();

  const isValid = useMemo(() => {
    if (isSent) {
      return false;
    }
    return (
      title.length > 3 &&
      body.length > 0 &&
      (title !== originalMessage?.title || body !== originalMessage?.body)
    );
  }, [isSent, title, body, originalMessage]);

  const patient = usePatient(patientId);

  const visitSummaries = useImmutableVisitSummaries(patientId);

  useEffect(() => {
    // check if we are updating or creating new. load message if updating..
    if (notificationId) {
      const currentSummary = visitSummaries.entries.find(
        (e) => e.visitId === visitId
      );
      const currentMessage = currentSummary?.followUpMessages.find(
        (m) => m.notificationId === notificationId
      );

      if (currentMessage) {
        setOriginalMessage(currentMessage);
        setTitle(currentMessage.title);
        setBody(currentMessage.body);
        setIsUpdating(true);
      }
    } else {
      const localizedDefaultTitle =
        patient?.preferredLanguage === "sv" ? "Uppdatering" : "Summary update";
      setTitle(localizedDefaultTitle);
    }
  }, [visitSummaries.entries, visitId, notificationId, patient]);

  const titleInput = useCallback(
    (value: string, onChange: (value: string) => void) => {
      function handleTitleKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
        if (event.key === "Enter") {
          event.preventDefault();
        }
      }

      return (
        <input
          type="text"
          value={value}
          placeholder="Add title..."
          onKeyDown={handleTitleKeyDown}
          onChange={(e) => onChange(e.target.value)}
          required
        />
      );
    },
    []
  );

  const submit = useCallback(async () => {
    if (isUpdating && originalMessage) {
      api.immutableVisitSummary
        .updateFollowUpMessage(
          {
            patientId,
            visitId,
            notificationId: originalMessage.notificationId,
          },
          { title, body }
        )
        .result.catch(logError);
    } else {
      // create new!
      api.immutableVisitSummary
        .createFollowUpMessage({ patientId, visitId }, { title, body })
        .result.catch(logError);
    }
    setIsSent(true);
  }, [
    api.immutableVisitSummary,
    isUpdating,
    patientId,
    logError,
    originalMessage,
    title,
    body,
    visitId,
  ]);

  const formHandle = useFormHandle(submit);

  const buttonText = useMemo(() => {
    if (isSent) {
      return "Message sent";
    }
    return isUpdating ? "Send update message" : "Send message";
  }, [isSent, isUpdating]);

  return (
    <div className={styles.FollowUpMessage}>
      <div className={styles.container}>
        <BackButtonClick onClick={hide}>
          {patient ? <PatientName patient={patient} /> : "Back"}
        </BackButtonClick>
        <div className={styles.title}>
          <PageHeader caption="New summary update" />
          <div className={styles.subtitle}>
            <div className={styles.subtitleText}>
              Summary updates are visible as entries in the member app. Use dash
              or star + space to create a bullet point. Click the title to edit.
            </div>
            <div className={styles.date}>
              <DayMonthYearDate date={DateTime.now()} />
            </div>
          </div>
        </div>
        <div className={styles.content}>
          <form onSubmit={formHandle.onSubmit}>
            <div className={styles.editorWrapper}>
              <h3>{titleInput(title, setTitle)}</h3>
              <MarkdownEditor
                value={body}
                onChange={setBody}
                placeholder="Add message here..."
              />
            </div>
            <div className={styles.submit}>
              <SubmitButton handle={formHandle} disabled={!isValid}>
                {buttonText}
              </SubmitButton>
              {!isValid && !isSent && <label>Add content to the message</label>}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
