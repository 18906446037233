import { Patient } from "@cur8/rich-entity";
import { Sticky } from "@pomle/react-viewstack";
import { useLLMConsent } from "lib/doctor-scribe/useLLMConsent";
import { FLAGS, Flags } from "lib/flags";
import { useCallback, useState } from "react";
import { useAppInsights } from "render/context/AppInsightsContext";
import { useMSAL } from "render/context/MSALContext";
import { PatientEncyclopediaComponent } from "render/pages/PatientEncyclopediaPage/components/PatientEncyclopediaComponent";
import { HoverTextButton } from "render/ui/trigger/HoverTextButton";
import DataTableIcon from "./assets/datatable.svg?react";
import PreBriefDataIcon from "./assets/prebrief.svg?react";
import SummariesIcon from "./assets/summaries.svg?react";
import { HideButton } from "./components/HideButton";
import { JSONView } from "./components/JSONView";
import { LLMItem } from "./components/LLMItem";
import { Questionnaire } from "./components/Questionnaire";
import { RightDrawer } from "./components/RightDrawer";
import { VisitSummaryView } from "./components/VisitSummaryView";
import styles from "./styles.module.sass";

import { usePreBrief } from "./hooks/usePreBrief";

interface LLMBriefProps {
  patient: Patient;
  visitId: string;
}

export function LLMBrief({ patient, visitId }: LLMBriefProps) {
  const preBrief = usePreBrief(patient.patientId, visitId);
  const [showBrief, setShowBrief] = useState(false);
  const [showDataView, setShowDataView] = useState(false);
  const [showPreviousSummaries, setShowPreviousSummaries] = useState(false);
  const [showDataTable, setShowDataTable] = useState(false);
  const [showQuestionnaireResponse, setShowQuestionnaireResponse] =
    useState(false);

  const { patientId } = patient;
  const { canUseSuggestions } = useLLMConsent(patientId);
  const { auth } = useMSAL();
  const appInsights = useAppInsights();

  const trackEvent = useCallback(
    (name: string) => {
      if (patient && auth && auth.account) {
        appInsights.trackEvent(
          { name },
          {
            userId: auth.account.homeAccountId,
            patient: patient.patientId,
          }
        );
      }
    },
    [appInsights, auth, patient]
  );

  const closeAllDrawers = useCallback(() => {
    setShowPreviousSummaries(false);
    setShowDataTable(false);
    setShowDataView(false);
    setShowQuestionnaireResponse(false);
  }, []);

  return (
    <div className={styles.LLMBrief}>
      <RightDrawer
        title="Visit Summaries"
        onClose={() => {
          setShowPreviousSummaries(false);
        }}
        active={showPreviousSummaries}
      >
        <VisitSummaryView visits={preBrief.visitSummaries} />
      </RightDrawer>

      <RightDrawer
        title="Data Table"
        active={showDataTable}
        onClose={() => setShowDataTable(false)}
      >
        <Sticky>
          {showDataTable ? (
            <PatientEncyclopediaComponent
              metrics={preBrief.patientVisitsMetrics}
              questionnaire={preBrief.questionnaire.data}
            />
          ) : null}
        </Sticky>
      </RightDrawer>

      <RightDrawer
        title="Pre-brief Data"
        active={showDataView}
        onClose={() => setShowDataView(false)}
      >
        <JSONView data={preBrief.results} />
      </RightDrawer>

      <RightDrawer
        title="Questionnaire response"
        active={showQuestionnaireResponse}
        onClose={() => setShowQuestionnaireResponse(false)}
      >
        <Questionnaire
          data={preBrief.questionnaire.data?.questionsAndAnswers}
        />
      </RightDrawer>

      {canUseSuggestions || FLAGS.has(Flags.DoctorBrief) ? (
        <div className={styles.container}>
          <div className={styles.title}>
            <h1>Pre-brief</h1>
            <HideButton
              dohide={!showBrief}
              onClick={() => {
                setShowBrief((p) => !p);
                trackEvent("brief_toggled");
              }}
            />
          </div>

          <div className={styles.header}>
            {showBrief && (
              <div>
                <p>
                  The pre-brief below is AI generated. Always use your own
                  clinical judgment to assess the relevant underlying data and
                  to consider any suggestions independently.
                </p>
                {preBrief.generatedAt && (
                  <div className={styles.generated}>
                    <p className={styles.generatedTime}>
                      Generated{" "}
                      {preBrief.generatedAt.toFormat("yyyy-LL-dd HH:mm")}
                    </p>
                    <div className={styles.noWrap}>
                      <HoverTextButton onClick={preBrief.regenerateBrief}>
                        Re-generate Brief
                      </HoverTextButton>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className={showBrief ? styles.body : styles.hideBody}>
            <LLMItem
              title="Summary of latest scan"
              response={preBrief.latestSummary}
            />
            <LLMItem
              title="Comparison with earlier scans"
              response={preBrief.compareSummary}
            />
            <LLMItem
              title="Suggestions for consideration"
              response={preBrief.suggestions}
            />
            <LLMItem
              title="Suggested questions to ask"
              response={preBrief.questions}
            />
            <div>
              <HoverTextButton
                disabled={preBrief.results == null}
                onClick={() => {
                  closeAllDrawers();
                  if (!showDataView) {
                    setShowDataView(true);
                    trackEvent("brief_lookedAtAllData");
                  }
                }}
              >
                <div className={styles.buttonLabel}>
                  Pre-brief data{" "}
                  <PreBriefDataIcon
                    style={{ opacity: preBrief.results ? 1 : 0.4 }}
                  />
                </div>
              </HoverTextButton>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.missing}>
          Cannot generate briefs due to missing consent.
        </div>
      )}
      <div className={styles.buttons}>
        {preBrief.visitSummaries.length > 0 && (
          <HoverTextButton
            onClick={() => {
              closeAllDrawers();
              if (!showPreviousSummaries) {
                setShowPreviousSummaries(true);
                trackEvent("brief_lookedAtSummaries");
              }
            }}
          >
            <div className={styles.buttonLabel}>
              Visit summaries <SummariesIcon />
            </div>
          </HoverTextButton>
        )}
        <HoverTextButton
          onClick={() => {
            closeAllDrawers();
            if (!showDataTable) {
              setShowDataTable(true);
              trackEvent("brief_lookedAtDataTable");
            }
          }}
        >
          <div className={styles.buttonLabel}>
            Data table
            <DataTableIcon />
          </div>
        </HoverTextButton>
        <HoverTextButton
          onClick={() => {
            closeAllDrawers();
            if (!showQuestionnaireResponse) {
              setShowQuestionnaireResponse(true);
              trackEvent("brief_lookedAtAnswers");
            }
          }}
        >
          <div className={styles.buttonLabel}>Questionnaire response</div>
        </HoverTextButton>
      </div>
    </div>
  );
}
