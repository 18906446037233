import { BodyAreas } from "lib/smpl";
import { toCenter } from "lib/three";
import { useMemo } from "react";
import { MaxArmBloodpressure } from "render/fragments/avatar/overlay/MaxArmBloodpressure";
import { Waist } from "render/fragments/avatar/overlay/Waist";
import { ScreenProjector } from "render/hooks/three/useProject";
import { useScreenAnchorsForObjects } from "render/hooks/three/useScreenAnchors";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import { Layer } from "render/pages/DashboardPage/types";
import * as THREE from "three";
import { Overlay } from "../../components/Overlay";
import styles from "./styles.module.sass";

interface DiabetesLayerProps {
  layer: Layer;
  areas: BodyAreas;
  projector: ScreenProjector;
}

export function DiabetesLayer({ layer, areas, projector }: DiabetesLayerProps) {
  const active = layer === Layer.Diabetes;

  const {
    metrics: { cardio },
    smpl: { metrics },
  } = usePatientData();

  const positions = useMemo(() => {
    const PRESSURE_LABEL_MIN_X = -300;

    const waist = new THREE.Vector3(
      areas.waist.max.x + 120,
      (areas.waist.min.y + areas.waist.max.y) / 2,
      (areas.waist.min.z + areas.waist.max.z) / 2
    );

    const rightShoulder = toCenter(areas.rightShoulder);

    const pressureLabel = rightShoulder.clone();
    pressureLabel.x += -100;
    pressureLabel.x = Math.max(pressureLabel.x, PRESSURE_LABEL_MIN_X);

    return {
      waist,
      pressureLabel,
    };
  }, [areas]);

  const preferredWaist = useMemo(() => {
    if (!metrics) {
      return;
    }

    return metrics.at(0)?.unit.circumferences.waist;
  }, [metrics]);

  const anchors = useScreenAnchorsForObjects(projector, positions, active);

  return (
    <div className={styles.DiabetesLayer} data-active={active}>
      {cardio.brachialPressure.left && cardio.brachialPressure.right && (
        <Overlay pos={anchors?.pressureLabel}>
          <div className={styles.item}>
            <MaxArmBloodpressure
              brachialPressure={cardio.brachialPressure}
              align="top-left"
              withKnob={false}
            />
          </div>
        </Overlay>
      )}

      {preferredWaist && (
        <Overlay pos={anchors?.waist}>
          <div className={styles.item}>
            <Waist circumference={preferredWaist} />
          </div>
        </Overlay>
      )}
    </div>
  );
}
