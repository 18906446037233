import { APITypesV1 } from "@cur8/api-client";
import { fromAPI, ImmutableVisitSummary } from "@cur8/rich-entity";
import { BULLET_MARKER } from "lib/doctor-scribe/constants";
import { ScribeFlavor } from "lib/doctor-scribe/types";
import { MouseEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { PatientName } from "render/fragments/patient/PatientName";
import { SummaryEditor } from "render/fragments/visit/Editors/SummaryEditor";
import { usePatient } from "render/hooks/api/usePatient";
import { useFormHandle } from "render/hooks/useFormHandle";
import { useReporting } from "render/hooks/useReporting";
import { PageHeader } from "render/ui/layouts/PageHeader";
import { BackButtonClick } from "render/ui/trigger/BackButtonClick";
import { SubmitButton } from "render/ui/trigger/SubmitButton";
import { toImmutableVisitSections } from "../MemberSummaryCreate";
import { Divider } from "../MemberSummaryCreate/components/Divider";
import { Scribe } from "../MemberSummaryCreate/components/Scribe";
import LegacySummary from "./components/LegacySummary/LegacySummary";
import { Seen } from "./components/Seen/Seen";
import styles from "./styles.module.sass";

interface MemberSummaryEditProps {
  patientId: string;
  visitId: string;
  hide: (event: MouseEvent) => void;
}

const MIN_LENGTH = 5;

function hasContent(v: string | string[]) {
  if (typeof v === "string") {
    return v.length > MIN_LENGTH;
  } else {
    return v.every((item) => item.length > MIN_LENGTH);
  }
}

function areSummarySectionsEqual(
  a: APITypesV1.SummarySections,
  b: APITypesV1.SummarySections
): boolean {
  const arraysAreEqual = (arr1: string[], arr2: string[]): boolean => {
    return arr1.length !== arr2.length
      ? false
      : arr1.every((value, index) => value === arr2[index]);
  };

  return (
    a.introduction === b.introduction &&
    arraysAreEqual(a.heart, b.heart) &&
    arraysAreEqual(a.body, b.body) &&
    arraysAreEqual(a.skin, b.skin) &&
    arraysAreEqual(a.recommendations, b.recommendations)
  );
}

function fromImmutableVisitSections(
  sections: APITypesV1.ImmutableVisitSummarySection[]
): APITypesV1.SummarySections {
  return {
    introduction: sections.at(0)?.body || "",
    heart: sections.at(1)?.body.split(BULLET_MARKER).slice(1) || [""],
    body: sections.at(2)?.body.split(BULLET_MARKER).slice(1) || [""],
    skin: sections.at(3)?.body.split(BULLET_MARKER).slice(1) || [""],
    recommendations: sections.at(4)?.body.split(BULLET_MARKER).slice(1) || [""],
  };
}

export function MemberSummaryEdit({
  patientId,
  visitId,
  hide,
}: MemberSummaryEditProps) {
  const api = useAPIClient();
  const [currentSummary, setCurrentSummary] = useState<ImmutableVisitSummary>();
  const patient = usePatient(patientId);
  const [sections, setSections] = useState<APITypesV1.SummarySections>();
  const [isSent, setIsSent] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isInvalid, setIsInvalid] = useState(true);
  const [isLegacySummary, setIsLegacySummary] = useState(false);

  const { logError } = useReporting();

  useEffect(() => {
    api.immutableVisitSummary
      .fetchSummary({ patientId, visitId })
      .result.then((c) => {
        const current = fromAPI.toImmutableVisitSummary(c);
        if (c.summarySections.length === 0) {
          setIsLegacySummary(true);
          setCurrentSummary(current);
          return;
        }
        setCurrentSummary(current);
        setSections(fromImmutableVisitSections(c.summarySections));
      })
      .catch(logError);
  }, [api.immutableVisitSummary, logError, patientId, visitId]);

  const sentDate = useMemo(() => {
    if (currentSummary?.visitDate) {
      const d = currentSummary.visitDate;
      return d.isValid ? d.toFormat("dd LLL yyyy") : "-";
    } else {
      return "-";
    }
  }, [currentSummary]);

  const submit = useCallback(async () => {
    if (!sections) {
      return;
    }

    const immutableVisitSummarySections = toImmutableVisitSections(
      sections,
      currentSummary?.summaryLanguage || null
    );

    api.immutableVisitSummary
      .updateSummary(
        { patientId, visitId },
        {
          ...currentSummary,
          visitId,
          immutableVisitSummarySections,
        }
      )
      .result.catch(logError);

    setIsSent(true);
  }, [
    api.immutableVisitSummary,
    currentSummary,
    patientId,
    logError,
    sections,
    visitId,
  ]);

  const formHandle = useFormHandle(submit);

  useEffect(() => {
    const isChanged =
      sections &&
      currentSummary &&
      !isLegacySummary &&
      !areSummarySectionsEqual(
        fromImmutableVisitSections(currentSummary.summarySections),
        sections
      );
    const isValid =
      isChanged && !isSent && Object.values(sections).every(hasContent);
    setIsInvalid(!isValid);
  }, [currentSummary, isLegacySummary, sections, isSent]);

  return (
    <div
      className={styles.MemberSummaryEdit}
      data-drawervisible={drawerVisible}
    >
      <div className={styles.container} data-drawervisible={drawerVisible}>
        <BackButtonClick onClick={hide}>
          {patient ? <PatientName patient={patient} /> : "Back"}
        </BackButtonClick>
        <div className={styles.title}>
          <PageHeader caption="Visit Summary" />
          <div className={styles.rightTitle}>
            {currentSummary?.isSummarySeen && (
              <>
                <Seen seen={currentSummary?.isSummarySeen} />
                <Divider />{" "}
              </>
            )}
            <div className={styles.date}>
              <span>Sent on </span>
              {sentDate}
            </div>
          </div>
        </div>
        <Scribe
          patientId={patientId}
          visitId={visitId}
          showDetailsOnly={true}
          flavor={ScribeFlavor.None}
          currentEditorSections={sections}
          onFlavor={() => {}}
          onSummary={() => {}}
          onSummaryLanguage={() => {}}
          onScan={() => {}}
          onDrawer={setDrawerVisible}
        />
        <div className={styles.content} data-drawervisible={drawerVisible}>
          <form onSubmit={formHandle.onSubmit}>
            {isLegacySummary ? (
              <LegacySummary summaryText={currentSummary?.summaryText} />
            ) : (
              <SummaryEditor
                patientId={patientId}
                visitId={visitId}
                sections={sections}
                onChange={setSections}
                editorKey={""}
              />
            )}
            <div className={styles.submit}>
              <SubmitButton handle={formHandle} disabled={isInvalid}>
                Send updated summary
              </SubmitButton>
              {isLegacySummary && (
                <label>Updating legacy summaries not supported</label>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
