import { Patient, Slot } from "@cur8/rich-entity";
import { useRouter } from "@pomle/react-router-paths";
import { useCallback, useMemo } from "react";
import { DropdownItem } from "render/ui/trigger/DropdownMenuButton/components/DropdownItem";
import CalendarAdd from "render/ui/trigger/DropdownMenuButton/components/DropdownItem/assets/calendar-add.svg?react";
import { useDoubleBookingConfirmation } from "../../hooks/useDoubleBookingConfirmation";
import { usePatientBookingToken } from "../../hooks/usePatientBookingToken";
import { useCreateBookingTokenPopup } from "./hooks/useCreateBookingTokenPopup";
import { APITypesV1 } from "@cur8/api-client";
import { useDeviceStorage } from "render/hooks/deviceStorage/useDeviceStorage";
import { getPreferredCalendarEntry } from "render/hooks/deviceStorage/lib/calendarPreference";

interface Props {
  patient: Patient;
  upcomingAppointment?: Slot;
}

export function BookPatientButton({ patient, upcomingAppointment }: Props) {
  const { history } = useRouter();
  const { calendarSettings } = useDeviceStorage();
  const { emitDialog: emitCreateBookingTokenDialog } =
    useCreateBookingTokenPopup();

  const { emitDoubleBookingConfirmation } = useDoubleBookingConfirmation();

  const bookingToken = usePatientBookingToken(patient.patientId);

  const hasActiveBookingToken = useMemo(() => {
    if (!bookingToken.entries) {
      return false;
    }

    const openTokens = bookingToken.entries.filter(
      (token) => !token.data?.slotItemId
    );

    return openTokens.length > 0 || false;
  }, [bookingToken.entries]);

  const navigateToCalendar = useCallback(() => {
    history.push(
      getPreferredCalendarEntry(calendarSettings.data, {
        assignPatientId: patient.patientId,
        medicalExams: APITypesV1.MedicalExam.NekoBodyScan,
        censorAppointments: true,
      })
    );
  }, [calendarSettings.data, history, patient.patientId]);

  const checkForDoubleBooking = useCallback(() => {
    if (upcomingAppointment) {
      emitDoubleBookingConfirmation(
        patient,
        upcomingAppointment,
        navigateToCalendar
      );
      return;
    }

    navigateToCalendar();
  }, [
    upcomingAppointment,
    patient,
    emitDoubleBookingConfirmation,
    navigateToCalendar,
  ]);

  const onClickHandler = useCallback(() => {
    if (!hasActiveBookingToken) {
      emitCreateBookingTokenDialog(patient.patientId, checkForDoubleBooking);
      return;
    }

    checkForDoubleBooking();
  }, [
    checkForDoubleBooking,
    emitCreateBookingTokenDialog,
    hasActiveBookingToken,
    patient,
  ]);

  return (
    <DropdownItem onClick={onClickHandler}>
      <CalendarAdd />
      Book Premium Appointment
    </DropdownItem>
  );
}
