import { Table } from "render/ui/layouts/Table";
import { ResultRow } from "../ResultRow";
import { PatientEncyclopediaMetrics } from "../../hooks/usePatientEncyclopediaMetrics";
import { Country } from "lib/visit/visit";

interface LipidPanelTableProps {
  metrics: PatientEncyclopediaMetrics;
  country: Country | undefined;
}

export function LipidPanelTable({ metrics, country }: LipidPanelTableProps) {
  const bloodwork = metrics.bloodwork;

  return (
    <Table>
      <thead>
        <tr>
          <th>Lipid panel</th>
          <th>Result</th>
          <th>
            Risk {country != null ? <>(based on {country})</> : undefined}
          </th>
          <th>Units</th>
        </tr>
      </thead>
      <tbody>
        <ResultRow
          caption="Total Chol"
          value={bloodwork.cholesterol?.at(0)}
          format={(metric) => metric.unit["mmol/L"].toFixed(2)}
          unit="mmol/L"
          summarize={(metric) => {
            return {
              risk: metric.riskRanges.riskLevel,
            };
          }}
        />
        <ResultRow
          caption="LDL"
          value={bloodwork.ldl?.at(0)}
          format={(metric) => metric.unit["mmol/L"].toFixed(2)}
          unit="mmol/L"
          summarize={(metric) => {
            return {
              risk: metric.riskRanges.riskLevel,
            };
          }}
        />
        <ResultRow
          caption="HDL"
          value={bloodwork.hdl?.at(0)}
          format={(metric) => metric.unit["mmol/L"].toFixed(2)}
          summarize={(metric) => {
            return {
              risk: metric.riskRanges.riskLevel,
            };
          }}
          unit="mmol/L"
        />
        <ResultRow
          caption="Triglycerides"
          value={bloodwork.triglycerides?.at(0)}
          format={(metric) => metric.unit["mmol/L"].toFixed(2)}
          summarize={(metric) => {
            return {
              risk: metric.riskRanges.riskLevel,
            };
          }}
          unit="mmol/L"
        />
        <ResultRow
          caption="Non-HDL"
          value={bloodwork.nonHDL?.at(0)}
          format={(metric) => metric.unit["mmol/L"].toFixed(2)}
          summarize={(metric) => {
            return {
              risk: metric.riskRanges.riskLevel,
            };
          }}
          unit="mmol/L"
        />
        <ResultRow
          caption="Chol/HDL"
          value={bloodwork.cholesterolHDL?.at(0)}
          format={(metric) => metric.unit.fraction.toFixed(2)}
          summarize={(metric) => {
            return {
              risk: metric.riskRanges.riskLevel,
            };
          }}
        />
      </tbody>
    </Table>
  );
}
