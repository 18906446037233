import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_NORMAL,
  KEY_BACKSPACE_COMMAND,
} from "lexical";
import { useEffect } from "react";

export function LinkBackspacePlugin() {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerCommand(
      KEY_BACKSPACE_COMMAND,
      () => {
        const selection = $getSelection();
        if (!$isRangeSelection(selection)) {
          return false;
        }
        const anchorNode = selection.anchor.getNode();
        const isLink =
          $isLinkNode(anchorNode) ||
          Boolean(
            anchorNode.getParent() && $isLinkNode(anchorNode.getParent())
          );
        if (isLink) {
          editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
          return true;
        }
        return false;
      },
      COMMAND_PRIORITY_NORMAL
    );
  }, [editor]);

  return null;
}
