import { fromAPI } from "@cur8/rich-entity";
import { skipToken, useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { useAPIClient } from "render/context/APIContext";

export function useVisitQuery({
  patientId,
  visitId,
}: {
  patientId: string | undefined;
  visitId: string | undefined;
}) {
  const apiClient = useAPIClient();

  return useQuery({
    queryKey: queryKey(patientId, visitId),
    queryFn:
      patientId != null && visitId != null
        ? queryFn(apiClient, patientId, visitId)
        : skipToken,
  });
}

function queryFn(apiClient: APIClient, patientId: string, visitId: string) {
  return async function () {
    const visitDTO = await apiClient.visit.fetchVisit({
      patientId,
      visitId,
    }).result;

    const result = fromAPI.toVisit(visitDTO);

    return result;
  };
}

function queryKey(patientId: string | undefined, visitId: string | undefined) {
  return ["visit", patientId, visitId] as const;
}
